import React, {useState , useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Container, Row, Col, Dropdown, ListGroup, OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import {BsArrowLeft} from 'react-icons/bs';
import {FaExpand, FaEllipsisV , FaRegHeart} from 'react-icons/fa';
import { IoMdHeart } from "react-icons/io";
import { TbCalendarTime } from "react-icons/tb";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from 'moment';
import { toast} from "react-hot-toast";
import PageHeader from '../Header/HeaderTop';
import SidebarMenu from '../Sidebar/SidebarNav';
import { getSinglePost ,clearPostData,changePostStatus,} from '../../redux/actions/post.action';
import {decodedLoggedUser ,likePosts} from '../../helpers/auth';
import 'bootstrap/dist/css/bootstrap.min.css';
import './textStyle.css';
import '../../App.css';
import LightboxComponent from '../LightBoxComponent';
import LiveCommentsAnimation from '../Comments/LiveCommentsAnimation';
import LinkRender from '../Link/LinkRender';


const check = ['undefined' , undefined , null , 'null' , '']

function TextPostSingle(props) {
    const{slug} = useParams();
    const [loading , setLoading] = useState(true);
    const [singleText , setSingleText] = useState('');
    const [commentList , setCommentsList] = useState('');
    const [isLightBoxOpen , setLightBox] = useState(false);
    const [postMedia , setPostMedia] = useState('');
    const [userId , setUserId] = useState(''); 
    const [postNotFound , setPostNotFound] =  useState(false);
    const [hideSideBar , setHideSideBar] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const apiResult = useSelector(state => state.post);
    const apiError = useSelector(state => state.post);
    const auth = useSelector(state => state.auth);
    const postStatus= useSelector(state => state.post.changeStatusSuccess);
    const postStatusError = useSelector(state => state.post.changeStatusError);

    const [isBActive, setBActive] = useState("false");
    const handleShowToggle = () => {
        setBActive(!isBActive);
    };


    useEffect(()=>{
        if (auth.loggedIn) {
            let token = decodedLoggedUser();
            setUserId(token.aud)
        }
        dispatch(getSinglePost(slug));
        return(()=>{
            dispatch(clearPostData());
        })
    },[slug])

    useEffect(()=>{
        if(apiResult && apiResult?.singlePostSuccess){
            if(apiResult?.singlePostSuccess && apiResult?.singlePostSuccess.Post){
                setSingleText(apiResult?.singlePostSuccess?.Post)
                setLoading(false)
            }
            if(apiResult?.singlePostSuccess && apiResult?.singlePostSuccess.commentList){
                setCommentsList(apiResult?.singlePostSuccess.commentList);
                setLoading(false)
            }
        }
        
    },[apiResult])

    useEffect(()=>{
        if(apiError && apiError.singlePostError){
            setLoading(false);
            setPostNotFound(true);
        }
    },[apiError])


    const checkHTML = param => {
        return new RegExp(/(\<\w*)((\s\/\>)|(.*\<\/\w*\>))/gm).test(param)
    }


    const convertToPlainText = (html) => {
        const parser = new DOMParser();
        const parsedDocument = parser.parseFromString(html, 'text/html');
        return parsedDocument.body.textContent || '';
    };

    const handleChangePostStatus = (postId , status) =>{
        const data = {postId:postId , status:status}
        setLoading(true)
        dispatch(changePostStatus(data))
    }

    useEffect(()=>{
        if(postStatus && postStatus.success){
            toast.success(postStatus.message, {
                position: 'top-center', 
            });
            if( postStatus.message.trim() === 'Post deleted'){
                navigate('/')
            }else{
                navigate('/drafts')
            }

        }

    },[postStatus])


/*********    using for share link / copy link to clipboard  ****************************/   

    const handleCopy = () => {
       
        toast.success('Link copy to clipboard', {position: 'top-center'});
       
    };
/******************************************************************************************** */  


    const handleLike = async (data) => {
        // if(Number(singleText.user.id) !== Number(userId)){
            likePosts(userId, data.id)
        // }
    }

    const handleCloseLightBox = ()=>{
        setLightBox(false)
        setHideSideBar(false)
    }

    const handleLightBox = (media) =>{
        const data = [{src:media, alt: 'thumbnail'}];
        setPostMedia(data)
        setLightBox(true)
        setHideSideBar(true)
    }

    const updateCommentList = () =>{
        dispatch(getSinglePost(slug));
    }


    const handleLikeLength = (post) =>{
        if(post){
            const postUserId = post.user_id

            const userFound = post.Likes.some(like => Number(like.user_id) === Number(postUserId))

            if(userFound){
                if(post.Likes && post.Likes.length > 0){
                    const length =  post.Likes.length-1 ;
                    if(length === 0){
                        return null
                    }else{
                        return length
                    }
                }
            }else{
                return post.Likes.length ;
            }
        }
    }

    const convertLinksInHtml = (htmlString) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlString, 'text/html');
      
        // Function to recursively replace URLs in text nodes
        const replaceTextNodes = (node) => {
          node.childNodes.forEach(child => {
            if (child.nodeType === Node.TEXT_NODE) {
              const updatedText = child.textContent.replace(
                /(https?:\/\/[^\s]+|www\.[^\s]+|[^\s]+\.com[^\s]*)/g,
                (match) => `<a href="${match.startsWith('http') ? match : 'https://' + match}" target="_blank" style= background-color:#33365E>${match}</a>`
              );
              const newSpan = document.createElement('span');
              newSpan.innerHTML = updatedText;
              child.replaceWith(newSpan);
            } else if (child.nodeType === Node.ELEMENT_NODE) {
              replaceTextNodes(child); // Recursively process child elements
            }
          });
        };
      
        replaceTextNodes(doc.body);
        
        return doc.body.innerHTML; // Return the processed HTML as a string
    };




      

    return (
        <>
            {isLightBoxOpen && 
                <LightboxComponent
                    isOpen={isLightBoxOpen}
                    images={postMedia}
                    onClose={() => handleCloseLightBox()}
                />
            }
            <PageHeader />
            <div className='page--content'>
                {!hideSideBar &&
                    <SidebarMenu />
                }               
                <div className='discover--module common--wrapper visual--single--page'>
                    <Container>
                        <Row>
                            <Col sm={12}>
                                <Link className='back--btn' onClick={()=>navigate(-1)}><BsArrowLeft /> Back</Link>
                            </Col>
                        {!loading  ? 
                            !check.includes(singleText) ?
                                <Col sm={12} lg={8} className=' mx-auto'>
                                    {(Number(userId) !== Number(singleText.user.id) && singleText.user.booked_schedulers && singleText.user.booked_schedulers.length > 0)&&
                                        <div className='calendar--icon'>
                                            <OverlayTrigger
                                                key='top'
                                                placement='top'
                                                overlay={<Tooltip id={`tooltip-top`}>Schedule a Call</Tooltip>}
                                                >
                                                <Button variant='primary'><TbCalendarTime onClick={()=>navigate(`/booking/${singleText.user.id}`)}/></Button>
                                            </OverlayTrigger>
                                        </div>
                                    }
                                    <div className='single--bg--col'>
                                        <div className='visual--user'>
                                            <div className='visual--user--name Gr--streamDesc'>
                                                <Link to={`/${singleText.user.username}`}>
                                                    <img src={(singleText.user && singleText.user.image)? singleText.user.image :'/images/user.png'} alt='...'/>
                                                </Link>    
                                                    {/* <h5></h5> */}
                                                <div className="Gr--streamUser">
                                                    <Link to={`/${singleText.user.username}`}>
                                                        <h4>{singleText.user.first}</h4>
                                                    </Link>
                                                    <p>{moment(singleText.createdAt).format('LL')}</p>
                                                </div>
                                                { (singleText.user && userId == singleText.user.id)
                                                    ?
                                                        <Dropdown className='edit--post--dropdown'>
                                                            <Dropdown.Toggle id="dropdown-basic">
                                                                <FaEllipsisV />
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                <Link className='dropdown-item' to={`/edit-text/${singleText.slug}`}>Edit</Link>
                                                                <Dropdown.Item>
                                                                    <CopyToClipboard text={window.location.href} onCopy={()=>{handleCopy()}}><p>Share Link</p></CopyToClipboard>   
                                                                </Dropdown.Item>
                                                                <Link className='dropdown-item' onClick={()=>handleChangePostStatus(singleText.id , '2')}>Move to Drafts</Link>
                                                                <Link className='dropdown-item' onClick={()=>handleChangePostStatus(singleText.id , '0')}>Delete</Link>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    : null    
                                                }  
                                            </div>
                                        </div>
                                        <div className='visual--desc textSingle--desc'>
                                            <h2 className='mt-3'>{checkHTML(singleText.name) ? convertToPlainText(singleText.name) : singleText.name}</h2>
                                            {checkHTML(singleText.description) 
                                                ? 
                                                    <div  className='mb-0'
                                                        style={{ wordWrap: 'break-word' }} 
                                                        // dangerouslySetInnerHTML={{ __html: singleText.description }} 
                                                        dangerouslySetInnerHTML={{ __html: convertLinksInHtml(singleText.description) }}
                                                    />
                                                    
                                                :
                                                    <p className='mb-0'>{singleText.description}</p>
                                            }
                                            {singleText.media !=='' ? 
                                                <LinkRender post= {singleText}/>
                                                : null
                                            }
                                        </div>
                                        
                                            {!check.includes(singleText.thumbnail) &&
                                                <div className='visual--block textSingle--block my-4'>
                                                    <img src={singleText.thumbnail} onClick={()=>handleLightBox(singleText.thumbnail)} alt='' />
                                                    <span className='fullscreen--icon' onClick={()=>handleLightBox(singleText.thumbnail)}><FaExpand/></span>
                                                </div>
                                            }    
                                        
                                        <div className='textSingleLike'>
                                            <ListGroup className='mb-2'>
                                                {(auth.loggedIn)&&
                                                    <ListGroup.Item onClick = {()=>handleLike(singleText)}>
                                                        {(singleText.Likes && singleText.Likes.length > 0) && <span className='me-2'>{handleLikeLength(singleText)}</span>}
                                                        {singleText?.Likes?.some(like => like?.user_id === userId) ? (
                                                            <IoMdHeart />) : (<FaRegHeart /> )}
                                                    </ListGroup.Item>
                                                }
                                            </ListGroup>
                                        </div>
                                    </div>
                                </Col>
                            :  <h2 className="mt-5 text-center">Posts Not Found</h2>    
                        :
                            <Col sm={12}>
                                <div className='text-center spinner--loader'></div>
                            </Col>    
                        }    
                        <Col sm={12} lg={8} className='mt-4 mx-auto'>
                            {singleText.commenting && 
                                <LiveCommentsAnimation updateComment={()=>updateCommentList()} postId = {singleText}/>
                            }
                        </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    );
}

export default TextPostSingle;