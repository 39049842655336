import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Row, Col, ListGroup, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { BsCardList, BsGridFill } from 'react-icons/bs';
import { toast, Toaster } from "react-hot-toast";
import InfiniteScroll from 'react-infinite-scroller';
import { getProfilePosts } from '../../../redux/actions/user.action';
import { decodedLoggedUser, likePosts } from '../../../helpers/auth';
import { changePostStatus } from '../../../redux/actions/post.action';
import StreamDesignView from '../../DesignView/StreamDesignView';
import { withRouter } from '../../../wrapper/withRouter';
import { TbCalendarTime } from "react-icons/tb";


class ProfilePostsTab extends Component {
    constructor(props) {
        super(props)
        this.state = {
            profilePostList: [],
            profilePostMeta: '',
            loading: false,
            page: 1,
            perPage: 3,
            loadingState: false,
            isActive: false,
            userId: this.props.user.id,
            logggedUser: false,
            status: '1'
        }
    }


    componentDidMount = async () => {
        if (this.props.auth.loggedIn) {
            let token = decodedLoggedUser()
            this.setState({ logggedUser: token.aud })
        }
        await this.getProfilePostList();
    }


    UNSAFE_componentWillReceiveProps = async nextProps => {
        if (this.props !== nextProps) {
            var { profilePost } = nextProps;
            if (profilePost && profilePost.profilePostList) {
                if (profilePost.profilePostList.peofilePost && profilePost.profilePostList.peofilePost.length > 0) {
                    this.setState({ profilePostList: profilePost.profilePostList.peofilePost })
                }
            }
        }
    }


    loadMoreItems() {
        if (this.state.loadingState) {
            return;
        } else {
            this.getProfilePostList();
        }
    }

    getProfilePostList = async () => {
        const { page, perPage, profilePostList, userId } = this.state;
        const data = { userId: userId, perPage: perPage, page: page };
        if (this.state.page == 1) {
            this.setState({ loading: true })
        }
        await this.props.getProfilePosts(data);
        if (this.props.profilePost && this.props.profilePost.success) {
            var posts = profilePostList;
            Array.prototype.push.apply(posts, this.props.profilePost.profilePostList.peofilePost);
            posts = posts.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i)
            this.setState({
                profilePostList: posts,
                loading: false,
                loadingState: false,
                profilePostMeta: this.props.profilePost.profilePostList.prfilePostMeta,
            })
            let totalPages = this.props.profilePost.profilePostList.prfilePostMeta
            if (posts.length !== totalPages) {
                this.setState({ page: page + 1 })
            } else {
                this.setState({ loadingState: true })
            }
        }
        if (this.props.profilePostError && this.props.profilePostError.message) {
            toast.error(this.props.profilePostError.message, {
                position: 'top-center',
            });
            this.setState({ loading: false })
        }
        this.setState({ loading: false })

    }

    handleToggle = () => {
        this.setState({ isActive: !this.state.isActive })
    }


    renderProfilePosts = () => {
        const { profilePostList } = this.state;
        if (profilePostList && profilePostList.length > 0) {
            return (
                <>
                    {profilePostList.map((item, idx) => {
                        if (item.PostCategory) {
                            return (
                                <StreamDesignView
                                    post={item}
                                    userId={this.state.logggedUser}
                                    copyText={this.copyText}
                                    handleChangePostStatus={this.handleChangePostStatus}
                                    handleCommentsLength={this.handleCommentsLength}
                                    handleLike={this.handleLike}
                                    isActive={this.state.isActive}
                                />
                            )
                        }

                    })}
                </>
            )
        }

    }

    handleCommentsLength = (comments) => {
        if (comments && comments.length > 0) {
            const validComments = comments.filter((item, index) => {
                if (item.status === '1') {
                    if (item.comment_id === null) {
                        return item
                    } else {
                        const reply = comments.find((c) => Number(c.id) === Number(item.comment_id));
                        if (reply && reply.status === '1') {
                            return item
                        }
                    }
                }
            });
            if (validComments && validComments.length > 0) {
                return (
                    <small className='ms-2'>{validComments.length}</small>
                )
            }

        }
    }

    handleLike = async (data) => {
        const { userId, logggedUser } = this.state;
        if (logggedUser) {
            likePosts(logggedUser, data.id)

        }
    }

    handleCopy = (post) => {
        if (post.PostCategory.slug === 'text') {
            let link = `${window.location.origin}/text/${post.slug}`;
            return link

        }
        if (post.PostCategory.slug === 'film') {
            let link = `${window.location.origin}/video/${post.slug}`;
            return link

        }
        if (post.PostCategory.slug === 'audio') {
            if (post.Postmeta.length) {
                let link = `${window.location.origin}/audio/album/${post.slug}`;
                return link

            }
            else {
                let link = `${window.location.origin}/audio/single/${post.slug}`;
                return link
            }
        }
        if (post.PostCategory.slug === 'visual') {
            let link = `${window.location.origin}/visual/${post.slug}`;
            return link

        }
        if (post.PostCategory.slug === 'link') {
            let link = post.media
            return link
        }

    }

    copyText = () => {
        toast.success('Link copy to clipboard', { position: 'top-center' });
    }



    handleChangePostStatus = async (postId, status) => {
        const data = { postId: postId, status: status }
        await this.props.changePostStatus(data)
        if (this.props.postStatus && this.props.postStatus.success) {
            this.setState({ status: status })
            toast.success(this.props.postStatus.message, {
                position: 'top-center',
            });
            if (status === '0') {
                this.props.navigate('/')
            }
            if (status === '2') {
                this.props.navigate('/drafts')
            }
        }
        if (this.props.postStatusError && this.props.postStatusError.message) {
            toast.error(this.props.postStatusError.message, {
                position: 'top-center',
            });
        }
    }


    componentDidUpdate(prevProps, prevState) {
        if (prevState.status !== this.state.status) {
            this.setState({
                profilePostList: [],
                profilePostMeta: '',
                loading: false,
                page: 1,
                perPage: 10,
                loadingState: false,
                status: '1'
            }, () => this.getProfilePostList())
        }
    }

    render() {
        const { loading, profilePostList, loadingState, isActive, page, userId, isBActive } = this.state;

        return (
            <>

                <Row className='mb-3 align-items-center'>
                    {/* <Col>
                        <Button variant='secondary'><Link to='/create'><BiPlus /> Create</Link></Button>
                    </Col> */}
                    <Col>
                        <ListGroup as="ul" horizontal>
                            {/* <ListGroup.Item className='p-0'>
                                <div className='calendar--icon mb-0'>
                                    <OverlayTrigger
                                        key='top'
                                        placement='top'
                                        overlay={<Tooltip id={`tooltip-top`}>Schedule a Call</Tooltip>}
                                    >
                                        <Button variant='primary'><TbCalendarTime /></Button>
                                    </OverlayTrigger>
                                </div>
                            </ListGroup.Item> */}
                            <ListGroup.Item as="li" className={isActive ? 'inactive ms-auto' : 'active ms-auto'} onClick={this.handleToggle}><BsGridFill /></ListGroup.Item>
                            <ListGroup.Item as="li" className={isActive ? 'active' : 'inactive'} onClick={this.handleToggle}><BsCardList /></ListGroup.Item>
                        </ListGroup>
                    </Col>
                </Row>
                <Row className={isActive ? 'grid--view' : 'list--view'}>
                    {!loading ?
                        profilePostList.length > 0

                            ?

                            <InfiniteScroll
                                pageStart={0}
                                loadMore={() => this.loadMoreItems()}
                                hasMore={!loadingState}
                                loader={
                                    <Col sm={12}>
                                        <div className='text-center spinner--loader'></div>
                                    </Col>
                                }
                                scrollableTarget="scrollableDiv"
                                getScrollParent={() => this.scrollParentRef}
                                window={false}
                            >
                                <Col sm={12}>
                                    <Row className={isActive ? 'justify-content-start threecols' : 'justify-content-center'}>
                                        {this.renderProfilePosts()}
                                    </Row>
                                </Col>
                            </InfiniteScroll>
                            : <h2 className="mt-5 text-center">Posts Not Found</h2>

                        : <Col>
                            <div className='text-center spinner--loader'></div>
                        </Col>
                    }
                    <Toaster />
                </Row>


            </>
        );
    }
}

const maopStateToProps = (state) => {
    return {
        profilePost: state.user.userPosts,
        profilePostError: state.post.userPostError,
        auth: state.auth,
        postStatus: state.post.changeStatusSuccess,
        postStatusError: state.post.changeStatusError,
    }

}

const dispatchActions = { getProfilePosts, changePostStatus };

export default connect(maopStateToProps, dispatchActions)(withRouter(ProfilePostsTab));

