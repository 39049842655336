import { 
    VIDEO_PLAYER_SUCCESS ,
    CLEAR_VIDEO_PLAYER,
    VIDEO_TIMESTAMP,
} from "../actions/types";

const initialState = {
    video: null,
};


export default (state = initialState, action) => {
    switch (action.type) {
        case VIDEO_PLAYER_SUCCESS:
            return{
                video :action.payload
            }
        case CLEAR_VIDEO_PLAYER:
            return{
                video :null , 
                timeStamp: null
            }
        case VIDEO_TIMESTAMP:
            return{
                ...state,
                timeStamp:action.payload
            }
        default: return state;        
    }
}