import React, { useEffect, useState } from "react";
import { Button, Row, Col, Form , Spinner} from 'react-bootstrap';
import { FaCogs } from 'react-icons/fa';
import {useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { EditorState, convertToRaw, getDefaultKeyBinding , ContentState} from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { notValid} from "../../../utils/validations";
import { toast} from "react-hot-toast";
import ImageCropper from "./imageCropper";
import { createVisualPost } from "../../../redux/actions/visualPost.action";
import {IoClose} from 'react-icons/io5';
import { clearVisualCreate } from "../../../redux/actions/visualPost.action";



function CreateAlbumPhoto({savedData , saveDataToForm}) {
    const intialValue = {name:'' , description: '',isStream: true,  commenting: true , board_name: '', board_id: '', images:[]};
    const [visualFields , setVisualFields] = useState (intialValue) ;
    const [boardList , setBoardList] = useState('')
    const [loading , setLoading] = useState(false) ; 
    const [draftLoading , setDraftLoading] = useState(false)
    const [errors , setErrors] = useState(false);
    const [ isCropperOpen, setCropperOpen ] = useState(false);
    const [ imageForCrop, setImageForCrop ] = useState(null);
    const [draft , setDraft] = useState(false);
    const [visual , setVisual] = useState(false);
    const [selectThumbnail , setSelectThumbnail] = useState(visualFields.images[0] || 0);
    const [showEditor , setShowEditor] = useState(true);
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const apiResult = useSelector(state => state.visualPost);
    const getPostResult = useSelector(state => state.post);


    useEffect(()=>{
        dispatch(clearVisualCreate());
    },[])

    useEffect(()=>{
        if(getPostResult && getPostResult.boardPostList){
            setBoardList(getPostResult.boardPostList.boardPosts)
        }
    },[getPostResult])


    useEffect(() => {
        if (savedData.album) {
            setVisualFields(savedData.album);
            if(savedData.album.description){
                const blocksFromHtml = htmlToDraft(savedData.album.description);
                const { contentBlocks, entityMap } = blocksFromHtml;
                const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
                const editorState = EditorState.createWithContent(contentState);
                setEditorState(editorState)
            }
        }
    }, [savedData]);




    const handleInput = (e) =>{
        const {value , name } = e.target ; 
        setVisualFields({...visualFields, [name]: value })
        setErrors({ ...errors, [name]: '' })

    }

    const handleCommenting = (e) =>{
        const copyVisualFields = {...visualFields} ;
        copyVisualFields.commenting = e.target.checked
        setVisualFields(copyVisualFields)
    }

    const validate = () =>{
        let isValid = true ;
        let errorMessages = {};
        let desc = checkHTML(visualFields.description) ? convertToPlainText(visualFields.description) : visualFields.description
        if (notValid(desc)) {
            errorMessages['description'] = 'Description is required'
            isValid = false;
        }
        if (notValid(visualFields.name)) {
            errorMessages['name'] = 'Title is required'
            isValid = false;
        }
        if(visualFields.images.length < 1){
            errorMessages['images'] = 'please upload an image'
        }

        if (!isValid) {
            setErrors({ ...errors, ...errorMessages });
        }
        return isValid;

    }



    const checkHTML = param => {
        return new RegExp(/(\<\w*)((\s\/\>)|(.*\<\/\w*\>))/gm).test(param)
    }


    const convertToPlainText = (html) => {
        const parser = new DOMParser();
        const parsedDocument = parser.parseFromString(html, 'text/html');
        return parsedDocument.body.textContent || '';
    };

    const handleImages = (e) =>{
        const files = e.target.files ;
        const check = ['image/jpeg', 'image/png' , 'image/jpg' , 'image/gif' ];
        let errorMessages = {...errors};
        if(files && visualFields['images'].length < 12){
            let copyVisualFields =  {...visualFields}
            Array.from(files).forEach((file) => {
                if (check.includes(file.type)) {
                    copyVisualFields['images'].push(file)
                    setVisualFields(copyVisualFields)
                    errorMessages['images'] = '' ;
                    setErrors(errorMessages)
                }else{
                    errorMessages['images'] = ' Please use only  png, jpg, jpeg, JPG, JPEG, PNG'
                    setErrors(errorMessages)
                }
            });
            
        }
    }

    const handleRemoveFiles = (index) => {
        let copyVisualFields =  {...visualFields}
        copyVisualFields['images'].splice(index, 1);
        setVisualFields(copyVisualFields)
    }


    const handleCreateVisualPost = async(draft) => {
        const copyVisualFields = {...visualFields}
        if(validate()) {
            setLoading(true)
            if(draft){
                copyVisualFields.status = '2'
                setLoading(false)
                setDraftLoading(true)
                setDraft(true)
            }else{
                setLoading(true)
                setDraftLoading(false)
                setVisual(true)
            }
            copyVisualFields.thumbnail = copyVisualFields.images[selectThumbnail] ; 
            const formData = new FormData();
            for (const [key, value] of Object.entries(copyVisualFields)) {
                if (typeof value === 'object' && key === 'images') {
                  value.forEach(element => {
                    formData.append('images', element);
                  });
                } else {
                  formData.append(key, value)
                }   
            }
            dispatch(createVisualPost(formData)) ;
        }
    }

    useEffect(()=>{
        if(apiResult && apiResult.createVisualSuccess){
            setLoading(false)
            setDraftLoading(false)
            if(draft){
                navigate('/drafts')
                setLoading(false)
                setDraftLoading(false)
            }
            if(visual){
                navigate('/stream')
                setLoading(false)
                setDraftLoading(false)
            }
        }    
        if(apiResult && apiResult.visualPostError){
            setLoading(false)
            setDraftLoading(false)
            toast.error(apiResult.visualPostError.message, {
                position: 'top-center', 
            });
        }
        setLoading(false)
        setDraftLoading(false)
    },[apiResult])


    const handleCloseCropper = () => {
        setImageForCrop(null)
        setCropperOpen(false)
    }


    const dataURItoBlob = (dataURI) => {
        // convert base64/URLEncoded data component to raw binary data held in a string
        var byteString;
        if (dataURI.split(',')[0].indexOf('base64') >= 0)
            byteString = atob(dataURI.split(',')[1]);
        else
            byteString = unescape(dataURI.split(',')[1]);
    
        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    
        // write the bytes of the string to a typed array
        var ia = new Uint8Array(byteString.length);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        
        return new Blob([ia], {type:mimeString});
    }


    const handeSaveCroppedImage = (dataURI) => {
        const imageFile = dataURItoBlob(dataURI);
        let copyVisualFields = {...visualFields}
        copyVisualFields['images'][imageForCrop.index] = imageFile
        setVisualFields(copyVisualFields)
    }


    const handleOpenCropper = (ind) => {
        setCropperOpen(true)
        let data = {
            index: ind,
            src: URL.createObjectURL(visualFields['images'][ind])
        }
        setImageForCrop(data)
    }



/**********************    handle description  /////   text editor  function using draft.js for text editing        ******/

    const onEditorStateChange = (editorState) => {
        const copyVisualFields = {...visualFields} ;
        let errorMessages = {...errors};
        const rawContentState = convertToRaw(editorState.getCurrentContent());
        const description = draftToHtml(rawContentState);
        copyVisualFields.description = description
        errorMessages.description = '' ;
        setVisualFields(copyVisualFields)
        setErrors(errorMessages)
        setEditorState(editorState);
    };


    const myKeyBindingFn = event => {
        return getDefaultKeyBinding(event);
    }
/**************************************************************************************************************************** */
    const selectBoard = () => {
        if(boardList && boardList.length > 0){
            return (
                <Form.Group className='form-group col-sm-12'>
                    <Form.Label>Realm</Form.Label>
                    <Form.Control value={visualFields.board_id || '' } onChange= {handleBoard} as="select">
                        <option selected>Select</option>
                        {boardList.map((item , idx)=>{
                            return (
                                <option key={idx} value={item.id}>{item.name}</option>
                            )
                        })}
                    </Form.Control>
                </Form.Group>

            )
        }
    }

    const handleBoard = (e)=>{
        let copyVisualFields = {...visualFields};
        copyVisualFields.board_id = e.target.value
        setVisualFields(copyVisualFields)
    }

    const updateParentState = () => {
        saveDataToForm( 'album' , visualFields);
    };

    return (
        <div className='create--form'>
            <Form onBlur={updateParentState}>
                <Row>
                    <Form.Group className='form-group col-sm-12'>
                        <Form.Label>Title</Form.Label>
                        <Form.Control type="text" name = 'name' value = {visualFields.name} onChange={handleInput} required />
                        {errors.name && <small className="error error-massege text-danger">{errors.name}</small>}
                    </Form.Group>
                    {(visualFields && visualFields.images.length > 0) &&  
                        <Form.Group className='form-group col-sm-12 post--thumb--upload post--album--grid'>
                            {visualFields.images.map((files, filesIndex) => {
                                return (
                                    <div className="album--upload">
                                        <img key={filesIndex} src={typeof files === 'object' ? URL.createObjectURL(files) : files}/>
                                        <span className="cerificate--files--close" onClick={() => handleRemoveFiles(filesIndex)}><IoClose/></span>
                                        <span className="post--media--edit" onClick={() => handleOpenCropper(filesIndex)}>Edit</span>
                                        <div className="use--thumbnail">
                                            <input id={filesIndex} type="checkbox" name="selectThumbnail" checked={selectThumbnail === filesIndex} onChange={() => setSelectThumbnail(filesIndex)} />
                                            <label htmlFor={filesIndex}>Use as thumbnail</label>
                                        </div>
                                    </div>
                                )
                            })}
                        </Form.Group>
                    }
                    <Form.Group className='form-group col-sm-12'>
                        <Form.Label>Upload Images</Form.Label>
                        <input type='file' hidden id="upload--header-multiple-images"  multiple onChange={handleImages} />
                        <label className='label--upload' htmlFor="upload--header-multiple-images" >Choose Files</label>
                        {errors.images && <small className="error error-massege text-danger">{errors.images}</small>}
                    </Form.Group>
                    <Form.Group className='form-group col-sm-12'>
                        <span className='editor--tool' onClick= {()=>setShowEditor(!showEditor)}><FaCogs /></span>
                    </Form.Group>
                    <Form.Group className='form-group col-sm-12 text-title-post'>
                        <Form.Label>Description</Form.Label>
                        <Editor
                            editorState={editorState}
                            wrapperClassName="demo-wrapper editor-w"
                            editorClassName="demo-editor input"
                            onEditorStateChange={onEditorStateChange}
                            keyBindingFn={myKeyBindingFn}
                            hashtag={{
                                separator: ' ',
                                trigger: '#',
                            }}
                            toolbarHidden={showEditor}
                            config={{ link: { trailingWhitespace: true } }}
                        />
                        {errors.description && <small className="error error-massege text-danger">{errors.description}</small>}
                    </Form.Group>
                    {selectBoard()}
                    <Form.Group className='form-group col-sm-12'>
                        <Form.Check type="checkbox" label="Allow Comments" id= 'commentCheck' checked={visualFields.commenting} onChange={handleCommenting}/>
                        <Form.Label htmlFor="commentCheck" onClick={handleCommenting}/>
                    </Form.Group>
                    <Col sm={12} className='text-end'>
                        <Button variant="secondary"  disabled = {loading || draftLoading} className='me-2' onClick={()=>handleCreateVisualPost(true)}>
                            {draftLoading ?  <> <Spinner animation="border" size="sm"/> Please wait </> : 'Save To Draft '}
                        </Button>
                        <Button variant="primary" disabled = {loading || draftLoading} onClick={()=>handleCreateVisualPost(false)}>
                            {loading ?  <> <Spinner animation="border" size="sm"/> Please wait </> : 'Publish Now'}
                        </Button>
                    </Col>
                </Row>
            </Form>
            {isCropperOpen && 
                <ImageCropper
                    isOpen={isCropperOpen}
                    src={imageForCrop.src}
                    onClose={() => handleCloseCropper()}
                    result={(val) => handeSaveCroppedImage(val)}
                />
            }
        </div>
    );
}

export default CreateAlbumPhoto;