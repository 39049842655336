import React, { Component} from 'react'
import { connect } from 'react-redux';
import { Container, Row, Col,ListGroup} from 'react-bootstrap';
import { BsCardList, BsGridFill} from 'react-icons/bs';
import PageHeader from '../Header/HeaderTop';
import SidebarMenu from '../Sidebar/SidebarNav';
import { toast} from "react-hot-toast";
import InfiniteScroll from 'react-infinite-scroller';
import { getAudioPostList } from '../../redux/actions/audioPost.action';
import { changePostStatus } from '../../redux/actions/post.action';
import {decodedLoggedUser , likePosts} from '../../helpers/auth';
import StreamDesignView from '../DesignView/StreamDesignView';
import 'bootstrap/dist/css/bootstrap.min.css';
import './audioStyle.css';
import '../../App.css';
import { withRouter } from '../../wrapper/withRouter';

class AudioPostsMain extends Component{
    constructor(props){
        super(props)
        this.state =  {
            audioPostList : [] ,
            audioPostMeta : '',
            loading : false , 
            page : 1 ,
            perPage : 10 ,
            loadingState : false ,
            isActive : true ,
            userId : '' ,
            status :'1'
        }
    }

    componentDidMount = async() => {
        if (this.props.auth.loggedIn) {
            let token = decodedLoggedUser()
            this.setState({userId:token.aud})
        }
        await this.getAudioPosts();
    }

    loadMoreItems() {
        if (this.state.loadingState) {
            return;
        } else {
            this.getAudioPosts();
        }   
    }

    getAudioPosts = async() =>{
        const {perPage , page , audioPostList} = this.state ;
        const data = {status:'1' , perPage:perPage , page:page};
        if (this.state.page == 1) {
            this.setState({ loading: true })
        }
        await this.props.getAudioPostList(data);
        if(this.props?.audioPost && this.props?.audioPost?.success){
            var posts =  audioPostList ;
            Array.prototype.push.apply(posts, this?.props?.audioPost?.audioPostList?.audioPost);
            posts = posts.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i)
            this.setState({
                audioPostList : posts,
                loading: false,
                loadingState: false,
            })
            let totalPages = this.props.audioPost.audioPostList.audioPostPostMeta ;
            this.setState({audioPostMeta:totalPages})
            if(posts.length !== totalPages){
                this.setState({page: page+1})
            }else{
                this.setState({ loadingState: true })
            }
            if(this.props.audioPostError && this.props.audioPostError.message){
                toast.error(this.props.audioPostError.message , {
                    position: 'top-center', 
                });
                this.setState({ loading: false })
            }
        }
    }

    handleToggle = () =>{
        this.setState({isActive : !this.state.isActive})
    }



    handleCommentsLength = (comments) =>{
        if(comments && comments.length > 0){
            const validComments = comments.filter((item , index)=>{
                if(item.status === '1'){
                    if(item.comment_id === null){
                        return item
                    }else{
                        const reply = comments.find((c) => Number(c.id) === Number(item.comment_id));
                        if(reply && reply.status === '1'){
                            return item
                        }
                    }
                }
            });
            if(validComments && validComments.length > 0){
                return (
                    <small className='ms-2'>{validComments.length}</small>
                )
            }

        }
    }

    audioPosts = () =>{
        const {audioPostList , isActive , userId} = this.state 
        if(audioPostList && audioPostList.length > 0){
            return (
                <Col sm={12}>
                    <Row className={isActive ? 'justify-content-start grid--view' : 'justify-content-center list--view'}>
                    {
                        audioPostList.map((item , idx)=>{
                            let url = '';
                            let title = ''
                            if (item.Postmeta.length) {
                                url = `/audio/album/${item.slug}`;
                                title = item.Postmeta
                            }
                            else {
                                url = `/audio/single/${item.slug}`;
                            }
                            if(item.user){
                                return(
                                    <>
                                        
                                        <StreamDesignView
                                            post={item}
                                            userId = {this.state.userId}
                                            copyText = {this.handleCopy}
                                            handleChangePostStatus = {this.handleChangePostStatus}
                                            handleCommentsLength = {this.handleCommentsLength}
                                            handleLike = {this.handleLike}
                                            isActive = {isActive}
                                        />
                                    </>
                                    
                                )
                            }    
                        })
                    }
                    </Row>
                </Col>

            )
        }
    }

    handleChangePostStatus = async(postId , status) =>{
        const data = {postId:postId , status:status}
        await this.props.changePostStatus(data)
        if(this.props.postStatus && this.props.postStatus.success){
            this.setState({status : status})
            toast.success(this.props.postStatus.message, {
                position: 'top-center', 
            });
            if( status === '0'){
                this.props.navigate('/')
            }
            if(status === '2'){
                this.props.navigate('/drafts')
            }
        }
        if(this.props.postStatusError && this.props.postStatusError.message){
            toast.error( this.props.postStatusError.message, {
                position: 'top-center', 
            });
        }
    }

    componentDidUpdate(prevProps, prevState){
        if(prevState.status !== this.state.status){
            this.setState({
                audioPostList : [] ,
                audioPostMeta : '',
                loading : false , 
                page : 1 ,
                perPage : 10 ,
                loadingState : false ,
                status: '1'
            },()=>this.getAudioPosts())
        }

        if(prevState.isActive !== this.state.isActive){
            this.setState({
                audioPostList : [] ,
                audioPostMeta : '',
                loading : false , 
                page : 1 ,
                perPage : 10 ,
                loadingState : false ,
                status: '1'
            },()=>this.getAudioPosts())

        }
    }


/*********    using for share link / copy link to clipboard  ****************************/   
    handleCopy = () => {
       
        toast.success('Link copy to clipboard', {position: 'top-center'});
       
    };
/******************************************************************************************** */    


    handleLink = (post) =>{
        if(post.Postmeta.length){
            let link = `${window.location.origin}/audio/album/${post.slug}`;
            return link
        }
        else {
            let link = `${window.location.origin}/audio/single/${post.slug}`;
            return link
        }
        
    }


    handleLike = (data) => {
        // if(Number(data.user.id) !== Number(this.state.userId)){
            likePosts(this.state.userId, data.id)
        // }
    }
   
    render(){
        const {loading , audioPostList , loadingState , isActive , page , visualPostMeta} = this.state ;
        return (
            <>
                <PageHeader />
                <div className='page--content'>
                    <SidebarMenu />
                    <div className='discover--module common--wrapper audio--main--page'>
                        <Container>
                            <ListGroup as="ul" horizontal>
                                <ListGroup.Item as="li" className={isActive ? 'inactive' : 'active'}  onClick={this.handleToggle}><BsGridFill /></ListGroup.Item>
                                <ListGroup.Item as="li" className={isActive ? 'active' : 'inactive'}  onClick={this.handleToggle}><BsCardList /></ListGroup.Item>
                            </ListGroup>
                            <Row id="scrollableDiv" ref={(ref) => this.scrollParentRef = ref} style={{ height: "100%" }}>
                                {!loading  ?
                                    (audioPostList && audioPostList.length > 0) ?
                                        <InfiniteScroll
                                            pageStart={0}
                                            loadMore={() => this.loadMoreItems()}
                                            hasMore={!loadingState}
                                            loader={
                                                <Col sm={12}>
                                                    <div className='text-center spinner--loader'></div>
                                                </Col>
                                            }
                                            scrollableTarget="scrollableDiv"
                                            getScrollParent={() => this.scrollParentRef}
                                            window={false}
                                        >
                                    
                                        {this.audioPosts()}
                                        </InfiniteScroll>    

                                    :   <h2 className="mt-5 text-center">Posts Not Found</h2>        
                                :
                                    <Col sm={12}>
                                        <div className='text-center spinner--loader'></div>
                                    </Col>
                                }
                            </Row>    
                        </Container>
                    </div>
                </div>
            </>
        );
    }
}

const maopStateToProps = (state) => {
    return{
        audioPost : state.audioPost.getAudioPostList,
        audioPostError : state.audioPost.audioPostError,
        auth : state.auth,
        postStatus : state.post.changeStatusSuccess,
        postStatusError: state.post.changeStatusError
    }    

}
const dispatchActions = {getAudioPostList , changePostStatus} ; 

export default connect(maopStateToProps, dispatchActions)(withRouter(AudioPostsMain));