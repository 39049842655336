import { 
    INVITED_USER_SUCCESS,
    GET_CHAT_USERS_SUCCESS,
    SET_CURRENT_CHAT_USER,
    NEW_MESSAGE,
    UPDATE_MESSAGES,
    UNSEEN_COUNT,
    LOAD_CHAT_MESSAGE_SUCCESS,
    UPDATE_CHAT_USERS ,
    SENDER_UNSEEN_COUNT
} from "../actions/types";

import { errorReducer } from "./error.reducer";
import { decodedLoggedUser } from "../../helpers/auth";

const initialState = {
    invited_users: [],
    chat_users:{},
    current_chat: 0,
    unseen_count: 0,
    chat_messages: [],
    more_messeges: [], 
    senderUnseenCount:[],
};


export default (state = initialState, action) => {
    switch (action.type) {
        case INVITED_USER_SUCCESS:
            return {
                ...state,
                invited_users: action.payload,
                removed : action.payload.removed,
                updated : action.payload.updated,
                added : action.payload.added,
                current_chat:0
            }
        case GET_CHAT_USERS_SUCCESS:
            return {
                ...state,
                chat_users: action.payload,
                chat_messages: action.payload.data.length ? action.payload.data[0].chat_messages : [],
                removed : action.payload.removed,
                updated : action.payload.updated,
                added : action.payload.added
            }

        case LOAD_CHAT_MESSAGE_SUCCESS:
            return {
                ...state,
                more_messeges: action.payload.data,
                removed : action.payload.removed,
                updated : action.payload.updated,
                added : action.payload.added

            }
        case SET_CURRENT_CHAT_USER:
            return {
                ...state,
                current_chat: action.payload,
                removed : action.payload.removed,
                updated : action.payload.updated,
                added : action.payload.added
            }
        case NEW_MESSAGE: {
            let data = action.payload.data 
            if (state.chat_messages && state.chat_messages.length && state.chat_messages.filter(chat => chat.chatid === data[0].chatid).length) {
                state.chat_messages = data
            }
            if (state.chat_users.success && state.chat_users.data.length) {
                state.chat_users.data = state.chat_users.data.filter(chat => {
                    if (chat.id === data[0].chatid) {
                        chat.chat_messages = data
                    }
                    return chat;
                })
                var index = state.chat_users.data.findIndex(chat => chat.id ===  data[0].chatid);
                if (index !== -1) {
                    let token = decodedLoggedUser()
                    if(Number(data[0].chat_sender.id) ===  Number(token.aud)){
                        var user = state.chat_users.data.splice(index, 1)[0]; // Remove user from the array
                        state.chat_users.data.unshift(user); // Add user to the beginning of the array
                        state.current_chat = 0

                    }
                }

            }

            return {
                ...state,
                chat_messages: [...state.chat_messages],
                chat_users: {...state.chat_users},
                removed:action.payload.removed,
                updated:action.payload.updated,
                added:action.payload.added,
                current_chat: state.current_chat
            }
        }
        case UPDATE_MESSAGES: {
            let data = action.payload.data
            let _chat_messages = [...state.chat_messages];
            let _chat_users = { ...state.chat_users } ;

            if (_chat_messages && _chat_messages.length && _chat_messages.filter(chat => chat.chatid === data[0].chatid).length) {
                _chat_messages = data
            }
            
            if (_chat_users.success && _chat_users.data.length) {
                _chat_users.data = _chat_users.data.filter(chat => {
                    if (chat.id === data[0].chatid) {
                        chat.chat_messages = data
                    }
                    return chat;
                })
            }

            return {
                ...state,
                chat_messages: _chat_messages,
                removed:action.payload.removed,
                updated:action.payload.updated,
                added:action.payload.added
            }
        }
        case UNSEEN_COUNT: {
            return {
                ...state,
                unseen_count: action.payload,
                removed : action.payload.removed,
                updated : action.payload.updated,
                added : action.payload.added
            }
        }
        case UPDATE_CHAT_USERS: {
            var { chat_users, invited_users } = state;
            var { user, is_online } = action.payload;
            if(chat_users.success && chat_users.data.length > 0){
                chat_users.data.filter(data => {
                    if (Number(user) === Number(data.receiverid)) {
                        data.receiver.is_online = is_online;
                    }
                    if (Number(user) === Number(data.senderid)) {
                        data.sender.is_online = is_online;
                    }
                    return data;
                })
            }
            if(invited_users && invited_users.length > 0){
                invited_users.filter(data => {
                    if (Number(user) === Number(data.user.id)) {
                        data.user.is_online = is_online;
                    }
                    return data
                })
            }
            return {
                ...state,
                chat_users: chat_users,
                invited_users: invited_users,
                removed : action.payload.removed,
                updated : action.payload.updated,
                added : action.payload.added
            }
        }
        default: return state;        
    }
}