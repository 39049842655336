import { get } from 'lodash';
import API from '../../helpers/api';
import * as auth from '../../helpers/auth';
import {
    CREATE_VISUAL_SUCCESS,
    CREATE_VISUAL_FAILED,
    GET_VISUAL_SUCCESS,
    GET_VISUAL_FAILED,
    VISUAL_COMMON_ERROR,
    EDIT_VISUAL_POST_SUCCESS,
    EDIT_VISUAL_POST_FAILED,
    CLEAR_VISUAL_CREATE
} from "./types";

const config = {
    headers: {
      "Content-Type": "multipart/form-data; charset=utf-8;"
      
    }
}


function errorRequest(err, dispatch) {
    let data = get(err, 'response.data', null);
    data = data || get(err, 'response');
    data = data || err;
    if (data.error) {
      dispatch({
        type: VISUAL_COMMON_ERROR,
        payload: data.error.message,
      });
    } else if (data.status === 401) {   
      auth.logout()
    } else {
      dispatch({
        type: VISUAL_COMMON_ERROR,
        payload: data.message,
      });
    }
}



export const clearVisualCreate = () =>{
  return async (dispatch) => {
    dispatch({type:CLEAR_VISUAL_CREATE});
  }
}


/** using for create the visual post  */

export const createVisualPost = (payload) =>{
    return async (dispatch) => {
        try{
            const response = await API.apiUploadFileURL('post', '/create-visual' , payload , config) ;
            if(response.data && response.data.success){
                await dispatch({ type:  CREATE_VISUAL_SUCCESS, payload: response.data});
            }else{
                await dispatch({ type:  CREATE_VISUAL_FAILED, payload: response.data});
            }
        }catch(error){
          errorRequest(error, dispatch);
        }
    }
}


export const getVisualPostList = (payload) =>{
  return async (dispatch) => {
      try{
          const response = await API.apiGetUrl('post', '/visual-post' , payload) ;
          if(response.data && response.data.success){
            await dispatch({ type: GET_VISUAL_SUCCESS, payload: response.data});
          }else{
            await dispatch({ type: GET_VISUAL_FAILED, payload: response.data});
          }
      }catch(error){
        errorRequest(error, dispatch);
      }
  }
}


export const editVisualPost = (payload)=>{
  return async (dispatch) => {
    try{
        const response = await API.apiUpdateUploadFile('post', '/edit-visual-post' , payload , config) ;
        if(response.data && response.data.success){
          await dispatch({ type: EDIT_VISUAL_POST_SUCCESS, payload: response.data});
        }else{
          await dispatch({ type: EDIT_VISUAL_POST_FAILED, payload: response.data});
        }
    }catch(error){
        errorRequest(error, dispatch);
    }
  }
}
  