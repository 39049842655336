import {useState ,useEffect , useRef } from 'react';
import { useSelector, useDispatch } from "react-redux";
import {useNavigate } from "react-router-dom";
import { toast} from "react-hot-toast";   
import { EditorState, convertToRaw, getDefaultKeyBinding, ContentState} from 'draft-js';
import LoadingOverlay from 'react-loading-overlay';
import API from '../../../helpers/api';
import { currentUser as user} from '../../../helpers/auth';
import { WebRTCAdaptor } from '@antmedia/webrtc_adaptor';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Button, Row, Col, Form, ProgressBar, ListGroup , Spinner} from 'react-bootstrap';
import { FaCogs} from 'react-icons/fa';
import {IoClose} from 'react-icons/io5';
import { BsPlusLg } from 'react-icons/bs';
import { startLiveAudio } from '../../../helpers/auth';
import { CopyToClipboard } from 'react-copy-to-clipboard';
// import { FaRegDotCircle, FaRegStopCircle } from 'react-icons/fa'
import { notValid,checkHTML , convertToPlainText } from "../../../utils/validations";
import ImageCropper from "./imageCropper";
import { editAudioPost} from "../../../redux/actions/audioPost.action";
import { decodedLoggedUser } from '../../../helpers/auth';
import { getSinglePost } from '../../../redux/actions/post.action';
import LiveCommentsAnimation from '../../Comments/LiveCommentsAnimation'
const MicRecorder = require('mic-recorder-to-mp3')
const Mp3Recorder = new MicRecorder({ bitRate: 128 })

function LiveSingleAudio({savedData , saveDataToForm}) {
    const intialValue = {name:'' , description: '',isStream: true,  commenting: true , board_name: '', board_id: '', images:'' , thumbnail:''};
    const [streamName , setStreamName] = useState('');
    const [audioFields , setAudioFields] = useState(intialValue);
    const [thumbnailLoader , setThumbnailLoader] = useState(false);
    const [scheduleModel , setScheduleModel] = useState(false);
    const [isStart , setIsStart] = useState(false);
    const [isStop , setIsStop]= useState(false)
    const [loading , setLoading] = useState(false) ; 
    const [boardList , setBoardList] = useState('');
    const [draftLoading , setDraftLoading] = useState(false);
    const [ isCropperOpen, setCropperOpen ] = useState(false);
    const [ imageForCrop, setImageForCrop ] = useState(null);
    const [showEditor , setShowEditor] = useState(true);
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
    const [websocketConnected, setWebsocketConnected] = useState(false);
    const [interval , setIntervalId] = useState('');
    const [errors , setErrors] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isRecording, setIsRecording] = useState(false);
    const [blobURL, setBlobURL] = useState('');
    const [isBlocked, setIsBlocked] = useState(false);
    const [timer, setTimer] = useState(0);
    const [userId, setUserId] = useState('');
    const webRTCAdaptor = useRef(null);
    const mediaRecorderRef = useRef(null);
    const AudioRef = useRef(null);
    var publishedStreamId = useRef(null);
    const apiResult = useSelector(state => state.audioPost); 
    const getPostResult = useSelector(state => state.post);
    
    


/*********************  Audio recorder Functions  **************************************/


    const random = () => {
        var randomString = "livestream";
        randomString = "livestream";
        var charSet = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        for (var i = 0; i < 10; i++) {
        var randomPoz = Math.floor(Math.random() * charSet.length);
        randomString += charSet.substring(randomPoz, randomPoz + 1);
        }
        return randomString;
    };



    useEffect(()=>{
        if(apiResult && apiResult.liveAudioStarted){
            if(apiResult.liveAudioStarted && apiResult.liveAudioStarted.success){
                if(apiResult.liveAudioStarted && apiResult.liveAudioStarted.result){
                    if(apiResult.liveAudioStarted.result.user_id === userId){
                        setAudioFields({...audioFields , ...apiResult.liveAudioStarted.result})
                        setIsStart(true);
                        setIsRecording(true);
                        setLoading(false);
                        dispatch(getSinglePost(apiResult.liveAudioStarted.result.slug))
                    }
                }
            }
        }
        if(apiResult && apiResult.editAudio){
            setLoading(false)
            setDraftLoading(false)
            navigate('/stream')
        }
        if(apiResult && apiResult.audioPostError){
            setLoading(false)
            setDraftLoading(false)
            toast.error(apiResult.audioPostError.message, {
                position: 'top-center', 
            });
        }
    },[apiResult])

    useEffect(() => {
        const interval = setInterval(() => {
            if (isRecording) {
                setTimer(seconds => seconds + 1);
            }
        }, 1000);
        setIntervalId(interval)
        return () => {
            clearInterval(interval);
            setTimer(0)
            handleStopLiveAudio()
        };
    }, [isRecording])

    useEffect(() => {
        let token = decodedLoggedUser()
        setUserId(Number(token.aud))

        // return(()=>{
        //     handleStopLiveAudio()
        // })
    }, [])


    useEffect(() => {
        if (savedData.audio) {
            setAudioFields(savedData.audio);
            if(savedData.audio.description){
                const blocksFromHtml = htmlToDraft(savedData.audio.description);
                const { contentBlocks, entityMap } = blocksFromHtml;
                const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
                const editorState = EditorState.createWithContent(contentState);
                setEditorState(editorState)
            }
        }
    },[savedData]);

    const connectWebrtc = () =>{
        if(isValid()){
            if(webRTCAdaptor.current === undefined || webRTCAdaptor.current === null){
                setLoading(true);
                webRTCAdaptor.current = new WebRTCAdaptor({
                websocket_url: 'wss://test.antmedia.io:/WebRTCAppEE/websocket',
                mediaConstraints: {
                    video: false ,
                    audio: {
                    noiseSuppression: true,
                    echoCancellation: true
                    },
                },
                peerconnection_config: {
                    iceServers: [{ urls: 'stun:stun1.l.google.com:19302' }],
                },
                sdp_constraints: {
                    OfferToReceiveAudio: false,
                    OfferToReceiveVideo: false,
                },
                localVideoId: 'localVideo',
                dataChannelEnabled: true,
                
                isRecording: true, // Enable recording
                isPlayMode: false, // E
                callback: (info, obj) => {
                    if (info === 'initialized') {
                    setWebsocketConnected(true);
                    handlePublish()
                    }
                },
                callbackError: function (error, message) {
                    console.log(error, message);
                },
                })
            }
        }
    }

    const handlePublish = () => {
        if(isValid()){
          const streamId = random();     // function is using for genrate a random stream id 
          setStreamName(streamId);
          webRTCAdaptor.current.publish(streamId);
          publishedStreamId.current=streamId;
          handleStartRecording()    // this function is using for record the live video using  RecordRTC
          handleLiveAudio(streamId)
        }
    };


    const handleLiveAudio = async(streamId) =>{
        const data = {
          addPost: streamId,
          user_id: user.aud,
          board_post: audioFields.board_id ? true : false,
          action: "online",
          description: audioFields.description,
          commenting: audioFields.commenting,
          board_id: audioFields.board_id !== 'public' ? audioFields.board_id : undefined,
          thumbnail:audioFields.thumbnail,
          name: audioFields.name,
        }  
        startLiveAudio(data)
    }


    // const handleStartRecording = () => {
    //     const stream = AudioRef.current.srcObject;  
    //     mediaRecorderRef.current = new MediaRecorder(stream, { mimeType: 'audio/webm' });
    //     mediaRecorderRef.current.start();
    // }; 

    const handleStartRecording = () => {
        Mp3Recorder.start()
          .then(() => {
            setIsRecording(true);
          })
          .catch((e) => console.error('Error starting recording:', e));
      };
    
      const handleStopRecording = () => {
        return new Promise((resolve, reject) => {
          Mp3Recorder.stop()
            .getMp3()
            .then(([buffer, blob]) => {
              const file = new File(buffer, 'recording.mp3', {
                type: blob.type,
                lastModified: Date.now()
              });
              resolve(file);
            })
            .catch((e) => {
              console.error('Error stopping recording:', e);
              reject(e);
            });
        });
      };
    

    const isValid = () => {
        let isValid = true;
        let errorMessages = {};
        const check = ['', null, undefined, '0', 0, 'null', 'undefined'];
        if (notValid(audioFields.name)) {
          errorMessages['name'] = 'Title is required'
          isValid = false;
        }
        if(audioFields.thumbnail === ''){
          errorMessages['thumbnail'] = 'Thumbnail is required'
          isValid = false;
        }
        if (!isValid) {
          setErrors({ ...errors, ...errorMessages });
        }
        return isValid;
    }



    const stopRecording = (e) => {  
        e.preventDefault();
        Mp3Recorder
            .stop()
            .getMp3()
            .then(([buffer, blob]) => {
               
                const file = new File(buffer, 'me-at-thevoice.mp3', {
                    type: blob.type,
                    lastModified: Date.now()
                });
                setBlobURL(file)
                setIsRecording(false);
            }).catch((e) => console.log(e)
        );
    }


    // const handleStopRecording = () => {
    //     const chunks = [];
    //     return new Promise((resolve) => {
    //       mediaRecorderRef.current.ondataavailable = (event) => {
    //         if (event.data.size > 0) {
    //           chunks.push(event.data)
    //         }
    //       };
    //       mediaRecorderRef.current.onstop = async() => {
    //         if( chunks && chunks.length > 0){
    //           const blob = new Blob(chunks, { type:'audio/webm'});
    //           resolve(blob);
    //         }
    //       };
    //     });
    // };


    const handleStopLiveAudio = async() =>{
        if(isStart && isValid()){
            setIsStop(true)
            setLoading(true)
            clearInterval(interval)
            webRTCAdaptor.current.stop(streamName);
            // mediaRecorderRef.current.stop();
            const audioFile = await handleStopRecording()
            if(audioFile){
                var audioUrl = await uploadThumbnailFileToServer(audioFile);
            }
            const data = {
            addPost:streamName,
            user_id: user.aud,
            board_post: audioFields.board_id ? true : false,
            action: "offline",
            description: audioFields.description,
            commenting: audioFields.commenting,
            board_id: audioFields.board_id !== 'public' ? audioFields.board_id : undefined,
            thumbnail: audioFields.thumbnail ,
            name: audioFields.name , 
            media: audioUrl ? audioUrl : '' ,
            slug:  audioFields.slug,
            postId:audioFields.id
            }
            dispatch( editAudioPost(data))
        }
  
    }

    const uploadThumbnailFileToServer = async (file) => {
        try {
            const formData = new FormData();
            formData.append('thumbnail', file);
            const response = await API.apiUploadFileURL('messenger', `/get-file-url`, formData);
            if(response.data && response.data.success){
              return response.data.fileUrl
            }
        } catch (error) {
          toast.error('Oops, unable to upload file');
        }
    }

    const formatTime = ({ timer }) => {
        const hours = Math.floor(timer / 3600);
        const minutes = Math.floor((timer % 3600) / 60);
        const seconds = timer % 60;
      
        const formattedHours = `0${hours}`.slice(-2);
        const formattedMinutes = `0${minutes}`.slice(-2);
        const formattedSeconds = `0${seconds}`.slice(-2);
      
        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    };
    
/************************************************************************************************************ */

/****************************  form Functions  ***************************************************************/

    useEffect(()=>{
        if(getPostResult && getPostResult.boardPostList){
            setBoardList(getPostResult.boardPostList.boardPosts)
        }
    },[getPostResult])


    const handleInput = (e) =>{
        const {value , name } = e.target ; 
        setAudioFields({...audioFields, [name]: value })
        setErrors({ ...errors, [name]: '' })
    }

    const handleCommenting = (e) =>{
        const copyAudioFileds = {...audioFields} ;
        copyAudioFileds.commenting = e.target.checked
        setAudioFields(copyAudioFileds)
    }

    /***************************** using for crop image  *****************************************************/
    const handleCloseCropper = () => {
        setImageForCrop(null)
        setCropperOpen(false)
    }

    const handeSaveCroppedImage = (dataURI) => {
        const imageFile = dataURItoBlob(dataURI);
        const copyAudioFields = {...audioFields}
        copyAudioFields['thumbnail'] = imageFile
        setAudioFields(copyAudioFields)
    }

    const dataURItoBlob = (dataURI) => {
        // convert base64/URLEncoded data component to raw binary data held in a string
        var byteString;
        if (dataURI.split(',')[0].indexOf('base64') >= 0)
            byteString = atob(dataURI.split(',')[1]);
        else
            byteString = unescape(dataURI.split(',')[1]);

        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        // write the bytes of the string to a typed array
        var ia = new Uint8Array(byteString.length);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        
        return new Blob([ia], {type:mimeString});
    }

    const handleOpenCropper = () => {
        
        setCropperOpen(true)
        let data = {
            src: URL.createObjectURL(audioFields.thumbnail)
        }
        setImageForCrop(data)
    }
    /******************************************************************************************************** */

    /***************************   handle thumbnail  ********************************/
    const handleThumnail = async (e)=>{
        const allImages = ['png', 'jpg', 'jpeg', 'JPG', 'JPEG', 'PNG'];
        let errorMessages = {...errors};
        const { files } = e.target ; 
        const copyAudioFile = {...audioFields};
        if(files && files.length < 2 ){
            const file = files[0];
            const fileName = file.name.toLowerCase();
            const fileExtension = fileName.split('.').pop();
            if(allImages.includes(fileExtension)){
                setThumbnailLoader(true);
                var thumbnailUrl = await uploadThumbnailFileToServer(file);
                if(thumbnailUrl){
                    copyAudioFile.thumbnail = thumbnailUrl
                    setThumbnailLoader(false);
                    setAudioFields(copyAudioFile)
                    errorMessages['thumbnail'] = ''
                    setErrors(errorMessages)
                }
            }else{
                errorMessages['thumbnail'] = ' Please use only  png, jpg, jpeg, JPG, JPEG, PNG'
                setErrors(errorMessages)
            }    
        }

    }

    const handleRemoveThubnail = () =>{
        const copyAudioFields = {...audioFields};
        copyAudioFields.thumbnail = '' ;
        setAudioFields(copyAudioFields)
    }
    /********************************************************************************************* */

    /**********************    handle description  /////   text editor  function using draft.js for text editing        ******/

    const onEditorStateChange = (editorState) => {
        const copyAudioFields = {...audioFields} ;
        let errorMessages = {...errors};
        const rawContentState = convertToRaw(editorState.getCurrentContent());
        const description = draftToHtml(rawContentState);
        copyAudioFields.description = description
        errorMessages.description = '' ;
        setAudioFields(copyAudioFields)
        setErrors(errorMessages)
        setEditorState(editorState);
    };

    const myKeyBindingFn = event => {
        return getDefaultKeyBinding(event);
    }
    /********************************************************************************************************************* */


    /**---------------------  form validation  -----------------------*/
    const validate = () =>{
        const check = ['undefined' , null , '' , undefined]
        let isValid = true ;
        let errorMessages = {};
        let desc = checkHTML(audioFields.description) ? convertToPlainText(audioFields.description) : audioFields.description
        if (notValid(desc)) {
            errorMessages['description'] = 'Description is required'
            isValid = false;
        }
        if (notValid(audioFields.name)) {
            errorMessages['name'] = 'Title is required'
            isValid = false;
        }

        if(check.includes(blobURL)){
            errorMessages['images'] = 'Audio is Required'
            isValid = false;
        }
        if (!isValid) {
            setErrors({ ...errors, ...errorMessages });
        }
        return isValid;
    }
    /***************************************************************************** */


    /*******************  board handling  ********************************************/

    const handleBoard = (e)=>{
        let copyAudioFields = {...audioFields};
        copyAudioFields.board_id = e.target.value
        setAudioFields(copyAudioFields)
    }

    const selectBoard = () => {
        if(boardList && boardList.length > 0){
            return (
                <Form.Group className='form-group col-sm-12'>
                    <Form.Label>Realm</Form.Label>
                    <Form.Control value={audioFields.board_id || '' } onChange= {handleBoard} as="select">
                        <option selected>Select</option>
                        {boardList.map((item , idx)=>{
                            return (
                                <option key={idx} value={item.id}>{item.name}</option>
                            )
                        })}
                    </Form.Control>
                </Form.Group>

            )
        }
    }

    /******************************************************************************************** */

    /********************************    submit Form  ************************************************/
    useEffect(()=>{
        if(apiResult && apiResult.createSingleAudio){
            setLoading(false)
            setDraftLoading(false)
            // toast.success(apiResult.createSingleAudio.message, {
            //     position: 'top-center', 
            // });
            navigate('/stream')
        }
        if(apiResult && apiResult.audioPostError){
            setLoading(false)
            setDraftLoading(false)
            toast.error(apiResult.audioPostError.message, {
                position: 'top-center', 
            });
        }
        setLoading(false)
        setDraftLoading(false)

    },[apiResult])




    /*********************************************************************************************************** */


    const updateParentState = () => {
        saveDataToForm('audio', audioFields);
    };


    const renderVideoLink = () =>{
        if(audioFields.slug){
          const link = `${window.location.origin}/audio/single/${audioFields.slug}`;
          return(
            <div>
              <p>{link}</p>  
              <CopyToClipboard text={handleLink(link)} onCopy={()=>{handleCopy()}}>
                  <Button>Copy Link</Button>
              </CopyToClipboard>   
            </div>
          )
        }
    }

    const handleLink = (link) =>{
        return link
    }

    const handleCopy = () => {
        toast.success('Link copy to clipboard', {position: 'top-center'});
    };


    const renderThumbnail = () =>{
        if( audioFields.thumbnail){
            return(
                <Form.Group className='form-group col-sm-12 col-lg-6'>
                    <div className="post--thumb--upload">
                        <img src={typeof audioFields.thumbnail === 'object' ? URL.createObjectURL(audioFields.thumbnail) : audioFields.thumbnail}/>
                        <span className="post--media--edit" onClick={() => handleOpenCropper()}>Edit</span>
                        <span className="post--media--close" onClick= {handleRemoveThubnail} ><IoClose/></span>
                    </div>
                </Form.Group>   
            )
        }else if(thumbnailLoader){
          return (
            <Form.Group className='form-group col-sm-12 col-lg-6'>
              <div className="post--thumb--upload">
                <Spinner animation="border"/>
              </div>
            </Form.Group>
          )
        }else {
          return(
            <Form.Group className='form-group col-sm-12 col-lg-6'>
                <input type='file' hidden id="upload--art" onChange={handleThumnail} />
                <label className='label--upload text-center' htmlFor="upload--art"><BsPlusLg/>Add Cover Art</label>
                {errors.thumbnail && <small className="error error-massege text-danger">{errors.thumbnail}</small>}
            </Form.Group>
          )
        }

    }
    return (
        <>
            <div className='create--form'>
                <LoadingOverlay
                    active={loading}
                    spinner
                    text = { isStop ? ' The saved copy of this live broadcast is processing. Please check back soon' : 'Please wait '}
                >
                    <Form  onBlur={updateParentState}>
                        <Row>
                            {renderThumbnail()}
                            <Form.Group className='form-group col-sm-12 col-lg-6'>
                                <Form.Label>Audio Title</Form.Label>
                                <Form.Control type="text" name = 'name' value ={audioFields.name} onChange={handleInput} required />
                                    {errors.name && <span className="error error-massege text-danger">{errors.name}</span>}
                                <Form.Control className='my-2' type="text" readOnly value={user.firstName} />
                                {selectBoard()}
                            </Form.Group>
                            <Form.Group className='form-group col-sm-12'>
                                <div className='audio--play--timer'>
                                {(isStart)&&    
                                    <div class="live-text-container"><div class="live-text"><span class="live-dot">•</span><span class="live-word">LIVE</span></div></div>
                                }
                                
                                    <p><strong>{formatTime({ timer })}</strong></p>
                                    <ProgressBar now={5} />
                                    <ListGroup horizontal id="play--controls">
                                        <audio
                                            ref={AudioRef} 
                                            id="localVideo"
                                            autoPlay
                                            muted
                                            // controls
                                        />
                                    </ListGroup>
                                </div>
                            </Form.Group>
                            <Form.Group className='form-group col-sm-12'>
                                <span className='editor--tool' onClick = {()=>setShowEditor(!showEditor)}><FaCogs /></span>
                            </Form.Group>
                            <Form.Group className='form-group col-sm-12 text-title-post'>
                                <Form.Label>Description</Form.Label>
                                <Editor
                                    editorState={editorState}
                                    wrapperClassName="demo-wrapper editor-w"
                                    editorClassName="demo-editor input"
                                    onEditorStateChange={onEditorStateChange}
                                    keyBindingFn={myKeyBindingFn}
                                    hashtag={{
                                        separator: ' ',
                                        trigger: '#',
                                    }}
                                    toolbarHidden={showEditor}
                                    config={{ link: { trailingWhitespace: true } }}
                                />
                                {errors.description && <small className="error error-massege text-danger">{errors.description}</small>}
                            </Form.Group>
                            <Form.Group className='form-group col-sm-12'>
                                <Form.Check type="checkbox" label="Allow Comments"  id= 'liveCommentAudio' checked={audioFields.commenting} onChange= {handleCommenting}/>
                                <Form.Label htmlFor="liveCommentAudio" onClick={handleCommenting}/>
                            </Form.Group>
                            <Col sm={12} className='text-end'> {renderVideoLink()} </Col>
                            <Col sm={12} className='text-end'>
                                {!isRecording
                                ? 
                                    <>
                                        <Button variant="secondary" className='me-2' onClick= {connectWebrtc}>
                                            Go Live Now
                                        </Button>
                                    </>  
                                :
                                    <Button variant="secondary" className='me-2 stopBtn' onClick = {handleStopLiveAudio}>
                                        Stop
                                    </Button>
                            }  
                            </Col>
                        </Row>
                    </Form>
                </LoadingOverlay>
                {isCropperOpen && 
                    <ImageCropper
                        isOpen={isCropperOpen}
                        src={imageForCrop.src}
                        onClose={() => handleCloseCropper()}
                        result={(val) => handeSaveCroppedImage(val)}
                    />
                }
            </div>
            {(isStart && audioFields.commenting)&&
                <div className='commentSec'>
                    <span class="comment-title">Comments</span><hr class="hr-style" />
                    <LiveCommentsAnimation />
                </div>
            }
        </>
    );
}

export default LiveSingleAudio;