import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Modal, Button, Row, Col, InputGroup, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { MdClose } from "react-icons/md";
import { FiPlus } from "react-icons/fi";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-hot-toast";
import * as Auth from "../../helpers/auth";
import { createSchedule , getEvents , createEvents} from "../../redux/actions/schedule.action";
import moment from "moment-timezone";
import MeetingScheduler from "./Scheduler";

function AddEvent() {

  const [ showMeeting , setShowMeeting] = useState(false);
  const [showEventModel , setShowEventModel] = useState(false)
  const [event , setEvent] = useState({duration:"15" , amount:'' , name:''})
  const [events , setEvents ] = useState([]);
  const [eventError , setEventError] = useState({name: false, amount: false,})
  const [eventLoading , setEventLoading] = useState(false);
  const [selectedEvent , setSelectedEvent] = useState('');
  const [isDisable , setIsDisable] = useState(false)

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const scheduleState = useSelector(state => state.schedule);


  

  useEffect(()=>{
    getUserEvents()
    setEventLoading(true);
  },[])



  useEffect(()=>{
    if(scheduleState && scheduleState.userEvents){
      if(scheduleState.userEvents && scheduleState.userEvents.schedules){
        setEvents(scheduleState.userEvents.schedules)
        setEventLoading(false);
      }
    }
    if(scheduleState && scheduleState.userEventError){
      setEventLoading(false);
    }
    if(scheduleState && scheduleState.event_created){
      toast.success('Event created')
      resetState()
    }else{
      setIsDisable(false)
    }
    if(scheduleState && scheduleState.create_event_error){
      toast.success('Something went wrong ! ')
      setIsDisable(false)
    }
  },[scheduleState])


  const resetState = () =>{
    setIsDisable(false)
    setEvent({duration:"15" , amount:'' , name:''})
    setShowEventModel(false);
    getUserEvents();
  }




  const getUserEvents = () =>{
    const userData = Auth.decodedLoggedUser();
    dispatch(getEvents({userId:userData.aud}))
  }



  const handleCloseEventModel = () =>{
    setShowEventModel(false)
  }

  // Function to handle input change and update state
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "amount") {
      const regex = /^[1-9]$|^\d+(\.\d{0,2})?$/; // Regex to allow valid amounts
      if (regex.test(value)) {
        // If amount is valid, update the state
        setEvent((prevData) => ({
          ...prevData,
          [name]: value,  // Update the totalAmount field
        }));
        setEventError({...eventError , [name]:''})
      } else {
        // If invalid amount, reset the totalAmount value
        setEvent((prevData) => ({
          ...prevData,
          [name]: '',  // Clear the totalAmount field
        }));
        setEventError({...eventError , [name]:''})
      }
    } else {
      // For other fields, update the state as usual
      setEvent((prevData) => ({
        ...prevData,
        [name]: value,
      }));
      setEventError({...eventError , [name]:''})
    }
  
  };


  const validateForm = () => {
    const newErrors = {
      name: !event.name,       // Event name is required
      amount: !event.amount,   // Total amount is required
    };

    setEventError(newErrors);

    // Return true if there are no errors
    return !Object.values(newErrors).includes(true);
  };


  const handleSaveEvent = () => {
    if (validateForm()) {
      const userData = Auth.decodedLoggedUser();
      const data = {...event , user_id:Number(userData.aud)}
      dispatch(createEvents(data))
      setIsDisable(true)
    } 
  };

  const createEvent = () =>{
    return (
      <Modal show={showEventModel} onHide={handleCloseEventModel} centered>
        <Modal.Header closeButton>
          <Modal.Title>Add Event</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="mt-3">
            <Form.Group className="mb-3 form-group">
              <Form.Label>Event time</Form.Label>
              <Form.Select
                name="duration"
                value={event.duration}
                onChange={handleInputChange}
              >
                <option value="15">15 minutes meeting</option>
                <option value="30">30 minutes meeting</option>
                <option value="45">45 minutes meeting</option>
                <option value="60">60 minutes meeting</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3 form-group">
              <Form.Label>Event name</Form.Label>
              <Form.Control
                aria-label=""
                type="text"
                name="name"
                value={event.name}
                onChange={handleInputChange}
              />
              {eventError.name && (<span className="error error-massege text-danger" >Event name is required.</span>)}
            </Form.Group>
            <Form.Group className="mb-3 form-group">
              <Form.Label>Total amount</Form.Label>
              <InputGroup>
                <InputGroup.Text>$</InputGroup.Text>
                <Form.Control
                  aria-label="Amount (to the nearest dollar)"
                  type="text"
                  name="amount"
                  value={event.amount}
                  onChange={handleInputChange}
                />
              </InputGroup>
              {eventError.amount && (<span  className="error error-massege text-danger">Event name is required.</span>)}
            </Form.Group>
            <Button className="add--interval ms-auto" onClick={()=>handleSaveEvent()}>Save</Button>
          </Form>
        </Modal.Body>
      </Modal>
    )
  }


  const handleSelectEvent = (e) =>{
    const {id} = e.target; 
    setSelectedEvent(id)
  }

  const handleAvailability = () =>{
    if(selectedEvent !== ''){
      setShowMeeting(true)
      
    }else{
      toast.error('Please select a event.')
    }
  }

  return (
    <>
     {!showMeeting && 
      <div className="p-4 py-md-5 container">
        <Row className="justify-content-center">
          <Col sm={12} lg={10} xl={8}>
            <Row>
              <Col>
                <Button variant="primary" onClick={() => navigate(-1)}>Back</Button>
              </Col>
              <Col className="text-end">
                <Button variant="secondary" onClick={()=>setShowEventModel(true)}>Add Event</Button>
              </Col>
            </Row>
            <div className="custom--add--times event--form">
            {(eventLoading)&&<Spinner></Spinner>}
            {(!eventLoading && events.length > 0)&&
              <>
                <Form>
                  {events.map((meeting , index) => (
                    <Form.Check 
                      key={index}
                      type="radio"
                      label={<span>{meeting.name} <small>{meeting.duration} min</small></span>}
                      id={meeting.id}
                      name="time-radio"
                      onChange={handleSelectEvent}
                    />
                  ))}
                </Form>
                <Button className="mt-3" variant="primary" onClick={handleAvailability}>Edit Availability</Button>
              </>
            }
            </div>
          </Col>
        </Row>
      </div>
    }
      
      {showEventModel && createEvent()}
      {showMeeting && <MeetingScheduler event={events.find((meeting) => Number(meeting.id) === Number(selectedEvent))} hideAvailability = {()=>setShowMeeting(false)}/>}
    </>
  );
}

export default AddEvent;
