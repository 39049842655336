import { 
    DISCOVER_POST,
    LIVE_VIDEO_STARTED,
    LIVE_AUDIO_STARTED

} from "../actions/types";

const initialState = {
    discoverPostsList : null 
};

export default (state = initialState, action) => {
    switch (action.type) {
        
        case  DISCOVER_POST : 
            return{
                discoverPostsList : action.payload,
            }   
        case  LIVE_VIDEO_STARTED:{
            if(state.discoverPostsList){
                let copyDiscover =  {...state.discoverPostsList}
                let livePost = copyDiscover.discoverPosts.liveVideoPost ;
                livePost.push(action.payload.result)
                let uniqueArray = [...new Set(livePost.map(obj => obj.id))].map(id => {
                    return livePost.find(obj => obj.id === id);
                    });
                copyDiscover.discoverPosts.liveVideoPost = uniqueArray
                return {
                    discoverPostsList: copyDiscover
                }
            }
        }
        case LIVE_AUDIO_STARTED:{
            if(state.discoverPostsList){
                let copyDiscover =  {...state.discoverPostsList}
                let livePost = copyDiscover.discoverPosts.audioPost ;
                livePost.push(action.payload.result)
                let uniqueArray = [...new Set(livePost.map(obj => obj.id))].map(id => {
                    return livePost.find(obj => obj.id === id);
                    });
                copyDiscover.discoverPosts.audioPost = uniqueArray
                return {
                    discoverPostsList: copyDiscover
                }
            }
        }
    
        default: return state;    
    }    

}