import React, { Component} from 'react'
import { connect } from 'react-redux';
import { Container, Row, Col, Dropdown, ListGroup} from 'react-bootstrap';
import { FaEllipsisV, FaRegUser, FaRegCalendarAlt , FaRegHeart} from 'react-icons/fa';
import { BsCardList, BsGridFill} from 'react-icons/bs';
import { BiCommentDetail,IoMdHear } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import CopyToClipboard from 'react-copy-to-clipboard';
import PageHeader from '../Header/HeaderTop';
import SidebarMenu from '../Sidebar/SidebarNav';
import moment from 'moment';
import { toast, Toaster } from "react-hot-toast";
import {decodedLoggedUser , logout , likePosts} from '../../helpers/auth';
import InfiniteScroll from 'react-infinite-scroller';
import { valid} from '../../utils/validations';
import 'bootstrap/dist/css/bootstrap.min.css';
import './textStyle.css';
import '../../App.css';
import { getTextPostList } from '../../redux/actions/textPost.action';
import { changePostStatus } from '../../redux/actions/post.action';
import StreamDesignView from '../DesignView/StreamDesignView';
import { withRouter } from '../../wrapper/withRouter';

const check = ['undefined' , undefined , null , 'null' , '']

class TextPostsMain  extends Component {
    constructor(props){
        super(props)
        this.state =  {
            textPostList : [] ,
            textPostMeta : '',
            loading : false , 
            page : 1 ,
            perPage : 10 ,
            loadingState : false ,
            isActive : true , 
            userId : '',
            status : '1'

        }
        
    }
  
   
    componentDidMount = async () => {
        if (this.props.auth.loggedIn) {
            let token = decodedLoggedUser()
            this.setState({userId:token.aud})
        }
        await this.getTextPosts();  
    }


    loadMoreItems() {
        if (this.state.loadingState) {
            return;
        } else {
            this.getTextPosts();
        }
    }

    getTextPosts = async() =>{
        const {perPage , page , textPostList} = this.state 
        const data = {status:'1' , perPage:perPage , page:page};
        if (this.state.page == 1) {
            this.setState({ loading: true })
        }
        await this.props.getTextPostList(data);
        if(this.props.textPost  && this.props.textPost.success){
            var posts = textPostList ;
            Array.prototype.push.apply(posts, this.props.textPost.textPostList.textPost);
            posts = posts.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i)
            this.setState({
                textPostList : posts ,
                loading: false,
                loadingState: false,
                textPostMeta: this.props.textPost.textPostList.textPostMeta ,
            })
            let totalPages = this.props.textPost.textPostList.textPostMeta
            if(posts.length !== totalPages){
                this.setState({page: page+1})
            }else{
                this.setState({ loadingState: true })
            }
        }
        if(this.props.textPostError && this.props.textPostError.message){
            toast.error(this.props.textPostError.message , {
                position: 'top-center', 
            });
            this.setState({ loading: false })
        }
       
    }

    checkHTML = param => {
        return new RegExp(/(\<\w*)((\s\/\>)|(.*\<\/\w*\>))/gm).test(param)
    }


    convertToPlainText = (html) => {
        const parser = new DOMParser();
        const parsedDocument = parser.parseFromString(html, 'text/html');
        return parsedDocument.body.textContent || '';
    };

    handleToggle = () =>{
        this.setState({isActive : !this.state.isActive})
    }

    handleLike = (data) => {
        // if(Number(data.user.id) !== Number(this.state.userId)){
            likePosts(this.state.userId, data.id)
        // }
    }


    handleCommentsLength = (comments) =>{
        if(comments && comments.length > 0){
            const validComments = comments.filter((item , index)=>{
                if(item.status === '1'){
                    if(item.comment_id === null){
                        return item
                    }else{
                        const reply = comments.find((c) => Number(c.id) === Number(item.comment_id));
                        if(reply && reply.status === '1'){
                            return item
                        }
                    }
                }
            });
            if(validComments && validComments.length > 0){
                return (
                    <small className='ms-2'>{validComments.length}</small>
                )
            }

        }
    }
    
    renderTextPost = () =>{
        const {textPostList,userId , isActive} = this.state ;
        if(textPostList && textPostList.length > 0){
            return (
                <Col sm={12}>
                    <Row className={isActive ? 'justify-content-start grid--view' : 'justify-content-center list--view'}>
                        { 
                            textPostList.map((item , idx)=>{
                                if(item.user){
                                    return (
                                        <>
                                            <StreamDesignView
                                                post={item}
                                                userId = {this.state.userId}
                                                copyText = {this.handleCopy}
                                                handleChangePostStatus = {this.handleChangePostStatus}
                                                handleCommentsLength = {this.handleCommentsLength}
                                                handleLike = {this.handleLike}
                                                isActive = {isActive}
                                            />
                                        </>

                                    )
                                }

                            })
                        }

                    </Row>
                </Col>
            )
        }
    }


/***   this method is used for both delted post or move post to draft  */

    handleChangePostStatus = async(postId , status) =>{
        const data = {postId:postId , status:status}
        await this.props.changePostStatus(data)
        if(this.props.postStatus && this.props.postStatus.success){
            this.setState({status : status})
            // this.getTextPosts();
            toast.success(this.props.postStatus.message, {
                position: 'top-center', 
            });
            if( status === '0'){
                this.props.navigate('/')
            }
            if(status === '2'){
                this.props.navigate('/drafts')
            }
        }
        if(this.props.postStatusError && this.props.postStatusError.message){
            toast.error( this.props.postStatusError.message, {
                position: 'top-center', 
            });
        }
    }

    componentDidUpdate(prevProps, prevState){
        if(prevState.status !== this.state.status){
            this.setState({
                textPostList : [] ,
                textPostMeta : '',
                loading : false , 
                page : 1 ,
                perPage : 10 ,
                loadingState : false ,
                status : '1'
            },()=>this.getTextPosts())
        }
        if(prevState.isActive !== this.state.isActive){
            this.setState({
                textPostList : [] ,
                textPostMeta : '',
                loading : false , 
                page : 1 ,
                perPage : 10 ,
                loadingState : false ,
                status : '1'
            },()=>this.getTextPosts())
        }
    }

    


/*********    using for share link / copy link to clipboard  ****************************/   
    handleCopy = () => {
       
        toast.success('Link copy to clipboard', {position: 'top-center'});
      
    };
/******************************************************************************************** */    

    render () {
            const {loading , textPostList , loadingState , isActive , page , userId} = this.state ;

        return (
            <>
                <PageHeader />
                <div className='page--content'>
                    <SidebarMenu />
                    <div className='discover--module common--wrapper audio--main--page'>
                        <Container>
                            <ListGroup as="ul" horizontal>
                                <ListGroup.Item as="li" className={isActive ? 'inactive' : 'active'}  onClick={this.handleToggle}><BsGridFill /></ListGroup.Item>
                                <ListGroup.Item as="li" className={isActive ? 'active' : 'inactive'}  onClick={this.handleToggle}><BsCardList /></ListGroup.Item>
                            </ListGroup>
                            <Row id="scrollableDiv" ref={(ref) => this.scrollParentRef = ref} style={{ height: "100%" }}>
                                {!loading  ?
                                    textPostList.length > 0
                                    
                                    ?  

                                        <InfiniteScroll
                                            pageStart={0}
                                            loadMore={() => this.loadMoreItems()}
                                            hasMore={!loadingState}
                                            loader={
                                                <Col sm={12}>
                                                    <div className='text-center spinner--loader'></div>
                                                </Col>
                                            }
                                            scrollableTarget="scrollableDiv"
                                            getScrollParent={() => this.scrollParentRef}
                                            window={false}
                                        >
                                            {this.renderTextPost()}
                                        </InfiniteScroll>     
                                    :   <h2 className="mt-5 text-center">Posts Not Found</h2>
                                    
                                :   <Col sm={12}>
                                        <div className='text-center spinner--loader'></div>
                                    </Col>
                                }
                            </Row>
                            <Toaster/>
                        </Container>
                    </div>
                </div>
            </>
        );
    }    
}


const maopStateToProps = (state) => {
    return{
        textPost : state.textPost.textPostList, 
        textPostError : state.textPost.textPostError,
        auth : state.auth ,
        postStatus : state.post.changeStatusSuccess,
        postStatusError: state.post.changeStatusError
    }    

}

const dispatchActions = {getTextPostList , changePostStatus} ; 
export default connect(maopStateToProps, dispatchActions)(withRouter(TextPostsMain));