import { 
    GET_VIDEO_POST_SUCCESS,
    GET_VIDEO_POST_FAILED,
    CREATE_VIDEO_SUCCESS,
    CREATE_VIDEO_FAILED,
    COMMOM_VIDEO_ERROR,
    UPLOAD_VIDEO_SUCCESS,
    UPLOAD_VIDEO_FAILED,
    EDIT_VIDEO_SUCCESS,
    EDIT_VIDEO_FAILED,
    LIKE_POST,
    LIVE_VIDEO_STARTED,
    STOP_LIVE_VIDEO,
    SCHEDULE_LIVE_POST_FAILED,
    SCHEDULE_LIVE_POST_SUCCESS,
    CLEAR_VIDEO_CREATE,
    CLEAR_POSTS
    
} from "../actions/types";

const initialState = {
    getVideoSuccess: null,
    videoPostError: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_VIDEO_POST_SUCCESS:
            if(state.getVideoSuccess !==null){
                if(action.payload.pageNumber !== '1'){
                    const copyPost = state.getVideoSuccess
                    let  post = state.getVideoSuccess.videoPostList.videoPost
                    let newPost = action.payload.videoPostList.videoPost
                    var mergePost = [...post , ...newPost]
                    mergePost = mergePost.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i)
                    copyPost.videoPostList.videoPost = mergePost
                    return{
                        ...state,
                        getVideoSuccess: copyPost
                    }    
                }else{
                    return{
                        getVideoSuccess: action.payload
                    }
                }    
            }else{
                return{
                    ...state,
                    getVideoSuccess: action.payload
                }    
            }    
        case GET_VIDEO_POST_FAILED:
            return{
                videoPostError:action.payload.message,
            }    
        case CREATE_VIDEO_SUCCESS:
            return{
                createVideoSuccess:action.payload,
            }
        case CREATE_VIDEO_FAILED:
            return{
                videoPostError:action.payload.message,
            }      
        case UPLOAD_VIDEO_SUCCESS:
            return{
                videoUrl:action.payload.videoUrl,
            }      
        case UPLOAD_VIDEO_FAILED:
            return{
                videoPostError:action.payload.message,
            }          
        case EDIT_VIDEO_SUCCESS:
            return{
                editVideo:action.payload,
            }      
        case EDIT_VIDEO_SUCCESS:
            return{
                videoPostError:action.payload.message,
            }   
        case LIKE_POST:
            if(state.getVideoSuccess){
                const postLikes = state.getVideoSuccess.videoPostList
                const likesIndex = postLikes.videoPost.findIndex(post => post.id === action.payload.post.postId)
                if(likesIndex !== -1){
                    var copyVideoPost = state.getVideoSuccess
                    copyVideoPost.videoPostList.videoPost[likesIndex].Likes = action.payload.post.likes
                    const updateLikes = {...copyVideoPost}
                    return{
                        getVideoSuccess: updateLikes,
                    }     
                }      
            }                 
        case COMMOM_VIDEO_ERROR:
            return{
                videoPostError:action.payload,
            }      
        case LIVE_VIDEO_STARTED:
            return{
                liveVideoStart : action.payload
            }    
        case STOP_LIVE_VIDEO:
            return{
                stopVideo: action.payload
            }            
        case SCHEDULE_LIVE_POST_SUCCESS:
            return{
                postScheduled:action.payload
            }    
        case SCHEDULE_LIVE_POST_FAILED:
            return{
                postScheduledFailed:action.payload
            }    
        case CLEAR_VIDEO_CREATE:
            return{
                createVideoSuccess: null
            }
        case CLEAR_POSTS: {
            return{
                createVideoSuccess: null,
            }
        }
        default: return state;                
    }
}