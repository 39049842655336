import {useState , useEffect} from 'react';
import { useNavigate , Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col, Table, Button, Badge, ButtonGroup } from 'react-bootstrap';
import PageHeader from '../../Header/HeaderTop';
import SidebarMenu from '../../Sidebar/SidebarNav';
import 'bootstrap/dist/css/bootstrap.min.css';
import { getSelledOrderList , ChangeOrderStatus} from '../../../redux/actions/orders.action';
import { toast, Toaster } from "react-hot-toast";
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './profileShopStyle.css';
import '../../../App.css';

function ShopOrders() {
    const [from , setFrom] = useState(new Date(new Date().setDate(new Date().getDate() - 60)));
    const [to , setTo] = useState(new Date());
    const [loading , setLoading] = useState(true);
    const [orderList , setOrderList] = useState('');
    const dispatch = useDispatch();
    const orderResult = useSelector(state =>state.order);

    useEffect(()=>{ 
        getOrders();
    },[])

    const getOrders = () =>{
        const data = {from:from.toISOString() , to:to.toISOString()}
        dispatch(getSelledOrderList(data))
    }

    useEffect(()=>{
        if(orderResult && orderResult.selledOrderList){
            setOrderList(orderResult.selledOrderList.orders)
            setLoading(false)
        }
        if(orderResult && orderResult.orderError){
            setLoading(false);
            toast.error(orderResult.orderError, {
                position: 'top-center', 
            });

        }
        if(orderResult && orderResult.selledOrderError){
            setLoading(false)
        }
        if(orderResult && orderResult.orderStatus){
            toast.success(orderResult.orderStatus.message, {
                position: 'top-center', 
            });
            getOrders();
        }
        if(orderResult && orderResult.orderStatusError){
            toast.error(orderResult.orderStatusError.message, {
                position: 'top-center', 
            });
        }
    },[orderResult])


    const handleTotalAmount = (quantity , amount) =>{
        const total = Number(quantity)*Number(amount).toFixed();
        return total
    }

    const onDateChange = (date, name) =>{
        if(name === 'startDate'){
            setFrom(date)
            const data = {from:date.toISOString() , to:to.toISOString()}
            dispatch(getSelledOrderList(data))
            setLoading(true)
        }
        if(name === 'endDate'){
            setTo(date);
            const data = {from:from.toISOString() , to:date.toISOString()}
            dispatch(getSelledOrderList(data))
            setLoading(true)
        }
    }

/************   using for change the order statue like ( accepet , decline , cancel , complete , also refund payment status) ***************/

    const handleStausChange = (orderId , status )=>{
        const data = {orderId:orderId , status:status};
        dispatch(ChangeOrderStatus(data));
    }
/***************************************************************************************************************************************** */    


    const renderShippingAddress = (first , last , address , paymnet) =>{
        const jsonObject = JSON.parse(address);
        if(paymnet !=="COD"){
            return (
                <address>
                    {`${first} ${last} , ${jsonObject.address_line_1} , ${jsonObject.admin_area_2} , ${jsonObject.postal_code} , ${jsonObject.country_code} `}
                </address>
            )
        }else{
            return (
                <address>
                    {`${first} ${last} , ${jsonObject.address1} , ${jsonObject.city} , ${jsonObject.postal_code} , ${jsonObject.country_code} `}
                </address>
            )
        }    
    }

    const handleButton= (item) =>{
        if(item.order_status === 'Pending'){
            return (
                <ButtonGroup aria-label="Basic example">
                    <Button variant='primary' size='sm' onClick = {()=>handleStausChange(item.order_id , 'Accepted')}>Accept</Button>
                    <Button variant='secondary' size='sm' onClick = {()=>handleStausChange(item.order_id , 'Decline')}>Decline</Button>
                </ButtonGroup>

            )
        }
        if(item.order_status === 'Cancel' || item.order_status === 'Decline' || item.payment_status_status === 'Refund Pending'){
            return (
                <Button variant='primary' size='sm' onClick = {()=>handleStausChange(item.order_id , 'Refunded')}>Refund</Button>
            )
        }
        if(item.order_status === 'Accepted'){
            return (
                <Button variant='primary' size='sm' onClick = {()=>handleStausChange(item.order_id , 'Completed')}>Complete</Button>
            )
        }
        if(item.order_status === 'Completed'){
            return (
               ''
            )
        }

    }


    return (
        <>
            <PageHeader />
            <div className='page--content'>
                <SidebarMenu />
                <div className='discover--module common--wrapper order--table'>
                    <Container>
                        <Row className='justify-content-center'>
                            {!loading ? 
                                <>
                                    <div className='col-sm-12 mb-3'>
                                        <div className='d-flex align-items-center justify-content-end'>
                                            <DatePicker
                                                selected={from}
                                                dateFormat="dd/MM/yyyy"
                                                onChange={(date) => onDateChange(date, "startDate")}
                                                selectsStart
                                                className="form-control"
                                                startDate={from}
                                                endDate={to}
                                            />
                                            <DatePicker
                                                selected={to}
                                                dateFormat="dd/MM/yyyy"
                                                onChange={(date) => onDateChange(date, "endDate")}
                                                selectsEnd
                                                className="form-control"
                                                startDate={from}
                                                endDate={to}
                                                minDate={from}
                                            />
                                        </div>
                                    </div>
                                    {(orderList && orderList.length > 0)
                                        ?
                                            <Col sm={12}>
                                                <Table striped responsive variant="dark">
                                                    <thead>
                                                        <tr>
                                                            <th className='text-start'>Order ID</th>
                                                            <th className='text-start'>Product</th>
                                                            <th>Total Items</th>
                                                            <th>Order Date</th>
                                                            <th>Order Status</th>
                                                            <th>Payment Status</th>
                                                            <th>Order Total</th>
                                                            <th>Shipping</th>
                                                            <th>Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            orderList.map((item , ind)=>{
                                                                return (
                                                                    <tr key={ind}>
                                                                        <td className='text-start'>{item.order_id}</td>
                                                                        <td className='text-start'>
                                                                            <span className='pro--image'>
                                                                                <img src={item.marketplaceProduct.marketplaceProductMeta[0].meta_value} alt='..' />
                                                                                <strong>{item.marketplaceProduct.title}<small>Buyer: {item.buyer.first}</small></strong>
                                                                            </span>
                                                                        </td>
                                                                        <td>{item.quantity}</td>
                                                                        <td>{moment(item.createdAt).format('LL')}</td>
                                                                        <td><Badge variant="info" className='bg-info'>{item.order_status}</Badge></td>
                                                                        <td>{item.payment_status}</td>
                                                                        <td>${handleTotalAmount(item.quantity , item.marketplaceProduct.price)}</td>
                                                                        <td>
                                                                          
                                                                            {renderShippingAddress(item.buyer.first , item.buyer.last , item.shipping_details , item.payment_method)}
                                                                            
                                                                        </td>
                                                                        <td>
                                                                            {/* { (item.payment_method !== 'COD' && item.order_status !== 'Cancel') ? 
                                                                                <>
                                                                                    <ButtonGroup aria-label="Basic example">
                                                                                        <Button variant='primary' size='sm' onClick = {()=>handleStausChange(item.order_id , 'Accepted')}>Accept</Button>
                                                                                        <Button variant='secondary' size='sm' onClick = {()=>handleStausChange(item.order_id , 'Decline')}>Decline</Button>
                                                                                    </ButtonGroup>
                                                                                </>
                                                                                :
                                                                                    <Button variant='primary' size='sm' onClick = {()=>handleStausChange(item.order_id , 'Refunded')}>Refund</Button>
                                                                            }         */}
                                                                            {handleButton(item)}
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </Table>
                                            </Col>
                                        :
                                            <Col sm={12}>
                                                <h3>Order Not Found</h3>
                                            </Col>    
                                    }
                                </>
                            
                            
                            :
                                <Col sm={12}>
                                    <div className='text-center spinner--loader'></div>
                                </Col>    
                            
                            }
                        </Row>
                    </Container>
                </div>
                <Toaster/>
            </div>
        </>
    );
}

export default ShopOrders;