import React, { Component} from 'react'
import { connect } from 'react-redux';
import { Container, Row, Col,ListGroup} from 'react-bootstrap';
import PageHeader from '../Header/HeaderTop';
import SidebarMenu from '../Sidebar/SidebarNav';
import { BsCardList, BsGridFill} from 'react-icons/bs';
import { toast, Toaster } from "react-hot-toast";
import InfiniteScroll from 'react-infinite-scroller';
import 'bootstrap/dist/css/bootstrap.min.css';
import { getVisualPostList } from '../../redux/actions/visualPost.action';
import { changePostStatus } from '../../redux/actions/post.action';
import {decodedLoggedUser ,likePosts} from '../../helpers/auth';
import StreamDesignView from '../DesignView/StreamDesignView';
import './visualStyle.css';
import '../../App.css';
import { withRouter } from '../../wrapper/withRouter';

class VisualPostsMain  extends Component {
    constructor(props){
        super(props)
        this.state =  {
            visualPostList : [] ,
            visualPostMeta : '',
            loading : false , 
            page : 1 ,
            perPage : 10 ,
            loadingState : false ,
            isActive : true ,
            userId : '',
            status : '1',
            likes : []
        }
    }

    componentDidMount = async () => {
        if (this.props.auth.loggedIn) {
            let token = decodedLoggedUser()
            this.setState({userId:token.aud})
        }
        await this.getVisualPosts();
    }

    loadMoreItems() {
        if (this.state.loadingState) {
            return;
        } else {
            this.getVisualPosts();
        }
    }

    getVisualPosts = async() =>{
        const {perPage , page , visualPostList} = this.state 
        const data = {status:'1' , perPage:perPage , page:page};
        if (this.state.page == 1) {
            this.setState({ loading: true })
        }
        await this.props.getVisualPostList(data);
        if(this.props.visualPost  && this.props.visualPost.success){
            var posts = visualPostList ;
            Array.prototype.push.apply(posts, this.props.visualPost.visualPostList.visualPost);
            posts = posts.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i)
            this.setState({
                visualPostList : posts ,
                loading: false,
                loadingState: false,
            })
            let totalPages = this.props.visualPost.visualPostList.visualPostPostMeta 
            this.setState({visualPostMeta:totalPages})
            if(posts.length !== totalPages){
                this.setState({page: page+1})
            }else{
                this.setState({ loadingState: true })
            }
        }
        if(this.props.visualPostError && this.props.visualPostError.message){
            toast.error(this.props.visualPostError.message , {
                position: 'top-center', 
            });
            this.setState({ loading: false })
        }
    }

    handleToggle = () =>{
        this.setState({isActive : !this.state.isActive})
    }

    shareLink = (slug) =>{
        const location = `window.location.href/${slug}`
    }

    handleCopyText = ()=>{
        toast.success('Link copy to clipboard', {position: 'top-center'});
    }

/*********************************************************************************************** */

    handleLike = async (data) => {
        // if(Number(data.user.id) !== Number(this.state.userId)){
            likePosts(this.state.userId, data.id)
        // }
    }

    handleCommentsLength = (comments) =>{
        // if(comments && comments.length > 0){
        //     const activeComments = comments.filter((item , index)=>{
        //         if(item.status === '1'){
        //             return item
        //         }
        //     })
        //     if(activeComments && activeComments.length >0){
        //         return (
        //             <small className='ms-2'>{activeComments.length}</small>
        //         )
        //     }
        // }
        if(comments && comments.length > 0){
            const validComments = comments.filter((item , index)=>{
                if(item.status === '1'){
                    if(item.comment_id === null){
                        return item
                    }else{
                        const reply = comments.find((c) => Number(c.id) === Number(item.comment_id));
                        if(reply && reply.status === '1'){
                            return item
                        }
                    }
                }
            });
            if(validComments && validComments.length > 0){
                return (
                    <small className='ms-2'>{validComments.length}</small>
                )
            }

        }
    }

    visualPosts = () =>{
        const {visualPostList , isActive , userId , likes} = this.state 
        if(visualPostList && visualPostList.length > 0){
            return (
                <Col sm={12}>
                    <Row className={isActive ? 'justify-content-start grid--view' : 'justify-content-center list--view'}>
                    {
                        visualPostList.map((item , idx)=>{
                            if(item.user){
                                return(
                                    <>
                                        <StreamDesignView
                                            post={item}
                                            userId = {this.state.userId}
                                            copyText = {this.handleCopyText}
                                            handleChangePostStatus = {this.handleChangePostStatus}
                                            handleCommentsLength = {this.handleCommentsLength}
                                            handleLike = {this.handleLike}
                                            isActive = {isActive}
                                        />
                                    </>
                                  
                                )
                            }    
                        })
                    }
                    </Row>
                </Col>
            )
        }
    }

    handleChangePostStatus = async(postId , status) =>{
        const data = {postId:postId , status:status}
        await this.props.changePostStatus(data)
        if(this.props.postStatus && this.props.postStatus.success){
            this.setState({status : status})
            // this.getTextPosts();
            toast.success(this.props.postStatus.message, {
                position: 'top-center', 
            });
            if( status === '0'){
                this.props.navigate('/')
            }
            if(status === '2'){
                this.props.navigate('/drafts')
            }
        }
        if(this.props.postStatusError && this.props.postStatusError.message){
            toast.error( this.props.postStatusError.message, {
                position: 'top-center', 
            });
        }
    }

    componentDidUpdate(prevProps, prevState){
        if(prevState.status !== this.state.status){
            this.setState({
                visualPostList : [] ,
                visualPostMeta : '',
                loading : false , 
                page : 1 ,
                perPage : 10 ,
                loadingState : false ,
                status: '1'
            },()=>this.getVisualPosts())
        }

        if(prevState.isActive !== this.state.isActive){
            this.setState({
                visualPostList : [] ,
                visualPostMeta : '',
                loading : false , 
                page : 1 ,
                perPage : 10 ,
                loadingState : false ,
                status: '1'
            },()=>this.getVisualPosts())
        }
    }


    render(){
        const {loading , visualPostList , loadingState , isActive , page , visualPostMeta , likes } = this.state ;
        return (    
            <>
                <PageHeader />
                <div className='page--content'>
                    <SidebarMenu />
                    <div className='discover--module common--wrapper audio--main--page'>
                        <Container>
                            <ListGroup as="ul" horizontal>
                                <ListGroup.Item as="li" className={isActive ? 'inactive' : 'active'}  onClick={this.handleToggle}><BsGridFill /></ListGroup.Item>
                                <ListGroup.Item as="li" className={isActive ? 'active' : 'inactive'}  onClick={this.handleToggle}><BsCardList /></ListGroup.Item>
                            </ListGroup>
                            <Row id="scrollableDiv" ref={(ref) => this.scrollParentRef = ref} style={{ height: "100%" }}>
                                {!loading  ?
                                    (visualPostList && visualPostList.length > 0) ?
                                        <InfiniteScroll
                                            pageStart={0}
                                            loadMore={() => this.loadMoreItems()}
                                            hasMore={!loadingState}
                                            loader={
                                                <Col sm={12}>
                                                    <div className='text-center spinner--loader'></div>
                                                </Col>
                                            }
                                            scrollableTarget="scrollableDiv"
                                            getScrollParent={() => this.scrollParentRef}
                                            window={false}
                                        >
                                  
                                            {this.visualPosts()}
                                        </InfiniteScroll>    

                                    :   <h2 className="mt-5 text-center">Posts Not Found</h2>        
                                :
                                <Col sm={12}>
                                    <div className='text-center spinner--loader'></div>
                                </Col>
                                }
                            </Row>
                        </Container>
                    </div>
                </div>
                <Toaster/>
            </>
        );
    }    
}

const maopStateToProps = (state) => {
    return{
        visualPost : state.visualPost.visualPostList, 
        likesPosts : state.visualPost.postLikes,
        visualPostError : state.visualPost.visualPostError,
        auth : state.auth ,
        postStatus : state.post.changeStatusSuccess,
        postStatusError: state.post.changeStatusError
    } 
}

const dispatchActions = {getVisualPostList , changePostStatus} ; 
export default connect(maopStateToProps, dispatchActions)(withRouter(VisualPostsMain));