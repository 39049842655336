import { get } from 'lodash';
import API from '../../helpers/api';
import * as auth from '../../helpers/auth';
import {
    CREATE_TEXT_SUCCESS,
    CREATE_TEXT_FAILED,
    TEXT_COMMON_ERROR,
    GET_TEXT_FAILED,
    GET_TEXT_SUCCESS,
    GET_SINGLE_TEXT_SUCCESS,
    GET_SINGLE_TEXT_FAILED,
    EDIT_TEXT_POST_SUCCESS,
    EDIT_TEXT_POST_FAILED,
    CLEAR_TEXT_CREATE,
    TEXT_TEMP_DATA
  } from "./types";

const config = {
  headers: {
    "Content-Type": "multipart/form-data; charset=utf-8;"
    
  }
}

function errorRequest(err, dispatch) {
  let data = get(err, 'response.data', null);
  data = data || get(err, 'response');
  data = data || err;
  if (data.error) {
    dispatch({
      type: TEXT_COMMON_ERROR,
      payload: data.error.message,
    });
  } else if (data.status === 401) {   
    auth.logout()
  } else {
    dispatch({
      type: TEXT_COMMON_ERROR,
      payload: data.message,
    });
  }
}


export const clearTextCreate = () =>{
  return async (dispatch) => {
    dispatch({type:CLEAR_TEXT_CREATE});
  }
}


/** using for create the text post  */

export const createTextPost = (payload) =>{
  return async (dispatch) => {
      try{
          const response = await API.apiUploadFileURL('post', '/create-text' , payload , config) ;
          if(response.data && response.data.success){
              await dispatch({ type: CREATE_TEXT_SUCCESS, payload: response.data});
          }else{
              await dispatch({ type: CREATE_TEXT_FAILED, payload: response.data});
          }
      }catch(error){
          errorRequest(error, dispatch);
      }
  }
}

/** using for get the text post list by  ///   perPage /  */

export const getTextPostList = (payload) =>{
  return async (dispatch) => {
      try{
          const response = await API.apiGetUrl('post', '/text-post' , payload) ;
          if(response.data && response.data.success){
            await dispatch({ type: GET_TEXT_SUCCESS, payload: response.data});
          }else{
            await dispatch({ type: GET_TEXT_FAILED, payload: response.data});
          }
      }catch(error){
          errorRequest(error, dispatch);
      }
  }
}

export const getSingleTextPost = (slug) =>{
  return async (dispatch) => {
    try{
        const response = await API.apiGetUrl('post', '/single/text' , `/${slug}`) ;
        if(response.data && response.data.success){
          await dispatch({ type: GET_SINGLE_TEXT_SUCCESS, payload: response.data});
        }else{
          await dispatch({ type: GET_SINGLE_TEXT_FAILED, payload: response.data});
        }
    }catch(error){
        errorRequest(error, dispatch);
    }
  }
}

export const editTextPost = (payload)=>{
  return async (dispatch) => {
    try{
        const response = await API.apiUpdateUploadFile('post', '/edit/text/post' , payload , config) ;
        if(response.data && response.data.success){
          await dispatch({ type: EDIT_TEXT_POST_SUCCESS, payload: response.data});
        }else{
          await dispatch({ type: EDIT_TEXT_POST_FAILED, payload: response.data});
        }
    }catch(error){
        errorRequest(error, dispatch);
    }
  }
}

export const saveTempText = (text) =>{
  return async (dispatch) => {
    await dispatch({ type: TEXT_TEMP_DATA , payload: text});
  }
}