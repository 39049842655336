import {useState , useEffect , useRef} from 'react';
import { useSelector, useDispatch} from "react-redux";
import {Button, Form} from 'react-bootstrap';
import { BsFillReplyAllFill, BsTrash, BsArrowLeft } from 'react-icons/bs';
import {FaPaperPlan , FaRegHeart , FaPaperPlane} from 'react-icons/fa';
import { IoMdHeart } from "react-icons/io";
import { getUserProfile } from '../../redux/actions/user.action';
import { decodedLoggedUser , SendComment , likeComments , deleteComment} from '../../helpers/auth';
import { getSinglePost } from '../../redux/actions/post.action';
import moment from 'moment';
import { Link } from 'react-router-dom';
import './LiveComments.css';

function LiveComments(prop) {
    const messageRef = useRef(null);
    const [commentList, setCommentsList] = useState();
    const [activeReplyStates, setActiveReplyStates] = useState(null);
    const [activeId, setActiveId] = useState(null);
    const [commentText , setCommentText] = useState('');
    const [commentError , setCommentError] = useState(false);
    const [user , setUser] =  useState('');
    const [userProfile , setUserProfile] =  useState('')
    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const userResult = useSelector(state => state.user);
    const commentResult = useSelector(state => state?.post?.singlePostSuccess) ;
    const getPostResult = useSelector(state => state.post);


    useEffect(()=>{
        if (auth.loggedIn) {
            let token = decodedLoggedUser()
            setUser(token)
            dispatch(getUserProfile(token.aud))
        }
    },[])

    useEffect(()=>{
        if(commentResult && commentResult.success){
            setCommentsList(commentResult.commentList)
        }
    },[commentResult])

    useEffect(() => {
        if (messageRef.current) {
          scrollToBottom();
        }
    }, [commentList]);
    
    const scrollToBottom = () => {
        if (messageRef.current) {
            messageRef.current.scrollTop = messageRef.current.scrollHeight;
        }
    };

    const handleShowToggle = (idx) => {
        setActiveReplyStates(activeReplyStates === idx ? null : idx);
    } ;

    const toggleAccordion = (id) => {
        setActiveId(activeId === id ? null : id);
    }

    useEffect(()=>{
        if(userResult.getUserDetails && userResult.getUserDetails.success){
            setUserProfile(userResult.getUserDetails.profile)
        }
    },[userResult])


    const handleComment = (e) =>{
        const {value} = e.target ; 
        if(value){
            setCommentError(false)
        }
        setCommentText(value)
    }

    const handleSummitComment = (parent , comment) =>{
        const check = ['undefined' , undefined , '' , null , 'null' ,]
        if(!check.includes(commentText)){
            if(check.includes(parent)){
                const data = {
                    user_id: user.aud,
                    post_id: prop.postId.id,
                    sequence_no: (commentList && commentList.length > 0 ) ? commentList.length : 0 + 1,
                    receiver_id: prop.postId.user_id,
                    comment_id: null,
                    comment_type: "main",
                    message:commentText,
                    reply: ''
                }
                SendComment(data);
                setActiveReplyStates(null);
                setActiveId(null)
                setCommentText('')
                dispatch(getSinglePost(prop.postId.slug))
            }else{
                const data = {
                    user_id: user.aud,
                    post_id: prop.postId.id,
                    sequence_id: (commentList && commentList.length > 0 ) ? commentList.length : 0 + 1,
                    receiver_id: parseInt(comment.user_id),
                    message: commentText,
                    comment_id: parent,
                    comment_type: "reply",
                    parent_id: comment.id,
                    reply: ''
                }
                SendComment(data);
                setActiveReplyStates(null);
                setActiveId(null)
                setCommentText('')
                dispatch(getSinglePost(prop.postId.slug));
            }
        }else{
            setCommentError(true)
        }
        
    }

    const handleLikeComments = (comment) =>{
        const data = {
            post_id: comment.post_id,
            user_id: user.aud ,
            comment_id: comment.id,
            notifyUser_id: comment.user_id 
        };
        if(comment.user_id !== user.aud){
            likeComments(data)
        }    
    }

    const handleDeleteComment = (comment)=>{
        const data = {
            post_id : prop.postId.id,
            commentId: comment.id
        }
        deleteComment(data)
        // prop.updateComment();
    }

    const renderComments = () => {
        if (commentList && commentList.length > 0) {
            return (
                // <ScrollToBottom className="ROOT_CSS">
                    // <div className="wrapperComments">
                    <div className='wrapperComments' style={{height: '600px', overflowY: 'auto' }} ref={messageRef}>
                        <div className="commentsPanel newCommentsPanel"> 
                            <div className='commentsBox'> 
                                {commentList.map((item, idx) => {
                                    if(Number(item.status) === Number(1) ){
                                       return (
                                            <div className='visualUserName' key={idx}>
                                                <Link to={`/${item.user.usernames}`}>
                                                    <img
                                                        src={(item.user && item.user.image) ? item.user.image : '/images/default-avtaar.png'}
                                                        alt={item.user.first}
                                                    />
                                                    <h5>
                                                        {item.user.first}
                                                        <span>{moment(item.createdAt).format('LL')}</span>
                                                    </h5>
                                                </Link>
                                                <div className='visualDesc'>
                                                    <p>{item.message}</p>
                                                    <ul>
                                                        {Number(user.aud) == Number(item.user.id)&& 
                                                            <li>
                                                                <BsTrash onClick= {()=>handleDeleteComment(item)}/>
                                                            </li>
                                                        }    
                                                        <li>
                                                            {/* {(item.nested_comments && item.nested_comments.length > 0)? <span className='btn btn-sm btn-secondary' onClick = {()=>toggleAccordion(item.id)}> Show Reply</span> : null} */}
                                                        </li>
                                                        <li className={activeReplyStates === item.id ? 'showBox' : 'hideBox'} onClick={() => handleShowToggle(item.id)}><span className='btn btn-sm btn-primary no-background no-border'><BsFillReplyAllFill/></span></li>
                                                        <li className='ms-auto me-0' onClick = {()=>handleLikeComments(item)}> 
                                                            <span className='me-2'>
                                                                {(item?.Comment_Likes?.length && item?.Comment_Likes?.length> 0)? item?.Comment_Likes?.length : null}
                                                            </span>
                                                            {item.Comment_Likes.some(like => like?.user_id === user.aud) ? (
                                                                <IoMdHeart />) : (<FaRegHeart /> )}
                                                        </li>
                                                    </ul>
                                                    {/* {(activeId === item.id)&& renderNestedComments(item.id , item.nested_comments)} */}
                                                    {renderNestedComments(item.id , item.nested_comments)}

                                                </div>
                                                {(activeReplyStates === item.id)&& sendReply(item.id, item) }
                                            </div>
                                        
                                        )    
                                    } 
                                })}  
                            </div>
                        </div>
                    </div>
                // </ScrollToBottom>        
               
            );
        }
    }

    const renderNestedComments = (parent , nestComm) =>{
        if(nestComm && nestComm.length > 0){
            
            return (
                <>
                    <div className='commentsBox newCommentsBox'>
                        {
                            nestComm.map((item , idx)=>{
                                return (
                                        <div className='visualUserName'  key={idx}>
                                            <Link to={`/${item.user.usernames}`}>
                                                <img
                                                    src={(item.user && item.user.image) ? item.user.image : '/images/default-avtaar.png'}
                                                    alt={item.user.first}
                                                />
                                                <h5>
                                                    {item.user.first}
                                                    <span>{moment(item.createdAt).format('LL')}</span>
                                                </h5>
                                            </Link>
                                        <div className='visualDesc'>
                                            <p>{item.message}</p>
                                            <ul>
                                                {Number(user.aud) == Number(item.user.id)&& 
                                                    <li>
                                                        <BsTrash onClick= {()=>handleDeleteComment(item)}/>
                                                    </li>
                                                }    
                                                <li className={activeReplyStates === item.id ? 'showBox' : 'hideBox'} onClick={() => handleShowToggle(item.id)}><span className='btn btn-sm btn-primary no-background no-border'><BsFillReplyAllFill/></span></li>
                                                <li className='ms-auto' onClick={()=>handleLikeComments(item)}>
                                                    <span className='me-2'>{(item?.Comment_Likes?.length && item?.Comment_Likes?.length> 0)? item?.Comment_Likes?.length : null}</span>
                                                    {item.Comment_Likes.some(like => like?.user_id === user.aud) ? (
                                                        <IoMdHeart />) : (<FaRegHeart/> 
                                                    )}
                                                </li>
                                            </ul>
                                        </div>
                                        {(activeReplyStates === item.id)&& sendReply(parent , item) }
                                    </div>
                                    

                                )

                            })
                        }
                    </div>
                    
                </>    
            )
        }
    }

    const handleKeyPress = (e,parent , comment) => {
        if (e.key === 'Enter' && !e.shiftKey) {
          // If Enter is pressed without Shift key, submit the comment
          e.preventDefault(); // Prevents adding a new line in the textarea
          handleSummitComment(parent , comment);
        }
    };
    

    const sendReply = ( parent , comment )=>{
        return (
            <div className='showTextArea replyBox'>
                <div className='visualUserName'>
                    <img src={userProfile.image ||'/images/default-avtaar.png'} alt={userProfile.first} />
                    <div className='liveComments'>
                        <Form.Control as='textarea' rows={1} placeholder='Your Comments'  value = {commentText} onChange= {handleComment} onKeyPress={(e)=>handleKeyPress(e , parent , comment)}/>
                        <Button variant="primary" onClick={(() => handleSummitComment( parent , comment))}><FaPaperPlane /></Button>
                        {commentError && <small className="error error-massege text-danger">Comment is required </small>}
                    </div>
                </div>
            </div>
        )
    }


  return (
    <>
        <div className="wrapperComments">
            <div className="commentsPanel newCommentsPanel"> 
                {renderComments()}
                <div className='liveComments'>
                    <Form.Control as='textarea' rows={1} placeholder='Write a comment'  value = {commentText} onChange= {handleComment} onKeyPress={handleKeyPress}/>
                    <Button variant="primary" onClick={(() => handleSummitComment())}>Post</Button>
                    {commentError && <small className="error error-massege text-danger">Comment is required </small>}
                </div>
            </div>
        </div>

    </>
  )
}

export default LiveComments;
