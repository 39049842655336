import React, {useState, useEffect} from 'react';
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap';
import PageHeader from '../Header/HeaderTop';
import SidebarMenu from '../Sidebar/SidebarNav';
import CreateLive from './CreateTabs/LiveTab';
import CreateAudio from './CreateTabs/AudioTab';
import CreateVideo from './CreateTabs/VideoTab';
import CreateText from './CreateTabs/TextTab';
import CreatePhoto from './CreateTabs/PhotoTab';
import { BsCardText, BsImages } from 'react-icons/bs';
import { useDispatch } from 'react-redux';
import { AiOutlineAudio } from 'react-icons/ai';
import { HiSignal } from 'react-icons/hi2';
import { MdVideoSettings } from 'react-icons/md';
import 'bootstrap/dist/css/bootstrap.min.css';
import { getBoardPostList } from '../../redux/actions/post.action';
import { clearLiveData , clearPostData} from '../../redux/actions/linkPost.action';
import './createFormStyle.css';
import '../../App.css';


function CreatePostForm() {
    const dispatch = useDispatch();
    const [ tab, setTab ] = useState('text');
    const [tabData, setTabData] = useState({
        text: null,
        photo: { single : null , album : null },
        video: null,
        audio: { single : null , album : null },
        live: {video : null , audio: null }
    });

    const handleTabData = (tabName, data) => {
        setTabData(prevTabData => ({
            ...prevTabData,
            [tabName]: data
        }));
    }


    const handlePhotoData = (tabName, data) => {
        setTabData(prevData => ({
            ...prevData,
            photo: {
                ...prevData.photo,
                [tabName]: data
            }
        }));
    };

    const handleAudioData = (tabName, data) => {
        setTabData(prevData => ({
            ...prevData,
            audio:{
                ...prevData.audio,
                [tabName]: data
            }
        }));
    };

    const handleLiveData = (tabName, data) => {
        setTabData(prevData => ({
            ...prevData,
            live : {
                ...prevData.live,
                [tabName]: data
            }
        }));
    };

    useEffect(()=>{
        dispatch(getBoardPostList());
        dispatch(clearLiveData());
        dispatch(clearPostData());
    },[]);

    const handleTab = (tabName) => {
        setTab(tabName)
    }

    return (
        <>
            <PageHeader />
            <div className='page--content'>
                <SidebarMenu />
                <div className='discover--module common--wrapper create--form'>
                    <Container>
                        <Row className='justify-content-center'>
                            <Col sm={12} lg={10}>
                                <div className='Tabs--vertical'>
                                    <Tabs 
                                        activeKey={tab} 
                                        id="create--post--tabs" 
                                        onSelect={(e) => handleTab(e)}
                                        mountOnEnter={true}
                                        unmountOnExit={true}
                                    >
                                        <Tab eventKey="text" title={<span><BsCardText/> Text</span>}>
                                            <CreateText savedData={tabData.text} saveData={(data) => handleTabData('text', data)}/>
                                        </Tab>
                                        <Tab eventKey="photo" title={<span><BsImages/> Photo</span>}>
                                            <CreatePhoto savedData={tabData.photo} saveDataToForm={(tab , data)=>handlePhotoData(tab, data)}/>
                                        </Tab>
                                        <Tab eventKey="video" title={<span><MdVideoSettings/> Video</span>}>
                                            <CreateVideo savedData={tabData.video} saveData={(data) => handleTabData('video', data)}/>
                                        </Tab>
                                        <Tab eventKey="audio" title={<span><AiOutlineAudio/> Audio</span>}>
                                            <CreateAudio savedData={tabData.audio} saveDataToForm={(tab , data)=>handleAudioData(tab, data)}/>
                                        </Tab>
                                        <Tab eventKey="live" title={<span><HiSignal/> Live</span>}>
                                            <CreateLive savedData={tabData.live} saveDataToForm={(tab , data)=>handleLiveData(tab, data)}/>
                                        </Tab>
                                    </Tabs>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    );
}

export default CreatePostForm;