import { 
    GET_USER_SUCCESS,
    GET_USER_FAILED,
    USER_COMMON_ERROR,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_FAILED,
    GET_SINGLE_USER_SUCCESS,
    GET_SINGLE_USER_FAILED,
    USER_CREATED_POSTS_SUCCESS,
    USER_CREATED_POSTS_FAILED,
    USERS_LIST_SUCCESS,
    USERS_LIST_FAILED,
    STEAMER_LIST_SUCCESS,
    STEAMER_LIST_FAILED,
    LIKE_POST,
    SEARCH_USER,
    UPDATE_HEADER_SUCCESS,
    UPDATE_HEADER_FAILED,
    UNMUTE_CREATER_SUCCESS,
    UNMUTE_CREATER_FAILED,
    MUTE_CREATER_SUCCESS,
    MUTE_CREATER_FAILED
  } from "../actions/types";
import { unmuteCreater } from "../actions/user.action";

  import { errorReducer } from "./error.reducer";

  const initialState = {
    getUserDetails: null,
  };
export default (state = initialState, action) => {
    switch (action.type) {
        case GET_USER_SUCCESS:
            return{
              getUserDetails:action.payload,
            
            }
        case GET_USER_FAILED:
            return{
                userError:action.payload,
               
            }    
        case UPDATE_USER_SUCCESS:
            return{
                userUpdatedSuccess:action.payload,
        
            }        
        case UPDATE_USER_FAILED:
            return{
                userError:action.payload,
         
            }          
        case USER_COMMON_ERROR:
            return{
                userError:action.payload,
            }           
        case GET_SINGLE_USER_SUCCESS:
            return{
                singleUser:action.payload,
            }      
        case GET_SINGLE_USER_FAILED:
            return{
                userError:action.payload,
            }       
        case USER_CREATED_POSTS_SUCCESS:
            if(state.userPosts !==null){
                if(action.payload.pageNumber && action.payload.pageNumber !== '1'){
                    const copyPost = state.userPosts
                    let  post = state.userPosts.profilePostList.peofilePost
                    let newPost = action.payload.profilePostList.peofilePost
                    var mergePost = [...post , ...newPost]
                    mergePost = mergePost.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i)
                    copyPost.profilePostList.peofilePost = mergePost
                    return{
                        ...state,
                        userPosts:copyPost
                    }    
                }else{
                    return {
                        userPosts:action.payload,
                    }
                }    
            }else{
                return{
                    ...state , 
                    userPosts:action.payload,
                }
            }
            // return{
            //     userPosts:action.payload,
            // }       
        case USER_CREATED_POSTS_FAILED:
            return{
                userPostError:action.payload,
            } 
                  
        case USERS_LIST_SUCCESS:
            return {
                ...errorReducer(state, action),
                usersList: action.payload
            }
        case USERS_LIST_FAILED:
            return {
                ...errorReducer(state, action),
                usersList: null,
            }
        case STEAMER_LIST_SUCCESS:
            return{
                ...state ,
                streamUserList:action.payload
            }
        case LIKE_POST:
            if(state.userPosts){
                const postLikes = state.userPosts.profilePostList
                const likesIndex = postLikes.peofilePost.findIndex(post => post.id === action.payload.post.postId)
                if(likesIndex !== -1){
                    var copyProfilePost = state.userPosts
                    copyProfilePost.profilePostList.peofilePost[likesIndex].Likes = action.payload.post.likes
                    const updateLikes = {...copyProfilePost}
                    return{
                        userPosts: updateLikes,
                    }     
                }      
            }    
        case  SEARCH_USER:
           return{
            searchedUserList : action.payload
        }  
        case MUTE_CREATER_SUCCESS:
            return{
                userMuted:action.payload
            }
        case MUTE_CREATER_FAILED:
            return{
                muteError:action.payload
            }
        case UNMUTE_CREATER_SUCCESS:{
            return{
                userUnmuted:action.payload
            }
        }
        case UNMUTE_CREATER_FAILED:{
            return{
                userUnmutedError:action.payload
            }
        }
        // case UPDATE_HEADER_SUCCESS:
        //     return{
        //         ...state ,
        //         headerUpdated: action.payload
        //     }
        default: return state;    
    }    

}