import React, { useState, useEffect } from "react";
import { Col, Container, Row, Form, ListGroup, Button , Modal, Spinner} from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import LoadingOverlay from 'react-loading-overlay';
import {bookSchedule} from "../../redux/actions/schedule.action";
import { getSingleUser } from "../../redux/actions/user.action";
import { v4 as uuidv4 } from 'uuid';
import API from "../../helpers/api";

function BookCheckout(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userResult = useSelector(state => state.user);
    const scheduleState = useSelector(state => state.schedule);

    const [purchaseUnits, setPurchaseUnits] = useState({});
    const [loading , setLoading]= useState(false);
    const [paymentLoading , setPaymentLoading] = useState(false);
    const [loadingContent , setLoadingContent] = useState('Order placing ,please wait...')
    const { id } = useParams();
   

    useEffect(()=>{
        getUser();
    },[])



    useEffect(() => {
        if (scheduleState.error) {
            toast.error(scheduleState.error.message);
        }
        if (!scheduleState.error) {
            if(scheduleState.scheduleBooked && scheduleState.scheduleBooked.success){
                toast.success('Event Booked');
                setPaymentLoading(false)
                navigate('/meetings')
                
            }
        }
    },[scheduleState]);

    useEffect(()=>{
        if(userResult?.singleUser && userResult?.singleUser?.success){
            let unit = {
                description:props.eventDetail.name,
                payee: {
                    email_address: userResult.singleUser.profile.paypal_email
                },
                reference_id: uuidv4(),
                amount: {
                    currency_code: 'USD',
                    value: props.eventDetail.amount
                },
            }
            setPurchaseUnits(unit)
            setLoading(false)
        }else{
            setLoading(false)
        }
        },[userResult])

    const getUser = () =>{
        setLoading(true)
        dispatch(getSingleUser(id))
        
    
    }

/***************************************************    payment functions  ******************************************************************/

    const createPaypalOrder = (data, actions) => {
        return actions.order.create({ purchase_units: [purchaseUnits] });
    }

    const captureOrder = async (orderData) => {
        const payload = { orderId: orderData.orderID };
        try {
            const response = await API.apiPostUrl('marketplace', '/capture-order', payload);
            if (response && response.data) {
                if(response.data && response.data.success){
                    if(response.data && response.data.oderCapture){
                        return response?.data?.oderCapture
                    }
                }
            }
        } catch (error) {
          toast.error(error.response?.data?.message || 'Error capturing payment', { position: 'top-center' });
        }
    };


   

    const onApprove = async (data, actions) => {
        try {
          const capture = await captureOrder(data);
          if (capture && capture.status === 'COMPLETED') {
            toast.success('Payment successfully completed', { position: 'top-center' });
            dispatch(bookSchedule(props.meetingData));
            setPaymentLoading(true)
          } else {
            toast.error('Payment could not be completed', { position: 'top-center' });
          }
        } catch (error) {
          toast.error('Error completing the transaction', { position: 'top-center' });
        }
      };


    const handlePaymentCancel = (data) => {
        //**  ----- Cancel Payment ----- */
        toast.error( 'Payment Has been Canceled For the Paypal Order ID' , data.orderID );
    }

    const style = {"layout":"vertical"}

    return (
        <Row className="justify-content-center">
            <Col>
                <Button onClick={()=>props.back()}>Back</Button>
                {(loading)&& <Spinner/>}
                {(!loading)&&
                    <>
                        <LoadingOverlay
                            active={paymentLoading}
                            spinner
                            text={loadingContent}
                            className='h-100'
                        >
                            <Container className='h-100'>
                                <Row className="justify-content-center align-items-center checkout_wrapper text-center">
                                    <Col sm={6}>
                                        <PayPalScriptProvider  options={{ clientId: process.env.REACT_APP_PAYPAL_CLIENTID }} >
                                            <PayPalButtons
                                                style={style} 
                                                disabled={false}
                                                forceReRender={[style]}
                                                fundingSource={undefined}
                                                createOrder={createPaypalOrder}
                                                onApprove={(data , action )=>onApprove(data , action )}
                                                onCancel={handlePaymentCancel}
                                                onError={(e) =>  toast.error('INVALID_REQUEST', {position: 'top-center', 
                                                })}
                                            />
                                        </PayPalScriptProvider>
                                    </Col> 
                                </Row>
                            </Container>

                        </LoadingOverlay>
                    </>
                }
               
            </Col>
        </Row>
    )
}

export default BookCheckout

