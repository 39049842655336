import { 
    SEARCH_SUCCESS,
    SEARCH_FAILED,
    SEARCH_COMMON_ERROR
} from "../actions/types";

const initialState = {
    searchResultList: null,
    searchError: null
   
};


export default (state = initialState, action) => {
    switch (action.type) {
        case SEARCH_SUCCESS:
            return{
                searchResultList:action.payload
            }
        case SEARCH_FAILED:
            return{
                searchError: action.payload
            }
        case SEARCH_COMMON_ERROR:
            return{
                searchError: action.payload
            }    
        default: return state;        
    }
}