import React, { useState, useEffect, useRef } from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { WebRTCAdaptor } from '@antmedia/webrtc_adaptor';
import { useNavigate } from 'react-router-dom';


function AudioSubcriber(props) {
    const [playing, setPlaying] = useState(false);
    const [websocketConnected, setWebsocketConnected] = useState(false);
    const [streamId, setStreamId] = useState(props.streamId);
    const [adaptorInitialized, setAdaptorInitialized] = useState(false);
    const webRTCAdaptor = useRef(null);
    var playingStream = useRef(null);
    const navigate = useNavigate()
   

    const handlePlay = () => {
      playingStream.current=streamId
      webRTCAdaptor.current.play(streamId);
      setPlaying(true)
    };


    const handleStopPlaying = () => {
      webRTCAdaptor.current.stop(playingStream.current);
    };


  useEffect(() => {
    if(webRTCAdaptor.current === undefined || webRTCAdaptor.current === null){
      webRTCAdaptor.current = new WebRTCAdaptor({
        websocket_url: 'wss://test.antmedia.io:/WebRTCAppEE/websocket',
        mediaConstraints: {
          video: false,
          audio: false,
        },
        peerconnection_config: {
          iceServers: [{ urls: 'stun:stun1.l.google.com:19302' }],
        },
        sdp_constraints: {
          OfferToReceiveAudio: true,
          OfferToReceiveVideo: false, // Set to true to receive video
        },
        remoteVideoId: 'remoteVideo',
        callback: (info, obj) => {
          if (info === 'initialized') {
            setWebsocketConnected(true);
            handlePlay();
          }
        },
        callbackError: function (error, message) {
          if (error === 'no_stream_exist'){
            navigate('/')
            handleStopPlaying();
            setPlaying(false);
          }
        },
        
      });
    }
  },[]);


 

  return (
    <Container className="text-center">
      <Row className="mb-4 mt-4">
        <Col>
        <div className='audioWrapper'>
          <audio
            id="remoteVideo"
            controls
            autoPlay
          /><div class="live-text"><span class="live-dot">•</span><span class="live-word">LIVE</span></div> 
          </div>
         
        </Col>
      </Row>
    </Container>
  )
}

export default AudioSubcriber


