import React, { Component } from 'react'
import { Container, Row, Col, Dropdown, ListGroup} from 'react-bootstrap';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';
import API from '../../helpers/api';
import { BsCardList, BsGridFill, BsHeart } from 'react-icons/bs';
import { FaEllipsisV, FaRegComment } from 'react-icons/fa';


export default class LinkRender extends Component {
    constructor(props) {
        super(props);
        this.state = {
            post: props.post ,
            metadata : ''
        }
    }
    async componentDidMount() {
        if (this.props.post) {
            const body= { url: this.state.post.media  }
            const metaResult = await API.apiGetUrl('post', '/get-url-meta' , body) ;
            if( metaResult.data && metaResult.data.success){
                this.setState({ metadata : metaResult.data.webMeta })
            }
        }
    }

    render() {
            const check = ['undefined' , undefined , null , '' , [] , {}]
            if(!check.includes(this.state.metadata)){
                return (
                    <>
                         {this.state.metadata.image && !this.state.metadata.image.includes('w=1200&h=630&crop=top&fit=crop')&&
                            <div className="Gr--streamImage">
                                <Link to={this.state.post.media}>
                                    <LazyLoadImage
                                        width="100%"
                                        placeholderSrc="/images/placeholder.gif"
                                        effect="blur"
                                        src={ this.state.metadata.image }
                                        onError={(e) => { e.target.src = '/images/place_holder.jpg'; }}
                                        alt={this.state.metadata.siteName}
                                    />
                                </Link>
                            
                            </div>
                        }
                        <div className="Gr--streamUser">
                            <Link to={this.state.post.media}>
                                <h4> {this.state.metadata.siteName || '' }</h4>
                            </Link>
                            <pre>{this.state.metadata.description || '' }</pre>
                        </div>
                    </>
                )
            }else{
                return(
                    <>
                        <Link to={this.state.post.media}>
                            {this.state.post.media}
                        </Link>
                    </>
                )
            }
        }
       
    }
