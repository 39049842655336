import React, { useState , useEffect} from 'react';
import { connect } from 'react-redux';
import { useSelector, useDispatch} from "react-redux";
import { Row, Col, Dropdown, ListGroup, Button, Form } from 'react-bootstrap';
import { BsHeart, BsReply, BsTrash, BsCardList, BsGridFill , BsPlay } from 'react-icons/bs';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import moment from 'moment';
import { toast, Toaster } from "react-hot-toast";
import InfiniteScroll from 'react-infinite-scroller';
import { checkHTML , convertToPlainText , valid } from '../../utils/validations';
import LinkRender from '../Link/LinkRender';
import { BiPlus } from 'react-icons/bi';
import { FaPaperPlane, FaEllipsisV, FaRegComment } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { getProfilePosts } from '../../redux/actions/user.action';
import { decodedLoggedUser } from '../../helpers/auth';

function SearchPostsTab(props) {
    const [searchPostList , setSearchPostList] = useState('');
    const [isActive , setIsActive] = useState(true);
    const [loading , setLoading] = useState(false);
    const [userId , setUserId] = useState('')
    const auth = useSelector(state => state.auth);

    useEffect(()=>{
        if (auth.loggedIn) {
            let token = decodedLoggedUser();
            setUserId(token.aud)
        }

        if(props && props.post){
            setSearchPostList(props.post)
        }
    },[props])
   

    const renderSearchPosts = () =>{    
        if(searchPostList && searchPostList.length > 0 ) {
            return (
                <>
                    {searchPostList.map((item ,idx)=>{
                        if(item && item.PostCategory){
                            if(item.PostCategory.slug === 'audio'){
                                return renderAudio(item)
                            }
                            if(item.PostCategory.slug === 'text'){
                                return renderText(item)
                            }
                            if(item.PostCategory.slug === 'film'){
                                return renderVideo(item)

                            }
                            if(item.PostCategory.slug === 'visual'){
                                return renderVisual(item)

                            }
                            if(item.PostCategory.slug === 'Live-Video'){
                                return renderLiveVideo(item)
                            }
                        }    
                    })}
                </>
            )
        }

    }


    const renderLiveVideo = (video) =>{
        if(valid(video)){
            return (
                <Col sm="12" lg="9">
                    <div className='single--stream--post mt-4'>
                        <Link to={`/live-video/${video.slug}`}>
                            <div className="Gr--stream-user">
                                <div className="Gr--streamImage">
                                    <span className='play--icon'><BsPlay/></span>
                                        <LazyLoadImage
                                            width="100%"
                                            placeholderSrc="/images/placeholder.gif"
                                            effect="blur"
                                            // src={valid(video.thumbnail) ? video.thumbnail : '/images/thumbnail.jpg'}
                                            src={(video.user && valid(video.user.image)) ? video.user.image : "/images/user.png"}
                                            alt={video.name}
                                        />
                                </div>
                                <div className="Gr--streamUser">
                                    <h4>{video.user ? video.user.first: ''}</h4>
                                    <p><span>{moment(video.createdAt).format('LL')}</span> </p>
                                </div>
                            </div>
                            <div className='Gr--streamPost--content contextonlywrap video--post'>
                                <h2 className='mb-4'>{checkHTML(video.name) ? convertToPlainText(video.name) : video.name}</h2>
                                <LazyLoadImage
                                    width="100%"
                                    placeholderSrc="/images/placeholder.gif"
                                    effect="blur"
                                    src={video.thumbnail} 
                                    alt={video.name}
                                    />
                                <p>{moment(video.createdAt).format('LL')}</p>
                            </div>
                        </Link>
                    </div>
                </Col>    
            )
        }
    }


    const renderAudio = (audio) =>{
        if(valid(audio)){
            let url = ''
            if (audio.Postmeta.length) {
                url = `/audio/album/${audio.slug}`;
            }
            else {
                url = `/audio/single/${audio.slug}`;
            }
            return (
                <Col sm="12" lg="9">
                    <div className='single--stream--post mt-4'>
                        <Link to={url}>
                            <div className="Gr--stream-user">
                                <div className="Gr--streamImage">
                                    <LazyLoadImage width="100%" 
                                        placeholderSrc="/images/placeholder.gif" 
                                        effect="blur"
                                        src={(audio.user && valid(audio.user.image)) ? audio.user.image : "/images/user.png"} />
                                </div>
                                <div className="Gr--streamUser">
                                    <h4>{(audio.user && valid(audio.user.first))? audio.user.first : ''}</h4>
                                    <p><span>{moment(audio.createdAt).format('LL')}</span> </p>
                                </div>
                            </div>
                            <div className='Gr--streamPost--content contextonlywrap audio--post'>
                                <h2 className='mb-4'>{checkHTML(audio.name) ? convertToPlainText(audio.name) : audio.name}</h2>
                                <LazyLoadImage
                                    width="100%"
                                    placeholderSrc="/images/placeholder.gif"
                                    effect="blur"
                                    src={(audio.user && valid(audio.user.image)) ? audio.user.image : "/images/user.png"}
                                    alt={audio.name}
                                />
                                <p>{moment(audio.createdAt).format('LL')}</p>
                            </div>
                        </Link>
                    </div>
                </Col>
            )
        }
    }


    const renderText = (text) =>{
        if(valid(text)){
            return(
                <Col sm="12" lg="9">
                    <div className='single--stream--post mt-4'>
                        <Link to={`/text/${text.slug}`}> 
                            <div className="Gr--stream-user">
                                <div className="Gr--streamImage">
                                    <LazyLoadImage
                                        width="100%"
                                        placeholderSrc="/images/placeholder.gif"
                                        effect="blur"
                                        src={(text.user && valid(text.user.image)) ? text.user.image : "/images/user.png"}
                                        alt={text.name}
                                    />
                                </div>
                                <div className="Gr--streamUser">
                                    <h4>{text.user ? text.user.first: ''}</h4>
                                    <p><span>{moment(text.createdAt).format('LL')}</span> </p>
                                </div>
                            </div>
                            <div className='Gr--streamPost--content contextonlywrap text--post'>
                                <h2 className='mb-4'>{checkHTML(text.name) ? convertToPlainText(text.name) : text.name}</h2>
                                {valid(text.thumbnail) ?
                                    <LazyLoadImage
                                    width="100%"
                                    placeholderSrc="/images/placeholder.gif"
                                    effect="blur"
                                    src={text.thumbnail} 
                                    alt={text.name}
                                    />
                                    :
                                    <pre>{checkHTML(text.description) ? convertToPlainText(text.description) : text.description}</pre>    
                                }
                                
                                {/* <p>{moment(text.createdAt).format('LL')}</p> */}
                            </div>
                            {text.media !=='' 
                                ? 
                                <LinkRender post= {text}/>
                                : null
                            }
                        </Link>                                
                    </div>
                </Col>
            )
        }
    }


    const renderVideo = (video) =>{
        if(valid(video)){
             return (
                <Col sm="12" lg="9">
                    <div className='single--stream--post mt-4'>
                        <Link to={`/video/${video.slug}`}>
                            <div className="Gr--stream-user">
                                <div className="Gr--streamImage">
                                    <span className='play--icon'><BsPlay/></span>
                                        <LazyLoadImage
                                            width="100%"
                                            placeholderSrc="/images/placeholder.gif"
                                            effect="blur"
                                            src={(video.user && valid(video.user.image)) ? video.user.image : "/images/user.png"}
                                            alt={video.name}
                                        />
                                </div>
                                <div className="Gr--streamUser">
                                    <h4>{video.user ? video.user.first: ''}</h4>
                                    <p><span>{moment(video.createdAt).format('LL')}</span> </p>
                                </div>
                            </div>
                            <div className='Gr--streamPost--content contextonlywrap video--post'>
                                <h2 className='mb-4'>{checkHTML(video.name) ? convertToPlainText(video.name) : video.name}</h2>
                                <LazyLoadImage
                                    width="100%"
                                    placeholderSrc="/images/placeholder.gif"
                                    effect="blur"
                                    src={video.thumbnail} 
                                    alt={video.name}
                                    />
                                {/* <p>{moment(video.createdAt).format('LL')}</p> */}
                            </div>
                        </Link>
                    </div>
                </Col>    
             )
        }
    }


    const renderVisual = (visual) =>{
        if(valid(visual)){
            return  (
                <Col sm="12" lg="9">
                    <div className='single--stream--post mt-4'>
                        <Link to={`/visual/${visual.slug}`}>
                            <div className="Gr--stream-user">
                                <div className="Gr--streamImage">
                                        <LazyLoadImage
                                            width="100%"
                                            placeholderSrc="/images/placeholder.gif"
                                            effect="blur"
                                            src={valid(visual.thumbnail)?visual.thumbnail :visual.Postmeta[0].meta_value}
                                            alt={visual.name}
                                        />
                                </div>
                                <div className="Gr--streamUser">
                                    <h4>{visual.user ? visual.user.first: ''}</h4>
                                    <p><span>{moment(visual.createdAt).format('LL')}</span> </p>
                                </div>
                            </div>
                            <div className='Gr--streamPost--content contextonlywrap visual--post'>
                                <h2 className='mb-4'>{checkHTML(visual.name) ? convertToPlainText(visual.name) : visual.name}</h2>
                                <LazyLoadImage
                                    width="100%"
                                    placeholderSrc="/images/placeholder.gif"
                                    effect="blur"
                                    src={visual.thumbnail || visual.Postmeta[0].meta_value } 
                                    alt={visual.name}
                                    />
                                {/* <p>{moment(visual.createdAt).format('LL')}</p> */}
                            </div>
                        </Link>
                    </div>
                </Col>   
            )
        }
    }

    return (
        <>
            <Row className={isActive ? 'grid--view justify-content-center' : 'list--view justify-content-center'}>
                {!loading  ?
                   (searchPostList && searchPostList.length > 0)
                            
                    ?
                        <Col sm={12}>
                            <Row className={isActive ? 'justify-content-center' : 'justify-content-center'}>
                            {renderSearchPosts()}
                            </Row>
                        </Col>
                    :   <h2 className="mt-5 text-center">Posts Not Found</h2>  
                :   <Col>
                        <div className='text-center spinner--loader'></div>
                    </Col>   
                }           
            </Row>   
            

        </>
    );
   
}

export default SearchPostsTab ;

