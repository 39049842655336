import { 
    ORDER_CREATED_SUCCESS,
    ORDER_CREATED_FALSE,
    ORDER_COMMON_ERROR,
    GET_BUY_ORDER_SUCCESS,
    GET_BUY_ORDER_FAILED,
    GET_SELLED_ORDER_SUCCESS,
    GET_SELLED_ORDER_FAILED,
    CHANGE_ORDER_STATUS_SUCCESS,
    CHANGE_ORDER_STATUS_ERROR,
} from "../actions/types";


const initialState = {
    createOrderSuccess:null,
    orderError:null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ORDER_CREATED_SUCCESS:
            return{
                createOrderSuccess:action.payload,
            }
        case ORDER_CREATED_FALSE:
            return{
                createOrderError:action.payload.message,
            }    
        case ORDER_COMMON_ERROR:
            return{
                orderError:action.payload,
            }    
        case GET_BUY_ORDER_SUCCESS:
            return {
                buyOrderList:action.payload
            }
        case GET_SELLED_ORDER_SUCCESS:
            return{
                selledOrderList:action.payload
            }
        case GET_BUY_ORDER_FAILED:
            return {
                buyOrderError:action.payload
            }    
        case GET_SELLED_ORDER_FAILED:
            return {
                selledOrderError:action.payload
            }    
        case CHANGE_ORDER_STATUS_SUCCESS:
            return{
                orderStatus:action.payload
            }   
        case CHANGE_ORDER_STATUS_ERROR:
            return{
                orderStatusError:action.payload
            }        
    default: return state;    
    }
}

