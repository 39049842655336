export const errorReducer = (state, action) => {
    if (!action.error) {
      return {
        ...state,
        error: null,
      }
    }
  
    return {
      ...state,
      error: true,
      ...action.payload
    }
}