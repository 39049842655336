import {
    ADD_TO_CART_SUCCESS,
    ADD_TO_CART_FAILED,
    GET_CART_ITEMS_SUCCESS,
    GET_CART_ITEMS_FAILED,
    REMOVE_CART_ITEM_SUCCESS,
    REMOVE_CART_ITEM_FAILED,
    CLEAR_CART_SUCCESS,
    CLEAR_CART_FAILED,
    CART_COMMON_ERROR,
} from "../actions/types";

const initialState = {
    carItemList: null,
    cartItemError: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ADD_TO_CART_SUCCESS:
            return{
                addedToCart:action.payload,
            }
        case ADD_TO_CART_FAILED:
            return{
                addedToCartError:action.payload.message,
            }    
        case GET_CART_ITEMS_SUCCESS:
            return{
                carItemList:action.payload,
            }
        case GET_CART_ITEMS_FAILED:
            return{
                carItemError:action.payload.message,
            }        
        case REMOVE_CART_ITEM_SUCCESS:
            return{
                cartItemRemoved:action.payload,
            }
        case REMOVE_CART_ITEM_FAILED:
            return{
                cartItemRemovedError:action.payload.message,
            }            
        case CLEAR_CART_SUCCESS:
            return{
                cartCleared:action.payload,
            }
        case CLEAR_CART_FAILED:
            return{
                clearCartError:action.payload.message,
            }    
        case CART_COMMON_ERROR:
            return{
                cartError:action.payload,
            } 
        default: return state;                             
    }
}


