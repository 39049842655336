import { get } from 'lodash';
import API from '../../helpers/api';
import * as auth from '../../helpers/auth';
import { GET_CHAT_USERS_FAILED, GET_CHAT_USERS_SUCCESS, INVITED_USER_FAILED, INVITED_USER_SUCCESS, LOAD_CHAT_MESSAGE_FAILED, LOAD_CHAT_MESSAGE_SUCCESS } from "./types";
import { errorActionCreator } from './error.action';
import { socket , currentUser} from '../../helpers/auth';

const config = {
    headers: {
        "Content-Type": "multipart/form-data; charset=utf-8;"
    }
}

/** ---- Say Hi to User ---- */
export const inviteUser = (id) => {
    return async dispatch => {
        try {
            const response = await API.apiGet('messenger', `/invite-user/${id}`);
            if(response.data && response.data.success){
                await dispatch({ type: INVITED_USER_SUCCESS, payload: response.data });
            } else {
                await dispatch({ type: INVITED_USER_FAILED, payload: response.data });
            }  
        } catch (error) {
            errorActionCreator(INVITED_USER_FAILED, error, dispatch)
        }
    }
}

/** ----- Get List of Chat Users ----- */
export const getChatUsers = (payload) => {
    return async dispatch => {
        try {
            const response = await API.apiGetUrl('messenger', `/chat-users` , payload);
            if(response.data && response.data.success){
                await dispatch({ type: GET_CHAT_USERS_SUCCESS, payload: response.data });
            }else{
                await dispatch({ type: GET_CHAT_USERS_FAILED, payload: response.data });
            }
        } catch (error) {
            errorActionCreator(GET_CHAT_USERS_FAILED, error, dispatch);
        }
    }
}

/**
 * @param {Number} id 
 * @param {Number} chat_id 
 */

export const loadMoreMessege = (id, chat_id) => {
    return async dispatch => {
        try {
            const response = await API.apiGetUrl('messenger', `/load-chat-messages?id=${id}&chatid=${chat_id}`);
            if(response.data && response.data.success){
                await dispatch({ type: LOAD_CHAT_MESSAGE_SUCCESS, payload: response.data });
            }else{
                await dispatch({ type: LOAD_CHAT_MESSAGE_FAILED, payload: response.data });
            }
        } catch (error) {
            errorActionCreator(LOAD_CHAT_MESSAGE_FAILED, error, dispatch);
        }
    }
}

/**
 * This action is use to gets the invited users
 */
export const invitedUser = ()  => {
    return async dispatch => {
       try {
            const response = await API.apiGet('messenger', '/invited-users');
            if(response.data && response.data.success){
                await dispatch({ type: INVITED_USER_SUCCESS, payload: response.data });
            }else{
                await dispatch({ type: INVITED_USER_FAILED, payload: response.data });
            }
       } catch (error) {
            errorActionCreator(INVITED_USER_FAILED, error, dispatch);
       } 
    } 
}

/**
 * This action is use for accept a chat invitation
 * @param {Number} id 
 */
export const acceptInvitation = (id) => async dispatch => {
    try {
        socket.emit("accept-invitation", id);
    } catch (error) {
        // toastError('Something Went Wrong')
    }
}


/**
 * This action is use for the send a message
 * @param {Object} obj 
 */
export const sendMessage = obj => async dispatch => {
    if(currentUser){
        try {
            socket.emit("send-message", obj);
        } catch (error) {
            console.log( "Error: ", error )
        }
    }
}


/**
 * This is use for the remove a message
 * @param {Object} obj 
 */
export const removeMessage = obj => async dispatch => {
    try {
        socket.emit("remove-message", obj);
    } catch (error) {
        console.log( "Error: ", error )
    }
}


/**
 * This action is use for update a message
 * @param {Object} obj 
 */
export const updateMessage = obj => async dispatch => {
    try {
        socket.emit("update-message", obj);
    } catch (error) {
        console.log( "Error: ", error )
    }
}


/**
 * This action is use for update unseen message count
 * @param {Object} obj 
 */
export const updateUnseenCount = obj => async dispatch => {
    try {
        socket.emit("update-unseen", obj);
    } catch (error) {
    }
}


export const updateUnseenCountOne = (obj) =>{
    try{
        socket.emit("update_unseen_single", obj);
    }catch(error){
        console.log("error" , error)
    }
}