import React from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import CreateSinglePhoto from './PhotoSingleTab';
import CreateAlbumPhoto from './CreateAlbumTab';


function CreatePhoto({savedData , saveDataToForm}) {
    return (
        <div className='sub--Tabs'>
            <Tabs defaultActiveKey="single" id="create--sub--tabs">
                <Tab eventKey="single" title='Single'>
                    <CreateSinglePhoto  savedData={savedData} saveDataToForm={(tab , data)=>saveDataToForm(tab, data)}/>
                </Tab>
                <Tab eventKey="album" title='Album'>
                    <CreateAlbumPhoto  savedData={savedData} saveDataToForm={(tab , data)=>saveDataToForm(tab, data)}/>
                </Tab>
            </Tabs>
        </div>
    );
}

export default CreatePhoto;