import React, { useEffect, useState } from "react";
import { Button, Row, Col, Form , Spinner} from 'react-bootstrap';
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toast, Toaster } from "react-hot-toast";
import {IoClose} from 'react-icons/io5';
import { FaCogs, FaCreativeCommonsPdAlt } from 'react-icons/fa';
import { EditorState, convertToRaw, getDefaultKeyBinding , ContentState} from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { notValid, emailValidation } from "../../../utils/validations";
import ImageCropper from "./imageCropper";
import { createVisualPost , clearVisualCreate} from "../../../redux/actions/visualPost.action";



function CreateSinglePhoto({savedData , saveDataToForm}) {
    const intialValue = {name:'' , description: '' , isStream: true,  commenting: true , board_name: '', board_id: '', images:''};
    const [visualFields , setVisualFields] = useState (intialValue) ;
    const [boardList , setBoardList] = useState('')
    const [loading , setLoading] = useState(false) ; 
    const [draftLoading , setDraftLoading] = useState(false);
    const [ isCropperOpen, setCropperOpen ] = useState(false);
    const [ imageForCrop, setImageForCrop ] = useState(null);
    const [showEditor , setShowEditor] = useState(true);
    const [draft , setDraft] = useState(false);
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
    const [errors , setErrors] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const apiResult = useSelector(state => state.visualPost)
    const getPostResult = useSelector(state => state.post);

    useEffect(()=>{
        return()=>{
            updateParentState()
        }
    },[])


    useEffect(() => {
        if (savedData.single) {
            setVisualFields(savedData.single);
            if(savedData.single.description){
                const blocksFromHtml = htmlToDraft(savedData.single.description);
                const { contentBlocks, entityMap } = blocksFromHtml;
                const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
                const editorState = EditorState.createWithContent(contentState);
                setEditorState(editorState)
            }
        }
    }, [savedData]);


    useEffect(()=>{
        if(getPostResult && getPostResult.boardPostList){
            setBoardList(getPostResult.boardPostList.boardPosts)
        }
    },[getPostResult])


    const handleInput = (e) =>{
        const {value , name } = e.target ; 
        setVisualFields({...visualFields, [name]: value })
        setErrors({ ...errors, [name]: '' })

    }


    const handleCommenting = (e) =>{
        const copyVisualFields = {...visualFields} ;
        copyVisualFields.commenting = e.target.checked
        setVisualFields(copyVisualFields)
    }


/***************************    using for upload / remove images ************************************************/
    const handleImages = (e)=>{
        const allImages = ['png', 'jpg', 'jpeg', 'JPG', 'JPEG', 'PNG', 'gif'];
        let errorMessages = {...errors};
        const { files } = e.target ; 
        const copyVisualFields = {...visualFields}
        if(files && files.length < 2 ){
            const file = files[0];
            const fileName = file.name.toLowerCase();
            const fileExtension = fileName.split('.').pop();
            if(allImages.includes(fileExtension)){
                copyVisualFields.images = file
                setVisualFields(copyVisualFields)
                errorMessages['images'] = '' ;
                setErrors(errorMessages)
            }else{
                errorMessages['images'] = ' Please use only  png, jpg, jpeg, JPG, JPEG, PNG'
                setErrors(errorMessages)
            }    
        }
    }

    const handleRemoveFile = () =>{
        const copyVisualFields = {...visualFields}
        copyVisualFields.images = '' ;
        setVisualFields(copyVisualFields)
    }
/************************************************************************************************************* */    


/********************************************* using for crop image  *********************************************/

    const handleCloseCropper = () => {
        setImageForCrop(null)
        setCropperOpen(false)
    }

    const handeSaveCroppedImage = (dataURI) => {
        const imageFile = dataURItoBlob(dataURI);
        const copyVisualFields = {...visualFields}
        copyVisualFields['images'] = imageFile
        setVisualFields(copyVisualFields)
    }

    const dataURItoBlob = (dataURI) => {
        // convert base64/URLEncoded data component to raw binary data held in a string
        var byteString;
        if (dataURI.split(',')[0].indexOf('base64') >= 0)
            byteString = atob(dataURI.split(',')[1]);
        else
            byteString = unescape(dataURI.split(',')[1]);

        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        // write the bytes of the string to a typed array
        var ia = new Uint8Array(byteString.length);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        
        return new Blob([ia], {type:mimeString});
    }


    const handleOpenCropper = () => {
        setCropperOpen(true)
        let data = {
            src: URL.createObjectURL(visualFields.images)
        }
        setImageForCrop(data)
    }



/************************************************************************************************************** */
/**********************    handle description  /////   text editor  function using draft.js for text editing        ******/

const onEditorStateChange = (editorState) => {
    const copyVisualFields = {...visualFields} ;
    let errorMessages = {...errors};
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const description = draftToHtml(rawContentState);
    copyVisualFields.description = description
    errorMessages.description = '' ;
    setVisualFields(copyVisualFields)
    setErrors(errorMessages)
    setEditorState(editorState);
};


const myKeyBindingFn = event => {
    return getDefaultKeyBinding(event);
}
/**************************************************************************************************************************** */

/*************************   validate form fields ************************************/

    const validate = () =>{
        let isValid = true ;
        let errorMessages = {};
        let desc = checkHTML(visualFields.description) ? convertToPlainText(visualFields.description) : visualFields.description
        if (notValid(desc)) {
            errorMessages['description'] = 'Description is required'
            isValid = false;
        }
        if (notValid(visualFields.name)) {
            errorMessages['name'] = 'Title is required'
            isValid = false;
        }

        if(visualFields.images === ''){
            errorMessages['images'] = 'Please upload image '
            isValid = false;
        }

        if (!isValid) {
            setErrors({ ...errors, ...errorMessages });
        }
        return isValid;
    }

    const checkHTML = param => {
        return new RegExp(/(\<\w*)((\s\/\>)|(.*\<\/\w*\>))/gm).test(param)
    }


    const convertToPlainText = (html) => {
        const parser = new DOMParser();
        const parsedDocument = parser.parseFromString(html, 'text/html');
        return parsedDocument.body.textContent || '';
    };
/********************************************************************************************** */

/**********************   form submit function  //  publish or save post in draft depends status ********/

    const handleCreateVisualPost = (draft) =>{
        const copyVisualFields = {...visualFields}
        if(validate()){
            if(draft){
                copyVisualFields.status = '2'
                setLoading(false);
                setDraftLoading(true);
                setDraft(true);
            }else{
                setLoading(true)
                setDraftLoading(false)
            }
            const formData = new FormData();
            for (const [key, value] of Object.entries(copyVisualFields)) {
                formData.append(key, value)
            }  
            dispatch(createVisualPost(formData)) ;
        }
    }

/********************************************************************************************************* */
    useEffect(()=>{
        if(apiResult && apiResult.createVisualSuccess){
            if(draft){
                navigate('/drafts')
                setLoading(false)
                setDraftLoading(false)
            }
            else{
                navigate('/')
                setLoading(false)
                setDraftLoading(false)
            }
        }    
        if(apiResult && apiResult.visualPostError){
            setLoading(false)
            setDraftLoading(false)
            toast.error(apiResult.visualPostError.message, {
                position: 'top-center', 
            });
        }
        setLoading(false)
        setDraftLoading(false)
    },[apiResult])


    const selectBoard = () => {
        if(boardList && boardList.length > 0){
            return (
                <Form.Group className='form-group col-sm-12'>
                    <Form.Label>Realm</Form.Label>
                    <Form.Control value={visualFields.board_id || '' } onChange= {handleBoard} as="select">
                        <option selected>Select</option>
                        {boardList.map((item , idx)=>{
                            return (
                                <option key={idx} value={item.id}>{item.name}</option>
                            )
                        })}
                    </Form.Control>
                </Form.Group>

            )
        }
    }

    const handleBoard = (e)=>{
        let copyVisualFields = {...visualFields};
        copyVisualFields.board_id = e.target.value
        setVisualFields(copyVisualFields)
    }

    const updateParentState = () => {
        saveDataToForm( 'single' , visualFields);
    };


   
    return (
        <div className='create--form'>
            <Form onBlur={updateParentState}>
                <Row>
                    <Form.Group className='form-group col-sm-12'>
                        <Form.Label>Title</Form.Label>
                        <Form.Control type="text" name = 'name' value = {visualFields.name} onChange={handleInput} required />
                        {errors.name && <small className="error error-massege text-danger">{errors.name}</small>}
                    </Form.Group>
                    {visualFields.images ?
                        <>
                            <Form.Group className='form-group col-sm-12'>
                                <div className="post--thumb--upload">
                                    <img src={typeof visualFields.images === 'object' ? URL.createObjectURL(visualFields.images) : visualFields.images}/>
                                    <span className="post--media--edit" onClick={() => handleOpenCropper()}>Edit</span>
                                    <span className="post--media--close" onClick= {handleRemoveFile}><IoClose/></span>
                                </div>
                            </Form.Group>
                        </>
                        :
                        <Form.Group className='form-group col-sm-12'>
                            <Form.Label>Upload Image</Form.Label>
                            <input type='file' hidden id="upload--header-image" onChange ={handleImages}/>
                            <label className='label--upload' htmlFor="upload--header-image">Choose File</label>
                            {errors.images && <small className="error error-massege text-danger">{errors.images}</small>}
                        </Form.Group>
                    }
                    <Form.Group className='form-group col-sm-12'>
                        <span className='editor--tool' onClick = {()=>setShowEditor(!showEditor)}><FaCogs /></span>
                    </Form.Group>
                    <Form.Group className='form-group col-sm-12 text-title-post'>
                        <Form.Label>Description</Form.Label>
                        <Editor
                            editorState={editorState}
                            wrapperClassName="demo-wrapper editor-w"
                            editorClassName="demo-editor input"
                            onEditorStateChange={onEditorStateChange}
                            keyBindingFn={myKeyBindingFn}
                            hashtag={{
                                separator: ' ',
                                trigger: '#',
                            }}
                            toolbarHidden={showEditor}
                            config={{ link: { trailingWhitespace: true } }}
                        />
                        {errors.description && <small className="error error-massege text-danger">{errors.description}</small>}
                    </Form.Group>
                    {selectBoard()}
                    <Form.Group className='form-group col-sm-12'>
                        <Form.Check type="checkbox" label="Allow Comments" id = 'commentCheckbox' checked={visualFields.commenting} onChange={handleCommenting}/>
                        <Form.Label htmlFor="commentCheckbox" onClick={handleCommenting}/>
                    </Form.Group>
                    <Col sm={12} className='text-end'>
                        <Button variant="secondary"  className='me-2'  disabled = {loading || draftLoading} onClick={()=>handleCreateVisualPost(true)}>
                            { draftLoading ? <> <Spinner animation="border" size="sm"/> Please wait </> : 'Save to Draft'}
                        </Button>
                        <Button variant="primary"   disabled = {loading || draftLoading} onClick={()=>handleCreateVisualPost(false)}>
                            {loading ?  <> <Spinner animation="border" size="sm"/> Please wait </> : 'Publish Now'}
                        </Button>
                    </Col>
                </Row>
            </Form>
            {isCropperOpen && 
                <ImageCropper
                    isOpen={isCropperOpen}
                    src={imageForCrop.src}
                    onClose={() => handleCloseCropper()}
                    result={(val) => handeSaveCroppedImage(val)}
                />
            }
        </div>
    );
}

export default CreateSinglePhoto;