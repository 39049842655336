module.exports = [
    {
      "Name": "UTC-12:00",
      "Offset": "-12:00",
      "OffsetInMinutes": -720,
      "Description": "UTC-12:00",
      "Location": "International Date Line West"
    },
    {
      "Name": "UTC-11:00",
      "Offset": "-11:00",
      "OffsetInMinutes": -660,
      "Description": "UTC-11:00",
      "Location": "Pago Pago, American Samoa"
    },
    {
      "Name": "UTC-10:00",
      "Offset": "-10:00",
      "OffsetInMinutes": -600,
      "Description": "UTC-10:00 (HAST)",
      "Location": "Honolulu, Hawaii, United States"
    },
    {
      "Name": "UTC-09:00",
      "Offset": "-09:00",
      "OffsetInMinutes": -540,
      "Description": "UTC-09:00 (AKST)",
      "Location": "Anchorage, Alaska, United States",
      "DST": "-08:00",
      "DSTInMinutes": -480,
      "DSTDescription": "UTC-08:00 (AKDT)",
      "DSTStart": { "year": 2024, "month": 3, "day": 10, "hour": 2, "minute": 0 },
      "DSTEnd": { "year": 2024, "month": 11, "day": 3, "hour": 2, "minute": 0 }
    },
    {
      "Name": "UTC-08:00",
      "Offset": "-08:00",
      "OffsetInMinutes": -480,
      "Description": "UTC-08:00 (PST)",
      "Location": "Los Angeles, California, United States",
      "DST": "-07:00",
      "DSTInMinutes": -420,
      "DSTDescription": "UTC-07:00 (PDT)",
      "DSTStart": { "year": 2024, "month": 3, "day": 10, "hour": 2, "minute": 0 },
      "DSTEnd": { "year": 2024, "month": 11, "day": 3, "hour": 2, "minute": 0 }
    },
    {
      "Name": "UTC-07:00",
      "Offset": "-07:00",
      "OffsetInMinutes": -420,
      "Description": "UTC-07:00 (MST)",
      "Location": "Denver, Colorado, United States",
      "DST": "-06:00",
      "DSTInMinutes": -360,
      "DSTDescription": "UTC-06:00 (MDT)",
      "DSTStart": { "year": 2024, "month": 3, "day": 10, "hour": 2, "minute": 0 },
      "DSTEnd": { "year": 2024, "month": 11, "day": 3, "hour": 2, "minute": 0 }
    },
    {
      "Name": "UTC-06:00",
      "Offset": "-06:00",
      "OffsetInMinutes": -360,
      "Description": "UTC-06:00 (CST)",
      "Location": "Chicago, Illinois, United States",
      "DST": "-05:00",
      "DSTInMinutes": -300,
      "DSTDescription": "UTC-05:00 (CDT)",
      "DSTStart": { "year": 2024, "month": 3, "day": 10, "hour": 2, "minute": 0 },
      "DSTEnd": { "year": 2024, "month": 11, "day": 3, "hour": 2, "minute": 0 }
    },
    {
      "Name": "UTC-05:00",
      "Offset": "-05:00",
      "OffsetInMinutes": -300,
      "Description": "UTC-05:00 (EST)",
      "Location": "New York, United States",
      "DST": "-04:00",
      "DSTInMinutes": -240,
      "DSTDescription": "UTC-04:00 (EDT)",
      "DSTStart": { "year": 2024, "month": 3, "day": 10, "hour": 2, "minute": 0 },
      "DSTEnd": { "year": 2024, "month": 11, "day": 3, "hour": 2, "minute": 0 }
    },
    {
      "Name": "UTC-04:00",
      "Offset": "-04:00",
      "OffsetInMinutes": -240,
      "Description": "UTC-04:00 (VET)",
      "Location": "Caracas, Venezuela"
    },
    {
      "Name": "UTC-03:00",
      "Offset": "-03:00",
      "OffsetInMinutes": -180,
      "Description": "UTC-03:00",
      "Location": "Buenos Aires, Argentina"
    },
    {
      "Name": "UTC-02:00",
      "Offset": "-02:00",
      "OffsetInMinutes": -120,
      "Description": "UTC-02:00",
      "Location": "Fernando de Noronha, Brazil"
    },
    {
      "Name": "UTC-01:00",
      "Offset": "-01:00",
      "OffsetInMinutes": -60,
      "Description": "UTC-01:00",
      "Location": "Cape Verde"
    },
    {
      "Name": "UTC+00:00",
      "Offset": "+00:00",
      "OffsetInMinutes": 0,
      "Description": "UTC+00:00 (GMT)",
      "Location": "London, United Kingdom",
      "DST": "+01:00",
      "DSTInMinutes": 60,
      "DSTDescription": "UTC+01:00 (BST)",
      "DSTStart": { "year": 2024, "month": 3, "day": 31, "hour": 1, "minute": 0 },
      "DSTEnd": { "year": 2024, "month": 10, "day": 27, "hour": 2, "minute": 0 }
    },
    {
      "Name": "UTC+01:00",
      "Offset": "+01:00",
      "OffsetInMinutes": 60,
      "Description": "UTC+01:00 (CET)",
      "Location": "Paris, France",
      "DST": "+02:00",
      "DSTInMinutes": 120,
      "DSTDescription": "UTC+02:00 (CEST)",
      "DSTStart": { "year": 2024, "month": 3, "day": 31, "hour": 2, "minute": 0 },
      "DSTEnd": { "year": 2024, "month": 10, "day": 27, "hour": 3, "minute": 0 }
    },
    {
      "Name": "UTC+02:00",
      "Offset": "+02:00",
      "OffsetInMinutes": 120,
      "Description": "UTC+02:00 (EET)",
      "Location": "Athens, Greece",
      "DST": "+03:00",
      "DSTInMinutes": 180,
      "DSTDescription": "UTC+03:00 (EEST)",
      "DSTStart": { "year": 2024, "month": 3, "day": 31, "hour": 3, "minute": 0 },
      "DSTEnd": { "year": 2024, "month": 10, "day": 27, "hour": 4, "minute": 0 }
    },
    {
      "Name": "UTC+03:00",
      "Offset": "+03:00",
      "OffsetInMinutes": 180,
      "Description": "UTC+03:00",
      "Location": "Moscow, Russia"
    },
    {
      "Name": "UTC+03:30",
      "Offset": "+03:30",
      "OffsetInMinutes": 210,
      "Description": "UTC+03:30 (IRST)",
      "Location": "Tehran, Iran"
    },
    {
      "Name": "UTC+04:00",
      "Offset": "+04:00",
      "OffsetInMinutes": 240,
      "Description": "UTC+04:00",
      "Location": "Dubai, United Arab Emirates"
    },
    {
      "Name": "UTC+04:30",
      "Offset": "+04:30",
      "OffsetInMinutes": 270,
      "Description": "UTC+04:30 (AFT)",
      "Location": "Kabul, Afghanistan"
    },
    {
      "Name": "UTC+05:00",
      "Offset": "+05:00",
      "OffsetInMinutes": 300,
      "Description": "UTC+05:00 (PKT)",
      "Location": "Karachi, Pakistan"
    },
    {
      "Name": "UTC+05:30",
      "Offset": "+05:30",
      "OffsetInMinutes": 330,
      "Description": "UTC+05:30 (IST)",
      "Location": "Mumbai, India"
    },
    {
      "Name": "UTC+05:45",
      "Offset": "+05:45",
      "OffsetInMinutes": 345,
      "Description": "UTC+05:45 (NST)",
      "Location": "Kathmandu, Nepal"
    },
    {
      "Name": "UTC+06:00",
      "Offset": "+06:00",
      "OffsetInMinutes": 360,
      "Description": "UTC+06:00 (BST)",
      "Location": "Dhaka, Bangladesh"
    },
    {
      "Name": "UTC+06:30",
      "Offset": "+06:30",
      "OffsetInMinutes": 390,
      "Description": "UTC+06:30",
      "Location": "Yangon, Myanmar"
    },
    {
      "Name": "UTC+07:00",
      "Offset": "+07:00",
      "OffsetInMinutes": 420,
      "Description": "UTC+07:00 (THA)",
      "Location": "Bangkok, Thailand"
    },
    {
      "Name": "UTC+08:00",
      "Offset": "+08:00",
      "OffsetInMinutes": 480,
      "Description": "UTC+08:00",
      "Location": "Beijing, China"
    },
    {
      "Name": "UTC+08:45",
      "Offset": "+08:45",
      "OffsetInMinutes": 525,
      "Description": "UTC+08:45 (ACWST)",
      "Location": "Eucla, Australia"
    },
    {
      "Name": "UTC+09:00",
      "Offset": "+09:00",
      "OffsetInMinutes": 540,
      "Description": "UTC+09:00 (JST)",
      "Location": "Tokyo, Japan"
    },
    {
      "Name": "UTC+09:30",
      "Offset": "+09:30",
      "OffsetInMinutes": 570,
      "Description": "UTC+09:30 (ACST)",
      "Location": "Adelaide, Australia",
      "DST": "+10:30",
      "DSTInMinutes": 630,
      "DSTDescription": "UTC+10:30 (ACDT)",
      "DSTStart": { "year": 2024, "month": 10, "day": 6, "hour": 2, "minute": 0 },
      "DSTEnd": { "year": 2024, "month": 4, "day": 7, "hour": 3, "minute": 0 }
    },
    {
      "Name": "UTC+10:00",
      "Offset": "+10:00",
      "OffsetInMinutes": 600,
      "Description": "UTC+10:00 (AEST)",
      "Location": "Sydney, Australia",
      "DST": "+11:00",
      "DSTInMinutes": 660,
      "DSTDescription": "UTC+11:00 (AEDT)",
      "DSTStart": { "year": 2024, "month": 10, "day": 6, "hour": 2, "minute": 0 },
      "DSTEnd": { "year": 2024, "month": 4, "day": 7, "hour": 3, "minute": 0 }
    },
    {
      "Name": "UTC+10:30",
      "Offset": "+10:30",
      "OffsetInMinutes": 630,
      "Description": "UTC+10:30 (LHST)",
      "Location": "Lord Howe Island, Australia",
      "DST": "+11:00",
      "DSTInMinutes": 660,
      "DSTDescription": "UTC+11:00 (LHDT)",
      "DSTStart": { "year": 2024, "month": 10, "day": 6, "hour": 2, "minute": 0 },
      "DSTEnd": { "year": 2024, "month": 4, "day": 7, "hour": 2, "minute": 30 }
    },
    {
      "Name": "UTC+11:00",
      "Offset": "+11:00",
      "OffsetInMinutes": 660,
      "Description": "UTC+11:00 (NFT)",
      "Location": "Norfolk Island, Australia"
    },
    {
      "Name": "UTC+12:00",
      "Offset": "+12:00",
      "OffsetInMinutes": 720,
      "Description": "UTC+12:00 (NZST)",
      "Location": "Auckland, New Zealand",
      "DST": "+13:00",
      "DSTInMinutes": 780,
      "DSTDescription": "UTC+13:00 (NZDT)",
      "DSTStart": { "year": 2024, "month": 9, "day": 29, "hour": 2, "minute": 0 },
      "DSTEnd": { "year": 2024, "month": 4, "day": 7, "hour": 3, "minute": 0 }
    },
    {
      "Name": "UTC+12:45",
      "Offset": "+12:45",
      "OffsetInMinutes": 765,
      "Description": "UTC+12:45 (CHAST)",
      "Location": "Chatham Islands, New Zealand",
      "DST": "+13:45",
      "DSTInMinutes": 825,
      "DSTDescription": "UTC+13:45 (CHADT)",
      "DSTStart": { "year": 2024, "month": 9, "day": 29, "hour": 2, "minute": 45 },
      "DSTEnd": { "year": 2024, "month": 4, "day": 7, "hour": 3, "minute": 45 }
    },
    {
      "Name": "UTC+13:00",
      "Offset": "+13:00",
      "OffsetInMinutes": 780,
      "Description": "UTC+13:00",
      "Location": "Nuku'alofa, Tonga"
    },
    {
      "Name": "UTC+14:00",
      "Offset": "+14:00",
      "OffsetInMinutes": 840,
      "Description": "UTC+14:00",
      "Location": "Kiritimati, Kiribati"
    }
  ]