import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col,Modal , Button} from 'react-bootstrap';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import LoadingOverlay from 'react-loading-overlay';
import { toast} from "react-hot-toast";
import PageHeader from '../Header/HeaderTop';
import SidebarMenu from '../Sidebar/SidebarNav';
import API from '../../helpers/api';
import ShippingInfoModel from './ShippingInfoModel';
import { genrateOrderNumber } from '../../utils/validations';
import { createOrder } from '../../redux/actions/orders.action';
import { decodedLoggedUser } from '../../helpers/auth';
import { withRouter } from '../../wrapper/withRouter';



class Checkout extends Component {
    constructor(props){
        super(props)
        this.state = {
            loading:false ,
            shippingModal:false ,
            currency: 'USD',
            purchaseUnits:'',
            total:'',
            paymentMethod:'',
            selectedCartItems:'',
            loadingContent:'Order placing ,please wait...',
            userId:'',
            useDetail:'',
        }
    }
    I
  
    componentDidMount = () => {
        const {location , navigate} = this.props ; 
        var _empty = false;
        /** ----- Location State is Blank ----- */
        if(!location || !location.state ){
            _empty = true
        }else{
            /** ------ State Values are Blank ------ */
            const { selectedItem, purchaseUnits, total, defaultPayment } = location.state;
            if( !selectedItem || !purchaseUnits ){
                _empty = true
            }else{
                this.setState({ 
                    purchaseUnits: location?.state?.purchaseUnits,
                    total: location?.state?.total,
                    paymentMethod: location?.state?.defaultPayment,
                    selectedCartItems: location?.state?.selectedItem
                })
            }
        }
        if(_empty){
            navigate('/marketplace')
        }
        if (this.props.auth.loggedIn) {
            let token = decodedLoggedUser()
            this.setState({userId:token.aud})
            this.setState({useDetail : token})
            

        }
    }

    orderNow = async(orderdata) => {
        this.setState({loading:true})
        await this.props.createOrder(orderdata)
        if(this.props.order && this.props.order.createOrderSuccess){
            this.setState({loading:false});
            toast.dismiss();
            toast.success(this.props.order.createOrderSuccess.message, { duration: 4000 , position: 'top-center', 
            });
            // this.props.navigate('/marketplace');
        }
        if(this.props.order && this.props.order.createOrderError){
            this.setState({loading:false})
            toast.error(this.props.order.createOrderError.message, {position: 'top-center', 
            });
        }
        if(this.props.order && this.props.order.orderError){
            this.setState({loading:false})
            toast.error(this.props.order.orderError, {position: 'top-center', 
            });
        }
    }

    createPaypalOrder = (data, actions) => {
        return actions.order.create({ purchase_units: this.state.purchaseUnits });
    }


    handlePaymentCancel = (data) => {
        //**  ----- Cancel Payment ----- */
        console.log( 'Payment Has been Canceled For the Paypal Order ID' , data.orderID );
    }

    captureOrder = async(orderData) =>{
        const payload = {orderId: orderData.orderID}
        try{
            const response = await API.apiPostUrl('marketplace', '/capture-order' , payload ) ;
            if(response  && response.data){
                if(response.data.success && response.data.oderCapture)
                return response.data.oderCapture
            }
        }catch(error){
            toast.error(error.response.data.message, {position: 'top-center', 
            });
        }
    }


    onApprove = async(data , actions) =>{
        let totalOrders = [] ;
        const capture =  await this.captureOrder(data)
        if(capture && capture.status === 'COMPLETED'){
            this.state.selectedCartItems.forEach((item , ind)=>{
                let order = {} ;
                order.user_id = this.state.userId;
                order.order_status = 'Pending';
                order.order_id = capture.purchase_units[ind].payments.captures[0].id;
                order.payment_method = data.paymentSource;
                order.seller_id = item.marketplaceProduct.user.id;
                order.amount = this.state.total;
                order.payment_status = capture.status ;
                order.shipping_details = JSON.stringify(capture.purchase_units[ind].shipping.address);
                order.product_id = item.product_id;
                order.quantity = item.quantity;
                totalOrders.push(order);
            })
        }

        if(totalOrders.length > 0){
            const data = {orderData:totalOrders , selectedItems:this.state.selectedCartItems}
            await this.orderNow(data)
        }
    }
    handleCOD = async(shippingInfo) =>{
        let totalOrders = [] ;
        this.state.selectedCartItems.forEach((item , ind)=>{
            let order = {} ;
            order.user_id = this.state.userId;
            order.order_status = 'Pending';
            order.order_id = genrateOrderNumber();
            order.payment_method = "Free";
            order.seller_id = item.marketplaceProduct.user.id;
            order.amount = this.state.total;
            order.payment_status = "Pending" ;
            order.shipping_details = JSON.stringify(shippingInfo);
            order.product_id = item.product_id;
            order.quantity = item.quantity;
            totalOrders.push(order);
        });
        if(totalOrders.length > 0){
            const data = {orderData:totalOrders , selectedItems:this.state.selectedCartItems}
            await this.orderNow(data)
        }
    }

   

    handleShippingModel = () =>{
        this.setState({shippingModal: false })
    }

    render () {
        const style = {"layout":"vertical"}
        return (
            <>
                <PageHeader />
                <div className='page--content'>
                    <SidebarMenu />
                    <div className='discover--module common--wrapper order--table'>
                            <LoadingOverlay
                                active={this.state.loading}
                                spinner
                                text={this?.state?.loadingContent}
                                className='h-100'
                            >
                            
                                <Container className='h-100'>
                                    <Row className="justify-content-center align-items-center checkout_wrapper text-center">
                                        <Col sm={6}>
                                            {this?.state?.currency && this.state.total !== 0
                                            ?   <PayPalScriptProvider 
                                                    options={{ clientId: process.env.REACT_APP_PAYPAL_CLIENTID }}
                                                >
                                                    <PayPalButtons
                                                        style={style} 
                                                        disabled={false}
                                                        forceReRender={[style]}
                                                        fundingSource={undefined}
                                                        createOrder={this.createPaypalOrder}
                                                        onApprove={(data , action )=>this.onApprove(data , action )}
                                                        onCancel={this.handlePaymentCancel}
                                                        onError={(e) =>  toast.error('INVALID_REQUEST', {position: 'top-center', 
                                                        })}
                                                        // onError={((e)=> console.log("error" , e))}
                                                    />
                                                </PayPalScriptProvider>
                                            : 
                                                this.state.total === 0 ?
                                                    <Button
                                                        onClick={() => this.setState({shippingModal: true })}
                                                    >
                                                        Order Now
                                                    </Button>
                                                : null
                                            }
                                            </Col> 
                                    </Row>

                                    <Modal centered className="modal__form" show={this.state.shippingModal} onHide={this.handleShippingModel}>
                                        <Modal.Header closeButton>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div className="login--Wrapper">
                                                <ShippingInfoModel   submit= {(info)=>this.handleCOD(info)}  userDetails = {this.state.useDetail}close= {this.handleShippingModel}/>
                                            </div>
                                        </Modal.Body>
                                    </Modal>
                                </Container>
                            </LoadingOverlay>
                    </div>
                </div>
            </>
        )
    }    
}

const maopStateToProps = (state) => {
    return{
        order: state.order,
        auth: state.auth

    }
}

const dispatchActions = {createOrder} ; 

export default connect( maopStateToProps , dispatchActions)(withRouter(Checkout));

