
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, ListGroup, Button, Row, Col , InputGroup } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { MdClose } from "react-icons/md";
import { FiPlus } from "react-icons/fi";
import { v4 as uuidv4 } from 'uuid';
import { toast } from "react-hot-toast";
import * as Auth from '../../helpers/auth';
import {createSchedule , getSchedules , setSchedules} from '../../redux/actions/schedule.action';
import moment from 'moment-timezone';


function MeetingScheduler(props) {
  const [meetingDuration, setMeetingDuration] = useState(30); // Default to 15 minutes
  const [timeOptions, setTimeOptions] = useState([]);
  const [endTimeOptions, setEndTimeOptions] = useState([]);
  const [selectedTimeZone, setSelectedTimeZone] = useState(moment.tz.guess());
  const [isDisable , setIsDisable] =  useState(false);
  const dispatch = useDispatch();
  const timeZones = moment.tz.names();
  const [errors, setErrors] = useState({});
  const [overlapError , setOverLapError] = useState(false);
  const [repeatCount, setRepeatCount] = useState(1);
  const [repeatType, setRepeatType] = useState("weeks"); // Default to weeks

  
  const [dayTimeFields, setDayTimeFields] = useState({
    mon: [],
    tue: [],
    wed: [],
    thu: [],
    fri: [],
    sat: [],
    sun: [],
  });
  const [dayCheckboxes, setDayCheckboxes] = useState({
    sun: false,
    mon: false,
    tue: false,
    wed: false,
    thu: false,
    fri: false,
    sat: false,
  });

  const navigate = useNavigate();
  const scheduleState = useSelector(state => state.schedule);

  const removeDuplicateTimeSlots = (events) => {
    const uniqueEvents = {};
    events.forEach(event => {
      const { event_start_time , event_start, event_end } = event;
      const date = new Date(event_start_time).toISOString().split('T')[0] ;
      // Use the date, start, and end as a unique key to prevent duplication
      const eventKey = `${date}-${event_start}-${event_end}`;
      // If this key does not exist in uniqueEvents, add it
      if (!uniqueEvents[eventKey]) {
        uniqueEvents[eventKey] = { date, event_start, event_end };
      }
    });
  
    // Return an array of unique events
    return Object.values(uniqueEvents);
  };

  // Fetch Logged User and Schedules
  useEffect(() => {
    generateTimeOptions(meetingDuration);
    getSchedulesList();
  },[]);

  useEffect(()=>{
    if(scheduleState && scheduleState.schedules){
      if(scheduleState.schedules.schedules && scheduleState.schedules.schedules.length > 0){
        loadSavedAvailability(scheduleState.schedules.schedules)
      }else{
        const TimeFields = dayTimeFields
        Object.keys(TimeFields).forEach(day => {
          if (TimeFields[day].length === 0) {
            TimeFields[day].push({ start: '09:00am', end: '05:00pm' }); // Set default time slot
            // Do not update the checkbox; it remains false
          }
        });
        setDayTimeFields(TimeFields);
      }
    }
  },[scheduleState])


  const getSchedulesList = () =>{
    const userData = Auth.decodedLoggedUser();
    dispatch(getSchedules({userId:userData.aud , eventId:props.event.id}))
  }

  const loadSavedAvailability = (eventSlots) => {
    const updatedDayTimeFields = { ...dayTimeFields };
    const updatedDayCheckboxes = dayCheckboxes ;
    if(eventSlots && eventSlots.length > 0){
      const uniqueTimeSlots = removeDuplicateTimeSlots(eventSlots);
      uniqueTimeSlots.forEach(event => {
        // Parse the event date and extract the day of the week (e.g., 'tue', 'wed')
        const eventDate = moment(new Date(event.date));
        const dayOfWeek = eventDate.format('ddd').toLowerCase();

        // Ensure the day exists in dayTimeFields (e.g., 'tue', 'wed')
        if (updatedDayTimeFields[dayOfWeek]) {
          // Check if the time slot already exists to avoid duplicates
          const existingTimeSlot = updatedDayTimeFields[dayOfWeek].find(
            timePair => timePair.start === event.event_start && timePair.end === event.event_end
          );

          // If the time slot does not exist, add it
          if (!existingTimeSlot) {
            updatedDayTimeFields[dayOfWeek].push({ start: event.event_start, end: event.event_end });
            updatedDayCheckboxes[dayOfWeek] = true
          }
        }
      });
      // Add default time slots for days without any time slots
      Object.keys(updatedDayTimeFields).forEach(day => {
        if (updatedDayTimeFields[day].length === 0) {
          updatedDayTimeFields[day].push({ start: '09:00am', end: '05:00pm' }); // Set default time slot
          // Do not update the checkbox; it remains false
        }
      });
      setDayTimeFields(updatedDayTimeFields);
      setDayCheckboxes(updatedDayCheckboxes);
    }
  };

  useEffect(() => {
    if (scheduleState.error) {
      toast.error(scheduleState.error.message);
      setIsDisable(false);
    }
    if (!scheduleState.error) {
      if (scheduleState.scheduleCreated && scheduleState.scheduleCreated.success) {
        toast.success('Events created.', {
          position: 'top-center',
        });
        setIsDisable(false)
      }
    }
  }, [scheduleState]);

  const generateTimeOptions = () => {
    const startOptions = [];
    const endOptions = [];
    const start = moment().startOf('day').hour(0).minute(0);
    const end = moment().endOf('day').hour(23).minute(59);
    let currentTime = start.clone();
    // Generate time options in 15-minute intervals
    while (currentTime.isBefore(end) || currentTime.isSame(end)) {
      startOptions.push(currentTime.format('hh:mma'));
      // currentTime = currentTime.add(props.event.duration === 45 ? '60' : props.event.duration, 'minutes'); // Fixed 15-minute gap
      currentTime = currentTime.add('15', 'minutes'); // Fixed 15-minute gap
    }
    setTimeOptions(startOptions);
    setEndTimeOptions(startOptions); // Same time options for both start and end
  };
  
  
  const handleAddTimeField = (day) => {
    const hasErrors = Object.values(errors[day] || {}).some(error => error !== '');
    if (hasErrors) {
      return;
    }

    setDayTimeFields(prevState => {
      const currentFields = [...prevState[day]];
      let newStartTime;
      if (currentFields.length > 0) {
        const lastField = currentFields[currentFields.length - 1];
        // newStartTime = moment(lastField.end, 'hh:mma').format('hh:mma'); // Increment by 1 hour
        newStartTime = moment(lastField.end, 'hh:mma').add('60' ,'minutes').format('hh:mma'); 
      } else {
        newStartTime = '09:00am';
      }
      const newEndTime = moment(newStartTime, 'hh:mma').add(1, 'hours').format('hh:mma'); // Add 1 hour for new end time
      // Check for duplicate
      const isDuplicate = currentFields.some(field => field.start === newStartTime && field.end === newEndTime);
      if (isDuplicate) {
        toast.error('Cannot add a new time field: the time interval is duplicated.');
        return prevState;
      }
      const updatedFields = [...currentFields, { start: newStartTime, end: newEndTime }];

      return {
        ...prevState,
        [day]: updatedFields,
      };
    });
  };

  

  // Handle Removing a Time Field
  const handleRemoveTimeField = (day, index) => {
    setDayTimeFields(prevState => {
      const updatedFields = prevState[day].filter((_, i) => i !== index);
      const newState = {
        ...prevState,
        [day]: updatedFields,
      };
      // Automatically uncheck the checkbox if all time fields are removed
      if (updatedFields.length === 0) {
        setDayCheckboxes(prevCheckboxes => ({
          ...prevCheckboxes,
          [day]: false,
        }));
      }
      return newState;
    });

    setErrors(prevErrors => {
      const updatedErrors = { ...prevErrors };
      if (updatedErrors[day]) {
        delete updatedErrors[day][index];
      }
      return updatedErrors;
    });
  };

  // Handle Checkbox Toggle
  const handleCheckboxChange = (day) => {
    setDayCheckboxes(prevState => ({
      ...prevState,
      [day]: !prevState[day],
    }));
  };

  // Handle Time Zone Change
  const handleTimeZoneChange = (e) => {
    setSelectedTimeZone(e.target.value);
  };

  // Get Date Object for a Specific Day
  const getDateForDay = (day) => {
    const today = moment().startOf('day');
    const targetDayIndex = moment().day(day).day(); // 'sun' => 0, 'mon' => 1, etc.
    const diff = targetDayIndex - today.day();
    return today.clone().add(diff >= 0 ? diff : diff + 7, 'days');
  };
 

  // Handle End Time Change

  const handleStartChange = (day, index, startValue) => {
    let timeSlot = dayTimeFields;
    timeSlot =  timeSlot[day].map((timePair, i) =>
      i === index ? { ...timePair, start: startValue, end: timePair.end} : timePair
    );
    setDayTimeFields({...dayTimeFields ,[day]:timeSlot});
    if (!validateTimeChange(timeSlot)) {
      setErrors(prevErrors => ({
        ...prevErrors,
        [day]: {
          ...prevErrors[day],
          [index]: `Start time must be before the end time or does not overlap.`
        }
      }));
    }else{
      setErrors(prevErrors => {
        const updatedErrors = { ...prevErrors };
        if (updatedErrors[day]) {
          delete updatedErrors[day][index];
        }
        return updatedErrors;
      });
    }
    

  };
  
  const handleEndChange = (day, index, value) => {
   let timeSlot = dayTimeFields;
    timeSlot =  timeSlot[day].map((timePair, i) =>
      i === index ? { ...timePair, start: timePair.start, end: value} : timePair
    );
    setDayTimeFields({...dayTimeFields ,[day]:timeSlot});
    if (!validateTimeChange(timeSlot)) {
    setErrors(prevErrors => ({
      ...prevErrors,
      [day]: {
        ...prevErrors[day],
        [index]: `Start time must be before the end time or does not overlap.`
      }
    }));
  } else {
    // If validation passes, remove the error for this slot
    setErrors(prevErrors => {
      const updatedErrors = { ...prevErrors };
      if (updatedErrors[day]) {
        delete updatedErrors[day][index];
      }
      return updatedErrors;
    });
  }

  };


  const validateTimeChange = (timeSlots) => {
    for (let i = 0; i < timeSlots.length; i++) {
      const currentSlot = timeSlots[i];
      const currentStart = moment(currentSlot.start, 'hh:mma');
      const currentEnd = moment(currentSlot.end, 'hh:mma');
      // Check that the start time is before the end time
      if (currentStart.isAfter(currentEnd)) {
        return false; // Validation failed
      }
      // Check for overlaps with other time slots
      for (let j = 0; j < timeSlots.length; j++) {
        if (i !== j) {
          const otherSlot = timeSlots[j];
          const otherStart = moment(otherSlot.start, 'hh:mma');
          const otherEnd = moment(otherSlot.end, 'hh:mma');
          // Check if current slot overlaps with any other slot
          if (
            (currentStart.isBetween(otherStart, otherEnd, null, '[]')) ||
            (currentEnd.isBetween(otherStart, otherEnd, null, '[]')) ||
            (otherStart.isBetween(currentStart, currentEnd, null, '[]')) ||
            (otherEnd.isBetween(currentStart, currentEnd, null, '[]'))
          ) {
            return false; // Validation failed
          }
        }
      }
    }
    return true; // Validation passed
  };
  

  // Validate Availability Before Saving
  const validateAvailability = () => {
    let error = '';
    // Check if time zone is selected
    if (!selectedTimeZone) {
      error = "Please select a time zone.";
    }
    // Check if at least one day is selected
    const anyDaySelected = Object.values(dayCheckboxes).some(isChecked => isChecked);
    if (!anyDaySelected) {
      error = "Please select at least one day.";
    }
    // Validate each selected day
    Object.keys(dayTimeFields).forEach(day => {
      if (dayCheckboxes[day]) {
        dayTimeFields[day].forEach(({ start, end }, index) => {
          const start24 = convertTo24HourFormat(start);
          const end24 = convertTo24HourFormat(end);
          if (start24 >= end24) {
            error = `End time must be after start time for ${day} interval ${index + 1}.`;
          }
        });
      }
    });
    if (error) {
      toast.error(error, {
        position: 'top-center',
      });
      return false;
    }
    return true;
  };

  // Handle Save Action
  // const handleSave = async () => {
  //   if (!validateAvailability()) {
  //     return; // Stop if validation fails
  //   }
  //   const availabilityId = uuidv4(); // Generate a unique ID for the availability
  //   const availabilityData = [];
  //   Object.keys(dayTimeFields).forEach(day => {
  //     if (dayCheckboxes[day]) {
  //       const dateForDay = getDateForDay(day);
  //       dayTimeFields[day].forEach(timePair => {
  //         const startMoment = dateForDay.clone().set({
  //           hour: moment(timePair.start, 'hh:mma').hour(),
  //           minute: moment(timePair.start, 'hh:mma').minute(),
  //         });
  //         const endMoment = dateForDay.clone().set({
  //           hour: moment(timePair.end, 'hh:mma').hour(),
  //           minute: moment(timePair.end, 'hh:mma').minute(),
  //         });

  //         // Calculate the interval times
  //         const intervalMinutes = props.event.duration; // e.g., 15 minutes
  //         let currentMoment = startMoment.clone();

  //         while (currentMoment.isBefore(endMoment)) {
  //           const intervalEndMoment = currentMoment.clone().add(intervalMinutes, 'minutes');
  //           if (intervalEndMoment.isAfter(endMoment)) {
  //             break; // Stop if the interval end time exceeds the overall end time
  //           }
  //           availabilityData.push({
  //             scheduleId: uuidv4(), // Unique ID for each interval
  //             timeZone: selectedTimeZone,
  //             start: currentMoment.toString(), // ISO format
  //             end: intervalEndMoment.toString(), // ISO format
  //             event_start:timePair.start,
  //             event_end:timePair.end,
  //             event_id: props.event.id
  //           });
  //           currentMoment = intervalEndMoment; // Move to the next interval
  //         }
  //       });
  //     }
  //   });
  //   const payload = {
  //     id: availabilityId,
  //     timeZone: selectedTimeZone,
  //     availability: availabilityData,
  //     meetingDuration: props.event.duration,
  //     event_id : props.event.id
  //   };
  //   dispatch(createSchedule({ schedules: payload }))
  //   setIsDisable(true)
  // };

  const handleSave = async () => {
    if (!validateAvailability()) {
      return; // Stop if validation fails
    }
  
    const availabilityId = uuidv4(); // Generate a unique ID for the availability
    const availabilityData = [];
    const repeatIntervalDays = repeatType === "weeks" ? repeatCount * 7 : 30 * repeatCount; // Calculate total repeat interval
  
    // Repeat the logic based on the selected repeat interval
    for (let i = 0; i <= repeatIntervalDays; i += 7) { 
      // Increment by 7 days to handle weekly intervals or for repeating multiple times for months
  
      Object.keys(dayTimeFields).forEach(day => {
        if (dayCheckboxes[day]) {
          const dateForDay = getDateForDay(day); 
          const repeatDate = dateForDay.clone().add(i, 'days'); // Add i days for each iteration to repeat the slots
  
          dayTimeFields[day].forEach(timePair => {
            const startMoment = repeatDate.clone().set({
              hour: moment(timePair.start, 'hh:mma').hour(),
              minute: moment(timePair.start, 'hh:mma').minute(),
            });
            const endMoment = repeatDate.clone().set({
              hour: moment(timePair.end, 'hh:mma').hour(),
              minute: moment(timePair.end, 'hh:mma').minute(),
            });
  
            // Calculate the interval times
            const intervalMinutes = props.event.duration; // e.g., 15 minutes
            let currentMoment = startMoment.clone();
  
            while (currentMoment.isBefore(endMoment)) {
              const intervalEndMoment = currentMoment.clone().add(intervalMinutes, 'minutes');
              if (intervalEndMoment.isAfter(endMoment)) {
                break; // Stop if the interval end time exceeds the overall end time
              }
              availabilityData.push({
                scheduleId: uuidv4(), // Unique ID for each interval
                timeZone: selectedTimeZone,
                start: currentMoment.toString(), // ISO format
                end: intervalEndMoment.toString(), // ISO format
                event_start: timePair.start,
                event_end: timePair.end,
                event_id: props.event.id
              });
              currentMoment = intervalEndMoment; // Move to the next interval
            }
          });
        }
      });
    }
  
    const payload = {
      id: availabilityId,
      timeZone: selectedTimeZone,
      availability: availabilityData,
      meetingDuration: props.event.duration,
      event_id: props.event.id
    };

    dispatch(createSchedule({ schedules: payload }));
    setIsDisable(true);
  };
  

  // Convert 12-Hour Time Format to 24-Hour Format
  const convertTo24HourFormat = (time12h) => {
    let [time, modifier] = time12h.split(/(am|pm)/i);
    let [hours, minutes] = time.trim().split(':');

    if (hours === '12') {
      hours = '00';
    }
    if (modifier.toLowerCase() === 'pm') {
      hours = parseInt(hours, 10) + 12;
    }
    return `${hours.toString().padStart(2, '0')}:${minutes.padStart(2, '0')}`;
  };

  const getTimeZoneOffset = (zone) => {
    const currentTime = moment();
    const offset = currentTime.tz(zone).format('Z'); // Get UTC offset for the timezone
    return `(UTC${offset}) ${zone}`;
  };


  const handleBackButton = () =>{
    dispatch(setSchedules());
    props.hideAvailability();
  }


  const hasErrors = () => {
    for (let day in errors) {
      if (Object.keys(errors[day]).length > 0) {
        // If there are any keys in the errors[day] object, there are errors
        for (let index in errors[day]) {
          if (errors[day][index]) {
            return true; // Return true if any error exists
          }
        }
      }
    }
    return false; // No errors found
    // return false;
  };

  return (
    <div className='p-5 container'>
      <Row className='justify-content-center'>
        <Col sm={12} lg={7}>
          <Button variant="primary" onClick={handleBackButton}>Back</Button>
          <Form.Group className="mt-3 form-group">
              <Form.Select value={selectedTimeZone} onChange={handleTimeZoneChange}>
                <option value={moment.tz.guess()}>{moment.tz.guess()}</option>
                {
                  timeZones.map((tz, index) => (
                    <option key={index} value={tz.Description}>
                      {/* {${tz.Description} ${tz.Location}} */}
                      {getTimeZoneOffset(tz)}
                    </option>
                  ))
                }
              </Form.Select>
          </Form.Group>
          <div className='custom--add--times'> 
            {/* Days of the Week */}
            <Form.Group className="mb-3">
              <Form.Label>Repeat</Form.Label>
              <InputGroup>
                <Form.Control
                  type="number"
                  min="1"
                  value={repeatCount}
                  onChange={(e) => setRepeatCount(Number(e.target.value))}
                />
                <Form.Select value={repeatType} onChange={(e) => setRepeatType(e.target.value)}>
                  <option value="weeks">Weeks</option>
                  <option value="months">Months</option>
                </Form.Select>
              </InputGroup>
            </Form.Group>
            <ListGroup>
              {['mon', 'tue', 'wed', 'thu', 'fri', 'sat','sun'].map(day => (
                <ListGroup.Item key={day}>
                  {/* Day Switch */}
                  <span className='day--checkbox'>
                    <Form.Check
                      type='switch'
                      id={day}
                      label={day.charAt(0).toUpperCase() + day.slice(1)}
                      checked={dayCheckboxes[day]}
                      onChange={() => handleCheckboxChange(day)}
                    />
                  </span>

                  {/* Time Slots for the Day */}
                  {dayCheckboxes[day] && (
                    <Form>
                      <div className="time--slots">
                        {dayTimeFields[day].map((timePair, index) => (
                          <div key={index} className='single--row'>
                            {/* Start Time Selection */}
                            <Form.Group className="form-group mb-0 me-2">
                              <Form.Select
                                value={timePair.start}
                                onChange={(e) => handleStartChange(day, index, e.target.value)}
                              >
                                {timeOptions.map(option => (
                                  <option key={option} value={option}>{option}</option>
                                ))}
                              </Form.Select>
                              {errors[day] && errors[day][index] && (
                                <span className="error-message" style={{ color: 'red' }}>
                                  {errors[day][index]}
                                </span>
                              )}
                            </Form.Group>

                            {/* End Time Selection */}
                            <Form.Group className="form-group mb-0">
                              <Form.Select
                                value={timePair.end}
                                onChange={(e) => handleEndChange(day, index, e.target.value)}
                              >
                                {endTimeOptions.map((option, ind) => (
                                  <option key={ind} value={option}>{option}</option>
                                ))}
                              </Form.Select>
                            </Form.Group>

                            {/* Remove Time Slot Button */}
                            <span className='remove--interval' onClick={() => handleRemoveTimeField(day, index)}>
                              <MdClose />
                            </span>
                          </div>
                        ))}
                      </div>

                      {/* Add Time Slot Button */}
                      <span className='action--icons'>
                        <span className='add--interval' onClick={() => handleAddTimeField(day)}>
                          <FiPlus />
                        </span>
                      </span>
                    </Form>
                  )}
                </ListGroup.Item>
              ))}
            </ListGroup>
            <Button className='add--interval mt-3' 
              disabled={isDisable || hasErrors()} 
              onClick={(e) => !isDisable && !hasErrors() && handleSave(e)}
            >
              Save
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default MeetingScheduler;


