import { 
    UPDATE_HEADER_SUCCESS ,
} from "../actions/types";

const initialState = {
    isActive: false,
};


export default (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_HEADER_SUCCESS:
            if( action.payload.user){
                var isActive = action.payload.user.show_header
            }
            return{
                isActive:isActive 
            }
        default: return state;        
    }
}