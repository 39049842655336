import React, { useEffect , useState } from 'react';
import { useNavigate , useLocation} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Container, Row, Col, Form , Spinner} from 'react-bootstrap';
import CurrencyInput from 'react-currency-input-field';
import { toast, Toaster } from "react-hot-toast";  
import { BsArrowLeft } from 'react-icons/bs';
import { FaCogs, FaCreativeCommonsPdAlt, FaTimes } from 'react-icons/fa';
import { EditorState, convertToRaw, getDefaultKeyBinding } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import PageHeader from '../Header/HeaderTop';
import SidebarMenu from '../Sidebar/SidebarNav';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../App.css';
import { Link } from 'react-router-dom';
import {IoClose} from 'react-icons/io5';
import { createProduct } from '../../redux/actions/product.action';
import { notValid , valid  } from '../../utils/validations';
import { getMyShops , getShopCatgory} from '../../redux/actions/shop.action';



const conditionOptions = [
    { value: 'new', label: 'New' },
    { value: 'used-new', label: 'Used -  Like New' },
    { value: 'used-good', label: ' Used - Good' },
    { value: 'used-fair', label: 'Used - Fair' },
]
const availabilityOptions = [
    { value: true, label: 'In Stock' },
    { value: false, label: 'Out of Stock' },
]


function CreateProduct() {
    const intialValue = {
        title: '',
        price:'0.00',
        category:'',
        payment_type:'cash',
        price_type:'fixed',
        currency: "USD",
        availability: '',
        condition:'',
        shop:0,
        short_description:'',
        images:[],
        description:'',
        stock:0,
        is_digital: false,
        payment_option: 0,
        media:''
    }
    const [productFields , setProductFileds] = useState(intialValue);
    const [loading , setLoading] = useState(false) ;
    const [errors , setErrors] = useState(false);
    const [showEditor , setShowEditor] = useState(true);
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
    const [categories , setCategories] = useState('')
    const [isShop , setIsShop] = useState(false);
    const [myShop, setMyShop] = useState('');
    const [shopName , setShopName] = useState('')
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const apiResult = useSelector(state => state.product);
    const shopResult = useSelector(state => state.shop);
    const location = useLocation();
    const shopProps = location.state;


    useEffect(()=>{
        if(shopProps){
            let copyProductsFields = {...productFields}
            copyProductsFields.shop = shopProps.id;
            copyProductsFields.category = shopProps.category
            setProductFileds(copyProductsFields)
        }else{
            dispatch(getMyShops())
            dispatch(getShopCatgory())
        }
    },[])


    useEffect(()=>{
        if(shopResult && shopResult.myShopList){
            setIsShop(true)
            setMyShop(shopResult.myShopList.myShopsList)
        }
        if(shopResult && shopResult.shopCatList){
            setCategories(shopResult.shopCatList.shopCatList)
        }
    },[shopResult])


    const handleInput = (e) =>{
        const {value , name } = e.target ; 
        if(name === 'short_description'){
            if (value.length <= 250) {
                setProductFileds({...productFields, [name]: value })
                setErrors({ ...errors, [name]: '' })
            }else{
                setErrors({ ...errors, [name]: 'Enter text maximum 250 words' })
            }
        }else{
            setProductFileds({...productFields, [name]: value })
            setErrors({ ...errors, [name]: '' })
        }
    }


/***********************************   images handling  **********************************************/    


    const handleImages = (e) =>{
        const files = e.target.files ;
        const check = ['image/jpeg', 'image/png' , 'image/jpg' , 'image/gif' ];
        if(files && productFields['images'].length <= 10){
            let copyProductsFields =  {...productFields}
            const copyErrors = {...errors} 
            Array.from(files).forEach((file) => {
                if (check.includes(file.type)) {
                    copyProductsFields['images'].push(file)
                    setProductFileds(copyProductsFields)
                    copyErrors.images = ''
                    setErrors(copyErrors);
                }else{
                    copyErrors['images'] = ' Please use only  png, jpg, jpeg, JPG, JPEG, PNG'
                    setErrors(copyErrors)
                }
            });
        }
    }


    const handleRemoveFiles = (index) =>{
        let copyProductsFields =  {...productFields}
        copyProductsFields['images'].splice(index, 1);
        setProductFileds(copyProductsFields)
    }


    const renderImages = (mediaType) =>{
        if(productFields && productFields[mediaType].length > 0){ 
            return(
                <Form.Group className='form-group col-sm-12'>
                    <div className='post--thumb--upload post--album--grid'>
                        {productFields[mediaType].map((files, filesIndex) => {
                            return (
                                <div className="album--upload">
                                    <img key={filesIndex} src={typeof files === 'object' ? URL.createObjectURL(files) : files}/>
                                    <span className="cerificate--files--close" onClick={() => handleRemoveFiles(filesIndex)}><IoClose/></span>
                                </div>
                            )
                        })}
                    </div>
                </Form.Group>
            )    
        }
    }

    


/*************************************************************************************************************** */   

/********************************    editior handling  *********************************************************/

    const onEditorStateChange = (editorState) => {
        const copyProductsFields = {...productFields} ;
        let errorMessages = {...errors};
        const rawContentState = convertToRaw(editorState.getCurrentContent());
        const description = draftToHtml(rawContentState);
        copyProductsFields.description = description
        errorMessages.description = '' ;
        setProductFileds(copyProductsFields)
        setErrors(errorMessages)
        setEditorState(editorState);
    };


    const myKeyBindingFn = event => {
        return getDefaultKeyBinding(event);
    }

/******************************************************************************************************************* */    
    const handlePriceFocus = (e) => {
        let copyProductsFields = {...productFields}
        if (e.target.value === '') {
            copyProductsFields.price = 'USD'
            setProductFileds( copyProductsFields);
           
        }
    };

    const handleStock = (value , name) => {
        if( name === 'price' ){
            value = value ? value : 0
        }

        setProductFileds({...productFields, [name]: value })
        setErrors({ ...errors, [name]: '' })

    }

    const handleCondition = (e) =>{
        let copyProductsFields = {...productFields};
        copyProductsFields.condition = e.target.value
        setProductFileds(copyProductsFields)
        const copyErrors = {...errors} 
        if(copyErrors.condition){
            copyErrors.condition = ''
            setErrors(copyErrors);

        }

    }

    const handleAvailability = (e)=>{
        let copyProductsFields = {...productFields};
        copyProductsFields.availability = e.target.value
        setProductFileds(copyProductsFields)
        const copyErrors = {...errors} 
        if(copyErrors.availability){
            copyErrors.availability = ''
            setErrors(copyErrors);
        }
    }

    const handleProductType = (value) => {
        let copyProductsFields = {...productFields};

        copyProductsFields.is_digital = value
        if(value){
            copyProductsFields.payment_option = 1;
        }
        setProductFileds(copyProductsFields)
    };

    const handlePaymentType = (e) =>{
        let copyProductsFields = {...productFields};
        copyProductsFields.payment_option = e.target.value
        setProductFileds(copyProductsFields)
    }


    const selecteCategory = () => {
        if(categories && categories.length > 0){
            return (
                <Form.Group className='form-group col-sm-12 col-lg-6'>
                    <Form.Label>Category</Form.Label>
                    <Form.Control value={productFields.category || '' } onChange= {handleCategory} as="select">
                        <option value="" selected>Select</option>
                        {categories.map((category, index) => (
                            <optgroup label={`${category.name} (${category.marketplaceSubCategories.length})`}>
                                {category.marketplaceSubCategories.map((subcategory, subIndex) => (
                                    <option key={subIndex} value={subcategory.id}>
                                    {subcategory.name}
                                    </option>
                                ))}
                            </optgroup>
                            
                        ))}
                    </Form.Control>
                </Form.Group>

            )
        }
    }


    const handleCategory = (e)=>{
        let copyProductsFields = {...productFields};
        setShopName('')
        copyProductsFields.category = e.target.value
        const shop = myShop.filter(sop => sop.category == copyProductsFields.category )
        if(shop && shop.length > 0){
            copyProductsFields.shop = shop[0].id
            setShopName(shop[0].title)
        }
        setProductFileds(copyProductsFields)
    }

    const handleShop = (e)=>{
        setShopName(e.target.value)
    }


/********************************   form validation  **********************************************/

    const isValid = () =>{
        let isValid = true ;
        let errorMessages = {};
        const check = ['null' , null , undefined , 'undefined' , '' , []] ; 
        if (notValid(productFields.short_description)) {
            errorMessages['description'] = ' Short description is  '
            isValid = false;
        }
        if(productFields.is_digital) {
            if (!productFields.media) {
                errorMessages.is_digital = true;
                errorMessages['is_digital'] = ' Media is required'
                isValid = false;
            }
        }
        if(notValid(productFields.title)){
            errorMessages['title'] = ' Please enter a product title'
            isValid = false;
        }
        if(notValid(productFields.condition)){
            errorMessages['condition'] = ' Please select a product condition'
            isValid = false;
        }
        if(productFields.images.length < 1){
            errorMessages['images']  = 'please upload product images'
            isValid = false;
        }
        if(notValid(productFields.availability)){
            errorMessages['availability']  = 'please select product availability'
            isValid = false;
        }
        if(productFields.availability){
            if(productFields.stock === 0){
                errorMessages['stock']  = 'please enter a valid stock'
                isValid = false;
            }
        }
        // if(productFields.price === '0.00'){
        //     errorMessages['price']  = 'please enter a valid price'
        //     isValid = false;
        // }
        if(check.includes(productFields.category)){
            errorMessages['price']  = 'please select a category'
            isValid = false;
        }
        if (!isValid) {
            setErrors({ ...errors, ...errorMessages});
        }
        return isValid;
       
    }    

    const handleCreateProduct = (e)=>{
        e.preventDefault();
        if(isValid()){
            setLoading(true)
            const formData = new FormData();
            for (const [key, value] of Object.entries(productFields)) {
                if (typeof value === 'object' && key === 'images') {
                  value.forEach(element => {
                    formData.append('images', element);
                  });
                } else if (typeof value === 'object' && key === 'media') {
                    formData.append('thumbnail', value);
                }else {
                  formData.append(key, value)
                }   
            }
            dispatch(createProduct(formData)) ;
        }
    }


    useEffect(()=>{
        if(apiResult && apiResult.productCreated){
            toast.success(apiResult.productCreated.message, {
                position: 'top-center', 
            });
            setLoading(false)
            navigate('/marketplace')
        }

        if(apiResult && apiResult.productCreatedError){
            toast.error(apiResult.productCreatedError, {
                position: 'top-center', 
            });
            setLoading(false)
        }
        if(apiResult && apiResult.productError){
            toast.error(apiResult.productError, {
                position: 'top-center', 
            });
            setLoading(false)

        }
    })

    const handleDigitalMedia = e => {
        const copyProductsFields = {...productFields}
        copyProductsFields.media = e.target.files[0];
      
        setProductFileds({...copyProductsFields});
    }

    const handleRemoveDigitalMedia = () => {
        const copyProductsFields = {...productFields}
        copyProductsFields.media = ''
        setProductFileds({...copyProductsFields});
    }
    

    return (
        <>
            <PageHeader />
            <div className='page--content'>
                <SidebarMenu />
                <div className='discover--module common--wrapper create--shop--form'>
                    <Container>
                        <Row className='justify-content-center pt-3'>
                            <Col sm={12} lg={10}>
                                <Link className='back--btn' to='/marketplace'><BsArrowLeft/> Back</Link>
                                <Form onSubmit={handleCreateProduct}>
                                    <Row>
                                        <Form.Group className='form-group col-sm-12 col-lg-12'>
                                            <Form.Label>Product Title</Form.Label>
                                            <Form.Control type="text"  name='title' value={productFields.title} onChange = {handleInput}/>
                                            {errors.title && <small className="error error-massege text-danger">{errors.title}</small>}
                                        </Form.Group>
                                        <Form.Group className='form-group col-sm-12'>
                                            <Form.Label>Short Description</Form.Label>
                                            <Form.Control as="textarea" name="short_description"  value={productFields.short_description} onChange={handleInput}rows={2} />
                                            {errors.short_description && <small className="error error-massege text-danger">{errors.short_description}</small>}
                                            <p>Word count: {productFields.short_description.split(/\s+/).length}/250</p>
                                            <small>0/250</small>
                                        </Form.Group>
                                        {renderImages('images')}
                                        <Form.Group className='form-group col-sm-12'>
                                            <Form.Label>Add Photos</Form.Label>
                                            <input type='file' hidden id="upload--header-products" multiple onChange={handleImages}/>
                                            <label className='label--upload' htmlFor="upload--header-products">Upload photos ( You can add up to 10 photos )</label>
                                            {errors.images && <small className="error error-massege text-danger">{errors.images}</small>}
                                        </Form.Group>
                                        <Form.Group className='form-group col-sm-12'>
                                            <span className='editor--tool' onClick= {()=>setShowEditor(!showEditor)}><FaCogs /></span>
                                        </Form.Group>
                                        <Form.Group className='form-group col-sm-12'>
                                            <Form.Label>Long Description</Form.Label>
                                            <Editor
                                                editorState={editorState}
                                                wrapperClassName="demo-wrapper editor-w"
                                                editorClassName="demo-editor input"
                                                onEditorStateChange={onEditorStateChange}
                                                keyBindingFn={myKeyBindingFn}
                                                hashtag={{
                                                    separator: ' ',
                                                    trigger: '#',
                                                }}
                                                toolbarHidden={showEditor}
                                                config={{ link: { trailingWhitespace: true } }}
                                                
                                            />
                                        </Form.Group>
                                        {(isShop)&&
                                            <>
                                                {selecteCategory()}
                                                <Form.Group className='form-group col-sm-12 col-lg-6'>  
                                                    <Form.Label>Shop</Form.Label>
                                                    <Form.Select value={shopName || '' } onChnage = {handleShop} className='form-control'>
                                                        <option>Select...</option>
                                                        <option value={shopName}>{shopName || ''}</option>
                                                       
                                                    </Form.Select>
                                                </Form.Group>  
                                            </>
                                           
                                        }    
                                        <Form.Group className='form-group col-sm-12 col-lg-3'>
                                            <Form.Label>Price</Form.Label>
                                            <CurrencyInput
                                                id="product-price"
                                                name="price"
                                                // defaultValue={productFields.price}
                                                decimalsLimit={2}
                                                onValueChange={(value, name)=>handleStock(value, name)}
                                                onChangeCapture={(event, maskedvalue, floatvalue) => console.log( event, maskedvalue, floatvalue ) }  
                                                suffix={` ${productFields.currency}`}
                                                value={productFields.price} 
                                            />
                                            {errors.price && <small className="error error-massege text-danger">{errors.price}</small>}
                                        </Form.Group>
                                        <Form.Group className='form-group col-sm-12 col-lg-3'>  
                                            <Form.Label>Condition</Form.Label>
                                            <Form.Select value={productFields.condition || '' } onChange= {handleCondition}   className='form-control'>
                                                <option value = ''>Select...</option>
                                                {conditionOptions.map((item ,idx)=>{
                                                    return ( 
                                                        <option key={idx} value={item.value}>{item.label}</option>
                                                    )
                                                })}
                                            </Form.Select>
                                            {errors.condition && <small className="error error-massege text-danger">{errors.condition}</small>}
                                        </Form.Group>
                                        <Form.Group className='form-group col-sm-12 col-lg-3'>
                                            <Form.Label>Stock</Form.Label>
                                            <CurrencyInput
                                                id="product-stock"
                                                name="stock"
                                                defaultValue={productFields.stock}
                                                allowDecimals={false} // Disallow decimals
                                                allowNegativeValue={false} // Disallow negative values
                                                onValueChange={(value, name)=> handleStock(value, name)}
                                            />
                                            {errors.stock && <small className="error error-massege text-danger">{errors.stock}</small>}
                                        </Form.Group>
                                        <Form.Group className='form-group col-sm-12 col-lg-3'>
                                            <Form.Label>Availability</Form.Label>
                                            <Form.Select value={productFields.availability || '' }  onChange= {handleAvailability}   className='form-control'>
                                                <option value="" >Select...</option>
                                                {availabilityOptions.map((item,idx)=>{
                                                    return (
                                                        <option  key={idx} value={item.value}>{item.label}</option>
                                                    )
                                                })}
                                            </Form.Select>
                                            {errors.availability && <small className="error error-massege text-danger">{errors.availability}</small>}
                                        </Form.Group>
                                        <Form.Group className='form-group col-sm-12 col-lg-12'>
                                            <Form.Label>Product Type</Form.Label>
                                            <br/>
                                            <Form.Check inline checked={productFields.is_digital} onChange={() => handleProductType(true)} type="radio" label="Digital" name="is_digital" value={true} />
                                            <Form.Check inline checked={!productFields.is_digital} onChange={() => handleProductType(false)} type="radio" label="Physical" name="is_digital" value={false} />
                                        </Form.Group>
                                        {(productFields.is_digital)&&
                                            <>
                                                
                                                <Form.Group className='form-group col-sm-12'>
                                                    <Form.Label>Upload Media</Form.Label>
                                                    <input
                                                        type="file"
                                                        className="form-control"
                                                        required
                                                        name="media"
                                                        id="filePicker"
                                                        onChange={handleDigitalMedia}
                                                        placeholder="upload file"
                                                        hidden
                                                    />
                                                    <label className='label--upload' htmlFor="filePicker">Upload file</label>
                                                </Form.Group>
                                                {errors.is_digital && <small className="error error-massege text-danger">{errors.is_digital}</small>}

                                                <Col sm={12}>
                                                    {(typeof productFields.media === 'object') && 
                                                        <span className="uploaded--file" id="old_media">
                                                            {productFields.media.name}
                                                            <FaTimes onClick={handleRemoveDigitalMedia}/>
                                                        </span>
                                                    }
                                                </Col>
                                            </>
                                        }

                                        <Form.Group className='form-group col-sm-12 col-lg-12'>
                                            <Form.Label>Payment Option</Form.Label>
                                            <br/>
                                            <Form.Check 
                                                inline 
                                                disabled={productFields.is_digital} 
                                                checked={Number(productFields.payment_option) === 0} 
                                                onChange={handlePaymentType} 
                                                type="radio" 
                                                label="Any" 
                                                name="payment_option" 
                                                value={0} 
                                            />
                                            <Form.Check 
                                                inline 
                                                checked={Number(productFields.payment_option) === 1}
                                                onChange={handlePaymentType} 
                                                type="radio" 
                                                label="Paypal" 
                                                name="payment_option" 
                                                value={1} 
                                            />
                                        </Form.Group>
                                        <Col sm={12} className='text-end'>
                                            <Button disabled={loading} variant="primary" type="submit">
                                                {loading ?  <> <Spinner animation="border" size="sm"/> Please wait... </> : 'Create '}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                    </Container>
                    <Toaster/>
                </div>
            </div>
        </>
    );
}

export default CreateProduct;