import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
// import { BsPlus, BsEmojiSmile } from 'react-icons/bs';
// import { FaPaperPlane } from 'react-icons/fa';
// import { ImAttachment } from 'react-icons/im';

import Chat from './Chat';
import ChatUsers from './ChatUsers';

import PageHeader from '../Header/HeaderTop';
import SidebarMenu from '../Sidebar/SidebarNav';

import 'bootstrap/dist/css/bootstrap.min.css';
import './messengerStyle.css';
import '../../App.css';

function Messenger() {
    const chat_users =  useSelector(state => state.messenger.chat_users);

    return (
        <>
            <PageHeader />
                <div className='page--content'>
                    <SidebarMenu />
                <div className='discover--module common--wrapper messenger--main'>
                    <Container>
                        <Row>
                            <Col sm={12} md={4} className='pe-md-0'>
                                <ChatUsers chat_users={chat_users}/>
                            </Col>
                            <Col sm={12} md={8} className='ps-md-0'>
                                <Chat />
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    );
}

export default Messenger;