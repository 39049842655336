import { 
    CREATE_LINK_SUCCESS,
    CREATE_LINK_FAILED,
    GET_LINK_SUCCESS,
    GET_LINK_FAILED,
    LINK_COMMON_ERROR,
    EDIT_LINK_SUCCESS,
    EDIT_LINK_FAILED,
    CLEAR_LINK_CREATE , 
    CLEAR_POSTS
} from "../actions/types";


const initialState = {
    createTextSuccess: null,
    textPostError: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case CREATE_LINK_SUCCESS:
            return{
                createLinkSuccess:action.payload,
            }
        case CREATE_LINK_FAILED:
            return{
                linkPostError:action.payload.message,
            }    
        case LINK_COMMON_ERROR:
            return{
                linkPostError:action.payload,
            }              
        case  GET_LINK_SUCCESS:
            return{
                linkPostList:action.payload,
            }        
        case  EDIT_LINK_SUCCESS:
            return{
                editLink:action.payload,
            }            
        case  EDIT_LINK_FAILED:
            return{
                linkPostError:action.payload,
            }            
        case  GET_LINK_FAILED:
            return{
                linkPostError:action.payload,
            }     
        case CLEAR_LINK_CREATE:
            return {
                createLinkSuccess:null,
            }
        case CLEAR_POSTS: {
            return{
                createLinkSuccess: null,
            }
        }
        default: return state;               
    }
}