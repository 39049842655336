import React, { useEffect, useState } from "react";
import { Table, Spinner, Button, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getMeetings } from "../../redux/actions/schedule.action";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import PageHeader from '../Header/HeaderTop';
import SidebarMenu from '../Sidebar/SidebarNav';

function BookMeetingsList() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true); // State for handling the loader
  const navigate = useNavigate();
  const scheduleState = useSelector((state) => state.schedule);

  useEffect(() => {
    dispatch(getMeetings()).finally(() => setLoading(false)); // Set loading to false after the data is fetched
  }, [dispatch]);

  const check = ['0.00' , '' , null , undefined , 'undefined' , '0' , 0]

  return (
    <>
      <PageHeader />
      <div className="page--content">
        <SidebarMenu />
        <div className="discover--module common--wrapper stream--page pb-4">
          <Container>
            <div className="text-end mb-4">
                <Button onClick={() => navigate(-1)}>Back</Button>
            </div>
            {/* <h2>Book Meetings List</h2> */}
            {loading ? (
              // Show the loader while fetching data
              <div className="text-center">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            ) : // Check if meetings array is null or empty
            scheduleState?.meetings?.meetings?.length > 0 ? (
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Amount</th>
                    <th>Scheduler Start Time (Scheduler's Timezone)</th>
                    <th> Creater Start Time (Creater's Timezone)</th>
                    <th>Duration</th>
                    <th> Scheduler  Details</th>
                    <th>Creator Details</th>
                    <th>Join Link</th>
                  </tr>
                </thead>
                <tbody>
                  {scheduleState.meetings.meetings
                    .slice()
                    .reverse()
                    .map((meeting, index) => {
                      // Parse the creater_detail field
                      const creatorDetail = JSON.parse(
                        meeting.creater_detail || "{}"
                      );
                      return (
                        <tr key={meeting.id}>
                          <td>{index + 1}</td>
                          <td>{check.includes(meeting.amount)? 'Free' : `$ ${meeting.amount}`}</td>
                          <td>
                          {moment.utc(meeting.start_time).format("YYYY-MM-DD hh:mm A")}
                          </td>
                          <td>
                            {moment.utc(meeting.start_date).format("YYYY-MM-DD hh:mm A")}
                          </td>
                          <td>{meeting.meeting_duration} minutes</td>
                          <td> 
                              {meeting.user.first} {meeting.user.last} {""}
                              <br/>
                              {meeting.user.email}
                          </td>
                          <td>
                            {creatorDetail.firstname} {creatorDetail.lastname}{" "}
                            <br />
                            {creatorDetail.email}
                          </td>
                          <td>
                            <Link
                              to={meeting.meeting_link}
                              style={{ color: "blue" }}
                            >
                              Join
                            </Link>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            ) : (
              // Show message if no meetings are found
              <div className="text-center">
                <p>No meetings found</p>
              </div>
            )}
          </Container>
        </div>
      </div>
    </>
  );
}

export default BookMeetingsList;