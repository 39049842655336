import { 
    GET_SINGLE_POST_SUCCESS,
    GET_SINGLE_POST_FAILED,
    COMMON_SINGLE_POST_ERROR,
    GET_SINGLE_BOARD_SUCCESS,
    GET_SINGLE_BOARD_FAILED,
    GET_BOARD_POST_SUCCESS,
    GET_BOARD_POST_FAILED,
    GET_DRAFT_POST_SUCCESS,
    GET_DRAFT_POST_FAILED,
    CHANGE_POST_STATUS_SUCCESS,
    CHANGE_POST_STATUS_FAILED, 
    LIKE_POST,
    DISCOVER_POST,
    DISCOVER_POST_ERROR,
    CREATE_POST_COMMENT,
    ADD_LIKE_COMMENT,
    DELETE_COMMENT,
    GET_USER_NOTIFICATION,
    GET_NOTIFICATION,
    CLEAR_POST_DATA,
    LIVE_VIDEO_STARTED,
    STOP_LIVE_VIDEO,
    CLEAR_LIVE_COMMENTS,
    GET_NOTIFICATION_LIST

} from "../actions/types";
import jwt_decode from "jwt-decode";



const initialState = {
    singlePostSuccess: null,
    singlePostError: null,
    discoverPosts:null ,
   
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_SINGLE_POST_SUCCESS:
            return{
                singlePostSuccess:action.payload,
            }
        case  GET_SINGLE_POST_FAILED:
            return{
                singlePostError:action.payload,
            }    
        case  COMMON_SINGLE_POST_ERROR:
            return{
                singlePostError:action.payload,
            }    
        case GET_BOARD_POST_SUCCESS:
            return{
                ...state , 
                boardPostList:action.payload,
                boardPostError: null
            }    
        case GET_BOARD_POST_FAILED:
            return{
                ...state ,
                boardPostList :null , 
                boardPostError:action.payload,
            }    
        case GET_SINGLE_BOARD_SUCCESS:
            return{
                singleBoardPostList:action.payload,
            }    
        case GET_SINGLE_BOARD_FAILED:
            return{
                singleBoardPostError:action.payload.message,
            }        
        case  GET_DRAFT_POST_SUCCESS:
            return{
                draftList:action.payload   
            }    
        case  GET_DRAFT_POST_FAILED:
            return{
                draftPostError:action.payload   
            }        
        case  CHANGE_POST_STATUS_SUCCESS:
            return {
                changeStatusSuccess: action.payload 
            }
        case CHANGE_POST_STATUS_FAILED:
            return{
                changeStatusError: action.payload
            }   
        case  DISCOVER_POST : 
            return {
                discoverPosts : action.payload,
                discoverPostsError : null ,
                ...state
            }   
        case  DISCOVER_POST_ERROR: 
            return {
                discoverPostsError : action.payload
            }           
        case  LIKE_POST:
            if(state.singlePostSuccess){
                let copyPost = state.singlePostSuccess
                const postLikes = action.payload?.post?.likes.filter((like , idx)=>{
                    if(Number(like.post_id) === Number(copyPost.Post.id)){
                        return like
                    }
                })
                copyPost.Post.Likes = postLikes
                const updatedPost = { ...copyPost };
                return{
                    singlePostSuccess: updatedPost
                }       
            }
            if(state.singleBoardPostList){
                const postLikes = state.singleBoardPostList.singleBoardPosts
                const likesIndex = postLikes.findIndex(post => post.id === action.payload.post.postId)
                if(likesIndex !== -1){
                    var copyPost = state.singleBoardPostList
                    copyPost.singleBoardPosts[likesIndex].Likes = action.payload.post.likes
                    const updateLikes = {...copyPost}
                    return{
                        singleBoardPostList: updateLikes,
                    }     
                }      
            }
        case CREATE_POST_COMMENT:
            if(state.singlePostSuccess){
                const newComments = action.payload.commentList
                if(newComments){
                    let copyPost = {...state.singlePostSuccess}
                    if (Number(copyPost.Post.id) === Number(newComments.result[0].post_id)) {
                        copyPost.commentList = newComments.result
                        const updatedPost = { ...copyPost };
                        return{
                            singlePostSuccess: updatedPost
                        }       
                    }
                }
            }    
        case ADD_LIKE_COMMENT:
            if(state.singlePostSuccess){
                const newLikes = action.payload.commentLikeList
                if(newLikes && newLikes.likes.length > 0){
                    let copyPost = state.singlePostSuccess
                    // copyPost.commentList.forEach(comment => {
                    //     if (Number(newLikes.comment_id) === Number(comment.id)) {
                    //       comment.Comment_Likes = newLikes.likes; // Replace commentLikes with the like's ID
                    //     }
                    //     comment.nested_comments.forEach(nested => {
                    //         if (Number(newLikes.comment_id) === Number(nested.id)) {
                    //             nested.Comment_Likes =newLikes.likes; // Replace nested commentLikes with the like's ID
                    //         }
                    //     });
                    // });

                    if(copyPost && copyPost.commentList.length > 0){
                        if(Number(copyPost.commentList[0].post_id) === Number(newLikes.likes[0].post_id)){
                            copyPost.commentList = newLikes.likes
                        }
                        
                    }
                    const updatedPost = { ...copyPost };
                    return{
                        singlePostSuccess: updatedPost
                    }       
                }
            }
        case DELETE_COMMENT:
            if(state.singlePostSuccess){
                let copyPost = state.singlePostSuccess
                const newComments = action.payload.result
                copyPost.commentList = action.payload.commentList
                const updatedPost = { ...copyPost };
                return{
                    singlePostSuccess: updatedPost
                }       
            }   
        case GET_USER_NOTIFICATION:
            return{
                ...state , 
                userNotifications:action.payload
            } 
        case CLEAR_POST_DATA:
            return{
                singlePostSuccess: null
            }
        case  LIVE_VIDEO_STARTED:{
            if(state.discoverPosts){
                let copyDiscover =  state.discoverPosts
                let livePost = copyDiscover.discoverPosts.liveVideoPost ;
                livePost.push(action.payload.result)
                let uniqueArray = [...new Set(livePost.map(obj => obj.id))].map(id => {
                    return livePost.find(obj => obj.id === id);
                  });
                copyDiscover.discoverPosts.liveVideoPost = uniqueArray
                return {
                    discoverPosts: copyDiscover
                }
            }
        }
        case STOP_LIVE_VIDEO:{
            if(state.discoverPosts){
                var copyDiscover =  state.discoverPosts;
                var livePost = copyDiscover.discoverPosts.liveVideoPost ;
                const postIndex = livePost.findIndex(post => post.id === action.payload.post.id)
                if(postIndex !== -1){
                    copyDiscover.discoverPosts.liveVideoPost[postIndex] = action.payload.post
                    return{
                        streamPostList: copyDiscover,
                    }     
                }      
            }
            return{
                updateLiveVideo:action.payload
            }
        }
        case  CLEAR_LIVE_COMMENTS:{
            if(state.singlePostSuccess){
                return {
                    singlePostSuccess:null
                }
            }
        }
        case GET_NOTIFICATION_LIST:{
            return {
                notificationList:action.payload
            }
        }
        case GET_NOTIFICATION:{
            const {post}  = action.payload ;
            if(post && post.notify){
                if(post?.notify && post?.notify?.notificationList && post?.notify?.userNotify){
                    const token = localStorage.getItem('accessToken');
                    const userData = jwt_decode(token)
                    if(userData && userData.aud){
                        if(Number(post?.notify?.userNotify) === Number(userData.aud)){
                            return{
                                ...state,
                                notificationList : post.notify
                            }
                        }
                    }
                }
            }
        }
        default: return state;    
    }    

}