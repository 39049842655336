import React, { Component} from 'react'
import { connect } from 'react-redux';
import { Container, Row, Col, Dropdown, ListGroup , Button} from 'react-bootstrap';
import { FaEllipsisV, FaRegUser, FaRegCalendarAlt , FaRegHeart} from 'react-icons/fa';
import { BsCardList, BsGridFill, BsPlay} from 'react-icons/bs';
import { IoMdHeart } from "react-icons/io";
import { BiCommentDetail} from 'react-icons/bi';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import InfiniteScroll from 'react-infinite-scroller';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast, Toaster } from "react-hot-toast";
import PageHeader from '../Header/HeaderTop';
import SidebarMenu from '../Sidebar/SidebarNav';
import moment from 'moment';
import 'bootstrap/dist/css/bootstrap.min.css';
import './videoStyle.css';
import '../../App.css';
import { Link } from 'react-router-dom';
import { checkHTML , convertToPlainText , valid} from '../../utils/validations';
import { getLiveVideoPosts } from '../../redux/actions/liveVideoPost.action';
import { changePostStatus } from '../../redux/actions/post.action';
import {decodedLoggedUser , logout , likePosts} from '../../helpers/auth';
import { withRouter } from '../../wrapper/withRouter';

class LiveVideos  extends Component{
    constructor(props){
        super(props)
        this.state = {
            videoPostList : [] ,
            videoPostMeta : '',
            loading : false , 
            page : 1 ,
            perPage : 10 ,
            loadingState : false ,
            isActive : true,
            status : '1',
            userId : ''
        }
    }

    componentDidMount = async () => {
        if (this.props.auth.loggedIn) {
            let token = decodedLoggedUser()
            this.setState({userId:token.aud})
        }
        await this.getVideoPostList();
    }

    loadMoreItems() {
        if (this.state.loadingState) {
            return;
        } else {
            this.getVideoPostList();
        }
    }

    getVideoPostList = async() =>{
        const {perPage , page , videoPostList} = this.state 
        const data = {status:'1' , perPage:perPage , page:page};
        if (this.state.page == 1) {
            this.setState({ loading: true })
        }
        await this.props.getLiveVideoPosts(data);
        if(this.props.videoPost && this.props.videoPost.success){
            var posts = videoPostList ;
            Array.prototype.push.apply(posts, this.props.videoPost.LivePostList.liveVideoPost);
            posts = posts.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i)
            this.setState({
                videoPostList : posts ,
                loading: false,
                loadingState: false,
                videoPostMeta: this.props.videoPost.LivePostList.livePostPostMeta,
            })
            let totalposts =  this.props.videoPost.LivePostList.livePostPostMeta

            if(posts.length !== totalposts){
                this.setState({page: page+1})
            }else{
                this.setState({ loadingState: true })
            }
        }
        if(this.props.videoPostError && this.props.videoPostError.message){
            toast.error(this.props.videoPostError.message , {
                position: 'top-center', 
            });
            this.setState({ loading: false })
        }
    }

    handleToggle = () =>{
        this.setState({isActive : !this.state.isActive})
    }



    handleChangePostStatus = async(postId , status) =>{
        const data = {postId:postId , status:status}
        await this.props.changePostStatus(data)
        if(this.props.postStatus && this.props.postStatus.success){
            this.setState({status : status})
            // this.getTextPosts();
            toast.success(this.props.postStatus.message, {
                position: 'top-center', 
            });
            if( status === '0'){
                this.props.navigate('/')
            }
            if(status === '2'){
                this.props.navigate('/drafts')
            }
        }
        if(this.props.postStatusError && this.props.postStatusError.message){
            toast.error( this.props.postStatusError.message, {
                position: 'top-center', 
            });
        }
    }

    componentDidUpdate(prevProps, prevState){
        if(prevState.status !== this.state.status){
            this.setState({
                videoPostList : [] ,
                videoPostMeta : '',
                loading : false , 
                page : 1 ,
                perPage : 10 ,
                loadingState : false ,
                status: '1'
            },()=>this.getVideoPostList())
        }
        if(prevState.isActive !== this.state.isActive){
            this.setState({
                videoPostList : [] ,
                videoPostMeta : '',
                loading : false , 
                page : 1 ,
                perPage : 10 ,
                loadingState : false ,
                status: '1'
            },()=>this.getVideoPostList())
        }
    }

    handleLike = (data) => {
        // if(Number(data.user.id) !== Number(this.state.userId)){
            likePosts(this.state.userId, data.id)
        // }
    }



/*********    using for share link / copy link to clipboard  ****************************/   
    handleCopy = () => {
       
        toast.success('Link copy to clipboard', {position: 'top-center'});
      
    };
/******************************************************************************************** */  



    handleCommentsLength = (comments) =>{
        if(comments && comments.length > 0){
            const validComments = comments.filter((item , index)=>{
                if(item.status === '1'){
                    if(item.comment_id === null){
                        return item
                    }else{
                        const reply = comments.find((c) => Number(c.id) === Number(item.comment_id));
                        if(reply && reply.status === '1'){
                            return item
                        }
                    }
                }
            });
            if(validComments && validComments.length > 0){
                return (
                    <small className='ms-2'>{validComments.length}</small>
                )
            }

        }
    }

    handleLikeLength = (post) =>{
        if(post){
            const postUserId = post.user_id

            const userFound = post.Likes.some(like => Number(like.user_id) === Number(postUserId))

            if(userFound){
                if(post.Likes && post.Likes.length > 0){
                    const length =  post.Likes.length-1 ;
                    if(length === 0){
                        return null
                    }else{
                        return length
                    }
                }
            }else{
                return post.Likes.length ;
            }
        }
    }

    render(){
        const {isActive , loading , videoPostList, loadingState , userId} = this.state
        return (
            <>
                <PageHeader />
                <div className='page--content'>
                    <SidebarMenu />
                    <div className='discover--module common--wrapper video--main--page'>
                        <Container>
                            <div className='btn--live'>
                                <Button variant='primary' className='me-2'><Link to='/live'> Go Live</Link></Button>
                                {/* <Button variant='secondary'><Link to='/live-video'>Live Videos</Link></Button> */}
                                <ListGroup as="ul" horizontal>
                                    <ListGroup.Item as="li" className={isActive ? 'inactive' : 'active'}  onClick={this.handleToggle}><BsGridFill /></ListGroup.Item>
                                    <ListGroup.Item as="li" className={isActive ? 'active' : 'inactive'}  onClick={this.handleToggle}><BsCardList /></ListGroup.Item>
                                </ListGroup>
                            </div>
                            <Row id="scrollableDiv" ref={(ref) => this.scrollParentRef = ref} style={{ height: "100%" }}>
                                {!loading  ? 

                                    videoPostList.length > 0 

                                    ?   
                                        <InfiniteScroll
                                            pageStart={0}
                                            loadMore={() => this.loadMoreItems()}
                                            hasMore={!loadingState}
                                            loader={
                                                <Col sm={12}>
                                                    <div className='text-center spinner--loader'></div>
                                                </Col>
                                            }
                                            scrollableTarget="scrollableDiv"
                                            getScrollParent={() => this.scrollParentRef}
                                            window={false}
                                        >
                                            <>
                                                <Col sm={12}>
                                                    <Row className={isActive ? 'justify-content-start grid--view' : 'justify-content-center list--view'}>
                                                        {
                                                            videoPostList.map((item , idx)=>{
                                                                if(item.user){
                                                                    return (
                                                                        <Col key ={idx} sm={isActive ? '6' : '12'} xl={isActive ? '3' : '8'} className='mb-4'>
                                                                            <div className="Gr--singleStream">
                                                                                {userId == item.user.id &&
                                                                                    <Dropdown className='edit--post--dropdown'>
                                                                                        <Dropdown.Toggle id="dropdown-basic">
                                                                                            <FaEllipsisV />
                                                                                        </Dropdown.Toggle>
                                                                                        <Dropdown.Menu>
                                                                                            {item.schedule_post ? 
                                                                                                <Link className='dropdown-item' to='/live' state={{post:item}}> Go Live </Link>
                                                                                            : 
                                                                                                <Link className='dropdown-item' to={`/edit-video/${item.slug}`}>Edit</Link>
                                                                                            }
                                                                                            <Dropdown.Item>
                                                                                                <CopyToClipboard text={`${window.location.href}/${item.slug}`} onCopy={()=>{this.handleCopy()}}>
                                                                                                    <p>Share Link</p>
                                                                                                </CopyToClipboard>   
                                                                                            </Dropdown.Item>
                                                                                            <Dropdown.Item onClick={()=>this.handleChangePostStatus(item.id,'2')}>Move to Drafts</Dropdown.Item>
                                                                                            <Dropdown.Item onClick={()=>this.handleChangePostStatus(item.id,'0')}>Delete</Dropdown.Item>
                                                                                        </Dropdown.Menu>
                                                                                    </Dropdown>
                                                                                }    
                                                                                <div className="Gr--streamImage">
                                                                                    <Link to={`/live-video/${item.slug}`}>
                                                                                        <span className='play--icon'><BsPlay/></span>
                                                                                        {/* <img src={valid(item.thumbnail) ? item.thumbnail : '/images/thumbnail.jpg'} alt="..." /> */}
                                                                                        <LazyLoadImage
                                                                                            width="100%"
                                                                                            placeholderSrc="/images/placeholder.gif"
                                                                                            effect="blur"
                                                                                            src={valid(item.thumbnail) ? item.thumbnail : '/images/thumbnail.jpg'}
                                                                                            alt={item.name}
                                                                                        /> 
                                                                                    </Link>
                                                                                </div>
                                                                                <div className="Gr--streamDesc">
                                                                                    <Link to={`/${item.user.username}`}>
                                                                                        <img src={(item.user && valid(item.user.image)) ? item.user.image : "/images/user.png"} alt='...' />
                                                                                    </Link> 
                                                                                    <div className="Gr--streamUser">
                                                                                        <h4><Link to={`/live-video/${item.slug}`}>{checkHTML(item.name) ? convertToPlainText(item.name) : item.name}</Link></h4>
                                                                                        <Link to={`/${item.user.username}`}>
                                                                                            <p><FaRegUser/> {(item.user && valid(item.user.first))? item.user.first : ''}</p>
                                                                                            <p><FaRegCalendarAlt /> {moment(item.createdAt).format('LL')}</p>
                                                                                        </Link>
                                                                                    </div>
                                                                                    <span className='like--action' >
                                                                                        {item.commenting && 
                                                                                            <>
                                                                                                <Link to={`/live-video/${item.slug}`}><BiCommentDetail/></Link>
                                                                                                {(item.comments && item.comments.length > 0)&&
                                                                                                    this.handleCommentsLength(item.comments)
                                                                                                }
                                                                                            </>
                                                                                        }    
                                                                                        { (item.Likes) && item.Likes.some(like => like.user_id === userId) ? (
                                                                                            <IoMdHeart  onClick = {()=>this.handleLike(item)}/>) : (<FaRegHeart onClick = {()=>this.handleLike(item)}/>
                                                                                               )}
                                                                                        <small className='ms-2'>{ (item.Likes && item.Likes.length > 0)&& this.handleLikeLength(item)}</small>
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </Col>   
                                                                    )
                                                                }    
                                                            })
                                                        }    
                                                    </Row>
                                                </Col>    
                                            
                                            </>

                                        </InfiniteScroll>   
                                    :   <h2 className="mt-5 text-center">Posts Not Found</h2>

                                :   <Col sm={12}>
                                        <div className='text-center spinner--loader'></div>
                                    </Col>
                                }
                            </Row>
                        </Container>
                    </div>
                    <Toaster/>
                </div>
            </>
        );
    }    
}

const maopStateToProps = (state) => {
    return{
        videoPost : state.liveVideo.getLiveVideoSuccess,
        videoPostError : state.liveVideo.liveVideoPostError,
        auth : state.auth ,
        postStatus : state.post.changeStatusSuccess,
        postStatusError: state.post.changeStatusError
    }    

}

const dispatchActions = {getLiveVideoPosts , changePostStatus} ; 
export default connect(maopStateToProps, dispatchActions)(withRouter(LiveVideos));