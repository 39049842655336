import { 
    AUDIO_LIST ,
    CLEAR_AUDIO_LIST,
} from "../actions/types";

const initialState = {
    audioList: null,
};


export default (state = initialState, action) => {
    switch (action.type) {
        case AUDIO_LIST:
            return{
                audioList:action.payload
            }
        case CLEAR_AUDIO_LIST:
            return{
                audioList:null
            }
        default: return state;        
    }
}