import { get } from 'lodash';
import API from '../../helpers/api';
import * as auth from '../../helpers/auth';
import {
    ORDER_CREATED_SUCCESS,
    ORDER_CREATED_FALSE,
    ORDER_COMMON_ERROR,
    GET_BUY_ORDER_SUCCESS,
    GET_BUY_ORDER_FAILED,
    GET_SELLED_ORDER_SUCCESS,
    GET_SELLED_ORDER_FAILED,
    CHANGE_ORDER_STATUS_SUCCESS,
    CHANGE_ORDER_STATUS_ERROR,
} from "./types";

function errorRequest(err, dispatch) {
    let data = get(err, 'response.data', null);
    data = data || get(err, 'response');
    data = data || err;
    if (data.error) {
      dispatch({
        type: ORDER_COMMON_ERROR,
        payload: data.error.message,
      });
    } else if (data.status === 401) {   
      auth.logout()
    } else {
      dispatch({
        type: ORDER_COMMON_ERROR,
        payload: data.message,
      });
    }
}


/*************************    using for create order  **************************************************/

export const createOrder = (payload) =>{
    return async (dispatch) => {
        try{
            const response = await API.apiPostUrl('marketplace', '/create-order' , payload ) ;
            if(response.data && response.data.success){
                await dispatch({ type: ORDER_CREATED_SUCCESS, payload: response.data});
            }else{
                await dispatch({ type: ORDER_CREATED_FALSE, payload: response.data});
            }
        }catch(error){
            errorRequest(error, dispatch);
        }
    }
}


/******************   using for get the buyed order list  **************************************************/

export const getBuyOrderList = (payload)=>{
  return async (dispatch) => {
    try{
      const response = await API.apiGetUrl('marketplace', '/get-buy-orders' , payload) ;
      if(response.data && response.data.success){
        await dispatch({ type: GET_BUY_ORDER_SUCCESS, payload: response.data});
      }else{
        await dispatch({ type: GET_BUY_ORDER_FAILED, payload: response.data});
      }
    }catch(error){
      errorRequest(error, dispatch);
    }
  } 
}

/***********************  using for get the selled products list  *************************************************/

export const getSelledOrderList = (payload)=>{
  return async (dispatch) => {
    try{
      const response = await API.apiGetUrl('marketplace', '/get-selled-orders' , payload) ;
      if(response.data && response.data.success){
        await dispatch({ type: GET_SELLED_ORDER_SUCCESS, payload: response.data});
      }else{
        await dispatch({ type: GET_SELLED_ORDER_FAILED, payload: response.data});
      }
    }catch(error){
      errorRequest(error, dispatch);
    }
  } 
}


/**********************   this function is using for order mangement for both buyer or client side for order ( cancel , complete, accepet , decline , refund payment) *****************/

export const ChangeOrderStatus = (payload)=>{
  return async (dispatch) => {
    try{
      const response = await API.apiPutUrl('marketplace', '/change-order-status' , payload) ;
      if(response.data && response.data.success){
        await dispatch({ type:  CHANGE_ORDER_STATUS_SUCCESS, payload: response.data});
      }else{
        await dispatch({ type:  CHANGE_ORDER_STATUS_ERROR, payload: response.data});
      }
    }catch(error){
      errorRequest(error, dispatch);
    }
  } 
}


export const downloadFile = (payload)=>{
  return async (dispatch) => {
    try{
      const response = await API.apiGetUrl('marketplace', '/download_file' , payload) ;
      if(response.data){
        const blob = await response.data.blob();
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = 'your-file-name.jpg'; // Replace with the desired file name
          document.body.appendChild(a);
          a.click();
          a.remove();
          window.URL.revokeObjectURL(url);
        }
        // await dispatch({ type: GET_SELLED_ORDER_SUCCESS, payload: response.data});
    }catch(error){
      errorRequest(error, dispatch);
    }
  } 
}



