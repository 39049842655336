import { get } from 'lodash';
import API from '../../helpers/api';
import * as auth from '../../helpers/auth';
import {
    ADD_TO_CART_SUCCESS,
    ADD_TO_CART_FAILED,
    GET_CART_ITEMS_SUCCESS,
    GET_CART_ITEMS_FAILED,
    REMOVE_CART_ITEM_SUCCESS,
    REMOVE_CART_ITEM_FAILED,
    CLEAR_CART_SUCCESS,
    CLEAR_CART_FAILED,
    CART_COMMON_ERROR,
  } from "./types";

  const config = {
    headers: {
        "Content-Type": "multipart/form-data; charset=utf-8;"
    }
}

function errorRequest(err, dispatch) {
    let data = get(err, 'response.data', null);
    data = data || get(err, 'response');
    data = data || err;
    if (data.error) {
      dispatch({
        type: CART_COMMON_ERROR,
        payload: data.error.message,
      });
    } else if (data.status === 401) {   
      auth.logout()
    } else {
      dispatch({
        type: CART_COMMON_ERROR,
        payload: data.message,
      });
    }
}

export const addToCart = (payload) => {
    return async (dispatch) => {
        try {
            const response = await API.apiPostUrl('marketplace' , '/add-item', payload);
            if (response.data && response.data.success) {
                await dispatch({ type: ADD_TO_CART_SUCCESS, payload: response.data });
            }else {
                await dispatch({ type: ADD_TO_CART_FAILED, payload: response.data});
            }
        } catch (error) {
            errorRequest(error, dispatch);
        }
    }
}

export const getCartItems = ()=>{
    return async (dispatch) => {
        try{
            const response = await API.apiGetUrl('marketplace', '/cart-items') ;
            if(response.data && response.data.success){
                await dispatch({ type: GET_CART_ITEMS_SUCCESS, payload: response.data});
            }else{
                await dispatch({ type: GET_CART_ITEMS_FAILED, payload: response.data});
            }
        }catch(error){
            errorRequest(error, dispatch);
        }
    }
}

export const removeCartItems = (payload)=>{
    return async (dispatch) => {
        try{
            const response = await API.apiDeleteUrl('marketplace', '/remove-item' , payload) ;
            if(response.data && response.data.success){
                await dispatch({ type: REMOVE_CART_ITEM_SUCCESS, payload: response.data});
            }else{
                await dispatch({ type: REMOVE_CART_ITEM_FAILED, payload: response.data});
            }
        }catch(error){
            errorRequest(error, dispatch);
        }
    }
}

export const clearCartItems = (payload)=>{
    return async (dispatch) => {
        try{
            const response = await API.apiDeleteUrl('marketplace', '/clear-cart' , payload) ;
            if(response.data && response.data.success){
                await dispatch({ type: CLEAR_CART_SUCCESS, payload: response.data});
            }else{
                await dispatch({ type: CLEAR_CART_FAILED, payload: response.data});
            }
        }catch(error){
            errorRequest(error, dispatch);
        }
    }
}


