import React, { useState, useEffect , useRef} from "react";
import * as Auth from '../../helpers/auth';
import { socket , joinRoom } from "../../helpers/auth";
import Peer from 'simple-peer';
import { LuScreenShare } from "react-icons/lu";
import { FaStop, FaVideo } from "react-icons/fa";
import { FcEndCall } from "react-icons/fc";
import { MdCall, MdOutlineChat, MdPhoneCallback } from "react-icons/md";
import { TbWindowMinimize } from "react-icons/tb";
import { BsArrowsAngleExpand } from "react-icons/bs";
import { IoCloseSharp } from "react-icons/io5";
import { useLocation } from 'react-router-dom';


function VideoChatMain() {
    const [isActive, setIsActive] = useState(false);
    const [chatisActive, setIsChatActive] = useState(false);
    const [me , setMe] = useState("");
    const [cameraFacingMode, setCameraFacingMode] = useState("user");
    const [stream , setStream] = useState("");
    const [videoEnabled, setVideoEnabled] = useState(true);
    const [receivingCall , setReceivingCall] = useState(false);
    const [isOngoingCall, setIsOngoingCall] = useState(false)
    const [caller , setCaller] = useState("");
    const [callerSingal , setCallerSingal] = useState();
    const [callAccpeted , setCallAccpeted] = useState(false);
    const [idToCall , setIdToCall] = useState("");
    const [callEnded , setCallEnded] = useState("");
    const [name , setName] = useState('');
    const [isCalling, setIsCalling] = useState(false);
    const myVideo = useRef(null) ;
    const userVideo = useRef(null);
    const connectionRef = useRef(null);
    const cameraStreamRef = useRef(null);
    const [messages, setMessages] = useState([]);
    const [message, setMessage] = useState('');
    const [myName , setMyName] = useState('')
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const roomId = params.get('roomid');


    const handleMinimize = event => {
        setIsActive(current => !current);
    };

    const handleToggle = event => {
        setIsChatActive(current => !current);
    };

    const getLoggedUser = async() =>{
        const userData = Auth.decodedLoggedUser();
        setName(userData.firstName)
        setMyName(userData.firstName)
        
    }


    const userLeave = (data) =>{
        connectionRef.current = null;
        setCallAccpeted(false);
        setCallEnded(true);
        setIsOngoingCall(false);
    }

    /***************************************************    component mounting functions ******************************************************************************/


        useEffect(() => {
            if (stream) {
                myVideo.current.srcObject = stream;
            }
        }, [stream]);

        useEffect(() => {
            if (callEnded) {
                connectionRef.current = null ; 
            }
        }, [callEnded]);



        useEffect(()=>{
            getLoggedUser() ; 
            joinRoom(roomId) ; 
            getCameraAccess('user') ; 
    
            socket.on('me' , (id)=>{
                setMe(id)
            })
            socket.on('callUser' , (data)=>{
                setReceivingCall(true)
                setCaller(data.from)
                setName(data.name)
                setCallerSingal(data.signal)
                setIsOngoingCall(true);
            });
            socket.on("userLeaved" , (data)=>{
                // userLeave (data) ;
                stopVideoCall();
                window.location.reload();
            })

            const handleReceiveMessage = (data) => {
                setMessages((prevMessages) => [...prevMessages, data]);
            };

            socket.on('receiveVideoChatMessage', handleReceiveMessage);

            // Cleanup to prevent multiple listeners
            return () => {
                socket.off('receiveVideoChatMessage', handleReceiveMessage);
            };
        },[])

   /**************************************************  Access media helping functions  ******************************************************************************/

        const getCameraAccess = () =>{
            navigator.mediaDevices.getUserMedia({ video:true, audio: true })
            .then((stream) => {
                setStream(stream);
                cameraStreamRef.current = stream
            })
            .catch(err => {
                console.error("Failed to get local stream", err);
            });
        }

        // const getCameraAccess = (facingMode) => {
        //     navigator.mediaDevices.getUserMedia({
        //         video: { facingMode: { exact: facingMode } }, // Use facingMode here
        //         audio: true
        //     })
        //     .then((stream) => {
        //         setStream(stream); // Set the stream state
        //         cameraStreamRef.current = stream; // Store the stream in the ref
        //     })
        //     .catch(err => {
        //         console.error("Failed to get local stream", err);
        //     });
        // };

        const startScreenShare = () => {
            navigator.mediaDevices.getDisplayMedia({ video: true, audio: true }) // Include audio if you want to capture system sounds
                .then(screenStream => {
                    setStream(screenStream); // Update the stream with the screen capture
                    connectionRef.current.replaceTrack(
                        connectionRef.current.streams[0].getVideoTracks()[0],
                        screenStream.getVideoTracks()[0],
                        connectionRef.current.streams[0]
                    );
        
                    // Listen to end event on the screen sharing stream, to revert to webcam
                    screenStream.getVideoTracks()[0].onended = () => {
                        stopScreenShare();
                    };
                }).catch(err => {
                    console.error("Failed to get screen stream", err);
                });
        };
        
        const stopScreenShare = () => {
            // getCameraAccess(); // Revert to the webcam video
            const cameraStream = cameraStreamRef.current;
            if (cameraStream) {
                setStream(cameraStream);
                if (connectionRef.current) {
                    connectionRef.current.replaceTrack(
                        connectionRef.current.streams[0].getVideoTracks()[0],
                        cameraStream.getVideoTracks()[0],
                        connectionRef.current.streams[0]
                    );
                }
            }   
        };
    

    /******************************************************************************************************************************************************************/


    /*****************************************************************  webrtc connection functions  *******************************************************************/



        const callUser = (id)=>{
            const peer = new Peer({
            initiator: true ,
            trickle: false , 
            stream: stream
            })

            peer.on("signal" , (data)=>{
                socket.emit("callUser",{
                    userToCall: roomId , 
                    signalData: data ,
                    from: me , 
                    name: name , 
                })
            })

            peer.on("stream" , (stream)=>{
                userVideo.current.srcObject = stream
            })

            socket.on("callAccepted" , (signal)=>{
                setCallAccpeted(true)
                peer.signal(signal)
                setIsCalling(false);
            })
            connectionRef.current = peer
            setIsCalling(true)
        }


        const answerCall = () =>{
            setCallAccpeted(true) ;
    
            const peer = new Peer({
            initiator : false ,
            trickle : false , 
            stream: stream
            })
            peer.on("signal" , (data)=>{
                socket.emit("anwerCall",{
                    signal : data , to : caller
                })
            })
    
            peer.on("stream" , (stream)=>{
                userVideo.current.srcObject = stream
            })
    
            peer.signal(callerSingal)
    
            connectionRef.current = peer
    
        }


        const leaveCall = () =>{
            socket.emit("leaveCall",{
                userName : name , to : caller , roomId : roomId
            })
            setCallEnded(true)
            stopVideoCall()
        }

        // const stopVideoCall = () =>{
        //     if (connectionRef.current) {
        //         connectionRef.current = null;
        //     }
    
        //     if (stream) {
        //         stopStream(stream);
        //         setStream(null);
        //     }

        // }

        const stopVideoCall = () => {
            if (connectionRef.current) {
                connectionRef.current.destroy();
                connectionRef.current = null;
            }
    
            if (stream) {
                stopStream(stream);
                setStream(null);
            }
    
            setCallAccpeted(false);
            setIsCalling(false);
            setIsOngoingCall(false);
        };
    
        const stopStream = (stream) => {
            if (stream) {
                stream.getTracks().forEach(track => track.stop());
            }
        };


        const getAvailableVideoInputs = async () => {
            const devices = await navigator.mediaDevices.enumerateDevices();
            return devices.filter(device => device.kind === 'videoinput');
        };


        const toggleCamera = () => {
            const newMode = cameraFacingMode === "user" ? "environment" : "user";
            getCameraAccess(newMode);
            setCameraFacingMode(newMode); // Update the state to reflect the new mode
        };

        const toggleVideo = () => {
            const videoTrack = cameraStreamRef.current.getVideoTracks()[0];
            if (videoTrack) {
                videoTrack.enabled = !videoTrack.enabled;
                setVideoEnabled(videoTrack.enabled);
            }
        };


        const sendMessage = () => {
            if (message.trim()) {
                socket.emit('sendVideoChatMessages', { 
                    roomId: roomId, 
                    user: myName, 
                    message: message 
                });
                setMessage(''); // Clear input after sending
            }
        };

    /*******************************************************************************************************************************************************************/
    
    return (
        <>
            <section className={chatisActive ? 'chat--sharing--screen chat--window--toggle' : 'chat--sharing--screen'}>
                <div className="screen--sharing">
                    <div className="shared--screens">
                        <div className={isActive ? 'mini--window' : 'single--window user--window'}>
                            {stream && <video playsInline muted ref={myVideo} autoPlay/>}
                            {(callAccpeted) &&
                                <button title="Minimize Screen" className={isActive ? 'd-none btn btn-primary' : 'btn btn-primary'} onClick={handleMinimize}><TbWindowMinimize /></button>
                            }
                            <button title="Expand Screen" className={isActive ? 'd-flex btn btn-primary' : 'd-none btn btn-primary'} onClick={handleMinimize}><BsArrowsAngleExpand /></button>
                        </div>
                        {(callAccpeted && !callEnded) &&
                            <div className="single--window">
                                <video playsInline  ref={userVideo} autoPlay/>
                            </div>
                        }
                    </div>

                    <div className="screening--buttons">
                        {isCalling && !callAccpeted && (
                            <div>Waiting  to answer...</div>
                        )}
                        {callAccpeted && !callEnded 
                            ?
                                <>
                                    <button title="Video" className="btn btn-primary" onClick={toggleVideo}><FaVideo /></button>
                                    <button onClick={startScreenShare} title="Share Screen" className="btn btn-primary"><LuScreenShare /></button>
                                    <button onClick={stopScreenShare} title="Stop sharing" className="btn btn-danger"><FaStop /></button>
                                    <button onClick={leaveCall}className="btn btn-light" title="End Call"><FcEndCall /></button>
                                </>
                            
                            :
                            // <button onClick={()=>callUser()} title="Call"> <MdCall /> </button>
                            !isOngoingCall && <button className="btn btn-success" onClick={() => callUser()} title="Call"> <MdCall /> </button>
                        }

                    </div>
                    <div className="screening--buttons">
                        {receivingCall && !callAccpeted && !isCalling ?
                            <>
                                <h1>{name} is calling...</h1>
                                <button onClick={answerCall} className="btn btn-success" title="Answer"> <MdPhoneCallback /> </button>
                            </>
                            :  null
                        }
                    </div>
                </div>

                <div className="video--screen--chat">
                    <div className="chat-container">
                        <div className="chat-window">
                            {messages.map((msg, index) => (
                                <div key={index} className="chat-message">
                                    <strong>{msg.user === myName ? 'You' : msg.user}</strong> {msg.message}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="chat-input">
                        <input
                            type="text"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            placeholder="Type your message"
                        />
                        <button className="btn btn-primary" onClick={sendMessage}>Send</button>
                    </div>
                </div>
                <div className={chatisActive ? 'chat--toggle chat--close--toggle' : 'chat--toggle'} onClick={handleToggle}>
                    <span className="message--dot"></span>
                    <MdOutlineChat className={chatisActive ? 'd-none' : 'd-block'} />
                    <IoCloseSharp className={chatisActive ? 'd-block' : 'd-none'} />
                </div>
            </section>
        </>
    )
}

export default VideoChatMain ; 