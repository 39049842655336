import { 
    LIKE_POST,
    GET_LIVE_VIDEO_POST_SUCCESS,
    GET_LIVE_VIDEO_POST_FAILED,
    CREATE_LIVE_VIDEO_POST,
    CREATE_LIVE_VIDEO_POST_ERROR,
    EDIT_LIVE_VIDEO_POST_SUCEESS,
    EDIT_LIVE_VIDEO_POST_FAILED,
    COMMON_LIVE_POST_ERROR,
    CLEAR_LIVE_CREATE ,
    LIVE_VIDEO_STARTED,
    STOP_LIVE_VIDEO,
    CLEAR_LIVE_POST
} from "../actions/types";

const initialState = {
    getLiveVideoSuccess: null,
    liveVideoPostError: null,
    
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_LIVE_VIDEO_POST_SUCCESS:
            if(state.getLiveVideoSuccess !==null){
                if(action.payload.pageNumber !== '1'){
                    const copyPost = state.getLiveVideoSuccess
                    let  post = state.getLiveVideoSuccess.LivePostList.liveVideoPost
                    let newPost = action.payload.LivePostList.liveVideoPost
                    var mergePost = [...post , ...newPost]
                    mergePost = mergePost.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i)
                    copyPost.LivePostList.liveVideoPost = mergePost
                    return{
                        ...state,
                        getLiveVideoSuccess: copyPost
                    }    
                }else{
                    return{
                        getLiveVideoSuccess: action.payload
                    }
                }    
            }else{
                return{
                    ...state,
                    getLiveVideoSuccess: action.payload
                }    
            }    
        case GET_LIVE_VIDEO_POST_FAILED:
            return{
                liveVideoPostError:action.payload
            }    
        case LIKE_POST:
            if(state.getLiveVideoSuccess){
                const postLikes = state.getLiveVideoSuccess.LivePostList
                const likesIndex = postLikes.liveVideoPost.findIndex(post => post.id === action.payload.post.postId)
                if(likesIndex !== -1){
                    var copyVideoPost = state.getLiveVideoSuccess
                    copyVideoPost.LivePostList.liveVideoPost[likesIndex].Likes = action.payload.post.likes
                    const updateLikes = {...copyVideoPost}
                    return{
                        getLiveVideoSuccess: updateLikes,
                    }     
                }      
            }   
        case CREATE_LIVE_VIDEO_POST:
            return{
                livePostCreated : action.payload
            }         
        case CREATE_LIVE_VIDEO_POST_ERROR:
            return{
                livePostError: action.payload
            }            
        case COMMON_LIVE_POST_ERROR:
            return{
                livePostError: action.payload
            }
        case EDIT_LIVE_VIDEO_POST_SUCEESS:
            return{
                liveVideoEdit: action.payload
            }
        case  EDIT_LIVE_VIDEO_POST_FAILED:
            return{
                editLiveVideoError: action.payload
            }
        case CLEAR_LIVE_CREATE:
            return{
                livePostCreated: null
            }
        case LIVE_VIDEO_STARTED:
            return{
                liveVideoStart: action.payload
            }
    case STOP_LIVE_VIDEO :
        return {
            liveVideoUpdated: action.payload
        }
    case CLEAR_LIVE_POST: 
        return {
            liveVideoUpdated: null ,
            liveVideoStart : null
        }
    default: return state;               
    }
}