import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import Slider from "react-slick";
import { Button, Container, Dropdown, Row, Col, Modal } from 'react-bootstrap';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { toast, Toaster } from "react-hot-toast";
import { FaEllipsisV, FaEye, FaPencilAlt } from 'react-icons/fa';
import { PiSelectionAllBold } from "react-icons/pi";
import { FaShop } from "react-icons/fa6";
import { BsBoxSeam } from "react-icons/bs";
import { CiShop } from "react-icons/ci";
import { BiTrash, BiPlus, BiPhotoAlbum } from 'react-icons/bi';
import Swal from 'sweetalert2';
import PageHeader from '../Header/HeaderTop';
import SidebarMenu from '../Sidebar/SidebarNav';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import './marketplaceStyle.css';
import '../../App.css';
import { Link } from 'react-router-dom';
import { changeShopStatus, deleteShop, getShopList } from '../../redux/actions/shop.action';
import sliderSetting from '../../utils/sliderSetting';
import { valid } from '../../utils/validations';
import { decodedLoggedUser } from '../../helpers/auth';
import { getProductsList, ChangeProductStatus } from '../../redux/actions/product.action';
import EditProductImages from './EditProductImages';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';



function MarketMain() {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [shopLoading, setShopLoading] = useState(false);
    const [productLoading, setProductLoading] = useState(false)
    const [shopList, setShopList] = useState('');
    const [userId, setUserId] = useState('');
    const [productList, setProductList] = useState('');
    const [slug, setSlug] = useState('');
    const [showEditModel, setShowEditModel] = useState(false);
    const [tab, setTab] = useState('shops');

    const shopResult = useSelector(state => state.shop);
    const productResult = useSelector(state => state.product)
    const auth = useSelector(state => state.auth);

    useEffect(() => {
        if (auth.loggedIn) {
            let token = decodedLoggedUser();
            setUserId(token.aud)
        }
        fetchShopList()
        fetchProductList()
    }, [])

    const fetchShopList = () => {
        dispatch(getShopList());
    }

    const fetchProductList = () => {
        dispatch(getProductsList())
    }

    useEffect(() => {
        if (shopResult) {
            if (shopResult.shopList) {
                setShopList(shopResult.shopList.shopsList);
                setShopLoading(true);
            }
            if (shopResult.shopListError) {
                toast.error(shopResult.shopListError, {
                    position: 'top-center',
                });
                setShopLoading(true);
            }

            if (shopResult.shopDeleted && shopResult.shopDeleted.success) {
                toast.success(shopResult.shopDeleted.message, {
                    position: 'top-center',
                });
                fetchShopList();
            }

            if (shopResult.shopDeleteError) {
                toast.error(shopResult.shopDeleteError, {
                    position: 'top-center',
                });
            }
        }

        if (productResult && productResult.productList) {
            setProductList(productResult.productList.products)
            setProductLoading(true)
        }
        if (productResult && productResult.shopProductsError) {
            toast.error(productResult.shopProductsError, {
                position: 'top-center',
            });
            setProductLoading(true)
        }
        if (productResult && productResult.statusChanged) {
            toast.success(productResult.statusChanged.message, {
                position: 'top-center',
            });
            fetchProductList()
        }
        if (productResult && productResult.statusError) {
            toast.error(productResult.statusError, {
                position: 'top-center',
            });
        }


    }, [shopResult, productResult])


    const handleDeleteShop = (shopId) => {
        dispatch(deleteShop({ shopId }));
    }

    const shopSlider = () => {
        if (shopList && shopList.length > 0) {
            return (
                <>
                    <h4><Link>Shops</Link></h4>
                    <Slider className='grid--Slider' {...sliderSetting.settingsAudio}>
                        {
                            shopList.map((item, idx) => {
                                if (item.user) {
                                    return (
                                        <div key={idx}>
                                            <div className="Gr--singleStream">
                                                <div className="Gr--streamImage">
                                                    {(parseInt(userId) == parseInt(item.user.id)) &&
                                                        <>
                                                            <Dropdown className='edit--post--dropdown'>
                                                                <Dropdown.Toggle id="dropdown-basic">
                                                                    <FaEllipsisV />
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    <Link className='dropdown-item' to={`/edit-shop/${item.slug}`}><FaPencilAlt /> Edit</Link>
                                                                    <Link className='dropdown-item' onClick={() => handleDeleteShop(item.id)}><BiTrash /> Delete</Link>
                                                                    <Link className='dropdown-item' to={`/marketplace/product/${item.slug}`}><FaEye /> Show</Link>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </>
                                                    }
                                                    <Link to={`/marketplace/product/${item.slug}`}>
                                                        <LazyLoadImage
                                                            width="100%"
                                                            placeholderSrc="/images/placeholder.gif"
                                                            effect="blur"
                                                            src={item.header || '/images/1566289079327-thumbnail_0524.jpg'}
                                                            alt={item.title}
                                                        />
                                                    </Link>
                                                </div>
                                                <div className="Gr--streamDesc">
                                                    <Link to={`/${item.user.username}`}>
                                                        <img src={valid(item.user.image) ? item.user.image : "/images/user.png"} alt="..." />
                                                    </Link>
                                                    <div className="Gr--streamUser">
                                                        <h4><Link to={`/marketplace/product/${item.slug}`}>{item.title}</Link></h4>
                                                        <Link to={`/${item.user.username}`}>
                                                            <p>{valid(item.user.first) ? item.user.first : ''}</p>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    )
                                }

                            })
                        }
                    </Slider>

                </>
            )

        } else {
            <h5> No Shops Available </h5>
        }
    }


    const renderProducts = () => {
        if (productList && productList.length > 0) {
            return (
                <>
                    <h4 className='mt-5'>Products</h4>
                    <Slider className='grid--Slider' {...sliderSetting.settingsAudio}>
                        {
                            productList.map((item, idx) => {
                                return (
                                    <div key={idx}>
                                        <div className="Gr--singleStream">
                                            <div className="Gr--streamImage">
                                                {(userId == item.user.id) &&
                                                    <>
                                                        <Dropdown className='edit--post--dropdown'>
                                                            <Dropdown.Toggle id="dropdown-basic">
                                                                <FaEllipsisV />
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                <Link className='dropdown-item' to={`/edit-product/${item.slug}`}><FaPencilAlt /> Edit</Link>
                                                                <Dropdown.Item onClick={() => openEditModel(item.slug)}><BiPhotoAlbum /> Edit Images </Dropdown.Item>
                                                                <Dropdown.Item className='dropdown-item' onClick={() => handleStatusChange(item.id, 'delete')}><BiTrash /> Delete</Dropdown.Item>
                                                                <Dropdown.Item className='dropdown-item' onClick={() => handleStatusChange(item.id, 'hide')}><FaEye /> hide</Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </>
                                                }
                                                <Link to={`/marketplace/single-product/${item.slug}`}>
                                                    <LazyLoadImage
                                                        width="100%"
                                                        placeholderSrc="/images/placeholder.gif"
                                                        effect="blur"
                                                        src={item.marketplaceProductMeta.length
                                                            ?
                                                            item.marketplaceProductMeta[0].meta_value
                                                            :
                                                            "/images/user.png"
                                                        }
                                                        alt={item.title}
                                                    />
                                                </Link>
                                            </div>
                                            <div className="Gr--streamDesc">
                                                <Link to={`/${item.user.username}`} state={{ user: item.user.id }}>
                                                    <img src={item.user.image ? item.user.image : "/images/user.png"} alt="..." />
                                                </Link>
                                                <div className="Gr--streamUser">
                                                    <h4><Link to={`/marketplace/single-product/${item.slug}`}>{item.title}</Link></h4>
                                                    <Link to={`/${item.user.username}`} state={{ user: item.user.id }}>
                                                        <p>{item.user.first}</p>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </Slider>
                </>
            )
        }
    }


    const handleEditModel = () => {
        setShowEditModel(false)
    }

    const openEditModel = (slug) => {
        setSlug(slug)
        setShowEditModel(true)

    }


    const handleStatusChange = (id, action) => {
        if (action === 'delete') {
            const data = { productId: id, status: true, hidden: false, show: false }
            Swal.fire({
                title: 'Delete a product',
                icon: 'warning',
                text: 'By doing this, the product will be removed from the orders or user shopping cart of this product.',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
            }).then((result) => {
                if (result.isConfirmed) {
                    handleYesClick(data)
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    handleNoClick();
                }
            });

        }
        if (action === 'hide') {
            const data = { productId: id, status: false, hidden: true, show: false }
            Swal.fire({
                title: 'Hide a product',
                icon: 'warning',
                text: 'By doing this, the product will be hide from the orders or user shopping cart of this product.',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
            }).then((result) => {
                if (result.isConfirmed) {
                    handleYesClick(data)
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    handleNoClick();
                }
            });
        }

    }


    const handleNoClick = () => {
        Swal.close();
    };

    const handleYesClick = (data) => {
        dispatch(ChangeProductStatus(data))

        Swal.close();
    };


    const handleRefesh = (data) => {
        if (data) {
            setShowEditModel(false)
            dispatch(getProductsList())
        }

    }

    const shopData = () => {
        if (shopList && shopList.length > 0) {
            return (
                <>
                    <Slider className='grid--Slider' {...sliderSetting.settingsAudio}>
                        {
                            shopList.map((item, idx) => {
                                if (item.user) {
                                    return (
                                        <div key={idx}>
                                            <div className='Gr--singleStream'>
                                                <div className='Gr--streamImage'>
                                                    {(parseInt(userId) == parseInt(item.user.id)) &&
                                                        <>
                                                            <Dropdown className='edit--post--dropdown'>
                                                                <Dropdown.Toggle id="dropdown-basic">
                                                                    <FaEllipsisV />
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    <Link className='dropdown-item' to={`/edit-shop/${item.slug}`}><FaPencilAlt /> Edit</Link>
                                                                    <Link className='dropdown-item' onClick={() => handleDeleteShop(item.id)}><BiTrash /> Delete</Link>
                                                                    <Link className='dropdown-item' to={`/marketplace/product/${item.slug}`}><FaEye /> Show</Link>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </>
                                                    }
                                                    <Link to={`/marketplace/product/${item.slug}`}>
                                                        <LazyLoadImage
                                                            width="100%"
                                                            placeholderSrc="/images/placeholder.gif"
                                                            effect="blur"
                                                            src={item.header || '/images/1566289079327-thumbnail_0524.jpg'}
                                                            alt={item.title}
                                                        />
                                                    </Link>
                                                </div>
                                                <div className='Gr--streamDesc'>
                                                    <Link to={`/${item.user.username}`} state={{ user: item.user.id }}>
                                                        <img src={valid(item.user.image) ? item.user.image : "/images/user.png"} alt="..." />
                                                    </Link>
                                                    <div className="Gr--streamUser">
                                                        <h4><Link to={`/marketplace/product/${item.slug}`}>{item.title}</Link></h4>
                                                        <p>{valid(item.user.first) ? item.user.first : ''}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            })
                        }
                    </Slider>
                </>
            )

        } else {
            <h5> No Shops Available </h5>
        }
    }


    const productData = () => {
        if (productList && productList.length > 0) {
            return (
                <>
                    <Slider className='grid--Slider' {...sliderSetting.settingsAudio}>
                    {
                        productList.map((item, idx) => {
                            return (
                                <div key={idx}>
                                    <div className='Gr--singleStream'>
                                        <div className='Gr--streamImage'>
                                            {/* <Dropdown className='edit--post--dropdown'>
                                                <Dropdown.Toggle id="dropdown-basic">
                                                    <FaEllipsisV />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Link className='dropdown-item'><FaPencilAlt/> Edit</Link>
                                                    <Dropdown.Item><BiPhotoAlbum/> Edit Images </Dropdown.Item>
                                                    <Link className='dropdown-item'><BiTrash/> Delete</Link>
                                                    <Dropdown.Item className='dropdown-item'><FaEye/> hide</Dropdown.Item>
                                                </Dropdown.Menu>
                                        
                                            </Dropdown> */}
                                            {(Number(userId) === Number(item.user.id)) &&
                                                <>
                                                    <Dropdown className='edit--post--dropdown'>
                                                        <Dropdown.Toggle id="dropdown-basic">
                                                            <FaEllipsisV />
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Link className='dropdown-item' to={`/edit-product/${item.slug}`}><FaPencilAlt /> Edit</Link>
                                                            <Dropdown.Item onClick={() => openEditModel(item.slug)}><BiPhotoAlbum /> Edit Images </Dropdown.Item>
                                                            <Dropdown.Item className='dropdown-item' onClick={() => handleStatusChange(item.id, 'delete')}><BiTrash /> Delete</Dropdown.Item>
                                                            <Dropdown.Item className='dropdown-item' onClick={() => handleStatusChange(item.id, 'hide')}><FaEye /> hide</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </>
                                            }
                                            <Link to={`/marketplace/single-product/${item.slug}`}>
                                                <LazyLoadImage
                                                    width="100%"
                                                    placeholderSrc="/images/placeholder.gif"
                                                    effect="blur"
                                                    src={item.marketplaceProductMeta.length
                                                        ?
                                                        item.marketplaceProductMeta[0].meta_value
                                                        :
                                                        "/images/user.png"
                                                    }
                                                    alt={item.title}
                                                />
                                            </Link>
                                        </div>
                                        <div className='Gr--streamDesc'>
                                            <Link to={`/${item.user.username}`} state={{ user: item.user.id }}>
                                                <img src={item.user.image ? item.user.image : "/images/user.png"} alt="..." />
                                            </Link>
                                            <div className="Gr--streamUser">
                                                <h4><Link to={`/marketplace/single-product/${item.slug}`}>{item.title}</Link></h4>
                                                <Link to={`/${item.user.username}`} state={{ user: item.user.id }}>
                                                    <p>{item.user.first}</p>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                    </Slider>
                </>
            )
        } else {
            <h5> Products Not Available </h5>
        }
    }


    return (
        <>
            <PageHeader />
            <div className='page--content'>
                <SidebarMenu />
                <div className='discover--module common--wrapper marketplace_wrap pt-5 mt-0 pb-5'>
                    <Container>
                        {(shopLoading && productLoading)
                            ? <>
                                {auth.loggedIn &&
                                    <Row>
                                        <Col sm={12} className='text-end mb-0'>
                                            <Button className='createShopBtn' variant='secondary'><Link to='/create-shop'><BiPlus /> Create Shop</Link></Button>
                                        </Col>
                                    </Row>
                                }
                                {/* {shopSlider()}
                                        {renderProducts()} */}
                                <h4 class="mt-2 category--title">Shops</h4>
                                <div className='marketplaceWrap'>
                                    {shopData()}
                                </div>
                                <h4 class="mt-4 category--title">Products</h4>
                                <div className='marketplaceWrap'>
                                    {productData()}
                                </div>
                                {/* <Tabs id='marketplace_id'>
                                            <TabList>
                                                <Tab eventKey="all"><PiSelectionAllBold/>Shops</Tab>
                                                <Tab eventKey="products"><BsBoxSeam/> Products</Tab>
                                            </TabList>
                                            <TabPanel>
                                                <Row className='marketplaceWrap'>
                                                    {shopData()}
                                                </Row>
                                            </TabPanel>
                                            <TabPanel>
                                                <Row className='marketplaceWrap'>
                                                    {productData()}
                                                </Row>
                                            </TabPanel>
                                        </Tabs> */}
                            </>
                            :
                            <Col sm={12}>
                                <div className='text-center spinner--loader'></div>
                            </Col>

                        }

                        <Modal centered className="modal__form" show={showEditModel} onHide={handleEditModel}>
                            <Modal.Header closeButton>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="login--Wrapper">
                                    <EditProductImages slug={slug} setModel={(val) => handleRefesh(val)} />
                                </div>
                            </Modal.Body>
                        </Modal>
                    </Container>
                    <Toaster />
                </div>
            </div>
        </>
    );
}

export default MarketMain;