import React, {useState , useEffect , useRef} from 'react';
import { useSelector, useDispatch } from "react-redux";
import { clearVideoPlayer} from '../../redux/actions/videoPost.action';
import ReactPlayer from 'react-player';


function VideoPlayer() {

    const [video , setVideo] = useState('');
    const [post , setPost] = useState('');
    const [isDragging, setIsDragging] = useState(false);
    const [offset, setOffset] = useState({ x: 0, y: 0 });
    const [isPlaying, setIsPlaying] = useState(true); // Track playing state
    const videoPost= useSelector(state => state.videoPlayer);
    const [isReady, setIsReady] = useState(false);
    const dispatch = useDispatch();
    const videoRef = useRef(null);
  


    useEffect(()=>{
        if(videoPost && videoPost.video !== null){
            setVideo(videoPost)
            setIsReady(true)
        }
    },[videoPost])

    useEffect(()=>{
      if(videoRef.current){
        onReady()
      }
    },[isReady])


    const handleMouseDown = e => {
        setIsDragging(true);
        setOffset({
          x: e.clientX - e.currentTarget.getBoundingClientRect().left,
          y: e.clientY - e.currentTarget.getBoundingClientRect().top
        });
      };
    
      const handleMouseMove = e => {
        if (isDragging) {
          const newX = e.clientX - offset.x;
          const newY = e.clientY - offset.y;
          const maxX = window.innerWidth - e.currentTarget.offsetWidth;
          const maxY = window.innerHeight - e.currentTarget.offsetHeight;
          const boundedX = Math.max(0, Math.min(newX, maxX));
          const boundedY = Math.max(0, Math.min(newY, maxY));
          e.currentTarget.style.left = boundedX + 'px';
          e.currentTarget.style.top = boundedY + 'px';
        }
      };
    
      const handleMouseUp = () => {
        setIsDragging(false);
      };


    const closePlayer = () =>{
        dispatch(clearVideoPlayer())
    }

    const onReady = React.useCallback(() => {
      if(videoPost && videoPost.timeStamp){
        const timeToStart = videoPost.timeStamp.time.toFixed(1);
        videoRef.current.seekTo(timeToStart, 'seconds');
      }
    },[isReady]);

    const renderVideo = () =>{
      if(videoPost && videoPost.video){
        const video = videoPost.video
        return (
            <div id="video-container-galaxy-miniplayer"
              onMouseDown={handleMouseDown}
              onMouseMove={handleMouseMove}
              onMouseUp={handleMouseUp}
              style={{ position: 'fixed', cursor: 'move' }}
            >
              {/* <video ref={videoRef} id="videoGalaxy" preload="auto"  src={videoPost.media}  poster={video.thumbnail || ''}  controls autoPlay></video> */}
              <ReactPlayer
                className="videoGalaxy"
                ref={videoRef}
                url={video.media}
                playing={false}
                controls
              />
              <div className="galaxy-miniplayer-infobar">
                  <div className="galaxy-miniplayer-inner">
                      <h1 className="title-galaxy-miniplayer"> {video.name}</h1>
                      <button class="galaxy-miniplayer-button" onClick={closePlayer}><svg height="100%" viewBox="0 0 24 24" width="100%"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" fill="#fff"></path></svg></button>
                  </div> 
              </div>
            </div> 
        )
      }
    }

  return (
    <>
       {renderVideo()}
    </>
  )
}

export default VideoPlayer