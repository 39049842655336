import { 
    CREATE_TEXT_SUCCESS,
    CREATE_TEXT_FAILED,
    TEXT_COMMON_ERROR,
    GET_TEXT_SUCCESS,
    GET_TEXT_FAILED,
    GET_SINGLE_TEXT_SUCCESS,
    GET_SINGLE_TEXT_FAILED,
    EDIT_TEXT_POST_SUCCESS,
    EDIT_TEXT_POST_FAILED,
    LIKE_POST,
    CLEAR_TEXT_CREATE , 
    CLEAR_POSTS, 
    TEXT_TEMP_DATA
} from "../actions/types";


const initialState = {
    createTextSuccess: null,
    textPostError: null,
    textPostList:null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case CREATE_TEXT_SUCCESS:
            return{
                createTextSuccess:action.payload,
            
            }
        case CREATE_TEXT_FAILED:
            return{
                textPostError:action.payload.message,
            }    
        case TEXT_COMMON_ERROR:
            return{
                textPostError:action.payload,
            }            
        case  GET_TEXT_SUCCESS:
            if(state.textPostList){
                if(action.payload.pageNumber !== '1'){
                    const copyPost = state.textPostList
                    let  post = state.textPostList.textPostList.textPost
                    let newPost = action.payload.textPostList.textPost
                    var mergePost = [...post , ...newPost]
                    mergePost = mergePost.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i)
                    copyPost.textPostList.textPost = mergePost
                    return{
                        ...state,
                        textPostList: copyPost
                    }    
                }else{
                    return {
                        textPostList: action.payload
                    }
                }    
            }else{
                return{
                    ...state,
                    textPostList: action.payload
                }    
            }    
        case  GET_TEXT_FAILED:
            return{
                textPostError:action.payload,
            }            
        case  GET_SINGLE_TEXT_SUCCESS:
            return{
                singleTextPost:action.payload,
            }            
        case  GET_SINGLE_TEXT_FAILED:
            return{
                textPostError:action.payload.message,
            }                
        case  EDIT_TEXT_POST_SUCCESS:
            return{
                editTextPost:action.payload,
            }                
        case  EDIT_TEXT_POST_FAILED:
            return{
                textPostError:action.payload.message,
            }                
        case  LIKE_POST:
            if(state.singleTextPost){
                let copyPost = state.singleTextPost
                const postLikes = action.payload?.post?.likes.filter((like , idx)=>{
                    if(Number(like.post_id) === Number(copyPost.textPost.id)){
                        return like
                    }
                })
                copyPost.textPost.Likes = postLikes
                const updatedPost = { ...copyPost };
                return{
                    singleTextPost: updatedPost
                }       
            }     
            if(state.textPostList){
                const postLikes = state.textPostList.textPostList
                const likesIndex = postLikes.textPost.findIndex(post => post.id === action.payload.post.postId)
                if(likesIndex !== -1){
                    var copyTextPost = state.textPostList
                    copyTextPost.textPostList.textPost[likesIndex].Likes = action.payload.post.likes
                    const updateLikes = {...copyTextPost}
                    return{
                        textPostList: updateLikes,
                    }     
                }      
            }
        case CLEAR_TEXT_CREATE:{
            return {
                createTextSuccess:null
            }
        }
        case CLEAR_POSTS: {
            return{
                createTextSuccess: null,
            }
        }
        case TEXT_TEMP_DATA :{
            return{
                tempTextState: action.payload,
            }

        }
        default: return state;    
    }    

}


