import axios from './instance';
import * as auth from '../auth';
import apiKeys from './apiKeys';

const getUrlByKey = (key) => {
  return apiKeys[key];
};

class API {
  // eslint-disable-next-line lines-around-comment
  /**
   * auth2 login api
   * @param {string} url String
   * @param {object} payload Object
   * @param {object} action Object e.g {type: 'AUTH', dispatch: function(){} }
   * @returns {Promise<void>} void
   */

  static apiGet = async (key, args) => {
    if (typeof args === 'string') {
      return await axios.get(getUrlByKey(key) + args, {
        withCredentials: false,
      });
    }
    return await axios.get(getUrlByKey(key), {
      params: args,
      withCredentials: false,
    });
  };

  static apiGetByKey = async (key, args, query) => {
    if (typeof args === 'string') {
      return axios.get(getUrlByKey(key) + args, {
        params: query,
        withCredentials: false,
      });
    }
    return axios.get(`${getUrlByKey(key)}?${query}`, {
      data: args,
      withCredentials: false,
    });
  };

  static apiGetUrl = async (key, dynamicUrl, args , configs) => {
    // return  axios.get(getUrlByKey(key) + dynamicUrl,{
    //   params: args,
    //   withCredentials: false,
    // })
    if (typeof args === 'string') {
      return await axios.get(getUrlByKey(key) + dynamicUrl +args, {
        withCredentials: false,
      });
    }
    return await axios.get(getUrlByKey(key) + dynamicUrl, {
      params: args,
      withCredentials: false,
    });
  };

  static apiPost = async (key, args, headers) => {
    return await axios.post(getUrlByKey(key), args, headers);
  };

  static apiPostUrl = async (key, dynamicUrl, args) => {
    return axios.post(getUrlByKey(key) + dynamicUrl, args);
  };

  static apiPut = async (key, args) => {
    if (typeof args === 'string') {
      return axios.put(getUrlByKey(key) + args, {
        withCredentials: false,
      });
    }
    return axios.put(getUrlByKey(key), args, {
      withCredentials: false,
    });
  };

  static apiPutUrl = async (key, dynamicUrl, args) => {
    return axios.put(getUrlByKey(key) + dynamicUrl, args);
  };

  static apiPatch = async (key, args) => {
    if (typeof args === 'string') {
      return await axios.patch(getUrlByKey(key) + args, {
        withCredentials: false,
      });
    }
    return await axios.patch(getUrlByKey(key), args, {
      withCredentials: false,
    });
  };

  static apiPatchUrl = async (key, dynamicUrl, args) => {
    return await axios.patch(getUrlByKey(key) + dynamicUrl, args);
  };

  static apiUploadFile = async (key, args, configs) => {
    return await axios.post(getUrlByKey(key), args, configs);
  };

  static apiUploadFileURL = async (key,dynamicUrl, args, configs) => {
    return await axios.post(getUrlByKey(key) + dynamicUrl, args, configs);
  };

  static apiUpdateUploadFile = async (key,dynamicUrl, args, configs) => {
    return await axios.put(getUrlByKey(key) + dynamicUrl, args, configs);
  };

  static apiUpdateWithFileUplaod = async (key, dynamicUrl, args, configs) => {
    return await axios.put(getUrlByKey(key) + dynamicUrl, args, configs);
  };

  static apiDelete = async (key, dynamicUrl , args) => {
    if (typeof args === 'string') {
      return await axios.delete(getUrlByKey(key) + dynamicUrl , args);
    }
    return await axios.delete(getUrlByKey(key) +dynamicUrl , {data:args});
  };

  
  static apiDeleteUrl = async (key, dynamicUrl, args) => {
    return await axios.delete(getUrlByKey(key) + dynamicUrl, {
      data: args});
  };
}

export default API;

axios.interceptors.request.use(
  (configs) => {
    return configs;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401 && window.location.pathname !== '/sign-in') {
    }
    return Promise.reject(error);
  }
);

export const setAuthorization = () => {
  axios.defaults.withCredentials = false;
  axios.defaults.headers.common.authorization = localStorage.getItem('accessToken') ? 'Bearer' + localStorage.getItem('accessToken') : '';
};
setAuthorization();

