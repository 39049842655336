import React, { useEffect , useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col, Table, Button, Form , Alert} from 'react-bootstrap';
import { toast, Toaster } from "react-hot-toast";  
import PageHeader from '../Header/HeaderTop';
import SidebarMenu from '../Sidebar/SidebarNav';
import { BsTrash } from 'react-icons/bs';
import { getCartItems , removeCartItems , clearCartItems} from '../../redux/actions/shoppingCart.action';
import 'bootstrap/dist/css/bootstrap.min.css';
import './shopStyle.css';
import '../../App.css';

function CartMain() {
    const [loading , setLoading] = useState(true);
    const [cartItems , setCartItems] = useState('');

    const [totalAmount , setTotalAmount] = useState(0);

    const [selectedItem , setSlectedItem] = useState([]);

    const [purchaseUnits, setPurchaseUnits] = useState([]);

    const [validPay, setValidPay] = useState(true);

    const [defaultPayment, setDefaultPayment] = useState('0');

    const dispatch = useDispatch();

    const cartResult = useSelector(state =>state.cart);

    const navigate = useNavigate();

    /** ---- Mounting ----- */
    useDispatch(()=>{
        fetchCartItems();
    },[]);

    /* ---- Cart Reducer Updates ----- */
    useEffect(()=>{
        if(cartResult && cartResult.carItemList){
            setCartItems( cartResult.carItemList.itemList )
            setSlectedItem(cartResult.carItemList.itemList)
            handlePaymentPayload(cartResult.carItemList.itemList);
            setLoading(false)
        }
        if(cartResult && cartItems.carItemError){
            toast.error(cartItems.carItemError, {
                position: 'top-center', 
            });
            setLoading(false)
        }
        if(cartResult && cartResult.cartError){
            toast.error(cartItems.carItemError, {
                position: 'top-center', 
            });
            setLoading(false)
        }

        if(cartResult && cartResult.cartItemRemoved){
            toast.success(cartResult.cartItemRemoved.message, {
                position: 'top-center', 
            });
            fetchCartItems()
        }
        if(cartResult && cartResult.cartItemRemovedError){
            toast.error(cartResult.cartItemRemovedError, {
                position: 'top-center', 
            });
        }
        if(cartResult && cartResult.cartCleared){
            toast.success(cartResult.cartCleared.message, {
                position: 'top-center', 
            });
            fetchCartItems()
        }

        if(cartResult && cartResult.clearCartError){
            toast.error(cartResult.clearCartError, {
                position: 'top-center', 
            });
        }
       
    },[cartResult])

    const fetchCartItems = () => {
        dispatch(getCartItems())
    }

    /** ------ Arrange Payment Payloads ------ */
    const handlePaymentPayload = (_selectedItems) => {
        var total=0, purchase_units=[];
        for (let i = 0; i < _selectedItems.length; i++) {
            const item = _selectedItems[i];
            if(item.marketplaceProduct){
                const amount = Number(Number(item.quantity) * Number(item.marketplaceProduct.price))
                
                let unit = {
                    description: item.marketplaceProduct.id,
                    payee: {
                        email_address: item.marketplaceProduct.user.paypal_email
                    },
                    reference_id: i,
                    amount: {
                        currency_code: item.marketplaceProduct.currency,
                        value: amount
                    },
                }
                /** ----- Collecting Purchase Units to Pay ----- */
                purchase_units.push(unit)
                /** ---- Collecting Cart Total Amount ---- */
                total = total + amount 
            }
        }

        /*** ------- Set State ------- */
            setPurchaseUnits(purchase_units);
            setTotalAmount(total);
            setSlectedItem(_selectedItems);
        /*** ------- Set State ------- */

        /** ----- Check Is Payment Options Valid ------ */
        checkValidPay(_selectedItems);
    }

    /** ------ Update Quantity ------ */
    const handleQuantity = (quantity , product) =>{
        const items = cartItems.filter(item => { if (item.id === product.id) item.quantity = Number(quantity); return item });
        const _selectedItem = selectedItem.filter(item => { if (item.id === product.id) item.quantity =  Number(quantity); return item });
       
        /** ----- Update Cart Items ----- */
        setCartItems(items);

        /** ----- Handle Payment Payload ----- */
        handlePaymentPayload(_selectedItem);

    }

    /** ----- Select Item From Cart To Purchase----- */
    const handleSelectCartItems = (e) =>{
        const { value, checked } = e.target;
        var _selectedItem = selectedItem;

        if(checked){
            let newItem = cartItems.filter(item => item.id === Number(value))[0];
            _selectedItem.push(newItem)
            // setSlectedItem([...selectedItem, newItem])
        }else{
            _selectedItem = selectedItem.filter(si => si.id !== Number(value))
            // setSlectedItem(selectitems)
        }
      
        // if (_selectedItem.length) {
        // }
        handlePaymentPayload(_selectedItem);
    }

    /** ----- Calculate Total ----- */
    const handleTotal = () =>{
        let total = 0;
        for (let i = 0; i < selectedItem.length; i++) {
            const item = selectedItem[i];
            if(item.marketplaceProduct){
                total = total + (item.quantity * item.marketplaceProduct.price);
            }
        }
        return total
    }

    /** ----- Remove Item From Cart ------ */
    const removeItem = (id) =>{
        let body={itemId:id};
        dispatch(removeCartItems(body))
    }

    const handleCheckout = () => {
        navigate('/checkout', {
            state:{
                // cartItems: cartItems,
                purchaseUnits: purchaseUnits,
                total: totalAmount,
                selectedItem: selectedItem,
                // validPay: validPay,
                defaultPayment: defaultPayment,
            }
        })
    }


    /** ------ check if payment options are not different / or sellers are not different ----- */
    const checkValidPay = (_selectedItems) => {
        var arr = []
        for (let i = 0; i < _selectedItems.length; i++) {
            const item = _selectedItems[i];
            if(item.marketplaceProduct){
                arr.push(item.marketplaceProduct.payment_option)
            }
        }
        let _valid = arr.every(v => v === arr[0]);
        setValidPay(_valid)
        setDefaultPayment(arr[0]);
    }

    return (
        <>
            <PageHeader />
            <div className='page--content'>
                <SidebarMenu />
                <div className='discover--module common--wrapper order--table'>
                    <Container>
                        <Row className='justify-content-center'>
                            {!loading 
                                ?   <>
                                        {(cartItems && cartItems.length > 0)
                                    
                                            ?   <Col sm={12}>
                                                    <Table striped responsive variant="dark">
                                                        <thead>
                                                            <tr>
                                                                <th className='text-start'>Select for payment</th>
                                                                <th className='text-start'>Products</th>
                                                                <th className='text-start'>Seller</th>
                                                                <th>Quantity</th>
                                                                <th>Payment Method</th>
                                                                <th>Amount</th>
                                                                <th>Remove Item</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {cartItems.map((item , idx)=>{
                                                                    return (item.marketplaceProduct) &&(
                                                                        <tr key={idx}>
                                                                            <td>
                                                                                <Form.Check 
                                                                                    checked={selectedItem.filter(si => si.id === item.id).length} 
                                                                                    type="checkbox" 
                                                                                    onChange={handleSelectCartItems} 
                                                                                    name={idx * Math.random()} 
                                                                                    value={item.id} 
                                                                                />
                                                                            </td>
                                                                            <td className='text-start'>
                                                                                <span className='pro--image'>
                                                                                    <img src={item.marketplaceProduct.marketplaceProductMeta[0].meta_value} alt='..'/>
                                                                                    <strong>{item.marketplaceProduct.title}</strong>    
                                                                                </span>
                                                                            </td>
                                                                            <td>
                                                                                <Link to={`/${item.marketplaceProduct.user.username}`}>{item.marketplaceProduct.user.first} {item.marketplaceProduct.user.last}</Link>
                                                                            </td>
                                                                            <td>
                                                                                <Form>
                                                                                    <Form.Group className='form-group'>
                                                                                        {/* <Form.Label>Quantity</Form.Label> */}
                                                                                        <Form.Control value={item.quantity || '' } onChange= {(e)=>handleQuantity(e.target.value , item)} as="select">
                                                                                            {[...Array(Number(item.marketplaceProduct.stock))].map((x, i) => {
                                                                                                return <option key={i} value={i + 1} >{i + 1}</option>
                                                                                            })}
                                                                                        </Form.Control>
                                                                                    </Form.Group>   
                                                                                </Form>
                                                                            </td>   
                                                                            <td>
                                                                                {item.marketplaceProduct.payment_option === '0' ? 'Any' : null}
                                                                                {item.marketplaceProduct.payment_option === '1' ? 'PayPal' : null}
                                                                                {item.marketplaceProduct.payment_option === '2' ? 'Cash On Delivery' : null}
                                                                            </td>
                                                                            <td>{item.marketplaceProduct.currency} {item.quantity * item.marketplaceProduct.price}</td>
                                                                            <td>
                                                                                <Button variant='primary' onClick={()=>removeItem(item.id)}><BsTrash/></Button>   
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                
                                                            })}
                                                        </tbody>
                                                    </Table>
                                                    
                                                    <Table responsive variant="dark">
                                                        <tbody>
                                                            <tr>
                                                                <td colSpan={2} className='text-end'>
                                                                    Subtotal: <strong>$ {handleTotal()}</strong>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={2} className='text-end'>
                                                                    Total: <strong>$ {handleTotal()}</strong>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className='text-start'>
                                                                    <Button variant='secondary' className='me-2' size='md'onClick={()=>navigate('/marketplace')}>Continue Shopping</Button>
                                                                    <Button variant='secondary' size='md' onClick={()=>dispatch(clearCartItems())}>Clear Cart</Button>
                                                                </td>
                                                                <td className='text-end'>
                                                                    <Button 
                                                                        variant='primary' 
                                                                        size='md' 
                                                                        onClick={() => handleCheckout()}
                                                                        disabled={!validPay}
                                                                    >
                                                                        Checkout
                                                                    </Button>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>

                                                    {!validPay 
                                                    ?   <Alert variant='danger' className="m-3">
                                                            The products added into your cart has been from different seller, and at one time we can accept only one payment method. Please select the checkbox of the product which you want to buy first and proceed the checkout. After done with the payment you can buy your other products, you can also buy multiple products at one time if the seller is same.
                                                        </Alert> 
                                                    : null}
                                                </Col> 
                                            :
                                                <Col sm={12}>
                                                    <div>
                                                        <h3>Your Cart Is Empty</h3>
                                                    </div>
                                                </Col>
                                                    
                                    }
                                
                                    </>
                                
                                :
                                    <Col sm={12}>
                                        <div className='text-center spinner--loader'></div>
                                    </Col>        
                                }    
                        </Row>
                    </Container>
                    <Toaster/>
                </div>
            </div>
        </>
    );
}

export default CartMain;