import React, { useEffect, useState , useRef} from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Row, Col, Form , Modal , Spinner, ListGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { currentUser as user} from '../../../helpers/auth';
import API from '../../../helpers/api';
import LoadingOverlay from 'react-loading-overlay';
import { startLiveVideo , stopLiveVideo} from '../../../helpers/auth';
import { useSelector , useDispatch} from 'react-redux';
import {IoClose} from 'react-icons/io5';
import { toast, Toaster } from "react-hot-toast";  
import { notValid,} from "../../../utils/validations";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ScheduleLiveVideo from './ScheduleLiveVideo';
import { WebRTCAdaptor } from '@antmedia/webrtc_adaptor';
import { IoCameraReverseOutline } from 'react-icons/io5';
import LiveCommentsAnimation from '../../Comments/LiveCommentsAnimation'
import { clearLiveComments } from '../../../redux/actions/liveVideoPost.action';
import { clearLiveData } from '../../../redux/actions/linkPost.action';
import { MdKeyboardArrowDown , MdKeyboardControlKey} from "react-icons/md";
import { decodedLoggedUser } from '../../../helpers/auth';
import { IoMdHeart } from "react-icons/io";
import {FaRegHeart} from 'react-icons/fa';
import { getSinglePost , addVideos} from '../../../redux/actions/post.action';
import axios from 'axios';




function LiveSingleVideo({savedData , saveDataToForm}) {
  const initialState = {name:'',mode: "Offline",addPost: '',description: '',board_post: true,board_id: '',commenting: true, thumbnail: ''};
/** ------------------   Manage Component State ---------------------*/
  const [myStreamData , setMyStreamData] = useState(initialState);
  const [streamName , setStreamName] = useState('');
  const [facingMode , setFacingMode] = useState('user');
  const [loading , setloading] = useState(false);
  const [isStart , setIsStart] = useState(false);
  const [isStop , setIsStop]= useState(false)
  const [boardList , setBoardList] = useState('');
  const [timer , setTimer] = useState(0);
  const [errors , setErrors] = useState({})
  const [thumbnailError , setThumnailError] = useState('');
  const [thumbnail , setThumbnail] = useState(false);
  const [thumbnailLoader , setThumbnailLoader] = useState(false);
  const [slug , setSlug] = useState(false);
  const [scheduleModel , setScheduleModel] = useState(false);
  const [publishing, setPublishing] = useState(false);
  const [videoUrl , setVideoUrl] = useState(false);
  const [intialPost , setIntialPost] = useState(false);
  const [commentBox , setCommentBox] = useState(false);
  const [mimeType , setMimeType] = useState(false);
  const [interval , setIntervalId] = useState('');
  const [showThumbnail , setShowThumbnail] = useState(false);
  const [hideCommments , setHideComments] = useState(false);
  const [userId , setUserId] = useState(false);
  const [singlePost , setSinglePost] = useState('')
  const navigate = useNavigate()
  let _stream = useRef(0);
  let videoRef = useRef(null);
  const webRTCAdaptor = useRef(null);
  const mediaRecorderRef = useRef(null);
  var publishedStreamId = useRef(null);
  const [websocketConnected, setWebsocketConnected] = useState(false);
  const liveStartPost  = useSelector(state => state.liveVideo)
  const apiResult = useSelector(state => state.post);

  const dispatch = useDispatch();
/**------------------------------------------------------------------------------ */
/////// ----------------------------   component life--cycle functions ----------------------------------//////
  useEffect(()=>{
    let token = decodedLoggedUser()
    setUserId(Number(token.aud))
    detectBrowser()
    dispatch(clearLiveComments());
    dispatch(clearLiveData());
    setMyStreamData(initialState)
  },[])

  useEffect(()=>{
    if(apiResult && apiResult?.singlePostSuccess){
        if(apiResult.singlePostSuccess.Post){
            setSinglePost(apiResult?.singlePostSuccess?.Post)
        }
    }
  },[apiResult]);


  useEffect(() => {
    if (savedData.video) {
      setMyStreamData(savedData.video);
    }
  },[savedData]);

  const updateParentState = () => {
    saveDataToForm('video', myStreamData);
  };



/**   -------------     using for update the componet when user GO LIve  ---------------*/

  useEffect(() => {
    const interval = setInterval(() => {
        if (isStart) {
            setTimer(seconds => seconds + 1);
        }
    }, 1000);
    setIntervalId(interval)
      return () => {
        clearInterval(interval);    ////    using for when compoent unmount 
        handleStopLiveVideo()
        dispatch(clearLiveData());
      };
    },[isStart])


  /*******  ---------   using for update component after the streaming start ********************/

    useEffect(()=>{
      if(liveStartPost && liveStartPost.liveVideoStart){
        if(liveStartPost.liveVideoStart && liveStartPost.liveVideoStart.success){
          if(liveStartPost.liveVideoStart && liveStartPost.liveVideoStart.result){
            if(liveStartPost.liveVideoStart.result.user_id === userId){
              setIntialPost(liveStartPost.liveVideoStart.result)
              if(myStreamData.thumbnail=== ''){
                setMyStreamData({...myStreamData , ...liveStartPost.liveVideoStart.result})
                setShowThumbnail(true)
                setTimeout(()=>{
                  setShowThumbnail(false)
                },2000)
              }else{
                const thumbnail = myStreamData.thumbnail ; 
                let copyStreamData = liveStartPost.liveVideoStart.result ; 
                copyStreamData.thumbnail = thumbnail ;
                setMyStreamData((prevData) => ({ ...prevData, ...copyStreamData }));
                setShowThumbnail(true)
                setTimeout(()=>{
                  setShowThumbnail(false)
                },2000)
              }
              setIsStart(true);
              setloading(false);
              dispatch(getSinglePost(liveStartPost.liveVideoStart.result.slug))
            }
          }
        }
      }
      if(liveStartPost && liveStartPost.liveVideoUpdated){
        if(liveStartPost.liveVideoUpdated.success){
            if(liveStartPost.liveVideoUpdated.post && liveStartPost.liveVideoUpdated.post.user_id === userId){
              navigate('/')
            }
        }
      }
    },[liveStartPost]);

  /*---------------------------------------------------------------------------------------------*/
/************************************************************************************************** */

  /****************************     live streaming function sections  ***********************/
    /** this function is use for create a web rtc connection  ****************************/

      const connectWebrtc = () =>{
        if(isValid()){
          if(webRTCAdaptor.current === undefined || webRTCAdaptor.current === null){
            setloading(true);
            webRTCAdaptor.current = new WebRTCAdaptor({
              websocket_url: 'wss://test.antmedia.io:/WebRTCAppEE/websocket',
              mediaConstraints: {
                // video: true,
                video: {
                  width: { ideal: 640 }, // Set your preferred video width
                  height: { ideal: 480 }, // Set your preferred video height
                  frameRate: { ideal: 15 }, // Set your preferred frame rate
                  // width: { ideal: 640, max: 640 }, // Set max width
                  // height: { ideal: 480, max: 480 }, // Set max height
                  // frameRate: { ideal: 15, max: 15 }, // Set 
                },
                audio: {
                  echoCancellation: true,
                  noiseSuppression: true,
                  // sampleRate: 16000, // Lower sample rate for reduced size
                },
              },
              peerconnection_config: {
                iceServers: [{ urls: 'stun:stun1.l.google.com:19302' }],
              },
              sdp_constraints: {
                OfferToReceiveAudio: false,
                OfferToReceiveVideo: false,
              },
              localVideoId: 'localVideo',
              dataChannelEnabled: true,
              isRecording: true, // Enable recording
              isPlayMode: false, // E
              callback: (info, obj) => {
                if (info === 'initialized') {
                  setWebsocketConnected(true);
                  handlePublish()
                }
              },
              callbackError: function (error, message) {
                console.log(" web error mess ", message);
              },
            })
          }
        }
      }
  /**********    ------------------------------------------------------------------------- */

  /*********     this function is using for start publish the live video  *************/

    const handlePublish = () => {
      if(isValid()){
        setPublishing(true);
        const streamId = random();     // function is using for genrate a random stream id 
        setStreamName(streamId);
        // webRTCAdaptor.current.switchDesktopCapture(streamId);
        webRTCAdaptor.current.publish(streamId);
        publishedStreamId.current=streamId;
        handleStartRecording()    // this function is using for record the live video using  RecordRTC
        handleLiveVideo(streamId)
      }
    };

  /**********  ---------------------------------------------------------------- *********/


  /*********************************************************************************************/

  /***************   functions which are used for record and stop recording of the live stream video  section *************/

  /************* this function use for record the video  ********************************/
    const handleStartRecording = () => {
      if (videoRef.current && videoRef.current.srcObject){
        const stream = videoRef.current.srcObject;  
        const videoTracks = stream.getVideoTracks();
      
        const constraints = {
          width: { max: 640 },  // Lower resolution
          height: { max: 480 }
        };
        const options = {
          audioBitsPerSecond: 64000,  // Reduced audio bitrate
          videoBitsPerSecond: 1000000,  // Reduced video bitrate
          mimeType : mimeType
        };
        videoTracks[0].applyConstraints(constraints)
        .then(() => {
          mediaRecorderRef.current = new MediaRecorder(stream, options);
          mediaRecorderRef.current.start();
        }).catch(error => console.error('Error applying constraints:', error));
      }
    }; 
  /**************************************************************************************** */

  /********************   this function is using for stop the live video recording  ************/
    const handleStopRecording = () => {
      const chunks = [];
      return new Promise((resolve) => {
        mediaRecorderRef.current.ondataavailable = (event) => {
          if (event.data.size > 0) {
            chunks.push(event.data)
          }
        };
        mediaRecorderRef.current.onstop = async() => {
          if( chunks && chunks.length > 0){
            const blob = new Blob(chunks, { type:mimeType});
            resolve(blob);
          }
        };
      });
    };
  /************************************************************************************************ */
  /***************  this function is used for genrate a thumbnail from the live video  *************/
    const captureImage = async() => {
      if (videoRef.current && videoRef.current.srcObject){
        const canvas = document.createElement('canvas');
        canvas.width = videoRef.current.videoWidth;
        canvas.height = videoRef.current.videoHeight;
        const context = canvas.getContext('2d');
        context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
        // You can now use the captured image data as needed
        const imageData = canvas.toDataURL('image/png');
        // Add logic here to handle the captured image, if needed
        const base64Data = imageData.replace(/^data:image\/png;base64,/, '');
        // Convert the base64 string to a binary format
        const binaryData = atob(base64Data);
        // Create a Blob from the binary data
        const blob = new Blob([new Uint8Array(binaryData.length).map((_, i) => binaryData.charCodeAt(i))], { type: 'image/png' });
        let imageElement = document.createElement('img');
        imageElement.src = URL.createObjectURL(blob);
        const url = await uploadThumbnailFileToServer(blob) ; 
        if(url){
          return(url)
        }
        
      }
    };
  /***********************************************************************************************************************/

/*******************************   function which are use for genrate the live video link  ******************************/
    /************   this function used for genrate the live video link on front end and save the intial data to db  */
      const handleLiveVideo = async(streamId) =>{
        const data = {
          addPost: streamId,
          user_id: user.aud,
          board_post: myStreamData.board_id ? true : false,
          action: "online",
          description: myStreamData.description,
          commenting: myStreamData.commenting,
          board_id: myStreamData.board_id !== 'public' ? myStreamData.board_id : undefined,
          thumbnail: await captureImage(),
          name: myStreamData.name
        }  
        startLiveVideo(data)
      }
    /********************************************************************************************************************/

  /*********************************   function which are used for stop the live video and update the live video data on server ***********/

    const uploadFileToGCS = async( url , contentType , videoFile) =>{
      try{
        const uploadResponse = await axios.put(url, videoFile, {
          headers: {
            'Content-Type': contentType,
          },
        });
      }catch(error){
        console.log("error" , error)
      }
    }

    const handleStopLiveVideo = async() =>{
      if(isStart && isValid()){
        setIsStop(true)
        setloading(true)
        clearInterval(interval)
        webRTCAdaptor.current.stop(streamName);
        webRTCAdaptor.current.turnOffLocalCamera();
        mediaRecorderRef.current.stop();
        const videoFile = await handleStopRecording()
        if(videoFile){
          // if(mimeType === 'video/mp4'){
          //   var videoUrl = await uploadThumbnailFileToServer(videoFile);
          // }else{
          //   var videoUrl = await handleUploadFileToServer(videoFile);
          // }
          var videoUrl =  await handleUploadFileToServer(videoFile);
        }
        const data = {
          addPost:streamName,
          user_id: user.aud,
          board_post: myStreamData.board_id ? true : false,
          action: "offline",
          description: myStreamData.description,
          commenting: myStreamData.commenting,
          board_id: myStreamData.board_id !== 'public' ? myStreamData.board_id : undefined,
          thumbnail: myStreamData.thumbnail ,
          name: myStreamData.name , 
          media: videoUrl ? videoUrl : '' 
        }  
        stopLiveVideo(data)
      }

    }
  /******************************************************************************************************************************************/

    /****************************   function which are used for form handling  ****************************************/
      const handleOnChange = (e) => {
        const { name, value, checked } = e.target;
        if(name === 'commenting'){
          setMyStreamData({ ...myStreamData, [name]: checked });
        }else{
          setMyStreamData({ ...myStreamData, [name]:value });
          setErrors({ ...errors, [name]: '' })
        }
      }

      const handleThumbnail = async (e) => {
        let thumbnail= '' ;
        if (validationImage(e.target.files[0])) {
          thumbnail = e.target.files[0];
          setThumnailError(false)
    
        } else {
          setThumnailError('Please use only jpg,png,jpeg or gif file')
        }
        if (!thumbnailError) {
          setThumbnailLoader(true);
          const url = await uploadThumbnailFileToServer(thumbnail) ; 
          if(url){
            let copyMyStream = {...myStreamData}
            copyMyStream.thumbnail = url
            setMyStreamData(copyMyStream)
            setShowThumbnail(true);
            setThumbnailLoader(false);
            setTimeout(()=>{
              setShowThumbnail(false)
            },2000)
          }else{
            setThumbnailLoader(false);
          }
        }
      }

      const selectBoard = () => {
        if(boardList && boardList.length > 0){
            return (
                <Form.Group className='form-group col-sm-12'>
                    <Form.Label>Realm</Form.Label>
                    <Form.Control value={myStreamData.board_id || '' } onChange= {handleBoard} as="select">
                        <option selected>Select</option>
                        {boardList.map((item , idx)=>{
                            return (
                                <option key={idx} value={item.id}>{item.name}</option>
                            )
                        })}
                    </Form.Control>
                </Form.Group>
            )
        }
      }
    
      const handleBoard = (e)=>{
        let copyMyStreamData = {...myStreamData};
        copyMyStreamData.board_id = e.target.value
        setMyStreamData(copyMyStreamData)
      }
    /******************************************************************************************************************/

      /***********************   form Validation function  ****************************/
        const isValid = () => {
          let isValid = true;
          let errorMessages = {};
          const check = ['', null, undefined, '0', 0, 'null', 'undefined'];
          if (notValid(myStreamData.name)) {
            errorMessages['name'] = 'Title is required'
            isValid = false;
          }
          if(isStart && myStreamData.thumbnail === ''){
            errorMessages['thumbnail'] = 'Thumbnail is required'
            isValid = false;
            setThumnailError('Thumbnail is required')
          }
          if (!isValid) {
            setErrors({ ...errors, ...errorMessages });
          }
         
          return isValid;
        }

        

        const validationImage = (image) => {
          const extentions = ['image/jpg', 'image/jpeg', 'image/png', 'image/gif']
          if (extentions.includes(image.type)) {
            return true;
          }
          else {
            return false;
          }
        }
      /***********************************************************************************/
    /*************************  Helper functions  *******************************************/
        /***********  used for genrate a random stream Name  *************************/
          const random = () => {
            var randomString = "livestream";
            randomString = "livestream";
            var charSet = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
            for (var i = 0; i < 10; i++) {
              var randomPoz = Math.floor(Math.random() * charSet.length);
              randomString += charSet.substring(randomPoz, randomPoz + 1);
            }
            return randomString;
          };
        /****************************************************************************** */
        /***********  used for show the time in stop wotch  format *****************************/
          const formatTime = ({ timer }) => {
            const hours = Math.floor(timer / 3600);
            const minutes = Math.floor((timer % 3600) / 60);
            const seconds = timer % 60;
          
            const formattedHours = `0${hours}`.slice(-2);
            const formattedMinutes = `0${minutes}`.slice(-2);
            const formattedSeconds = `0${seconds}`.slice(-2);
          
            return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
          };
        /******************************************************************************************/
        /*******************   used for show a loader timer  *************************/
          const renderTime = ({ remainingTime }) => {
            return (
              <div className="timer">
                <div className="text">{isStart ? ' Live Stop In ' : 'Live In' }</div>
                <div className="value">{remainingTime}</div>
                <div className="text">seconds</div>
              </div>
            );
          };
        /*******************************************************************************/
        /***********************  used for switch the camera /Rear/front  **************/
          const toggleCameraFacingMode = () => {
            setFacingMode((prevFacingMode) =>
              prevFacingMode === 'user' ? 'environment' : 'user'
            );
            if(streamName){
              webRTCAdaptor.current.switchVideoCameraFacingMode(streamName, facingMode)
            }
          };
        /********************************************************************************/
        /***********   function used for store and remove the thumbnail or video into google cloud and get the cloud url **/
          const handleUploadFileToServer = async (file) => {
            try {
                const formData = new FormData();
                formData.append('thumbnail', file);
                const response = await API.apiUploadFileURL('post', `/upload-live-video`, formData);
                if(response.data && response.data.success){
                  return response.data.fileUrl
                }
            } catch (error) {
              toast.error('Oops, unable to upload file');
            }
          }

          const uploadThumbnailFileToServer = async (file) => {
            try {
                const formData = new FormData();
                formData.append('thumbnail', file);
                const response = await API.apiUploadFileURL('messenger', `/get-file-url`, formData);
                if(response.data && response.data.success){
                  return response.data.fileUrl
                }
            } catch (error) {
              toast.error('Oops, unable to upload file');
            }
          }

          const handleRemoveThubnail = async() =>{
            await API.apiPostUrl('messenger', '/remove-file', { fileUrl:myStreamData.thumbnail});  // using for remove file from google cloud also
            let copyMedia = {...myStreamData} ;
            copyMedia.thumbnail = '' 
            setMyStreamData(copyMedia);
          }
        /*****************************************************************************************************************/
        /************************  used for open the golive later model ********************/
          const handleScheduleModel = () =>{
            setScheduleModel(false)
          }
        /***********************************************************************************/
          const renderVideoLink = () =>{
            if(myStreamData.slug){
              const link = `${window.location.origin}/Live-Video/${myStreamData.slug}`;
              return(
                <div>
                  <p>{link}</p>  
                  <CopyToClipboard text={handleLink(link)} onCopy={()=>{handleCopy()}}>
                      <Button>Copy Link</Button>
                  </CopyToClipboard>   
                </div>
              )
            }
          }
          const handleLink = (link) =>{
            return link
          }

          const handleCopy = () => {
            toast.success('Link copy to clipboard', {position: 'top-center'});
          };


          const detectBrowser = () =>{
            if (navigator.userAgent.indexOf("Firefox") > -1) {
              setMimeType('video/webm')
            }
            if (navigator.userAgent.indexOf("Safari") > -1) {
              setMimeType('video/mp4')
            }
            if(navigator.userAgent.indexOf("Chrome") > -1){
              setMimeType('video/webm;codecs=vp8')
            }
          }

          const handleThumbnailField = () =>{
            setShowThumbnail(!showThumbnail)
          }
  /*******************   ---------------------------------------------------------------------------------------------------------------------------------------------- */

        const renderThumbnail = () =>{
          if( myStreamData.thumbnail && showThumbnail){
            return (
              <Form.Group className='form-group col-sm-12 col-lg-12'>
                <Form.Label>Thumbnail<span><MdKeyboardControlKey onClick = {handleThumbnailField}/></span></Form.Label>
                <div className="post--thumb--upload">
                  <img src={typeof myStreamData.thumbnail === 'object' ? URL.createObjectURL(myStreamData.thumbnail) : myStreamData.thumbnail}/>
                  <span className="post--media--close" onClick= {handleRemoveThubnail} ><IoClose/></span>
                </div>
              </Form.Group>   
            )
          }else if (myStreamData.thumbnail){
              return(
                <Form.Group className='form-group col-sm-12 col-lg-12'>
                  <Form.Label>Thumbnail<span><MdKeyboardArrowDown onClick = {handleThumbnailField}/></span></Form.Label>
                </Form.Group>
              )
          }else if(thumbnailLoader){
            return (
              <Form.Group className='form-group col-sm-12 col-lg-6'>
                <div className="post--thumb--upload">
                  <Spinner animation="border"/>
                </div>
              </Form.Group>
            )
          }else {
            return(
              <Form.Group >
                 <Form.Label>Thumbnail</Form.Label>
                <input type='file' hidden id="upload--art" onChange={(e) => handleThumbnail(e)}/>
                <label className='label--upload text-center' htmlFor="upload--art">Choose File ( Preferred Size 1920 X 1080 )</label>
                {thumbnailError && <small className="error error-massege text-danger">{thumbnailError}</small>}
              </Form.Group>
            )
          }

        }


        const handleCommentSection = ()=>{
          setHideComments(!hideCommments)
        }

        const renderCommentSection = () =>{
          if(intialPost && myStreamData.commenting ){
              if(hideCommments){
                return (
                  <>
                    <span className='commentsLab'>
                      Comments
                      <MdKeyboardArrowDown onClick = {handleCommentSection}/>
                    </span>
                  </>
                )
              }else{
                return (
                  <>
                    <span className='commentsLab'>
                      Comments
                      <MdKeyboardControlKey onClick = {handleCommentSection}/>
                    </span>
                    <LiveCommentsAnimation postId ={intialPost}/>
                  </>
                )
              }
          }

        }


      /******************************************     compress video file  ***********************************************************/

  return (
    
    <div className='create--form'>
      <LoadingOverlay
        active={loading}
        spinner
        text = { isStop ? ' The saved copy of this live broadcast is processing. Please check back soon' : 'Please wait '}
      >
        <Form onBlur={updateParentState}>
          <Row>
            <Form.Group className='form-group col-sm-12 col-lg-6'>
              <div className='live--frame' >
                <video
                  ref={videoRef} 
                  id="localVideo"
                  autoPlay
                  muted
                  width={'100%'} 
                  height={'100%'} 
                  style={{"transform": "scaleX(-1)"}}
                />
                <span className='online--dot'><span className={ myStreamData.action === 'online' ?'dot--circle circle--green' : 'dot--circle circle--red'}></span>{myStreamData.action === 'online' ? 'Online' : 'Offline'}</span>
                <span className='flip--camera' onClick = {toggleCameraFacingMode}><IoCameraReverseOutline/></span>
              </div>
            </Form.Group>
            <Form.Group className='form-group col-sm-12 col-lg-6'>
              <Form.Label>Video Title</Form.Label>
              <Form.Control type="text" className='mb-2'  placeholder='Enter video title' name="name" value = {myStreamData.name}onChange={(e) => handleOnChange(e)}/>
              {errors.name && <small className="error error-massege text-danger">{errors.name}</small>}
              {selectBoard()}
              {renderThumbnail()}
              <Form.Label>Description</Form.Label>
              <Form.Control as='textarea' rows={5}  className='mb-2' name="description" value={myStreamData['description']} onChange={(e) => handleOnChange(e)} />
              {errors.description && <small className="error error-massege text-danger">{errors.description}</small>}
              <Form.Check type="checkbox" label="Allow Comments" checked={myStreamData.commenting} id = "liveComment" name="commenting" onChange={(e) => handleOnChange(e)}/>
              <Form.Label htmlFor="liveComment" onClick={handleOnChange}/>
              {!isStart 
                ? 
                  <>
                    <Button variant="secondary" className='me-2' onClick= {connectWebrtc}>
                        Go Live Now
                    </Button>

                    <Button variant="primary" onClick = {()=>setScheduleModel(true)}>
                      Go Live Later
                    </Button>
                  </>  
                :
                  <Button variant="secondary" className='me-2 stopBtn' onClick = {handleStopLiveVideo}>
                    Stop
                  </Button>
              }  
            </Form.Group>
            <Col sm={12} className='text-end'> {renderVideoLink()} </Col>
            <Col sm={12} className='comt'>
              <div className='user--frame'>
                <Link to='javascript:;'>
                    <img src={(user && user.image) ? user.image : '/images/default-avtaar.png'} alt='...' />
                    <span>{(user && user.firstName) ? user.firstName : 'N/A'}</span>
                </Link>
                <ListGroup className='mb-2'>
                </ListGroup>
                  <div className='likewrapper'><span className='me-2'>
                    {(singlePost.Likes && singlePost.Likes.length > 0)&& singlePost.Likes.length}</span> 
                    <IoMdHeart/>
                  </div>
              </div>
              <div className='comtTimmer'>
                {isStart &&
                  <p><strong>{formatTime({ timer })}</strong></p>
                }  
              </div>
              {renderCommentSection()}
            </Col>
          </Row>
        </Form>
      </LoadingOverlay>  
      <Modal centered className="modal__form" show={scheduleModel} onHide={handleScheduleModel}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="login--Wrapper">
              <ScheduleLiveVideo  streamData= {myStreamData} CloseModel={()=>handleScheduleModel()}/>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default LiveSingleVideo;