import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {useNavigate ,useLocation} from "react-router-dom";
import { toast} from 'react-toastify';
import { Container, Row, Col, Button, Form , Alert , Modal} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { notValid, emailValidation } from "../../utils/validations";
import { login , requestVerificationLink , clearSignUpState} from "../../redux/actions/auth.action";
import ResetPassword from "./ResetPasswordModel";
import '../../App.css';
import './authStyle.css';
import { Link } from "react-router-dom";
import { ErrorMessage } from "../ErrorMessage";
import * as auth from '../../helpers/auth';
import axios from "axios";


function LoginMain() {

    const initalValue = {userNameOrEmail : '' , password : '' , ip_address :''} ;
    const alertValue = { message: '', show: false, variant: ''};
    const [alert , setAlert] = useState(alertValue);
    const [user , setUser] = useState(initalValue);
    const [resendLink , setResentLink] = useState(false);
    const [ip , setIp] = useState('')
    const [errors , setErrors] = useState('');
    const [loading , setLoading] = useState(false);
    const [showResetPasswordModel , setShowResetPasswordModel] = useState(false);
    const dispatch = useDispatch();
    const res = useSelector(state => state.auth)
    const apiResult = useSelector(state => state.auth);
    const navigate = useNavigate();
    const location = useLocation();

    console.log("apiResult" , apiResult)



    useEffect(()=>{
        const queryParams = new URLSearchParams(location.search);
        const authToken = queryParams.get('authToken');
        if(authToken ){
            auth.login(authToken)
        }
    },[location])



    useEffect(()=>{
        const fetchIp = async () => {
            try {
              const response = await fetch('https://api.ipify.org?format=json');
              const data = await response.json();
              if(data.ip){
                setUser({ ...user, ip_address: data.ip})
              }
            } catch (error) {
              console.error('Error fetching IP address:', error);
            }
          };
      
          fetchIp();
    },[])
  

    const handleOnChange = (e) =>{
        const {name , value } = e.target ; 
        setUser({ ...user, [name]: value })
        setErrors({ ...errors, [name]: '' })
        setAlert(alertValue);
    }

    const validate = () =>{
        let isValid = true
        let passwordErrorMessage = "Please provide password";
        let userNameOrEmailMessage = 'Please enter a valid username or email';
        let errorMessages = {};
        if (notValid(user.userNameOrEmail)) {
            errorMessages['userNameOrEmail'] = userNameOrEmailMessage;
            isValid = false;
        }
        if (notValid(user.password)) {
            errorMessages['password'] = passwordErrorMessage;
            isValid = false;
        }else if (user.password.length < 6 ){
            errorMessages['password'] = ' Password must be 6 characters long. Please enter a password with 6 characters. ';
            isValid = false;
        }
        if (!isValid) {
            setErrors({ ...errors, ...errorMessages });
        }

        return isValid;
    }

    const handleOnSubmit = async(e) =>{
        e.preventDefault();
        if (validate()) {
            setLoading(true)
            dispatch(login(user))
        }
    }

    useEffect(()=>{
        if(apiResult && apiResult.error){
            alert['show'] = true
            alert['message'] = apiResult.error
            alert['variant'] = "danger"
            setLoading(false)
        }else{
            if (apiResult && apiResult.token && !apiResult.error) {
                setLoading(false)   
            }
            if(apiResult && apiResult.verificationLink && !apiResult.error){
                setLoading(false)
                toast.success(apiResult.verificationLink.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                dispatch(clearSignUpState());
            }
        }
        if(apiResult && apiResult.loginError){
            alert['show'] = true
            alert['message'] = apiResult.loginError.message || apiResult.loginError
            alert['variant'] = "danger"
            setLoading(false)
        }
        if(apiResult && apiResult.verificationLinkError){
            alert['show'] = true
            alert['message'] = apiResult.verificationLinkError.message || apiResult.verificationLinkError
            alert['variant'] = "danger"
            setLoading(false)
        }
        if (apiResult && apiResult.accountCreated) {
            dispatch(clearSignUpState());
        }
        if( apiResult && apiResult.resetPasswordSuccess){
            if(apiResult.resetPasswordSuccess.success && apiResult.resetPasswordSuccess.token)
            auth.login(apiResult.resetPasswordSuccess.token);
            dispatch(clearSignUpState());
        }
       
       
    },[apiResult])


    const showAlertMessage = () => {
        return (
            <Alert variant={alert.variant}> {alert['message']} </Alert>
        )
    }

    const handleResetPassModel = () =>{
        setShowResetPasswordModel(false)
        dispatch(clearSignUpState());
    }


    const resendEmailVerifaction = ()=>{
        let isValid = true
        if (notValid(user.email)) {
            toast.error('email is required', {
                position: 'top-center', 
            });
            isValid = false;
        }else if (!emailValidation(user.email)) {
            toast.error('Please provide a valid email address', {
                position: 'top-center', 
            });
            isValid = false;
        }
        return isValid;

    }

    const handleResendVerification = (e) =>{
        e.preventDefault();
        if(resendEmailVerifaction()){
            const data = {email : user.email}
            setLoading(true)
            dispatch(requestVerificationLink(data))
            setResentLink(false)
        }
    }


/**********  using for send verification link again  **********************************/
    const resendVerificationLink = () =>{
        return(
            <Form onSubmit={handleResendVerification}>
                {alert['show'] && showAlertMessage()}
                <h4> Resend Verification Link </h4>
                <Form.Group className='form-group'>
                    <Form.Control type="email" placeholder="Email" name = 'email' value = {user.email} onChange = {handleOnChange} required/>
                    {errors.email && <span className="error error-massege text-danger">{errors.email}</span>}
                </Form.Group>
                <Form.Group className='form-group'>
                    <Button variant="primary" type="submit">{loading ? 'Please Wait ' : ' Submit '}</Button>    
                </Form.Group>
            </Form>    
        )
    }

/**********  using for hanlde its model  **********************************************************/    
    const handleResendLink = () =>{
        setResentLink(false);
    }

    const sendVerificationRequest = async(e) =>{
        e.preventDefault();
       
    }
  
/**************************************************************************************************************** */    





   
    return (
        <>
            <div className='page--content'>
                <div className='discover--module auth--screen'>
                    <Container className='h-100'>
                        <Row className='h-100 align-items-center justify-content-center'>
                            <Col sm={12} className='text-center'>
                                <h1>The Galaxy</h1>
                                <ErrorMessage message={res} />
                                <Form onSubmit={handleOnSubmit}>
                                    <h4>Login</h4>
                                    {alert['show'] && showAlertMessage()}
                                    <Form.Group className='form-group'>
                                        <Form.Control type="text" placeholder="Username or Email" name = 'userNameOrEmail' value = {user.userNameOrEmail} onChange = {handleOnChange} />
                                        {errors.userNameOrEmail && <small className="error error-massege text-danger">{errors.userNameOrEmail}</small>}
                                    </Form.Group>
                                    <Form.Group className='form-group'>
                                        <Form.Control type="password" placeholder="Password" name = 'password' value = {user.password} onChange = {handleOnChange} />
                                        {errors.password && <small className="error error-massege text-danger">{errors.password}</small>}
                                    </Form.Group>
                                    <Form.Group className='form-group text-end'>
                                        <Link className='text-info' to='resetPass:;' onClick= {()=>setShowResetPasswordModel(true)} >Forgot Your Password?</Link>
                                    </Form.Group>
                                    <Form.Group className='form-group'>
                                        <Button variant="primary" type="submit">{loading ? 'Please Wait ' : ' Login '}</Button>    
                                        <Link className='text-info' to='resetPass:;' onClick= {()=>setResentLink(true)} >Resend Verification Link?</Link>
                                    </Form.Group>
                                    <span>OR</span>
                                    <Button variant="outline-primary" onClick={()=>navigate('/register')}>Sign Up</Button>
                                </Form>
                            </Col>
                        </Row>
                    </Container>
                    {resendLink 
                    
                    ?
                        <>
                            <Modal centered className="modal__form" show={resendLink} onHide={handleResendLink}>
                                <Modal.Header closeButton>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="login--Wrapper">
                                       {resendVerificationLink()}
                                    </div>
                                </Modal.Body>
                            </Modal>
                        
                        </>
                    :
                        <>
                            <Modal centered className="modal__form" show={showResetPasswordModel} onHide={handleResetPassModel}>
                                <Modal.Header closeButton>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="login--Wrapper">
                                        <ResetPassword  hideResetModel={setShowResetPasswordModel}/>
                                    </div>
                                </Modal.Body>
                            </Modal>
                        </>
                    }
                </div>
            </div>
        </>
    );
}

export default LoginMain;