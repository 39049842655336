import React, { useEffect, useState } from "react";
import {useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Container, Row, Col, Button, Form , Alert} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { notValid, emailValidation } from "../../utils/validations";
import { createAccount, clearSignUpState} from "../../redux/actions/auth.action";
import '../../App.css';
import './authStyle.css';
import { toast } from "react-toastify";


function SignupMain() {
    const initalValue = {firstName: '' , lastName: '' , userName: '' , email : '' , password : '' } ;
    const alertValue = { message: '', show: false, variant: ''};
    const[alert , setAlert] = useState(alertValue)
    const [user , setUser] = useState(initalValue);
    const [errors , setErrors] = useState('');
    const [loading , setLoading] = useState(false);
    const [apiError , setApiError] = useState('')
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const apiResult = useSelector(state => state.auth);




    const cleanStateData = () =>{
        dispatch(clearSignUpState());
    }
   


    const handleOnChange  = (e) =>{
        const {name , value} = e.target ; 
        setUser({ ...user, [name]: value })
        setErrors({ ...errors, [name]: '' })
    }

    const validate = () =>{
        let isValid = true
        let passwordErrorMessage = "Please provide password";
        let userNameErrorMessage = "Please provide Username";
        let email = "Please enter your email";
        let validEmail = "Please enter a valid email address";
        let errorMessages = {};
        if (notValid(user.firstName)) {
            errorMessages['firstName'] = 'Please provide first name';
            isValid = false;
        }
        if (notValid(user.lastName)) {
            errorMessages['lastName'] = 'Please provide last name ';
            isValid = false;
        }
        if (notValid(user.userName)) {
            errorMessages['userName'] = userNameErrorMessage;
            isValid = false;
        }
        if (notValid(user.password)) {
            errorMessages['password'] = passwordErrorMessage;
            isValid = false;
        }else if (user.password.length < 6 ){
            errorMessages['password'] = ' Password must be 6 characters long. Please enter a password with 6 characters. ';
            isValid = false;
        }
        if (notValid(user.email)) {
            errorMessages['email'] = email;
            isValid = false;
        }else if (!emailValidation(user.email)) {
            errorMessages['email'] = validEmail;
            isValid = false;
        }
        if (!isValid) {
            setErrors({ ...errors, ...errorMessages });
        }

        return isValid;

    }

    const handleOnSubmit = (e) =>{
        e.preventDefault();
        if (validate()) {
            setLoading(true)
            dispatch(createAccount(user))
        }
    }

    const showToaster = (variant, message) => {
        toast.dismiss();
        toast[variant](message, {
            position: "top-center",
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }


    useEffect(()=>{
        if(apiResult && apiResult.error){
            alert['show'] = true
            alert['message'] = apiResult.error.message || apiResult.error
            alert['variant'] = "danger"
            setApiError(apiResult.error)
            setLoading(false)
        }
        if(apiResult && apiResult.createAccountError){
            alert['show'] = true
            alert['message'] = apiResult.createAccountError.message || apiResult.createAccountError
            alert['variant'] = "danger"
            setApiError(apiResult.error)
            setLoading(false)
        }
        if (apiResult && apiResult.accountCreated) {
            showToaster('success' , apiResult.accountCreated.message)
            setLoading(false)
            navigate('/login')
        }
    }, [apiResult])


    useEffect(()=>{
        cleanStateData();
    },[])

    const showAlertMessage = () => {
        return (
            <Alert variant={alert.variant}> {alert['message']} </Alert>
        )
    }


    return (
        <>
            <div className='page--content'>
                <div className='discover--module auth--screen'>
                    <Container className='h-100'>
                        <Row className='h-100 align-items-center justify-content-center'>
                            <Col sm={12} className='text-center'>
                                <h1>The Galaxy</h1>
                                <Form onSubmit={handleOnSubmit}>
                                    <h4>Register</h4>
                                    {alert['show'] && showAlertMessage()}
                                    <Form.Group className='form-group'>
                                        <Form.Control type="text" placeholder="First Name" name = 'firstName' value = {user.firstName} onChange= {handleOnChange}/>
                                        {errors.firstName && <small className="error error-massege text-danger">{errors.firstName}</small>}
                                    </Form.Group>
                                    <Form.Group className='form-group'>
                                        <Form.Control type="text" placeholder="Last Name"  name = 'lastName' value = {user.lastName} onChange= {handleOnChange}/>
                                        {errors.lastName && <small className="error error-massege text-danger">{errors.lastName}</small>}
                                    </Form.Group>
                                    <Form.Group className='form-group'>
                                        <Form.Control type="text" placeholder="Username" name = 'userName' value = {user.userName} onChange= {handleOnChange}/>
                                        {errors.userName && <small className="error error-massege text-danger">{errors.userName}</small>}
                                    </Form.Group>
                                    <Form.Group className='form-group'>
                                        <Form.Control type="text" placeholder="Email"  name = 'email' value = {user.email} onChange= {handleOnChange}/>
                                        {errors.email && <small className="error error-massege text-danger">{errors.email}</small>}
                                    </Form.Group>
                                    <Form.Group className='form-group'>
                                        <Form.Control type="password" placeholder="Password" name = 'password' value = {user.password} onChange= {handleOnChange}/>
                                        {errors.password && <small className="error error-massege text-danger">{errors.password}</small>}
                                    </Form.Group>
                                    <Form.Group className='form-group mt-4'>
                                        <Button variant="primary" type="submit">
                                            {loading ? 'Please Wait' : ' Sign Up '}
                                        </Button>    
                                    </Form.Group>
                                    <span>OR</span>
                                    <Button variant="outline-primary" type='button' onClick={()=>navigate('/login')}>
                                        Login
                                    </Button>
                                </Form>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    );
}

export default SignupMain;