import {useState , useEffect, useId} from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useNavigate , useParams , Link} from 'react-router-dom';
import { Button, Container, Row, Col, Dropdown , Modal} from 'react-bootstrap';
import { toast, Toaster } from "react-hot-toast";
import PageHeader from '../Header/HeaderTop';
import SidebarMenu from '../Sidebar/SidebarNav';
import { BsArrowLeft } from 'react-icons/bs';
import { FaEllipsisV, FaEye, FaPencilAlt } from 'react-icons/fa';
import { BiTrash, BiPlus, BiPhotoAlbum } from 'react-icons/bi';
import Swal from 'sweetalert2';
import { getSingleShop , changeShopStatus } from '../../redux/actions/shop.action';
import { getSingleShopProducts } from '../../redux/actions/product.action';
import { valid } from '../../utils/validations';
import { decodedLoggedUser } from '../../helpers/auth';
import { getProductsList , ChangeProductStatus} from '../../redux/actions/product.action';
import EditProductImages from './EditProductImages';
import 'bootstrap/dist/css/bootstrap.min.css';
import './categoryStyle.css';
import '../../App.css';

function SingleProductCategory() {
    const{slug} = useParams();
    const [loading , setLoading] = useState(true)
    const [productLink , setProductLink] = useState('')
    const [loggedUserId , setLoggedUserId] = useState(null);
    const [shopOwnerUserId, setShopOwnerUserId] = useState(null);
    const navigate = useNavigate();
    const [shopProducts , setShopProducts] = useState('');
    const [showEditModel , setShowEditModel] = useState(false);
    const dispatch = useDispatch();
    const apiResult = useSelector(state => state.product)
    const auth = useSelector(state => state.auth);


    useEffect(()=>{
        dispatch(getSingleShopProducts(slug))
        if (auth.loggedIn) {
            let token = decodedLoggedUser();
            setLoggedUserId(token.aud)
        }
    },[])

    useEffect(()=> {
        if(apiResult && apiResult.shopProductList){
            setShopProducts(apiResult.shopProductList.shopProducts)
            setLoading(false)
        }
        if(apiResult && apiResult.shopProductsError){
            toast.error(apiResult.singlePostError , {
                position: 'top-center', 
            });
            setLoading(false)
        }
        if(apiResult && apiResult.statusChanged){
            toast.success(apiResult.statusChanged.message , {
                position: 'top-center', 
            });
            dispatch(getSingleShopProducts(slug))
        }
        if(apiResult && apiResult.statusError){
            toast.success(apiResult.statusError , {
                position: 'top-center', 
            });
        }
    },[apiResult])

    const renderShop =() =>{
        if(shopProducts && shopProducts.shop){
            const shopDetail =shopProducts.shop
            return (
                <Col sm={12}>
                    <div className='product--banner'>
                        <img src={shopDetail.logo ||'/images/background.png'} alt='...' />
                        <h1>{shopDetail.title}</h1>
                    </div>
                </Col>
            )
        }
    }

    const rednerProducts = () =>{
        if(shopProducts && shopProducts.shop){
            if(shopProducts && shopProducts.products.length > 0 ){
                return (
                    <>
                        {
                            shopProducts.products.map((item ,idx)=>{
                                return (
                                    <Col sm={6} lg={4} xl={3} className='mb-4'>
                                        <div className="Gr--singleStream">
                                            <div className="Gr--streamImage">
                                            {(Number(shopProducts.shop.user_id) === Number(loggedUserId))&&
                                                <Dropdown className='edit--post--dropdown'>
                                                    <Dropdown.Toggle id="dropdown-basic">
                                                        <FaEllipsisV />
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        {item.hidden ?
                                                            <>
                                                                <Dropdown.Item className='dropdown-item' onClick={()=>handleStatusChange(item.id ,'show')}><FaEye/> show </Dropdown.Item>
                                                                <Dropdown.Item className='dropdown-item' onClick={()=>handleStatusChange(item.id ,'delete')}><BiTrash/> Delete</Dropdown.Item>
                                                            </>
                                                        :
                                                            <>
                                                                <Link className='dropdown-item' to={`/edit-product/${item.slug}`}><FaPencilAlt/> Edit</Link>
                                                                <Dropdown.Item onClick={()=>openEditModel(item.slug)}><BiPhotoAlbum/> Edit Images </Dropdown.Item>
                                                                <Dropdown.Item className='dropdown-item' onClick={()=>handleStatusChange(item.id ,'delete')}><BiTrash/> Delete</Dropdown.Item>
                                                                <Dropdown.Item className='dropdown-item' onClick={()=>handleStatusChange(item.id ,'hide')}><FaEye/> hide</Dropdown.Item>
                                                            </>
                                                        }
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            }
                                                <Link to={`/marketplace/single-product/${item.slug}`}>
                                                    <img src={item.marketplaceProductMeta ? item.marketplaceProductMeta[0].meta_value : ''} alt="..." />
                                                </Link>
                                            </div>
                                            <div className="Gr--streamUser">
                                                <h4><Link to={`/marketplace/single-product/${item.slug}`}>{item.title}</Link></h4>
                                                <p className='product--price'>{Number(item.price) ===  0 ? 'Free' : item.price }</p>
                                            </div>
                                        </div>
                                    </Col>
                                )
                            })    
                        }
                    </>    
                )    
            }else{
                return (
                    <h5>No Products Available</h5>
                )
            }
        }else{
            return(
                <h5>No Shop Available</h5>
            )
        }    
    }

    const openEditModel = (slug)=>{
        setProductLink(slug)
        setShowEditModel(true)
    }

    const handleEditModel = () =>{
        setShowEditModel(false)
    }

    const handleNavigate = () =>{
        navigate('/create-product', { state: shopProducts.shop});
    }

    const handleStatusChange = ( id , action) =>{
        if(action === 'delete'){
            const data = {productId:id , status:true , hidden:false , show:false}
            Swal.fire({
                title: 'Delete a product',
                icon: 'warning',
                text: 'By doing this, the product will be removed from the orders or user shopping cart of this product.',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
            }).then((result) => {
                    if (result.isConfirmed) {
                        handleYesClick(data)
                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                    handleNoClick();
                    }
              });
           
        }
        if(action === 'hide'){
            const data = {productId:id , status:false , hidden:true , show:false}
            Swal.fire({
                title: 'Hide a product',
                icon: 'warning',
                text:'By doing this, the product will be hide from the orders or user shopping cart of this product.',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
            }).then((result) => {
                    if (result.isConfirmed) {
                        handleYesClick(data)
                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                        handleNoClick();
                    }
              });
        }
        if(action === 'show'){
            const data = {productId:id , status:false , hidden:false , show:true}
            dispatch(ChangeProductStatus(data))
        }

    }

    const handleNoClick = () => {
        Swal.close();
      };
    
    const handleYesClick = (data) => {
        dispatch(ChangeProductStatus(data))
        Swal.close();
    };

    const handleRefesh = (data) =>{
        if(data){
            setShowEditModel(false)
            dispatch(getSingleShopProducts(slug))
        }

    }


    return (
        <>
            <PageHeader />
            <div className='page--content'>
                <SidebarMenu />
                <div className='discover--module common--wrapper product--category--page'>
                    <Container>
                        {!loading 
                            ?
                                <Row>
                                    <Col sm={12}>
                                        <Link className='back--btn' to='/marketplace'><BsArrowLeft /> Back</Link>
                                    </Col>
                                    {renderShop()}
                                  { (Number(shopProducts.shop.user_id) === Number(loggedUserId)) &&
                                    <Col sm={12} className='mb-4 text-end'>
                                        <Button variant='secondary' onClick={handleNavigate}><i className='fa fa-plus'></i> Add Item</Button>
                                    </Col>}
                                    {rednerProducts()}
                                </Row>
                            :
                                <Col sm={12}>
                                    <div className='text-center spinner--loader'></div>
                                </Col>    
                        }

                            <Modal centered className="modal__form" show={showEditModel} onHide={handleEditModel}>
                                <Modal.Header closeButton>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="login--Wrapper">
                                        <EditProductImages  slug={productLink} setModel={(val)=>handleRefesh(val)}/>
                                    </div>
                                </Modal.Body>
                            </Modal>
                    </Container>
                    <Toaster/>
                </div>
            </div>
        </>
    );
}

export default SingleProductCategory;