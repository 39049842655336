import React, { useState , useEffect} from 'react';
import { connect } from 'react-redux';
import { useSelector, useDispatch} from "react-redux";
import { Row, Col, Dropdown, ListGroup, Button, Form } from 'react-bootstrap';
import { BsHeart, BsReply, BsTrash, BsCardList, BsGridFill , BsPlay } from 'react-icons/bs';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import moment from 'moment';
import { toast, Toaster } from "react-hot-toast";
import InfiniteScroll from 'react-infinite-scroller';
import { checkHTML , convertToPlainText , valid , capitalizeFirstLetter} from '../../utils/validations';
import LinkRender from '../Link/LinkRender';
import { BiPlus } from 'react-icons/bi';
import { FaPaperPlane, FaEllipsisV, FaRegComment } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { getProfilePosts } from '../../redux/actions/user.action';
import { decodedLoggedUser } from '../../helpers/auth';
import { unmuteCreater , muteCreater } from '../../redux/actions/user.action';
import { useNavigate } from 'react-router-dom';


function SearchUserTab(props) {
    const [searchUserList , setSearchUserList] = useState('');
    const [isActive , setIsActive] = useState(true);
    const [loading , setLoading] = useState(false);
    const [userId , setUserId] = useState('')
    const auth = useSelector(state => state.auth);

    const userStatus = useSelector(state =>state.user);

    const navigate = useNavigate();

    const dispatch = useDispatch();

    useEffect(()=>{
        if (auth.loggedIn) {
            let token = decodedLoggedUser();
            setUserId(token.aud)
        }

        if(props && props.users){
            setSearchUserList(props.users)
        }
    },[props]);


    useEffect(()=>{
        if(userStatus && userStatus.userMuted){
            toast.success(userStatus.userMuted.message, {
                position: 'top-center', 
            });
            navigate('/stream')
        }
        if(userStatus && userStatus.userUnmuted){
            toast.success(userStatus.userUnmuted.message, {
                position: 'top-center', 
            });
            navigate('/stream')
        }
    })



    const handleMuteUsers = (user) =>{
        const data = {mutedCreatorId:user.id}  
        const data2 = {creatorId:user.id}
        if(user.muted){
            dispatch(unmuteCreater(data2))
        }else{
            dispatch(muteCreater(data))
        }
    }


   

    const renderUser = ()=>{
        if(searchUserList && searchUserList.length > 0 ) {
            return (
                <Col sm={'12'} className='row'>
                {
                    searchUserList.map((item , ind)=>{
                        return(
                            <Col sm={'6'} lg={'4'} className='mb-4'>
                                <div className="Gr--singleStream">
                                    {userId &&
                                        <Dropdown className='edit--post--dropdown'>
                                            <Dropdown.Toggle id="dropdown-basic">
                                                <FaEllipsisV />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Link className='dropdown-item' onClick={()=>handleMuteUsers(item)}>{item.muted ? 'Unmute' : 'Mute'}</Link>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    }
                                    <Link to={`/${item.username}`}>
                                        <div className="Gr--streamImage">
                                            <LazyLoadImage
                                                width="100%"
                                                placeholderSrc="/images/placeholder.gif"
                                                effect="blur"
                                                src={item.image || '/images/user.png' }
                                                alt={item.first}
                                            />
                                        </div>
                                        <div className="Gr--streamDesc">
                                            <p>{capitalizeFirstLetter(item.first)} {capitalizeFirstLetter(item.last)}</p>
                                        </div>
                                    </Link>   
                                </div>                              
                            </Col>  
                            )
                        })
                    }
                </Col>
            )
        }
    }
    return(
        <>
            <Row className='grid-view'>
                {
                    (searchUserList && searchUserList.length > 0)
                            
                    ?
                        <Col sm={12}>
                            <Row className={'justify-content-start'}>
                                {renderUser()}
                            </Row>
                        </Col>
                    :   <h2 className="mt-5 text-center">Users Not Found</h2> 
                }
                
            </Row>
        
        </>

    )
}
export default SearchUserTab ;