import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, useParams , Link} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Container, Row, Col, Form, Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { IoClose } from 'react-icons/io5';
import { toast, Toaster } from "react-hot-toast";
import { editProductImages, getSingleProduct , getProductsList, getSingleShopProducts} from '../../redux/actions/product.action';
import { notValid, valid } from '../../utils/validations';
import '../../App.css';


function EditProductImages(prop) {
    const { slug } = useParams();
    const intialValue = { images: [], oldImages: [] };
    const [productFields, setProductFileds] = useState(intialValue);
    const [loading, setLoading] = useState(false);
    const [loader, seLoader] = useState(false)
    const [errors, setErrors] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const apiResult = useSelector(state => state.product);

    useEffect(() => {
        dispatch(getSingleProduct(prop.slug));
        seLoader(true)
    }, [])

    useEffect(() => {
        if (apiResult && apiResult.singleProduct) {
            const product = apiResult.singleProduct.product
            let copyProductsFields = { ...productFields };
            copyProductsFields.productId = product.id
            if (product.marketplaceProductMeta && product.marketplaceProductMeta.length > 0) {
                const imageUrls = product.marketplaceProductMeta.map((item, idx) => {
                    return item.meta_value
                })
                copyProductsFields.images = imageUrls
            }
            setProductFileds(copyProductsFields)
            seLoader(true)
        }
        if (apiResult && apiResult.productImagesUpdated) {
            toast.success(apiResult.productImagesUpdated.message, {
                position: 'top-center',
            });
            setLoading(false)
            prop.setModel(apiResult.productImagesUpdated.message)
        }
        if (apiResult && apiResult.productImagesError) {
            toast.error(apiResult.productImagesError, {
                position: 'top-center',
            });
            setLoading(false)
        }
    }, [apiResult])



    /*****************************  images handling  *************************************************/
    const handleImages = (e) => {
        const files = e.target.files;
        const check = ['image/jpeg', 'image/png', 'image/jpg', 'image/gif'];
        if (files && productFields['images'].length < 10) {
            let copyProductsFields = { ...productFields }
            Array.from(files).forEach((file) => {
                if (check.includes(file.type)) {
                    copyProductsFields['images'].push(file)
                }
            }); 
            setProductFileds(copyProductsFields)
            const copyErrors = {...errors} 
            if(copyErrors.images){
                copyErrors.images = ''
                setErrors(copyErrors);

            }
        }
    }


    const handleRemoveFiles = (index) => {
        let copyProductsFields = { ...productFields }
        copyProductsFields['images'].splice(index, 1);
        setProductFileds(copyProductsFields)
    }


    const renderImages = () => {
        if (productFields && productFields.images.length > 0) {
            return (
                <Form.Group className='form-group col-sm-12'>
                    <div className='post--thumb--upload post--album--grid'>
                        {productFields.images.map((files, filesIndex) => {
                            return (
                                <div className="album--upload">
                                    <img key={filesIndex} src={typeof files === 'object' ? URL.createObjectURL(files) : files} />
                                    <span className="cerificate--files--close" onClick={() => handleRemoveFiles(filesIndex)}><IoClose /></span>
                                </div>
                            )
                        })}
                    </div>
                </Form.Group>
            )
        }
    }

    /********************************   form validation  **********************************************/

    const isValid = () => {
        let isValid = true;
        let errorMessages = {};
        const check = ['null', null, undefined, 'undefined', '', []];
        if(productFields.images.length < 1){
            errorMessages['images']  = 'please upload product images'
            isValid = false;
        }
        if (!isValid) {
            setErrors({ ...errors, ...errorMessages });
        }
        return isValid;

    }

    const handleCreateProduct = (e) => {
        e.preventDefault();
        if (isValid()) {
            setLoading(true)
            let copyProductsFields = {...productFields}
            const newImages = copyProductsFields.images.filter(item => typeof item === 'object'); // using for filter the new images 
            const oldImages =  copyProductsFields.images.filter(item => typeof item !== 'object'); // using for filter the old images 
            if(newImages && newImages.length){
                copyProductsFields.images = newImages
            }
            if(oldImages && oldImages.length > 0){
                copyProductsFields.oldImages = oldImages
            }
            const formData = new FormData();
            for (const [key, value] of Object.entries(copyProductsFields)) {
                if (typeof value === 'object' && key === 'images') {
                  value.forEach(element => {
                    formData.append('images', element);
                  });
                }
                else if(typeof value === 'object' && key === 'oldImages'){
                    value.forEach(element => {
                        formData.append('oldImages', element);
                    });
                }else{
                    formData.append(key, value)
                }    
            }    
            dispatch((editProductImages(formData)));
        }
    }

    return (
        <>
            <div className='create--form'>
                <Form className='change--image--modal' onSubmit={handleCreateProduct}>
                    {loader ?
                        <Row>
                            {renderImages()}
                            <Form.Group className='form-group col-sm-12'>
                                <Form.Label>Add Photos</Form.Label>
                                <input type='file' hidden id="upload--header-products" multiple onChange={handleImages} />
                                <label className='label--upload' htmlFor="upload--header-products">Upload photos ( You can add up to 10 photos )</label>
                                {errors.images && <small className="error error-massege text-danger">{errors.images}</small>}
                            </Form.Group>
                            <Col sm={12} className='text-end'>
                                <Button disabled={loading} variant="primary" type="submit">
                                    {loading ? <> <Spinner animation="border" size="sm" /> Please wait </> : 'Update'}
                                </Button>
                            </Col>
                        </Row>
                    :
                        <div className='text-center spinner--loader'></div>   
                    }     
                </Form>
            </div>
        </>
    );
}

export default EditProductImages;