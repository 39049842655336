import { 
  CREATE_ACCOUNT_SUCCESS,
  CREATE_ACCOUNT_FAILED,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  REQUESET_RESET_PASS_SUCCESS,
  REQUESET_RESET_PASS_FAILED,
  RESET_PASSWORD_SUCCESS , 
  RESET_PASSWORD_FAILED,
  AUTH_COMMON_ERROR,
  RESEND_VERIFICATION_LINK_SUCCESS,
  RESEND_VERIFICATION_LINK_FAILED,
  OTP_REQUEST_SUCCESS,
  OTP_REQUEST_FAILED,
  OTP_VERIFY_SUCCESS,
  OTP_VERIFY_FAILED,
  CLEAR_SIGN_UP , 
  
} from "../actions/types";
import * as auth from '../../helpers/auth';
const token = auth.getToken();


const initialState = {
    token: null,
    loggedIn: token ? true : false,
    accountCreated : null , 
    createAccountError : null , 
    requestOtp : null , 
    resetRequestSuccess : null

};

export default (state = initialState, action) => {
    switch (action.type) {
      case CREATE_ACCOUNT_SUCCESS:
          return{
            accountCreated:action.payload,
            loggedIn: false
          }    
      case CREATE_ACCOUNT_FAILED:
          return{
            createAccountError:action.payload,
            loggedIn: false
          } 
      case LOGIN_SUCCESS: 
        const accessToken = localStorage.getItem('accessToken');
        if(accessToken && accessToken !==null){
          return {
            token: action.payload,
            loggedIn: true ,
          }
        }
      case LOGIN_FAILED: 
        return { 
          loggedIn: token ? true : false ,
          loginError: action.payload
        }; 
      case REQUESET_RESET_PASS_SUCCESS:
        return { 
          resetRequestSuccess: action.payload, 
          loggedIn: token ? true : false 
        }; 
      case  REQUESET_RESET_PASS_FAILED:
        return { 
          resetRequestError: action.payload,
          loggedIn: token ? true : false 
        };   
      case  RESET_PASSWORD_SUCCESS:
        return { 
          resetPasswordSuccess: action.payload, 
          loggedIn: token ? true : false 
        }; 
      case  RESET_PASSWORD_FAILED:
        return { 
          resetPasswordError: action.payload,
          loggedIn: token ? true : false 
        };   
      case  RESEND_VERIFICATION_LINK_SUCCESS:
        return { 
          verificationLink : action.payload, 
        };   
      case  RESEND_VERIFICATION_LINK_FAILED:
        return { 
          verificationLinkError:action.payload
        };    
      case OTP_REQUEST_SUCCESS: 
        return {
          requestOtp: action.payload,
          loggedIn: false 
        } 
      case OTP_REQUEST_FAILED:
        return {
          otpError: action.payload,
          loggedIn: false 
        }
      case OTP_VERIFY_SUCCESS:
        return {
          verifyOtp: action.payload,
          loggedIn: false 
        }
      case OTP_VERIFY_FAILED:
        return {
          otpVerifyError:action.payload,
          loggedIn: false 
        }
      case AUTH_COMMON_ERROR: 
        return {
          error: action.payload, 
          loggedIn: token ? true : false 
        }
      case CLEAR_SIGN_UP:
        return {
          createAccountError: null , 
          accountCreated : null , 
          requestOtp : null , 
          resetRequestSuccess : null , 
          verifyOtp : null , 
          verificationLink : null , 
          resetRequestError : null , 
          resetPasswordError : null , 
          otpError : null , 
          verificationLinkError : null , 
          otpVerifyError :null

        }
      default: return state;
    }
};
  