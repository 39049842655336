import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {useNavigate } from "react-router-dom";
import { toast} from "react-hot-toast";   
import { EditorState, convertToRaw, getDefaultKeyBinding, ContentState} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Button, Row, Col, Form , Spinner} from 'react-bootstrap';
import { FaCogs} from 'react-icons/fa';
import {IoClose} from 'react-icons/io5';
import { BsFileEarmarkMusic, BsPlusLg } from 'react-icons/bs';
import { notValid,checkHTML , convertToPlainText , valid } from "../../../utils/validations";
import { createAudioPost , clearAudioCreate} from "../../../redux/actions/audioPost.action";

function CreateAlbumAudio({savedData , saveDataToForm}) {
    const intialValue = {name:[] , description: '', album: '' , isStream: true,  commenting: true , board_name: '', board_id: '', images:[] , thumbnail:''};
    const [audioFields , setAudioFields] = useState(intialValue);
    const [boardList , setBoardList] = useState('');
    const [loading , setLoading] = useState(false) ; 
    const [draftLoading , setDraftLoading] = useState(false);
    const [ isCropperOpen, setCropperOpen ] = useState(false);
    const [ imageForCrop, setImageForCrop ] = useState(null);
    const [draft , setDraft] = useState(false);
    const [audio , setAudio] = useState(false);
    const [showEditor , setShowEditor] = useState(true);
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
    const [errors , setErrors] = useState(false);
    const [errAudio , setErrAudio] = useState([]);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const apiResult = useSelector(state => state.audioPost); 
    const getPostResult = useSelector(state => state.post);


    useEffect(()=>{
        dispatch(clearAudioCreate())
    },[])

    useEffect(() => {
        if (savedData.album) {
            setAudioFields(savedData.album);
            if(savedData.album.description){
                const blocksFromHtml = htmlToDraft(savedData.album.description);
                const { contentBlocks, entityMap } = blocksFromHtml;
                const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
                const editorState = EditorState.createWithContent(contentState);
                setEditorState(editorState)
            }
        }
    },[savedData]);


    useEffect(()=>{
        if(getPostResult && getPostResult.boardPostList){
            setBoardList(getPostResult.boardPostList.boardPosts)
        }
    },[getPostResult])



    const handleInput = (e) =>{
        const {value , name } = e.target ; 
        setAudioFields({...audioFields, [name]: value })
        setErrors({ ...errors, [name]: '' })
    }

    const handleCommenting = (e) =>{
        const copyAudioFileds = {...audioFields} ;
        copyAudioFileds.commenting = e.target.checked
        setAudioFields(copyAudioFileds)
    }

/********************************************* using for crop image  *********************************************/

    const handleCloseCropper = () => {
        setImageForCrop(null)
        setCropperOpen(false)
    }

    const handeSaveCroppedImage = (dataURI) => {
        const imageFile = dataURItoBlob(dataURI);
        const copyAudioFields = {...audioFields}
        copyAudioFields['thumbnail'] = imageFile
        setAudioFields(copyAudioFields)
    }

    const dataURItoBlob = (dataURI) => {
        // convert base64/URLEncoded data component to raw binary data held in a string
        var byteString;
        if (dataURI.split(',')[0].indexOf('base64') >= 0)
            byteString = atob(dataURI.split(',')[1]);
        else
            byteString = unescape(dataURI.split(',')[1]);

        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        // write the bytes of the string to a typed array
        var ia = new Uint8Array(byteString.length);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        
        return new Blob([ia], {type:mimeString});
    }


    const handleOpenCropper = () => {
        setCropperOpen(true)
        let data = {
            src: URL.createObjectURL(audioFields.images)
        }
        setImageForCrop(data)
    }
/************************************************************************************************************** */


/**********************    handle description  /////   text editor  function using draft.js for text editing        ******/

    const onEditorStateChange = (editorState) => {
        const copyAudioFields = {...audioFields} ;
        let errorMessages = {...errors};
        const rawContentState = convertToRaw(editorState.getCurrentContent());
        const description = draftToHtml(rawContentState);
        copyAudioFields.description = description
        errorMessages.description = '' ;
        setAudioFields(copyAudioFields)
        setErrors(errorMessages)
        setEditorState(editorState);
    };

    const myKeyBindingFn = event => {
        return getDefaultKeyBinding(event);
    }
/********************************************************************************************************************* */


/**---------------------  form validation  -----------------------*/
    const validate = () =>{
        let isValid = true ;
        let errorMessages = {};
        let desc = checkHTML(audioFields.description) ? convertToPlainText(audioFields.description) : audioFields.description
        if (notValid(desc)) {
            errorMessages['description'] = 'Description is required'
            isValid = false;
        }
        if (notValid(audioFields.album)) {
            errorMessages['album'] = 'Title is required'
            isValid = false;
        }

        if(audioFields.images.length <= 1){

            errorMessages['images'] = 'Please Upload more than 1 audio for album'
            isValid = false;
      
        }
        errAudio.forEach((item, index) => {
            if (item.name) {
                isValid = false;
            }
        });

        if (!isValid) {
            setErrors({ ...errors, ...errorMessages });
        }
        return isValid;
    }


/**************************   audio album files handles using images name for audio files  **************/
    const handleAudioFiles = (e) =>{
        const copyError = {...errors};
        const files = e.target.files ;
        if(files && audioFields['images'].length < 12){
            let copyAudioFields =  {...audioFields}
            Array.from(files).forEach((file) => {
                if (file.type.includes('audio/')) {
                    copyAudioFields['images'].push(file)
                    copyAudioFields['name'].push(file.name.split('.')[0])
                    copyError.images = '' 
                    setErrors(copyError);
                }
            });
            setAudioFields(copyAudioFields)
        }

    }

    const handleTrackName = (e , i) =>{
        const copyAudioFields = {...audioFields};
        let errorMessages = {};
        let copyErrAudio = errAudio ;
        copyAudioFields.name[i] =e.target.value ; 
        setAudioFields(copyAudioFields);
        if(!valid(copyAudioFields.name[i])){
            errorMessages.track = i ;
            errorMessages.name = ' title name is required '
            copyErrAudio[i] = errorMessages;
        }else{
            copyErrAudio[i] = '';
        }
        setErrAudio(copyErrAudio);
    }

    const handleRemove = (i)=>{
        const copyAudioFields = {...audioFields};
        copyAudioFields['images'].splice(i, 1);
        copyAudioFields['name'].splice(i, 1);
        setAudioFields(copyAudioFields)
    }

    const previewAudioFiles = () =>{
        if(audioFields.images && audioFields.images.length > 0){
            return (
                <>
                    {/* <input type='file' hidden id="upload--audio-album" accept="audio/*"  multiple onChange={handleAudioFiles}/> */}
                    {
                        audioFields.images.map((file , ind)=>{
                            return (
                                <Form.Group className='audioPlayerWrapper form-group col-sm-12 col-lg-12'>
                                    <div className="audio--upload--grid">
                                        <span className="numberWrap"> {ind+1}</span>
                                        <div className="contentWrap">
                                            <Form.Control type="text" placeholder='Track Title' value={audioFields.name[ind]} required  onChange={(e)=>handleTrackName(e,ind)}/>
                                            <audio controls>
                                                <source src={typeof file === 'object' ? URL.createObjectURL(file) : file} type={file.type} />
                                            </audio>
                                            <span className="post--media--close" onClick = {()=>handleRemove(ind)}><IoClose/></span>
                                        </div>
                                    </div>
                                    {errAudio[ind] && <small className="error error-massege text-danger">{errAudio[ind].name}</small>}
                                </Form.Group>
                            )
                        })
                    }    
                </>
            )
        }
    }


    const handleThumnail = (e)=>{
        const allImages = ['png', 'jpg', 'jpeg', 'JPG', 'JPEG', 'PNG'];
        let errorMessages = {...errors};
        const { files } = e.target ; 
        const copyAudioFile = {...audioFields};
        if(files && files.length < 2 ){
            const file = files[0];
            const fileName = file.name.toLowerCase();
            const fileExtension = fileName.split('.').pop();
            if(allImages.includes(fileExtension)){
                copyAudioFile.thumbnail = file
                setAudioFields(copyAudioFile)
                errorMessages['thumbnail'] = ''
                setErrors(errorMessages)
            }else{
                errorMessages['thumbnail'] = ' Please use only  png, jpg, jpeg, JPG, JPEG, PNG'
                setErrors(errorMessages)
            }    
        }

    }

    const handleRemoveThubnail = () =>{
        const copyAudioFields = {...audioFields};
        copyAudioFields.thumbnail = '' ;
        setAudioFields(copyAudioFields)
    }

////////////////     ************************************************************************************ ///////////////
    const handleCreateAudioPost = (draft) =>{
        let copyAudioFields = {...audioFields}
        copyAudioFields.name = audioFields.name.join(',')
        if(validate()){
            if(draft){
                copyAudioFields.status = '2'
                setLoading(false)
                setDraftLoading(true)
                setDraft(true)
                setAudio(false)
            }else{
                setLoading(true)
                setDraftLoading(false)
                setAudio(true)
                setDraft(false)
            }
            const formData = new FormData();
            for (const [key, value] of Object.entries(copyAudioFields)) {
                if (typeof value === 'object' && key === 'images') {
                  value.forEach(element => {
                    formData.append('images', element);
                  });
                } else {
                  formData.append(key, value)
                }   
            }
            dispatch(createAudioPost(formData)) ;
        }
    }    


    useEffect(()=>{
        if(apiResult && apiResult.createSingleAudio){
            setLoading(false)
            setDraftLoading(false)
            if(audio){
                navigate('/stream')
            }
            if(draft){
                navigate('/drafts')
            }
        }
        if(apiResult && apiResult.audioPostError){
            setLoading(false)
            setDraftLoading(false)
            toast.error(apiResult.audioPostError.message, {
                position: 'top-center', 
            });
        }
        setLoading(false)
        setDraftLoading(false)

    },[apiResult])

    const selectBoard = () => {
        if(boardList && boardList.length > 0){
            return (
                <Form.Group className='form-group col-sm-12'>
                    <Form.Label>Realm</Form.Label>
                    <Form.Control value={audioFields.board_id || '' } onChange= {handleBoard} as="select">
                        <option selected>Select</option>
                        {boardList.map((item , idx)=>{
                            return (
                                <option key={idx} value={item.id}>{item.name}</option>
                            )
                        })}
                    </Form.Control>
                </Form.Group>
            )
        }
    }

    const handleBoard = (e)=>{
        let copyAudioFields = {...audioFields};
        copyAudioFields.board_id = e.target.value
        setAudioFields(copyAudioFields)
    }


    const updateParentState = () => {
        saveDataToForm('album', audioFields);
    };

    return (
        <div className='create--form'>
            <Row className="perviewAudio">
            <Col sm-12 md-12> 
                    <Form onBlur={updateParentState}>
                        <Row> 
                            <Col sm-12 md-12> 
                                <Form.Group className='form-group col-sm-12'>
                                    <Form.Label>Album Title</Form.Label>
                                    <Form.Control type="text" name = 'album' value={audioFields.album} onChange = {handleInput} required />
                                    {errors.album && <span className="error error-massege text-danger">{errors.album}</span>}
                                </Form.Group>
                            </Col>
                            </Row>
                            <Row>
                            <Col sm-12 md-6> 
                            <Form.Group className='form-group col-sm-12 col-lg-12'>
                                <input type='file' hidden id="upload--audio-album" accept="audio/*"  multiple onChange={handleAudioFiles}/>
                                <label className='label--upload text-center' htmlFor="upload--audio-album" ><BsFileEarmarkMusic/>Add Track</label>
                                {errors.images && <small className="error error-massege text-danger">{errors.images}</small>}
                            </Form.Group>
                                {previewAudioFiles()}
                            </Col>
                            <Col sm-12 md-6> 
                                {audioFields.thumbnail 
                                ?
                                    <Form.Group className='form-group col-sm-12 col-lg-12 uploadGroupWrap'>
                                        <div className="post--thumb--upload">
                                            <img src={typeof audioFields.thumbnail === 'object' ? URL.createObjectURL(audioFields.thumbnail) : audioFields.thumbnail}/>
                                            <span className="post--media--edit" onClick={() => handleOpenCropper()}>Edit</span>
                                            <span className="post--media--close" onClick= {handleRemoveThubnail} ><IoClose/></span>
                                        </div>
                                    </Form.Group>   
                                :
                                    <Form.Group className='form-group col-sm-12 col-lg-12 uploadGroupWrap'>
                                        <input type='file' hidden id="upload--album--art" onChange={handleThumnail} />
                                        <label className='label--upload text-center' htmlFor="upload--album--art"><BsPlusLg/>Add Cover Art</label>
                                        {errors.thumbnail && <small className="error error-massege text-danger">{errors.thumbnail}</small>}
                                    </Form.Group>
                                }
                            </Col>
                        </Row>
                        <Row className="mt-3"> 
                            <Form.Group className='form-group col-sm-12'>
                                <span className='editor--tool' onClick = {()=>setShowEditor(!showEditor)}><FaCogs /></span>
                            </Form.Group>
                            <Form.Group className='form-group col-sm-12 text-title-post'>
                            <Form.Label>Description</Form.Label>
                                <Editor
                                    editorState={editorState}
                                    wrapperClassName="demo-wrapper editor-w"
                                    editorClassName="demo-editor input"
                                    onEditorStateChange={onEditorStateChange}
                                    keyBindingFn={myKeyBindingFn}
                                    hashtag={{
                                        separator: ' ',
                                        trigger: '#',
                                    }}
                                    toolbarHidden={showEditor}
                                    config={{ link: { trailingWhitespace: true } }}
                                />
                                {errors.description && <small className="error error-massege text-danger">{errors.description}</small>}
                            </Form.Group>
                            {selectBoard()}
                            <Form.Group className='form-group col-sm-12'>
                                <Form.Check type="checkbox" label="Allow Comments"  id="commentCheck1"  checked={audioFields.commenting} onChange= {handleCommenting}/>
                                <Form.Label htmlFor="commentCheck1" onClick={handleCommenting}/>
                            </Form.Group>
                            <Col sm={12} className='text-end'>
                                <Button variant="secondary"  className='me-2' disabled = {loading || draftLoading} onClick={()=>handleCreateAudioPost(true)}>
                                    { draftLoading ? <> <Spinner animation="border" size="sm"/> Please wait </> : 'Save to Draft'}
                                </Button>
                                <Button variant="primary" disabled = {loading || draftLoading} onClick={()=>handleCreateAudioPost(false)}>
                                    {loading ?  <> <Spinner animation="border" size="sm"/> Please wait </> : 'Publish Now'}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </div>
    );
}

export default CreateAlbumAudio;