import { 
    STREAM_POST_SUCCESS,
    STREAM_POST_ERROR,
    LIKE_POST,
} from "../actions/types";


const initialState = {
    streamPostList:null,
    streamPostError:null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case STREAM_POST_SUCCESS:
            if(state.streamPostList !==null){
                if(action.payload.pageNumber && action.payload.pageNumber !== '1'){
                    const copyPost = state.streamPostList
                    let  post = state.streamPostList.streamPostList.streamPost
                    let newPost = action.payload.streamPostList.streamPost
                    var mergePost = [...post , ...newPost]
                    mergePost = mergePost.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i)
                    copyPost.streamPostList.streamPost = mergePost
                    return{ 
                        ...state,
                        streamPostList:copyPost
                    }    
                }else{
                    return {
                        streamPostList:action.payload,
                    }
                }    
            }else{
                return{
                    ...state , 
                    streamPostList:action.payload,
                }
            }
        case STREAM_POST_ERROR:
            return{
                streamPostError: action.payload
            }
        case LIKE_POST:
            if(state.streamPostList !== null){
                if(state.streamPostList.streamPostList  &&  state.streamPostList.streamPostList.streamPost){
                    const postLikes = state.streamPostList.streamPostList
                    const likesIndex = postLikes.streamPost.findIndex(post => post.id === action.payload.post.postId)
                    if(likesIndex !== -1){
                        var copyVideoPost = state.streamPostList
                        copyVideoPost.streamPostList.streamPost[likesIndex].Likes = action.payload.post.likes
                        const updateLikes = {...copyVideoPost}
                        return{
                            streamPostList: updateLikes,
                        }     
                    }      
                }
            }                 
        default: return state;    
    }    
}

