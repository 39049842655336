import React, {useState , useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast, Toaster } from "react-hot-toast";
import moment from 'moment';
import { Container, Row, Col } from 'react-bootstrap';
import { getBoardPostList} from '../../redux/actions/post.action';
import { checkHTML , convertToPlainText , valid} from '../../utils/validations';
import PageHeader from '../Header/HeaderTop';
import SidebarMenu from '../Sidebar/SidebarNav';
import 'bootstrap/dist/css/bootstrap.min.css';
// import '../../';
import '../../App.css';


function RealmsMain() {
    const [realmList , setRealmList] = useState('');
    const [loading , setLoading ] = useState(true);
    const dispatch = useDispatch() ; 
    const apiResult = useSelector(state =>  state.post.boardPostList);
    const apiError = useSelector(state =>  state.post.boardPostError);

    useEffect(()=>{
        dispatch(getBoardPostList())
    },[])

    useEffect(()=>{
        if(apiResult && apiResult.success){
            setRealmList(apiResult.boardPosts)
            setLoading(false)
        }
    },[apiResult]);

    useEffect(()=>{
        if(apiError){
            // toast.error(apiError, {
            //     position: 'top-center', 
            // });
            setLoading(false)
        }
    },[apiError])


    const renderRealm = () =>{
        if(realmList && realmList.length > 0){
            return (
                <>
                    {
                        realmList.map((item , ind)=>{
                            return (
                                <Col sm={6} lg={4} xl={3}>
                                    <div className="Gr--realmMPanel">
                                        <Link to={`/realm/${item.name.toLowerCase()}`} state={{slug:item.slug}}>
                                            <img src={valid(item.thumbnail)? item.thumbnail: '/images/background.png'} alt="..." />
                                            <h4>{item.name}</h4>
                                        </Link>
                                    </div>
                                </Col>
                            )
                        })
                    }
                </>
            )
        }
    }
    return (
        <>
            <PageHeader />
            <div className='page--content'>
                <SidebarMenu />
                <div className='discover--module common--wrapper'>
                    <Container>
                        <Row>
                            {!loading 
                            ? 
                              (realmList && realmList.length > 0 ) 
                              
                                ?   renderRealm()

                                :  <h2> Realm not found </h2>


                            :
                                <Col sm={12}>
                                    <div className='text-center spinner--loader'></div>
                                </Col>

                            }


                            {/* <Col sm={6} lg={4} xl={3}>
                                <div className="Gr--realmMPanel">
                                    <Link to="/disclosure">
                                        <img src="/images/1566289323435-thumbnail_0544.jpg" alt="..." />
                                        <h4>Disclosure</h4>
                                    </Link>
                                </div>
                            </Col> */}
                           
                        </Row>
                    </Container>
                </div>
            </div>
            <Toaster/>
        </>
    );
}

export default RealmsMain;