import {
    CREATE_SHOP_SUCCESS,
    CREATE_SHOP_FAILED,
    GET_SHOP_SUCCESS,
    GET_SHOP_FAILED,
    SHOP_CAT_SUCCESS,
    SHOP_CAT_FAILED,
    GET_MY_SHOP_SUCCESS,
    GET_MY_SHOP_FAILED,
    GET_SINGLE_SHOP_FAILED,
    GET_SINGLE_SHOP_SUCCESS,
    CHANGE_SHOP_STATUS_SUCCESS,
    CHANGE_SHOP_STATUS_FAILED,
    SHOP_COMMON_ERROR,
    EDIT_SHOP_SUCCESS,
    EDIT_SHOP_FAILED,
    DELETE_SHOP_SUCCESS,
    DELETE_SHOP_FAILED,
} from "../actions/types";

const initialState = {
    shopList: null,
    shopError: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case CREATE_SHOP_SUCCESS:
            return{
               shopCreated:action.payload,
            }
        case CREATE_SHOP_FAILED:
            return{
                shopCreateError:action.payload.message,
            }
        case SHOP_CAT_SUCCESS:
            return{
                shopCatList:action.payload,
            }
        case  SHOP_CAT_FAILED:
            return{
                shopCatError:action.payload.message,
            }
        case GET_SHOP_SUCCESS:
            return{
                shopList:action.payload,
            }
        case GET_SHOP_FAILED:
            return{
                shopListError:action.payload.message,
            }    
        case GET_MY_SHOP_SUCCESS:
            return{
                myShopList:action.payload,
            }
        case GET_MY_SHOP_FAILED:
            return{
                myShopListError:action.payload.message,
            }        
        case GET_SINGLE_SHOP_SUCCESS:
            return{
                singleShop:action.payload,
            }
        case  GET_SINGLE_SHOP_FAILED:
            return{
                singleShopError:action.payload.message,
            }            
        case CHANGE_SHOP_STATUS_SUCCESS:
            return{
                shopStatus:action.payload,
            }
        case  CHANGE_SHOP_STATUS_FAILED:
            return{
                shopStatusError:action.payload.message,
            }                
        case SHOP_COMMON_ERROR:
            return{
                shopError:action.payload,
            }        
        case EDIT_SHOP_SUCCESS:
            return{
                shopUpdated:action.payload,
            }    
        case EDIT_SHOP_FAILED:
            return{
                shopUpdatedError:action.payload.message,
            }     
        case DELETE_SHOP_SUCCESS:
            return {
                shopDeleted: action.payload,
            }   
        case DELETE_SHOP_FAILED:
            return {
                shopDeleteError: action.payload.message,
            }
        default: return state;    
    }
}