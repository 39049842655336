import React, { Component } from 'react'
import { Modal, Form, Media,Image } from 'react-bootstrap'
import { connect } from 'react-redux'
import { getUsersList } from '../../redux/actions/user.action';
import { inviteUser, invitedUser, acceptInvitation } from '../../redux/actions/message.action';
import { isValueNotNull } from '../../utils/validations';
import { searchUser } from '../../redux/actions/user.action';

class InvitationChatModel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            users: [],
            main_users: [],
            invited: [],
            invitations: [],
            show_model:true , 
            search : ''
        }
        this.timeout = null; // Initialize a timeout variable
    }
   
    componentDidMount = async () => {
        await this.props.invitedUser();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if( nextProps.invited && nextProps.invited.success ){
            this.setState({ invited: nextProps.invited.data })
        }
    }

    /** ---- To Say Hi and Start Chat ----- */
    connectForChat = async e => {
        var { id } = e.target;
        var { invited } = this.state;
        invited.push(Number(id));
        this.setState({ invited });

        await this.props.inviteUser(Number(id));
        var inviterId = [];
        var acceptId = null;

        if(this.state.invited.length){
            inviterId=this.state.invited.filter((item)=>{
                if( item.user_id==id){
                    return item.id
                }
            })
        }
        if(inviterId.length){
            acceptId = inviterId.map((item)=>{
                return item.id
            })
            await this.props.acceptInvitation(Number(acceptId));
            this.props.updatedChatUsers();
            this.props.closeModel();
        }
    }
    
    /** ---- Not In Use ----- */
    /*accept = async id => {
        var { invitations } = this.state;
        await this.props.acceptInvitation(id);
        invitations = invitations.filter(invitation => invitation.id !== id)
        this.setState({ invitations });
    }*/

    /** ----- Search To Start Chat ---- */
    search = async e => {
        var { users, main_users } = this.state;
        this.setState({search:e.target.value})
        // let value = e.target.value.trim().toLowerCase()
        // const data = {value:value}
        // await this.props.searchUser(data)
    }

    searchUsers = async(data) =>{
        await this.props.searchUser(data)
        if(this.props.searchedUser && this.props.searchedUser.searchedUserList){
            if(this.props.searchedUser.searchedUserList && this.props.searchedUser.searchedUserList.success){
                this.setState({users:this.props.searchedUser.searchedUserList.users})
                this.setState({loading:false})
            }else{
                this.setState({loading:false})
            }
        }else{
            this.setState({loading:false})
        }

    }

    debounceSearchUsers = (data) => {
        if (this.timeout) {
            clearTimeout(this.timeout); // Clear the previous timeout
        }
        this.timeout = setTimeout(() => {
            this.searchUsers(data); // Call searchUsers after the delay
        }, 1200); // Set the delay to 300 milliseconds
    };

    componentDidUpdate(prevProps, prevState){
        if(prevState.search !== this.state.search){
            let value = this.state.search.trim().toLowerCase();
            const data = { value: value };
            // Assuming that searchUser is a prop function
            this.setState({
                loading : true ,
                users: [] ,
            },()=>this.debounceSearchUsers(data))
        }
    }

    

    render() {
        return (
                <div>
                    <div className="search-bar my-2 chat-search-m-w">
                        <Form.Group controlId="chat-search">
                            <Form.Control 
                                type="text" 
                                value ={this.state.search}
                                onChange={(e)=>this.search(e)} 
                                placeholder="Search here!" 
                            />
                        </Form.Group>
                    </div>
                    {this.state.loading 
                        ?   <div className='text-center spinner--loader'></div>
                        :   this.state.users.length 
                            ?   <div className="user-lists">
                                    {this.state.users.map((user, i) => {
                                            var condition = this.state.invited.filter(ui => Number(ui.user_id) === user.id).length
                                            var check = this.props.chat_users.filter(u => (u.senderid === user.id || u.receiverid === user.id));
                                            return (
                                                <div key={i} className="userSingle">
                                                    <div className='user-list-invite-main'>
                                                        <div className='img-list-user-main-wraper'>
                                                            <img alt="The Galaxy Messanger User" className="me-2" src={isValueNotNull(user.image) ? user.image : "../images/user.png"} />
                                                            <div className='user-text-list-main-wraper'>
                                                                <h5>{user.first} {user.last}  -  {user.username}</h5>
                                                            </div>
                                                        </div>
                                                            <div className="d-md-flex align-items-center justify-content-between list-text-heading-m-w ms-auto">
                                                                {/* <h5>{user.first} {user.last} </h5> */}
                                                                {condition || check.length ?
                                                                <>
                                                                    <button 
                                                                        type="button" 
                                                                        className="btn-sm btn btn-secondary"
                                                                        disabled
                                                                    >
                                                                        Sent
                                                                    </button>
                                                                </>
                                                                :
                                                                <>
                                                                    <button 
                                                                        type="button"
                                                                        onClick={!condition ? this.connectForChat : null} 
                                                                        id={user.id}
                                                                        className="btn-sm btn-primary send-btn-disabl-1"
                                                                    >
                                                                        Send Message 
                                                                        {/* <Image src='./images/hi-icon.png'></Image> */}
                                                                    </button>
                                                                </>
                                                                
                                                            }
                                                            </div>
                                    
                                                    </div>
                                                </div>
                                            )
                                    })}
                                </div>
                        // :   <h4 className='user-no-yet'>No users yet!</h4>}
                            : null }
                </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { user, messenger } = state
    return {
        users: user,
        invited: messenger.invited_users,
        chat_users: messenger.chat_users.success ? messenger.chat_users.data : [],
        searchedUser : user
    }
}

const dispatchActions = {
    getUsersList,
    inviteUser, 
    invitedUser,
    acceptInvitation, // socket
    searchUser,
}
export default connect(mapStateToProps, dispatchActions)(InvitationChatModel)