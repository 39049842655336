import {
    CREATE_SCHEDULE,
    GET_SCHEDULE,
    CREATE_SCHEDULE_ERROR,
    GET_SCHEDULE_ERROR,
    UPDATE_SCHEDULE,
    UPDATE_SCHEDULE_ERROR,
    SCHEDULE_COMMON_ERROR , 
    DELETE_SCHEDULE_SUCCESS,
    DELETE_SCHEDULE_ERROR,
    GET_TIME_SLOTS,
    GET_TIME_SLOTS_ERROR,
    BOOK_SCHEDULE,
    GET_EVENT,
    BOOK_SCHEDULE_ERROR,
    GET_EVENT_ERROR,
    CLEAR_SCHEDULES,
    CREATE_EVENT,
    CREATE_EVENT_ERROR,
    GET_MEETINGS,
    GET_MEETINGS_ERROR


} from "../actions/types";


const initialState = {
    schedules: null,
    scheduleError: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case CREATE_SCHEDULE : 
            return {
                scheduleCreated : action.payload
            }
        case CREATE_SCHEDULE_ERROR :
            return{
                createError : action.payload
            }
        case GET_SCHEDULE :
            return {
                schedules : action.payload
            }
        case GET_SCHEDULE_ERROR :
            return {
                getError : action.payload
            }
        case UPDATE_SCHEDULE :
            return {
                scheduleUpdated : action.payload
            }
        case UPDATE_SCHEDULE_ERROR :
            return {
                updateError : action.payload
            }
        case DELETE_SCHEDULE_SUCCESS :
            return {
                deleted : action.payload
            }
        case DELETE_SCHEDULE_ERROR :
            return {
                deleteError : action.payload
            }
        case SCHEDULE_COMMON_ERROR :
            return {
                commonError : action.payload
            }
        case GET_TIME_SLOTS :
            return{
                timeSlots : action.payload
            }
        case GET_TIME_SLOTS_ERROR :
            return{
                timeSlotsEror : action.payload
            }
        case  BOOK_SCHEDULE:
            return{
                scheduleBooked : action.payload
            }
        case  BOOK_SCHEDULE_ERROR:
            return {
                bookScheduleError : action.payload
            }
        case GET_EVENT:
            return {
                userEvents: action.payload
            }
        case GET_EVENT_ERROR:
            return{
                userEventError: action.payload
            }
        case CLEAR_SCHEDULES:
            if(state.schedules){
                console.log("state.schedules" , state.schedules)
                return{
                    schedules:''
                }
            }
        case CREATE_EVENT:
            return{
                event_created: action.payload
            }
        case CREATE_EVENT_ERROR :
            return{
                create_event_error: action.payload
            }
        case GET_MEETINGS:
            return{
               meetings:action.payload
            }
        default: return state;   

    }

}