import React, { useEffect, useState } from "react";
import { Button, Row, Col, Form , Spinner} from 'react-bootstrap';
import {useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { notValid} from "../../../utils/validations"
import { toast} from "react-hot-toast";
import DatePicker from "react-datepicker";
import {IoClose} from 'react-icons/io5';
import { scheduleLivePost } from "../../../redux/actions/liveVideoPost.action";
import API from '../../../helpers/api';


function ScheduleLiveVideo(props) { 
    const intialValue = { name:  props.streamData.name ? props.streamData.name : '', thumbnail: props.streamData.thumbnail ? props.streamData.thumbnail : '' , schedule_date: new Date() , schedule_post:true , description:props.streamData.description ? props.streamData.description : ''} ;
    const [linkFields , setLinkFields] = useState(intialValue) ; 
    const [schedule_date , setSchedule_date] = useState(new Date())
    const [errors , setErrors] = useState('');
    const [loading , setLoading] = useState(false);
    const [thumbnailLoader , setThumbnailLoader] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const apiResult = useSelector(state =>state.videoPost);

    const handleChange = (e) =>{
        const {value , name } = e.target ; 
        setLinkFields({...linkFields, [name]: value })
        setErrors({ ...errors, [name]: '' })
    }


    
    const validate = () =>{
        const check = ['', null, undefined, '0', 0, 'null', 'undefined'];
        let isValid = true ;
        let errorMessages = {};
        if (notValid(linkFields.name)) {
            errorMessages['name'] = 'Title is required'
            isValid = false;
        }
        if(check.includes(linkFields.thumbnail)){
            errorMessages['thumbnail'] = 'Image is required'
            isValid = false;
        }
        if (!isValid) {
            setErrors({ ...errors, ...errorMessages });
        }
        return isValid;

    }
    

    const handleSchudleVideoPost = (e) =>{
        e.preventDefault();
        if(validate()){
            var copyLinkFields = {...linkFields}
            copyLinkFields.schedule_date = schedule_date;
            setLoading(true);
            dispatch(scheduleLivePost(linkFields)) ;
        }
    }


    useEffect(()=>{
        if(apiResult && apiResult.postScheduled){
            navigate('/')
        }
        if(apiResult && apiResult.postScheduledFailed){
            toast.error(apiResult.postScheduledFailed, {
                position: 'top-center',
            });
        }
    },[apiResult])


    const handleDate = (date) =>{
        setSchedule_date(date)
    }


    const validationImage = (image) => {
        const extentions = ['image/jpg', 'image/jpeg', 'image/png', 'image/gif']
        if (extentions.includes(image.type)) {
          return true;
        }
        else {
          return false;
        }
    }

    const handleThumbnail = async (e) => {
        let errorMessages = {};
        let thumbnail= '' ;
        if (validationImage(e.target.files[0])) {
            thumbnail = e.target.files[0];
            errorMessages['thumbnail']=''
            setErrors({ ...errors, ...errorMessages });
            setThumbnailLoader(true)
            const url = await handleUploadFileToServer(thumbnail) ; 
            if(url){
                setLinkFields({...linkFields , thumbnail:url})
                setThumbnailLoader(false)
            }else{
                setThumbnailLoader(false)
            }
        } else {
            errorMessages['thumbnail'] = 'Please use only jpg,png,jpeg or gif file'
            setErrors({ ...errors, ...errorMessages });
        }
    }


    const handleUploadFileToServer = async (file) => {
        try {
            const formData = new FormData();
            formData.append('thumbnail', file);
            const response = await API.apiUploadFileURL('messenger', `/get-file-url`, formData);
            if(response.data && response.data.success){
              return response.data.fileUrl
            }
        } catch (error) {
          toast.error('Oops, unable to upload file');
        }
      }


    const handleRemoveThubnail = async() =>{
        let copyMedia = {...linkFields} ;
        copyMedia.thumbnail = '' 
        setLinkFields(copyMedia);
    }
      

    return (
        <>
            <div className='create--form'>
                <Form onSubmit={handleSchudleVideoPost}>
                    <Row>
                        <Form.Group className='form-group col-sm-12'>
                            <Form.Label>Title</Form.Label>
                            <Form.Control type="text" name= 'name' value= {linkFields.name} onChange= {handleChange} required />
                            {errors.name && <small className="error error-massege text-danger">{errors.name}</small>}
                        </Form.Group>
                        {linkFields.thumbnail ?
                            <Form.Group className='form-group col-sm-12'>
                            <div className="post--thumb--upload">
                                <Form.Label>Add Thumbnail</Form.Label>
                                <img src={typeof linkFields.thumbnail === 'object' ? URL.createObjectURL(linkFields.thumbnail) : linkFields.thumbnail}/>
                                <span className="post--media--close" onClick= {handleRemoveThubnail} ><IoClose/></span>
                            </div>
                            </Form.Group>
                        :   thumbnailLoader ? 
                            (
                                <Form.Group className='form-group col-sm-12 col-lg-12'>
                                    <div className="post--thumb--upload">
                                    <Spinner animation="border"/>
                                    </div>
                                </Form.Group>
                            )
                            :
                                <Form.Group className='form-group col-sm-12'>
                                    <div className="post--thumb--upload">
                                    <Form.Label>Add Thumbnail</Form.Label>
                                        <input type='file' hidden id="upload--header" onChange={(e) => handleThumbnail(e)}/>
                                        <label className='label--upload' htmlFor="upload--header">Choose File</label>
                                        {errors.thumbnail && <small className="error error-massege text-danger">{errors.thumbnail}</small>}
                                    </div>
                                </Form.Group>
                        }    
                        <Form.Group className='form-group col-sm-12'>
                            <Form.Label>Description</Form.Label>
                            <Form.Control as='textarea' rows={5}  className='mb-2' name="description" value={linkFields['description']} onChange={(e) => handleChange(e)} />
                            {errors.description && <small className="error error-massege text-danger">{errors.description}</small>}
                        </Form.Group>
                        <Form.Group  className='form-group col-sm-12'> 
                            <Form.Label>Schedule Time</Form.Label><br />
                            <DatePicker
                                selected={schedule_date}
                                onChange={(date) => handleDate(date)}
                                minDate={new Date()}
                                timeFormat="h:mm aa"
                                timeIntervals={15}
                                timeCaption="time"
                                showTimeSelect
                                className="form-control"
                                dateFormat="MM/dd/yyyy h:mm aa"
                            />
                        </Form.Group> 
                        <Col sm={12} className='text-end'>
                            <Button variant="secondary"  type='submit'  disabled = {loading} className='me-2'>
                                { loading ? <> <Spinner animation="border" size="sm"/> Please Wait </> : ' Submit'}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        </>
    );
}

export default ScheduleLiveVideo;