import React, { Component} from 'react';
import {privateRoutes ,   publicRoutes} from './routes';
import { withRouter } from "./wrapper/withRouter";
import { Routes, Route, useLocation } from "react-router-dom";
// import SidebarMenu from "./Components/Sidebar/SidebarNav";
import { connect } from 'react-redux';
import { socket } from './helpers/auth';
import { LIKE_POST , CREATE_POST_COMMENT , ADD_LIKE_COMMENT , DELETE_COMMENT , GET_NOTIFICATION , GET_USER_NOTIFICATION, NEW_MESSAGE, UPDATE_MESSAGES, UNSEEN_COUNT , LIVE_VIDEO_STARTED , STOP_LIVE_VIDEO , UPDATE_CHAT_USERS , SENDER_UNSEEN_COUNT , LIVE_AUDIO_STARTED , STOP_LIVE_AUDIO} from './redux/actions/types';
// import toast, { Toaster } from 'react-hot-toast';
import 'react-toastify/dist/ReactToastify.css';
import { Toaster } from 'react-hot-toast';
import AudioMediaPlayer from './Components/Audio/AudioPlayer';
import VideoPlayer from './Components/Video/VideoPlayer';
import { ToastContainer} from 'react-toastify';


class App extends Component { 
  constructor(props){
    super(props)
    this.state = {
      hideSideBar : true , 
      showPlayer : false , 
    }
  }
  
  getRoutes = (routes, _privateRoute) =>
    routes.map((route , _privateRoute) => {
      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }
    return null;
  });

  componentDidMount = () => {
    const {location} = this.props ; 
    this.checkIfSidebarShouldBeVisible();

    if(this.props.loggedIn){
      socket.on('LikePost', (data) => {
        if( data.success ){
          this.props.likePost(data);
          this.props.getNotify(data)
        }else{
          // toast.dismiss()
          // toast.error(data.error);
        }
      })

      socket.on('comment_received', data => {
        if(data.success){
          this.props.createComment(data);
        }else{
          // toast.error(data.error);
        }
      });

      socket.on('like_received_comment', data => {
        if(data.success){
          this.props.addCommentLike(data);
        }else{
          // toast.error(data.error);
        }
      });

      socket.on('delete_comment', data => {
        if(data.success){
          this.props.deleteComment(data);
        }else{
          // toast.error(data.error);
        }
      });
    

  /***************   start live video  ********************/
      socket.on('live-video-created' , data =>{
        this.props.startVideo(data)
      })


      socket.on('live-audio-created' , data =>{
        this.props.startAudioLive(data)

      })

  /****************   stop video and update video post   ********************/
      socket.on('stop-live-video' , data =>{
        this.props.stopVideo(data)
      })

      socket.on('stop-live-Audio' , data =>{
        this.props.stopAudio(data)
      })



      socket.on('newUserNotications' , data =>{
        this.props.getUserNotifications(data)
      })

      /** --------------- Messenger ------------- */

        /**
          * This socket will be called when a user arrives online.
        */
        socket.on("online_a_user", user => {
          this.props.updateChatStatus({ user, is_online: true })
        });
      /**
        * This socket will be called when a user goes offline.
      */
      socket.on("offline_a_user", user => {
        this.props.updateChatStatus({ user, is_online: false })
      });

      socket.on("send-message", data => {
        let d = {
          data: data,
          removed: false,
          updated: false,
          added:true,
        }
        this.props.newChatMessage(d)
      });

      /**
         * This socket will be called when someone remove message
         */
      socket.on("remove-message", data => {
        let d = {
          data: data,
          removed: true,
          updated: false,
          added:false,
        }
        this.props.updateMessage(d)
      });
      /**
       * This socket will be called when someone update a message
       */
      socket.on("update-message", data => {
        let d = {
          data: data,
          removed: false,
          updated: true,
          added:false,
        }
        this.props.updateMessage(d)
      });

      /**
         * This socket used to count unseen messages
         */
      socket.on("unseen-count", data => {
        this.props.setUnseenCount(data)
      })

     
    }
    } 

  componentDidUpdate(prevProps) {
    const {location , audioList} = this.props ; 
    if (this.props.location !== prevProps.location) {
      this.checkIfSidebarShouldBeVisible();
    }


  }


  checkIfSidebarShouldBeVisible = () => {
    const { pathname } = this.props.location;
    const hideSidebarRoutes = ['/login', '/register'];
    const hideSideBar = !hideSidebarRoutes.includes(pathname);
    this.setState({ hideSideBar});
  };


  renderVideoPlayer = () =>{
    const { pathname } = this.props.location;
    
    const dynamicPathRegexVideo = /^\/video\/.+$/;
    const dynamicPathRegexLive = /^\/live-video\/.+$/;
    const res = pathname.match(dynamicPathRegexVideo)
    const resTwo = pathname.match(dynamicPathRegexLive)
    if (!res && !resTwo) {
      return (
        <div>
          <VideoPlayer/>
        </div>
      )
    }
  }


  render(){
    return (
     <>
     {/* playerActive */}
      <div className={this.props.loggedIn ? (this.props.audioList !==null) ? "main--wrapper logged--in playerActive" : "main--wrapper logged--in" : (this.props.audioList !==null) ? 'main--wrapper playerActive' : 'main--wrapper'}>
        <Toaster/>
        <ToastContainer/>
        {
          this.props.loggedIn 
            ?   
              
              <Routes>
                {this.getRoutes(privateRoutes)}
              </Routes>
             
            :   
             
              <Routes>
                {this.getRoutes(publicRoutes)}
              </Routes>
              
        }
      </div>
        {this.props.audioList !== null ? <AudioMediaPlayer /> : null}
        {this.renderVideoPlayer()}  
      </>
    )
  }  
}  

const mapDispatchToProps = dispatch => {
  return {
    likePost: async payload => {await dispatch({ type: LIKE_POST, payload }) },
    createComment: async payload => { await dispatch({ type: CREATE_POST_COMMENT, payload }) },
    addCommentLike: async payload => { await dispatch({ type: ADD_LIKE_COMMENT, payload }) },
    deleteComment: async payload => { await dispatch({ type: DELETE_COMMENT, payload }) },
    getNotify:async payload => { await dispatch({ type: GET_NOTIFICATION, payload }) },
    getUserNotifications:async payload => { await dispatch({ type: GET_USER_NOTIFICATION, payload }) },
    /** ------ Messenger -------- */
    newChatMessage: async payload => { await dispatch({ type: NEW_MESSAGE, payload }) },
    updateMessage: async payload => {  await dispatch({ type: UPDATE_MESSAGES, payload })},
    setUnseenCount: async payload => { await dispatch({ type: UNSEEN_COUNT, payload }) },
    startVideo: async payload => { await dispatch({ type:LIVE_VIDEO_STARTED, payload }) }, 
    startAudioLive: async payload => { await dispatch({ type: LIVE_AUDIO_STARTED, payload }) }, 
    stopVideo: async payload => { await dispatch({ type: STOP_LIVE_VIDEO, payload }) }, 
    updateChatStatus:async payload => { await dispatch({ type: UPDATE_CHAT_USERS, payload }) }, 
    setSenderUnseenCount:async payload => { await dispatch({ type: SENDER_UNSEEN_COUNT, payload }) }, 
    stopAudio:async payload =>{await dispatch({ type: STOP_LIVE_AUDIO, payload })}
  }  
}

const mapStateToProps = state => {
  const { loggedIn } = state.auth;
  const {audioList} = state.audioPlayer
  const {video} = state.videoPlayer
  return { loggedIn , audioList , video}
}
export default connect( mapStateToProps , mapDispatchToProps)(withRouter(App));