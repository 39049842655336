import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate , useParams} from "react-router-dom";
import { toast} from "react-hot-toast";   
import { EditorState, convertToRaw, getDefaultKeyBinding , ContentState} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Button, Row, Col, Form , Spinner , Container} from 'react-bootstrap';
import { FaCogs} from 'react-icons/fa';
import {IoClose} from 'react-icons/io5';
import { BsFileEarmarkMusic, BsPlusLg } from 'react-icons/bs';
import { notValid,checkHTML , convertToPlainText} from "../../utils/validations";
import { getSinglePost , getBoardPostList } from "../../redux/actions/post.action"
import { editAudioPost} from "../../redux/actions/audioPost.action"
import PageHeader from "../Header/HeaderTop";
import '../Shop/shopStyle.css';
import '../../App.css';
import SidebarMenu from "../Sidebar/SidebarNav";


function EditAudio() {
    const{slug} = useParams(); 
    const intialValue = {postId : '' , oldTrack: '' , name:'' , description: '', album: '' , isStream: true,  commenting: true , board_name: '', board_id: '', images:[] , thumbnail:''};
    const [audioFields , setAudioFields] = useState(intialValue);
    const [trackError , setTrackError] = useState(false)
    const [boardList , setBoardList] = useState('');
    const [loading , setLoading] = useState(false) ; 
    const [publishLoading , setPublishLoading] = useState(false);
    const [draftLoading , setDraftLoading] = useState(false)
    const [showEditor , setShowEditor] = useState(true);
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
    const [errors , setErrors] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const apiResult = useSelector(state => state.audioPost); 
    const getPostResult = useSelector(state => state.post);

    useEffect(()=>{
        dispatch(getSinglePost(slug));
        dispatch(getBoardPostList())
    },[]);


    useEffect(()=>{
        if(getPostResult && getPostResult.singlePostSuccess){
            let copyAudioFields = {...audioFields};
            copyAudioFields.postId = getPostResult.singlePostSuccess.Post.id ;
            copyAudioFields.thumbnail = getPostResult.singlePostSuccess.Post.thumbnail || '';
            copyAudioFields.commenting = getPostResult.singlePostSuccess.Post.commenting ;
            copyAudioFields.isStream = getPostResult.singlePostSuccess.Post.isStream || true ; 
            copyAudioFields.board_name = getPostResult.singlePostSuccess.Post.board_name || '';
            copyAudioFields.board_id  = getPostResult.singlePostSuccess.Post.board_id || '' ;
            copyAudioFields.name =  getPostResult.singlePostSuccess.Post.name.split(',') || '';
            copyAudioFields.images = getPostResult.singlePostSuccess.Post.media.split(',') || ''
            if(getPostResult.singlePostSuccess.Post.description){
                const blocksFromHtml = htmlToDraft(getPostResult.singlePostSuccess.Post.description);
                const { contentBlocks, entityMap } = blocksFromHtml;
                const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
                const editorState = EditorState.createWithContent(contentState);
                setEditorState(editorState)
                copyAudioFields.description = getPostResult.singlePostSuccess.Post.description
            }
            if(getPostResult.singlePostSuccess.Post.Postmeta.length){
                copyAudioFields.album = getPostResult.singlePostSuccess.Post.Postmeta[0].meta_value
               
               
            }else{
                copyAudioFields.name =  getPostResult.singlePostSuccess.Post.name|| '';
                copyAudioFields.images = getPostResult.singlePostSuccess.Post.media.split(',')|| ''
            }
            setAudioFields(copyAudioFields)
        }   
        if(getPostResult.singlePostError){
            toast.error(apiResult.textPostError, {
                position: 'top-center', 
            });
        }
        if(getPostResult && getPostResult.boardPostList){
            setBoardList(getPostResult.boardPostList.boardPosts)
        }
        if(getPostResult && getPostResult.boardPostError){
            toast.error(getPostResult.boardPostError.message, {
                position: 'top-center', 
            });
        }
        setLoading(false)
    },[getPostResult])



    const handleInput = (e) =>{
        const {value , name } = e.target ; 
        setAudioFields({...audioFields, [name]: value })
        setErrors({ ...errors, [name]: '' })
    }

    const handleCommenting = (e) =>{
        const copyAudioFileds = {...audioFields} ;
        copyAudioFileds.commenting = e.target.checked
        setAudioFields(copyAudioFileds)
    }

/************************************************************************************************************** */


/**********************    handle description  /////   text editor  function using draft.js for text editing        ******/

    const onEditorStateChange = (editorState) => {
        const copyAudioFields = {...audioFields} ;
        let errorMessages = {...errors};
        const rawContentState = convertToRaw(editorState.getCurrentContent());
        const description = draftToHtml(rawContentState);
        copyAudioFields.description = description
        errorMessages.description = '' ;
        setAudioFields(copyAudioFields)
        setErrors(errorMessages)
        setEditorState(editorState);
    };

    const myKeyBindingFn = event => {
        return getDefaultKeyBinding(event);
    }
/********************************************************************************************************************* */


/**---------------------  form validation  -----------------------*/
    const validate = () =>{
        let isValid = true ;
        let errorMessages = {};
        let desc = checkHTML(audioFields.description) ? convertToPlainText(audioFields.description) : audioFields.description
        if (notValid(desc)) {
            errorMessages['description'] = 'Description is required'
            isValid = false;
        }
        if (notValid(audioFields.album)) {
            errorMessages['album'] = 'Title is required'
            isValid = false;
        }

        if(audioFields.images.length <= 1){

            errorMessages['images'] = 'Please Upload more than 1 audio for album'
            isValid = false;
      
        }
        if(trackError){
            isValid = false;
        }
        if (!isValid) {
            setErrors({ ...errors, ...errorMessages });
        }
        return isValid;
    }


/**************************   audio album files handles using images name for audio files  **************/
    const handleAudioFiles = (e) =>{
        const files = e.target.files ;
        if(files && audioFields['images'].length < 12){
            let copyAudioFields =  {...audioFields}
            Array.from(files).forEach((file) => {
                if (file.type.includes('audio/')) {
                    copyAudioFields['images'].push(file)
                    copyAudioFields['name'].push(file.name.split('.')[0])
                }
            });
            setAudioFields(copyAudioFields)
        }

    }

    const handleTrackName = (e , i) =>{
        const copyAudioFields = {...audioFields};
        copyAudioFields.name[i] =e.target.value ; 
        setTrackError(false)
        setAudioFields(copyAudioFields);
        if(copyAudioFields.name[i] === '' ){
           setTrackError(true)
        }
    }

    const handleRemove = (i)=>{
        const copyAudioFields = {...audioFields};
        copyAudioFields['images'].splice(i, 1);
        copyAudioFields['name'].splice(i, 1);
        setAudioFields(copyAudioFields)
    }

    const previewAudioFiles = () =>{
        if(audioFields.images && audioFields.images.length > 0){
            return (
                <>
                    {
                        audioFields.images.map((file , ind)=>{
                            return (
                                <Form.Group className='form-group col-sm-12 col-lg-4'>
                                    <div className="audio--upload--grid">
                                        <Form.Control type="text" placeholder='Track Title' value={audioFields.name[ind]}  onChange={(e)=>handleTrackName(e,ind)}  required />
                                        {audioFields.name[ind] === '' && <span className="error error-massege text-danger"> Title is required</span>}
                                        <audio controls>
                                            <source src={typeof file === 'object' ? URL.createObjectURL(file) : file} type={file.type} />
                                        </audio>
                                        <span className="post--media--close" onClick = {()=>handleRemove(ind)}><IoClose/></span>
                                    </div>
                                </Form.Group>
                            )
                        })
                    }    
                </>
            )
        }
    }


    const handleThumnail = (e)=>{
        const allImages = ['png', 'jpg', 'jpeg', 'JPG', 'JPEG', 'PNG'];
        let errorMessages = {...errors};
        const { files } = e.target ; 
        const copyAudioFile = {...audioFields};
        if(files && files.length < 2 ){
            const file = files[0];
            const fileName = file.name.toLowerCase();
            const fileExtension = fileName.split('.').pop();
            if(allImages.includes(fileExtension)){
                copyAudioFile.thumbnail = file
                setAudioFields(copyAudioFile)
                errorMessages['thumbnail'] = ''
                setErrors(errorMessages)
            }else{
                errorMessages['thumbnail'] = ' Please use only  png, jpg, jpeg, JPG, JPEG, PNG'
                setErrors(errorMessages)
            }    
        }

    }

    const handleRemoveThubnail = () =>{
        const copyAudioFields = {...audioFields};
        copyAudioFields.thumbnail = '' ;
        setAudioFields(copyAudioFields)
    }

////////////////     ************************************************************************************ ///////////////
    const handleCreateAudioPost = (draft) =>{
        let copyAudioFields = {...audioFields}
        copyAudioFields.name = audioFields.name.join(',')
        if(validate()){
            if(draft){
                copyAudioFields.status = '2'
                setPublishLoading(false)
                setDraftLoading(true)
            }else{
                setPublishLoading(true)
                setDraftLoading(false)
            }
            const newAudio = copyAudioFields.images.filter(item => typeof item === 'object'); // using for filter the new images 
            const oldTracks =  copyAudioFields.images.filter(item => typeof item !== 'object'); 
            copyAudioFields.images = newAudio
            if(oldTracks && oldTracks.length > 0){
                copyAudioFields.oldTrack = oldTracks
            }
            const formData = new FormData();
            for (const [key, value] of Object.entries(copyAudioFields)) {
                if (typeof value === 'object' && key === 'images') {
                  value.forEach(element => {
                    formData.append('images', element);
                  });
                }else if(typeof value === 'object' && key === 'oldTrack'){
                    value.forEach(element => {
                        formData.append('oldTrack', element);
                    });
                }else {
                  formData.append(key, value)
                }   
            }
            dispatch(editAudioPost(formData)) ;
        }
    }    


    useEffect(()=>{
        if(apiResult && apiResult.editAudio){
            setPublishLoading(false)
            setDraftLoading(false)
            toast.success(apiResult.editAudio.message, {
                position: 'top-center', 
            });
            navigate('/audio')
        }
        if(apiResult && apiResult.audioPostError){
            setPublishLoading(false)
            setDraftLoading(false)
            toast.success(apiResult.audioPostError.message, {
                position: 'top-center', 
            });
        }
        setPublishLoading(false)
        setDraftLoading(false)

    },[apiResult])

    const selectBoard = () => {
        if(boardList && boardList.length > 0){
            return (
                <Form.Group className='form-group col-sm-12'>
                    <Form.Label>Realm</Form.Label>
                    <Form.Control value={audioFields.board_id || '' } onChange= {handleBoard} as="select">
                        <option selected>Select</option>
                        {boardList.map((item , idx)=>{
                            return (
                                <option key={idx} value={item.id}>{item.name}</option>
                            )
                        })}
                    </Form.Control>
                </Form.Group>

            )
        }
    }

    const handleBoard = (e)=>{
        let copyAudioFields = {...audioFields};
        copyAudioFields.board_id = e.target.value
        setAudioFields(copyAudioFields)
    }

    return (
        <>
            <PageHeader />
            <div className='page--content'>
                <SidebarMenu />
                <div className='discover--module common--wrapper create--shop--form'>
                    <Container>
                        <Row className='justify-content-center pt-3'>
                            {!loading 
                                ?    <Col sm={12} lg={10}>
                                        <Form>
                                            <Row>
                                                {previewAudioFiles()}
                                            </Row>
                                            <Row>
                                                <Form.Group className='form-group col-sm-12 col-lg-6'>
                                                    <input type='file' hidden id="upload--audio-album" accept="audio/*"  multiple onChange={handleAudioFiles}/>
                                                    <label className='label--upload text-center' htmlFor="upload--audio-album" ><BsFileEarmarkMusic/>Add Track</label>
                                                    {errors.images && <small className="error error-massege text-danger">{errors.images}</small>}
                                                </Form.Group>
                                                {audioFields.thumbnail 
                                                ?
                                                    <Form.Group className='form-group col-sm-12 col-lg-6'>
                                                        <div className="post--thumb--upload">
                                                            <img src={typeof audioFields.thumbnail === 'object' ? URL.createObjectURL(audioFields.thumbnail) : audioFields.thumbnail}/>
                                                            {/* <span className="post--media--edit" onClick={() => handleOpenCropper()}>Edit</span> */}
                                                            <span className="post--media--close" onClick= {handleRemoveThubnail} ><IoClose/></span>
                                                        </div>
                                                    </Form.Group>   
                                                :
                                                    <Form.Group className='form-group col-sm-12 col-lg-6'>
                                                        <input type='file' hidden id="upload--album--art" onChange={handleThumnail} />
                                                        <label className='label--upload text-center' htmlFor="upload--album--art"><BsPlusLg/>Add Cover Art</label>
                                                        {errors.thumbnail && <small className="error error-massege text-danger">{errors.thumbnail}</small>}
                                                    </Form.Group>
                                                }
                                                <Form.Group className='form-group col-sm-12'>
                                                    <Form.Label>Album Title</Form.Label>
                                                    <Form.Control type="text" name = 'album' value={audioFields.album} onChange = {handleInput} required />
                                                    {errors.album && <span className="error error-massege text-danger">{errors.album}</span>}
                                                </Form.Group>
                                                <Form.Group className='form-group col-sm-12'>
                                                    <span className='editor--tool' onClick = {()=>setShowEditor(!showEditor)}><FaCogs /></span>
                                                </Form.Group>
                                                <Form.Group className='form-group col-sm-12'>
                                                    <Editor
                                                        editorState={editorState}
                                                        wrapperClassName="demo-wrapper editor-w"
                                                        editorClassName="demo-editor input"
                                                        onEditorStateChange={onEditorStateChange}
                                                        keyBindingFn={myKeyBindingFn}
                                                        hashtag={{
                                                            separator: ' ',
                                                            trigger: '#',
                                                        }}
                                                        toolbarHidden={showEditor}
                                                        config={{ link: { trailingWhitespace: true } }}
                                                    />
                                                    {errors.description && <small className="error error-massege text-danger">{errors.description}</small>}
                                                </Form.Group>
                                                {selectBoard()}
                                                <Form.Group className='form-group col-sm-12'>
                                                <Form.Check type="checkbox" label="Allow Comments"   checked={audioFields.commenting} onChange= {handleCommenting}/>
                                                </Form.Group>
                                                <Col sm={12} className='text-end'>
                                                    <Button variant="secondary"  className='me-2' disabled = {publishLoading || draftLoading} onClick={()=>handleCreateAudioPost(true)}>
                                                        { draftLoading ? <> <Spinner animation="border" size="sm"/> Please wait </> : 'Save to Draft'}
                                                    </Button>
                                                    <Button variant="primary" disabled = {publishLoading|| draftLoading} onClick={()=>handleCreateAudioPost(false)}>
                                                        {publishLoading ?  <> <Spinner animation="border" size="sm"/> Please wait </> : 'Publish Now'}
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Form>

                                    </Col>
                                :    <Col sm={12} lg={10}>
                                        <div className='text-center spinner--loader'></div>
                                    </Col>               
                            }
                            
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    );
}

export default EditAudio;