import React, {useEffect, useState } from 'react';
import { Button,Row, Col, Form, Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../App.css';



function ShippingInfoModel(props) {
   
   
    const intialValue = {first_name:'' , last_name:'' , address1: '' , address2:'' , city:'', postal_Code: '' , country_code:"US" }

    const [shippingAddress , setShippingAddress] = useState(intialValue);
    const [errors , setErrors] = useState(false);
    const [postalError , setPostalError] = useState(true)
    const [loading , setLoading] = useState(false)


    useEffect(()=>{
        const data = {first_name : props.userDetails.firstName , last_name:props.userDetails.lastName}
        setShippingAddress(prevState => ({
            ...prevState,  // Keep the existing values
            ...data // Override with the new data from the token
        }));
    },[props])



  

    const handleOnChange  =  (e) =>{
        setPostalError(true)
        const {value , name } = e.target ; 
        setShippingAddress({...shippingAddress, [name]: value })
        if(name === 'postal_Code'){
            setPostalError(/^\d+$/.test(value))
        }
        setErrors({ ...errors, [name]: '' })
    }

    const handleSubmit = (e) =>{
        e.preventDefault();
        if(postalError){
            setLoading(true)
            props.submit(shippingAddress);
            props.close()
        }
    }
    
    return (
        <>
            <div className='create--form'>
                <Form className='change--image--modal' onSubmit= {handleSubmit}>
                    <Row>
                        <Form.Group className='form-group col-sm-12'>
                            <Form.Control name="first_name" type="text" placeholder="First name"  value = {shippingAddress.first_name} required  onChange = {handleOnChange}/>
                        </Form.Group>
                        <Form.Group className='form-group col-sm-12'>
                            <Form.Control name="last_name" type="text" placeholder="Last name"  value = {shippingAddress.last_name} required onChange={handleOnChange}/>
                        </Form.Group>
                        <Form.Group className='form-group col-sm-12'>
                            <Form.Control name="address2" type="text" placeholder="Street Address" value = {shippingAddress.address2} required  onChange={handleOnChange}/>
                        </Form.Group>
                        <Form.Group className='form-group col-sm-12'>
                            <Form.Control name="address1" type="text" placeholder="Apt. ste. bldg." value = {shippingAddress.address1} onChange={handleOnChange}/>
                        </Form.Group>
                        <Form.Group className='form-group col-sm-12'>
                            <Form.Control name="city" type="text" placeholder="City"  value = {shippingAddress.city}  required onChange={handleOnChange}/>
                        </Form.Group>
                        <Form.Group className='form-group col-sm-12'>
                            <Form.Control name="postal_Code" type="text" placeholder="Zip Code"   value = {shippingAddress.postal_Code} required  onChange={handleOnChange}/>
                            {!postalError && <small className="error error-massege text-danger">Please provide a valid Zip Code</small>}
                        </Form.Group>
                        <Col sm={12} className='text-end'>
                            <Button type='submit'>Done
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        </>
    );
}

                        
export default ShippingInfoModel;

