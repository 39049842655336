import { combineReducers } from "redux";
import authReducer from "./auth.reducer";
import userReducer from "./user.reducer";
import textPostReducer from "./textPost.reducer";
import linkPostReducer from "./linkPost.reducer";
import visualPostReducer from "./visualPost.reducer";
import postReducer from "./post.reducer";
import videoReducer from "./videoPost.reducer";
import audioReducer from "./audioPost.reducer";
import shopReducer from "./shop.reducer";
import productsReducer from "./products.reducer";
import shoppingCartReducer from "./shoppingCart.reducer";
import searchReducer from "./search.reducer";
import ordersReducer from "./orders.reducer";
import messageReducer from "./message.reducer";
import liveVideoPostReducer from "./liveVideoPost.reducer";
import streamPostReducer from "./streamPost.reducer";
import discoverPostReducer from "./discoverPost.reducer";
import audioPlayerReducer from "./audioPlayer.reducer";
import videoPlayerReducer from "./videoPlayer.reducer";
import HeaderReducer from "./Header.reducer";
import scheduleReducer from "./schedule.reducer";

export default combineReducers({
  auth: authReducer,
  user: userReducer , 
  textPost: textPostReducer,
  linkPost: linkPostReducer ,
  visualPost: visualPostReducer,
  post: postReducer,
  videoPost: videoReducer,
  audioPost: audioReducer,
  shop: shopReducer,
  product: productsReducer,
  cart:shoppingCartReducer,
  search:searchReducer,
  order:ordersReducer,
  messenger: messageReducer,
  liveVideo:liveVideoPostReducer,
  stream:streamPostReducer ,
  discoverList: discoverPostReducer , 
  audioPlayer: audioPlayerReducer,
  videoPlayer:videoPlayerReducer,
  showHeader:HeaderReducer ,
  schedule : scheduleReducer
});