import { get } from 'lodash';


async function errorRequest(err) {
    let data = await get(err, 'response.data', null);
    data = data || await get(err, 'response');
    data = data || err;
    if (data.error) {
        return data.error
    } else {
        return data
    }
}

export const errorActionCreator = async (errorType, error, dispatch) => {
    const errorM = await errorRequest(error)
    dispatch({ 
        type: errorType,
        error: true,
        payload: errorM, 
    })
}