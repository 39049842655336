import { get } from 'lodash';
import API from '../../helpers/api';
import * as auth from '../../helpers/auth';
import {
    SCHEDULE_LIVE_POST_SUCCESS,
    SCHEDULE_LIVE_POST_FAILED,
    GET_LIVE_VIDEO_POST_SUCCESS,
    GET_LIVE_VIDEO_POST_FAILED,
    CREATE_LIVE_VIDEO_POST,
    CREATE_LIVE_VIDEO_POST_ERROR,
    EDIT_LIVE_VIDEO_POST_SUCEESS,
    EDIT_LIVE_VIDEO_POST_FAILED,
    COMMON_LIVE_POST_ERROR,
    CLEAR_LIVE_CREATE,
    CLEAR_LIVE_COMMENTS
} from "./types";



const config = {
  headers: {
    "Content-Type": "multipart/form-data; charset=utf-8;"
    
  }
}

function errorRequest(err, dispatch, type) {
    let data = get(err, 'response.data', null);
    data = data || get(err, 'response');
    data = data || err;
    if (data.error){
        dispatch({
            type: type ? type: COMMON_LIVE_POST_ERROR,
            payload: data.error.message,
        });
    }else if (data.status === 401) {   
        auth.logout()
    } else {
        dispatch({
            type: type ? type: COMMON_LIVE_POST_ERROR,
            payload: data.message,
        });
    }
}

export const clearLiveVideoCreate = () =>{
    return async (dispatch) => {
      dispatch({type:CLEAR_LIVE_CREATE});
    }
}


export const clearLiveComments = () =>{
    return async (dispatch) => {
      dispatch({type:CLEAR_LIVE_COMMENTS});
    }
}




export const createLiveVideoPost = (payload)=>{
    return async (dispatch) => {
        try{
            const response = await API.apiPostUrl('post', '/create-live-video-post' , payload) ;
            if(response.data && response.data.success){
                await dispatch({ type:  CREATE_LIVE_VIDEO_POST, payload: response.data});
            }else{
                await dispatch({ type:  CREATE_LIVE_VIDEO_POST_ERROR, payload: response.data});
            }
        }catch(error){
            errorRequest(error, dispatch , CREATE_LIVE_VIDEO_POST_ERROR);
        }
    }
}


export const getLiveVideoPosts = (payload) =>{
    return async (dispatch) => {
        try{
            const response = await API.apiGetUrl('post', '/live-video-post' , payload) ;
            if(response.data && response.data.success){
                await dispatch({ type: GET_LIVE_VIDEO_POST_SUCCESS, payload: response.data});
            }else{
                await dispatch({ type: GET_LIVE_VIDEO_POST_FAILED, payload: response.data});
            }
        }catch(error){
            errorRequest(error, dispatch , GET_LIVE_VIDEO_POST_FAILED);
        }
    }
}


export const scheduleLivePost = (payload) =>{
    return async (dispatch) => {
        try{
            const response = await API.apiUploadFileURL('post', '/schedule_live_video' , payload ) ;
            if(response.data && response.data.success){
                await dispatch({ type: SCHEDULE_LIVE_POST_SUCCESS, payload: response.data});
            }else{
                await dispatch({ type: SCHEDULE_LIVE_POST_FAILED, payload: response.data});
            }
        }catch(error){
            errorRequest(error, dispatch , SCHEDULE_LIVE_POST_FAILED);
        }
    }
}
  

export const editLiveVideoPost = (payload)=>{
    return async (dispatch) => {
        try{
            const response = await API.apiPutUrl('post', '/edit-live-video-post' , payload , config) ;
            if(response.data && response.data.success){
                await dispatch({ type: EDIT_LIVE_VIDEO_POST_SUCEESS, payload: response.data});
            }else{
                await dispatch({ type: EDIT_LIVE_VIDEO_POST_FAILED, payload: response.data});
            }
        }catch(error){
            errorRequest(error, dispatch , EDIT_LIVE_VIDEO_POST_FAILED);
        }
    }
}









