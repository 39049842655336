import React, { useEffect , useState } from 'react';
import { useNavigate , useLocation , useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Container, Row, Col, Form , Spinner} from 'react-bootstrap';
import CurrencyInput from 'react-currency-input-field';
import { toast, Toaster } from "react-hot-toast";  
import { BsArrowLeft } from 'react-icons/bs';
import { FaCogs, FaCreativeCommonsPdAlt } from 'react-icons/fa';
import {EditorState, ContentState, convertToRaw, getDefaultKeyBinding} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import PageHeader from '../Header/HeaderTop';
import SidebarMenu from '../Sidebar/SidebarNav';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../App.css';
import { Link } from 'react-router-dom';
import {IoClose} from 'react-icons/io5';
import {editProduct , getSingleProduct } from '../../redux/actions/product.action';
import { notValid , valid  } from '../../utils/validations';

const conditionOptions = [
    { value: 'new', label: 'New' },
    { value: 'used-new', label: 'Used -  Like New' },
    { value: 'used-good', label: ' Used - Good' },
    { value: 'used-fair', label: 'Used - Fair' },
]
const availabilityOptions = [
    { value: true, label: 'In Stock' },
    { value: false, label: 'Out of Stock' },
]


function EditProduct() {
    const {slug} = useParams();
    const intialValue = {productId:'' , title: '',
        price:'',
        category:'',
        payment_type:'cash',
        price_type:'fixed',
        currency: "USD",
        availability: '',
        condition:'',
        shop:0,
        short_description:'',
        description:'',
        stock:0,
        is_digital: false,
        payment_option: 0,
    }
    const [productFields , setProductFileds] = useState(intialValue);
    const [loading , setLoading] = useState(false) ;
    const [loader , seLoader] = useState(true)
    const [errors , setErrors] = useState(false);
    const [showEditor , setShowEditor] = useState(true);
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
    const [categories , setCategories] = useState('')
    const [isShop , setIsShop] = useState(false);
    const [myShop, setMyShop] = useState('');
    const [shopName , setShopName] = useState('')
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const apiResult = useSelector(state => state.product);
  
    useEffect(()=>{
        dispatch(getSingleProduct(slug));
    },[])

    useEffect(()=>{
        if(apiResult && apiResult.singleProduct){
            const product = apiResult.singleProduct.product
            let copyProductsFields = {...productFields};
            copyProductsFields.productId = product.id
            copyProductsFields.title = product.title ;
            copyProductsFields.short_description = product.short_description ;
            copyProductsFields.category = product.category ;
            copyProductsFields.availability = product.availability ;
            copyProductsFields.shop = product.shop ;
            copyProductsFields.condition = product.condition;
            copyProductsFields.stock = Number(product.stock);
            copyProductsFields.is_digital = product.is_digital;
            copyProductsFields.price =product.price;
            copyProductsFields.payment_option = product.payment_option
            if(product.description && product.description !==''){
                const blocksFromHtml =  htmlToDraft(product.description);
                const { contentBlocks, entityMap } = blocksFromHtml;
                const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
                const editorState = EditorState.createWithContent(contentState);
                setEditorState(editorState)
            }
            setProductFileds(copyProductsFields)
            seLoader(false)
            
        }
        if(apiResult && apiResult.singleProductError){
            toast.error(apiResult.singleProductError , {
                position: 'top-center', 
            });
            seLoader(false)
        }
       
    },[apiResult])
   

    const handleInput = (e) =>{
        const {value , name } = e.target ; 
        setProductFileds({...productFields, [name]: value })
        setErrors({ ...errors, [name]: '' })
    } 

/********************************    editior handling  *********************************************************/

    const onEditorStateChange = (editorState) => {
        const copyProductsFields = {...productFields} ;
        let errorMessages = {...errors};
        const rawContentState = convertToRaw(editorState.getCurrentContent());
        const description = draftToHtml(rawContentState);
        copyProductsFields.description = description
        errorMessages.description = '' ;
        setProductFileds(copyProductsFields)
        setErrors(errorMessages)
        setEditorState(editorState);
    };


    const myKeyBindingFn = event => {
        return getDefaultKeyBinding(event);
    }

/******************************************************************************************************************* */    
    const handlePrice = (value , name)=>{
        setProductFileds({...productFields, [name]: value })
        setErrors({ ...errors, [name]: '' })

    }

    const handleCondition = (e) =>{
        let copyProductsFields = {...productFields};
        copyProductsFields.condition = e.target.value
        setProductFileds(copyProductsFields)
        const copyErrors = {...errors} 
        if(copyErrors.condition){
            copyErrors.condition = ''
            setErrors(copyErrors);
        }

    }

    const handleAvailability = (e)=>{
        let copyProductsFields = {...productFields};
        copyProductsFields.availability = e.target.value
        setProductFileds(copyProductsFields)
        const copyErrors = {...errors} 
        if(copyErrors.condition){
            copyErrors.condition = ''
            setErrors(copyErrors);
        }
    }

    // const handleProductType = (value) => {
    //     let copyProductsFields = {...productFields};
    //     copyProductsFields.is_digital = value
    //     setProductFileds(copyProductsFields)
    // };

    const handleProductType = (value) => {
        let copyProductsFields = {...productFields};

        copyProductsFields.is_digital = value
        if(value){
            copyProductsFields.payment_option = 1;
        }
        setProductFileds(copyProductsFields)
    };

    const handlePaymentType = (e) =>{
        let copyProductsFields = {...productFields};
        copyProductsFields.payment_option = e.target.value
        setProductFileds(copyProductsFields)
    }

/********************************   form validation  **********************************************/

    const isValid = () =>{
        let isValid = true ;
        let errorMessages = {};
        const check = ['null' , null , undefined , 'undefined' , '' , []] ; 
        if (check.includes(productFields.short_description)) {
            errorMessages['description'] = ' Short description is required'
            isValid = false;
        }
        if(check.includes(productFields.title)){
            errorMessages['title'] = ' Please enter a product title'
            isValid = false;
        }
        if(check.includes(productFields.condition)){
            errorMessages['condition'] = ' Please select a product condition'
            isValid = false;
        }
        if(check.includes(productFields.availability)){
            errorMessages['availability']  = 'please select product availability'
            isValid = false;
        }
        if(productFields.availability){
            if(productFields.stock === 0){
                errorMessages['stock']  = 'please enter a valid stock'
                isValid = false;
            }
        }
        // if(productFields.price === '0.00'){
        //     errorMessages['price']  = 'please enter a valid price'
        //     isValid = false;
        // }
        if(check.includes(productFields.category)){
            errorMessages['price']  = 'please select a category'
            isValid = false;
        }
        if (!isValid) {
            setErrors({ ...errors, ...errorMessages});
        }
        return isValid;
       
    }    

    const handleCreateProduct = (e)=>{
        e.preventDefault();
        if(isValid()){
            setLoading(true)
            dispatch(editProduct(productFields)) ;
        }
    }


    useEffect(()=>{
        if(apiResult && apiResult.productUpdated){
            toast.success(apiResult.productUpdated.message, {
                position: 'top-center', 
            });
            setLoading(false)
            navigate('/marketplace')
        }

        if(apiResult && apiResult.productUpdatedError){
            toast.error(apiResult.productUpdatedError, {
                position: 'top-center', 
            });
            setLoading(false)
        }
        if(apiResult && apiResult.productError){
            toast.error(apiResult.productError, {
                position: 'top-center', 
            });
            setLoading(false)

        }
    })

    return (
        <>
            <PageHeader />
            <div className='page--content'>
                <SidebarMenu />
                <div className='discover--module common--wrapper create--shop--form'>
                    <Container>
                        <Row className='justify-content-center pt-3'>
                            <Col sm={12} lg={10}>
                                <Link className='back--btn' to='/marketplace'><BsArrowLeft/> Back</Link>
                                <Form onSubmit={handleCreateProduct}>
                                    <Row>
                                        <Form.Group className='form-group col-sm-12 col-lg-12'>
                                            <Form.Label>Product Title</Form.Label>
                                            <Form.Control type="text"  name='title' value={productFields.title} onChange = {handleInput} required />
                                            {errors.title && <small className="error error-massege text-danger">{errors.title}</small>}
                                        </Form.Group>
                                        <Form.Group className='form-group col-sm-12'>
                                            <Form.Label>Short Description</Form.Label>
                                            <Form.Control as="textarea" name="short_description"  value={productFields.short_description} onChange={handleInput} required  rows={2} />
                                            {errors.short_description && <small className="error error-massege text-danger">{errors.short_description}</small>}
                                            <small>0/250</small>
                                        </Form.Group>
                                        
                                        <Form.Group className='form-group col-sm-12'>
                                            <span className='editor--tool' onClick= {()=>setShowEditor(!showEditor)}><FaCogs /></span>
                                        </Form.Group>
                                        <Form.Group className='form-group col-sm-12'>
                                            <Form.Label>Long Description</Form.Label>
                                            <Editor
                                                editorState={editorState}
                                                wrapperClassName="demo-wrapper editor-w"
                                                editorClassName="demo-editor input"
                                                onEditorStateChange={onEditorStateChange}
                                                keyBindingFn={myKeyBindingFn}
                                                hashtag={{
                                                    separator: ' ',
                                                    trigger: '#',
                                                }}
                                                toolbarHidden={showEditor}
                                                config={{ link: { trailingWhitespace: true } }}
                                            />
                                        </Form.Group>
                                        <Form.Group className='form-group col-sm-12 col-lg-6'>
                                            <Form.Label>Price</Form.Label>
                                            <CurrencyInput
                                                id="product-price"
                                                name="price"
                                                value={parseFloat(productFields.price).toFixed(2)}
                                                decimalsLimit={2}
                                                onValueChange={(value, name)=>handlePrice(value, name)}
                                                suffix="$"    
                                            />
                                            {errors.price && <small className="error error-massege text-danger">{errors.price}</small>}
                                        </Form.Group>
                                        <Form.Group className='form-group col-sm-12 col-lg-6'>
                                            <Form.Label>Currency</Form.Label>
                                            <Form.Control type="text" name='currency' value = {productFields.currency} readOnly  required />
                                        </Form.Group>
                                        <Form.Group className='form-group col-sm-12 col-lg-6'>  
                                            <Form.Label>Condition</Form.Label>
                                            <Form.Select value={productFields.condition || '' } onChange= {handleCondition} required className='form-control'>
                                                <option value = ''>Select...</option>
                                                {conditionOptions.map((item ,idx)=>{
                                                    return (
                                                        <option key={idx} value={item.value}>{item.label}</option>
                                                    )
                                                })}
                                            </Form.Select>
                                            {errors.condition && <small className="error error-massege text-danger">{errors.condition}</small>}
                                        </Form.Group>
                                        <Form.Group className='form-group col-sm-12 col-lg-6'>
                                            <Form.Label>Stock</Form.Label>
                                            <CurrencyInput
                                                id="product-stock"
                                                name="stock"
                                                value={productFields.stock}
                                                allowDecimals={false} // Disallow decimals
                                                allowNegativeValue={false} // Disallow negative values
                                                onValueChange={(value, name)=>handlePrice(value, name)}
                                            />
                                            {errors.stock && <small className="error error-massege text-danger">{errors.stock}</small>}
                                        </Form.Group>
                                        <Form.Group className='form-group col-sm-12 col-lg-6'>
                                            <Form.Label>Availability</Form.Label>
                                            <Form.Select value={productFields.availability || '' }  onChange= {handleAvailability} required className='form-control'>
                                                <option value= ''>Select...</option>
                                                {availabilityOptions.map((item,idx)=>{
                                                    return (
                                                        <option value={item.value}>{item.label}</option>
                                                    )   
                                                })}
                                            </Form.Select>
                                            {errors.availability && <small className="error error-massege text-danger">{errors.availability}</small>}
                                        </Form.Group>
                                        <Form.Group className='form-group col-sm-12 col-lg-6'>
                                            <Form.Label>Product Type</Form.Label>
                                            <br/>
                                            {/* <Form.Check inline type='radio' name='product' id='digital' label='Digital' />
                                            <Form.Check inline type='radio' name='product' id='physical' label='Physical' /> */}
                                            <Form.Check inline checked={productFields.is_digital} onChange={() => handleProductType(true)} type="radio" label="Digital" name="is_digital" value={true} />
                                            <Form.Check inline checked={!productFields.is_digital} onChange={() => handleProductType(false)} type="radio" label="Physical" name="is_digital" value={false} />
                                        </Form.Group>
                                        <Form.Group className='form-group col-sm-12 col-lg-6'>
                                            <Form.Label>Payment Option</Form.Label>
                                            <br/>
                                            <Form.Check 
                                                inline 
                                                disabled={productFields.is_digital} 
                                                checked={Number(productFields.payment_option) === 0} 
                                                onChange={handlePaymentType} 
                                                type="radio" 
                                                label="Any" 
                                                name="payment_option" 
                                                value={0} 
                                            />
                                            <Form.Check 
                                                inline 
                                                checked={Number(productFields.payment_option) === 1}
                                                onChange={handlePaymentType} 
                                                type="radio" 
                                                label="Paypal" 
                                                name="payment_option" 
                                                value={1} 
                                            />
                                            <Form.Check 
                                                inline 
                                                disabled={productFields.is_digital} 
                                                checked={Number(productFields.payment_option) === 2} 
                                                onChange={handlePaymentType} 
                                                type="radio" 
                                                label="Cash On Delivery" 
                                                name="payment_option" 
                                                value={2} 
                                            />
                                            {/* <Form.Check 
                                                inline checked={Number(productFields.payment_option) === 0} onChange={handlePaymentType} type="radio" label="Any" name="payment_option" value={0} />
                                            <Form.Check inline checked={Number(productFields.payment_option) === 1} onChange={handlePaymentType} type="radio" label="Paypal" name="payment_option" value={1} />
                                            <Form.Check inline checked={Number(productFields.payment_option) === 2} onChange={handlePaymentType} type="radio" label="Cash On Delivery" name="payment_option" value={2} />
                                           */}
                                        </Form.Group>
                                        <Col sm={12} className='text-end'>
                                            <Button disabled={loading} variant="primary" type="submit">
                                                {loading ?  <> <Spinner animation="border" size="sm"/> Please wait </> : 'Update'}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                    </Container>
                    <Toaster/>
                </div>
            </div>
        </>
    );
}

export default EditProduct;