import React, {useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Tabs, Tab } from 'react-bootstrap';
import CreateSingleAudio from './AudioSingleTab';
import CreateAlbumAudio from './AudioAlbumTab';

function CreateAudio({savedData , saveDataToForm}) {

    return (
        <div className='sub--Tabs'>
            <Tabs defaultActiveKey="single" id="create--sub--tabs">
                <Tab eventKey="single" title='Single'>
                    <CreateSingleAudio savedData={savedData} saveDataToForm={(tab , data)=>saveDataToForm(tab, data)}/>
                </Tab>
                <Tab eventKey="album" title='Album'>
                    <CreateAlbumAudio savedData={savedData} saveDataToForm={(tab , data)=>saveDataToForm(tab, data)}/>
                </Tab>
            </Tabs>
        </div>
    );
}

export default CreateAudio;