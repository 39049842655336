import { get } from 'lodash';
import API from '../../helpers/api';
import * as auth from '../../helpers/auth';
import {
    GET_VIDEO_POST_SUCCESS,
    GET_VIDEO_POST_FAILED,
    CREATE_VIDEO_SUCCESS,
    CREATE_VIDEO_FAILED,
    COMMOM_VIDEO_ERROR,
    UPLOAD_VIDEO_SUCCESS,
    UPLOAD_VIDEO_FAILED,
    EDIT_VIDEO_SUCCESS,
    EDIT_VIDEO_FAILED,
    CLEAR_VIDEO_CREATE,
    VIDEO_PLAYER_SUCCESS ,
    CLEAR_VIDEO_PLAYER , 
    SHOW_VIDEO_PLAYER , 
    START_VIDEO_PLAYER,
    STOP_VIDEO_PLAYER,
    VIDEO_TIMESTAMP ,
} from "./types";



const config = {
  headers: {
    "Content-Type": "multipart/form-data; charset=utf-8;"
    
  }
}

function errorRequest(err, dispatch, type) {
  let data = get(err, 'response.data', null);
  data = data || get(err, 'response');
  data = data || err;
  if (data.error) {
    dispatch({
      type: type ? type: COMMOM_VIDEO_ERROR,
      payload: data.error.message,
    });
  } else if (data.status === 401) {   
    auth.logout()
  } else {
    dispatch({
      type: type ? type: COMMOM_VIDEO_ERROR,
      payload: data.message,
    });
  }
}

export const clearVideoCreate = () =>{
  return async (dispatch) => {
    dispatch({type:CLEAR_VIDEO_CREATE});
  }
}




export const getVideoPosts = (payload) =>{
  return async (dispatch) => {
    try{
        const response = await API.apiGetUrl('post', '/video-post' , payload) ;
        if(response.data && response.data.success){
          await dispatch({ type: GET_VIDEO_POST_SUCCESS, payload: response.data});
        }else{
          await dispatch({ type: GET_VIDEO_POST_FAILED, payload: response.data});
        }
    }catch(error){
        errorRequest(error, dispatch , GET_VIDEO_POST_FAILED);
    }
  }
}


export const createVideoPost = (payload) =>{
  return async (dispatch) => {
    try{
        const response = await API.apiUploadFileURL('post', '/create-video' , payload , config) ;
        if(response.data && response.data.success){
            await dispatch({ type:  CREATE_VIDEO_SUCCESS, payload: response.data});
        }else{
            await dispatch({ type:  CREATE_VIDEO_FAILED, payload: response.data});
        }
    }catch(error){
      errorRequest(error, dispatch, CREATE_VIDEO_FAILED);
    }
  }
}


/** this function is using for upload video to cloud and get the url  */

export const uploadVideo = (payload) =>{
  return async (dispatch) => {
    try{
        const response = await API.apiUploadFileURL('post', '/get-video-url' , payload) ;
        if(response.data && response.data.success){
            await dispatch({ type: UPLOAD_VIDEO_SUCCESS , payload: response.data});
        }else{
            await dispatch({ type: UPLOAD_VIDEO_FAILED , payload: response.data});
        }
    }catch(error){
      errorRequest(error, dispatch , UPLOAD_VIDEO_FAILED);
    }
  }
}


export const editVideoPost = (payload)=>{
  return async (dispatch) => {
    try{
        const response = await API.apiUpdateUploadFile('post', '/edit-video' , payload , config) ;
        if(response.data && response.data.success){
          await dispatch({ type: EDIT_VIDEO_SUCCESS, payload: response.data});
        }else{
          await dispatch({ type: EDIT_VIDEO_FAILED, payload: response.data});
        }
    }catch(error){
        errorRequest(error, dispatch , EDIT_VIDEO_FAILED);
    }
  }
}


export const clearVideoPlayer= () =>{
  return async (dispatch) => {
    await dispatch({ type: CLEAR_VIDEO_PLAYER});
  }
}

export const AddVideoPlayer= (data) =>{
  return async (dispatch) => {
    await dispatch({ type: VIDEO_PLAYER_SUCCESS , payload: data});
  }
}

export const addVideoTimeStamp = (data)=>{
  return async (dispatch) => {
    await dispatch({ type: VIDEO_TIMESTAMP , payload: data});
  }
}

export const showVideoPlayer = () =>{
  return async (dispatch) => {
    await dispatch({ type: SHOW_VIDEO_PLAYER });
  }
}
  



