import React, { useEffect, useState , useRef} from 'react';
import { useNavigate , useLocation} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Button, Row, Col, Form , Modal ,Spinner} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { currentUser as user, getToken } from '../../helpers/auth';
import ReactPlayer from 'react-player'
import API from '../../helpers/api';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import LoadingOverlay from 'react-loading-overlay';
import { useSelector } from 'react-redux';
import {IoClose} from 'react-icons/io5';
import { toast, Toaster } from "react-hot-toast";  
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { notValid} from "../../utils/validations";
import '../Create/createFormStyle.css';
import PageHeader from '../Header/HeaderTop';
import SidebarMenu from '../Sidebar/SidebarNav';
import '../../App.css';
import ScheduleLiveVideo from '../Create/CreateTabs/ScheduleLiveVideo';
import LiveComments from '../Comments/LiveComments';
import { clearLiveComments } from '../../redux/actions/liveVideoPost.action';
import { WebRTCAdaptor } from '@antmedia/webrtc_adaptor';
import RecordRTC from 'recordrtc';
import { startLiveVideo , stopLiveVideo } from '../../helpers/auth';
import { editLiveVideoPost } from '../../redux/actions/liveVideoPost.action';
import { clearLiveData } from '../../redux/actions/linkPost.action'; 

function EditLiveVideo(){
  const initialState = {name:'',mode: "Offline",addPost: '',description: '',board_post: true,board_id: '',commenting: true, thumbnail: '' , media: ''};
/** ------------------   Manage Component State ---------------------*/
  const [myStreamData , setMyStreamData] = useState(initialState);
  const [streamName , setStreamName] = useState('');
  const [facingMode , setFacingMode] = useState('user');
  const [loading , setloading] = useState(false);
  const [isStart , setIsStart] = useState(false);
  const [boardList , setBoardList] = useState('');
  const [timer , setTimer] = useState(0);
  const [errors , setErrors] = useState({})
  const [thumbnailError , setThumnailError] = useState('');
  const [isEdit , setIsEdit] = useState(false);
  const [thumbnail , setThumbnail] = useState(false);
  const [slug , setSlug] = useState(false);
  const [scheduleModel , setScheduleModel] = useState(false);
  const [publishing, setPublishing] = useState(false);
  const [videoUrl , setVideoUrl] = useState(false);
  const [intialPost , setIntialPost] = useState(false);
  const [mimeType , setMimeType] = useState(false);
  const [commentBox , setCommentBox] = useState(false);
  const [thumbLoader , setThumbLoader] = useState(false);
  const [interval , setIntervalId] = useState('');
  const navigate = useNavigate()
  let _stream = useRef(0);
  let videoRef = useRef(null);
  const webRTCAdaptor = useRef(null);
  const mediaRecorderRef = useRef(null);
  var publishedStreamId = useRef(null);
  const [websocketConnected, setWebsocketConnected] = useState(false);
  const startVideoResult = useSelector(state =>state.videoPost.liveVideoStart);
  const stopVideoResult =  useSelector(state =>state.videoPost.stopVideo);
  const liveStartPost  = useSelector(state => state.liveVideo)
  const getPostResult = useSelector(state => state.post);
  const editLiveVideoResult = useSelector(state => state.liveVideo.liveVideoEdit)
  const dispatch = useDispatch();
  const location = useLocation();
/**------------------------------------------------------------------------------ */
/////// ----------------------------   component life--cycle functions ----------------------------------//////
  useEffect(()=>{
    detectBrowser()
    dispatch(clearLiveComments());
    dispatch(clearLiveData())
    const {state}= location;
    if( state && state.post){
      setIsEdit(true);
      setMyStreamData({...myStreamData , ...state.post})
    }  
  },[])

  //   useEffect(() => {
//     const {state}= location;
//     if( state && state.post){
//       setIsEdit(true);
//       setMyStreamData({...myStreamData , ...state.post})
//       return () => {
//       };
//     }  
//     return () => {
//       stopRecording();
//     };
//   }, []);

 
/**   -------------     using for update the componet when user GO LIve  ---------------*/

  useEffect(() => {
    const interval = setInterval(() => {
        if (isStart) {
          setTimer(seconds => seconds + 1);
        }
    }, 1000);
    setIntervalId(interval)
      return () => {
        clearInterval(interval);    ////    using for when compoent unmount 
        handleStopLiveVideo()
      };
  },[isStart])


  /*******  ---------   using for update component after the streaming start ********************/

    useEffect(()=>{
      if(liveStartPost && liveStartPost.liveVideoStart){
        if(liveStartPost.liveVideoStart && liveStartPost.liveVideoStart.success){
          setIntialPost(liveStartPost.liveVideoStart.result)
          setMyStreamData({...myStreamData , ...liveStartPost.liveVideoStart.result})
          setIsStart(true);
          setloading(false);
        }
      }
      if(liveStartPost && liveStartPost.liveVideoUpdated){
        if(liveStartPost.liveVideoUpdated.success){
          navigate('/')
        }
      }
      if(editLiveVideoResult && editLiveVideoResult.success){
        navigate('/')
      }
    },[liveStartPost , editLiveVideoResult]);

  /*---------------------------------------------------------------------------------------------*/
/************************************************************************************************** */

  /****************************     live streaming function sections  ***********************/
    /** this function is use for create a web rtc connection  ****************************/

      const connectWebrtc = () =>{
        if(isValid()){
          if(webRTCAdaptor.current === undefined || webRTCAdaptor.current === null){
            setloading(true);
            webRTCAdaptor.current = new WebRTCAdaptor({
              websocket_url: 'wss://test.antmedia.io:/WebRTCAppEE/websocket',
              mediaConstraints: {
                video: {
                  width: { ideal: 640 }, // Set your preferred video width
                  height: { ideal: 480 }, // Set your preferred video height
                  frameRate: { ideal: 15 }, // Set your preferred frame rate
                },
                audio: true,
              },
              peerconnection_config: {
                iceServers: [{ urls: 'stun:stun1.l.google.com:19302' }],
              },
              sdp_constraints: {
                OfferToReceiveAudio: false,
                OfferToReceiveVideo: false,
              },
              localVideoId: 'localVideo',
              dataChannelEnabled: true,
              isRecording: true, // Enable recording
              isPlayMode: false, // E
              callback: (info, obj) => {
                if (info === 'initialized') {
                  setWebsocketConnected(true);
                  handlePublish()
                }
              },
              callbackError: function (error, message) {
              },
            })
          }
        }
      }
  /**********    ------------------------------------------------------------------------- */

  /*********     this function is using for start publish the live video  *************/

    const handlePublish = () => {
      if(isValid()){
        setPublishing(true);
        const streamId = random();     // function is using for genrate a random stream id 
        setStreamName(streamId);
        webRTCAdaptor.current.publish(streamId);
        publishedStreamId.current=streamId;
        handleStartRecording()    // this function is using for record the live video using  RecordRTC
        handleLiveVideo(streamId)
      }
    };

  /**********  ---------------------------------------------------------------- *********/


  /*********************************************************************************************/

  /***************   functions which are used for record and stop recording of the live stream video  section *************/

  /************* this function use for record the video  ********************************/
    const handleStartRecording = () => {
      // const stream = videoRef.current.srcObject;  
      // mediaRecorderRef.current = new RecordRTC(stream, { type: 'video' });
      // mediaRecorderRef.current.startRecording();
      // captureImage();    // this function is using for genrate a thumbnail for the video 
      const stream = videoRef.current.srcObject;  
      const options = {
        audioBitsPerSecond: 128000,
        videoBitsPerSecond: 2500000,
        mimeType : mimeType
      };
      mediaRecorderRef.current = new MediaRecorder(stream ,  options);
      mediaRecorderRef.current.start();
    }; 
  /**************************************************************************************** */

  /********************   this function is using for stop the live video recording  ************/
    const handleStopRecording = () => {
      // return new Promise((resolve) => {
      //   mediaRecorderRef.current.stopRecording(function () {
      //     let blob = mediaRecorderRef.current.getBlob();
      //     resolve(blob);
      //   });
      // });
      const chunks = [];
      return new Promise((resolve) => {
        mediaRecorderRef.current.ondataavailable = (event) => {
          if (event.data.size > 0) {
            chunks.push(event.data)
          }
        };
        mediaRecorderRef.current.onstop = async() => {
          if( chunks && chunks.length > 0){
            const blob = new Blob(chunks, { type:mimeType});
            resolve(blob);
          }
        };
      });
    };
  /************************************************************************************************ */
  /***************  this function is used for genrate a thumbnail from the live video  *************/
    const captureImage = async() => {
      if (videoRef.current) {
        const canvas = document.createElement('canvas');
        canvas.width = videoRef.current.videoWidth;
        canvas.height = videoRef.current.videoHeight;
        const context = canvas.getContext('2d');
        context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
        // You can now use the captured image data as needed
        const imageData = canvas.toDataURL('image/png');
        // Add logic here to handle the captured image, if needed
        const base64Data = imageData.replace(/^data:image\/png;base64,/, '');
        // Convert the base64 string to a binary format
        const binaryData = atob(base64Data);
        // Create a Blob from the binary data
        const blob = new Blob([new Uint8Array(binaryData.length).map((_, i) => binaryData.charCodeAt(i))], { type: 'image/png' });
        let imageElement = document.createElement('img');
        imageElement.src = URL.createObjectURL(blob);
        const url = await uploadThumbnailFileToServer(blob) ; 
        if(url){
          return(url)
        }
      }
    };
  /***********************************************************************************************************************/

/*******************************   function which are use for genrate the live video link  ******************************/
    /************   this function used for genrate the live video link on front end and save the intial data to db  */
      const handleLiveVideo = async(streamId) =>{
        var data = {}
        if(myStreamData.schedule_post){
          data = {
            addPost: streamId,
            user_id: user.aud,
            board_post: myStreamData.board_id ? true : false,
            action: "online",
            description: myStreamData.description,
            commenting: myStreamData.commenting,
            board_id: myStreamData.board_id !== 'public' ? myStreamData.board_id : undefined,
            thumbnail: await captureImage(),
            name: myStreamData.name ,
            schedule_post: myStreamData.schedule_post,
            postId: myStreamData.id
          }  
        }else{
          data = {
            addPost: streamId,
            user_id: user.aud,
            board_post: myStreamData.board_id ? true : false,
            action: "online",
            description: myStreamData.description,
            commenting: myStreamData.commenting,
            board_id: myStreamData.board_id !== 'public' ? myStreamData.board_id : undefined,
            thumbnail: await captureImage(),
            name: myStreamData.name ,
          }  
        } 
        startLiveVideo(data)
      }
    /********************************************************************************************************************/

  /*********************************   function which are used for stop the live video and update the live video data on server ***********/
    const handleStopLiveVideo = async() =>{
      if(isStart){
        setloading(true)
        clearInterval(interval)
        let data = {} ;
        webRTCAdaptor.current.stop(streamName);
        webRTCAdaptor.current.turnOffLocalCamera();
        // const videoFile = await handleStopRecording()
        // if(videoFile){
        //   var videoUrl = await handleUploadFileToServer(videoFile);
        // }
        mediaRecorderRef.current.stop();
        const videoFile = await handleStopRecording()
        if(videoFile){
          if(mimeType === 'video/mp4'){
            var videoUrl = await uploadThumbnailFileToServer(videoFile);
          }else{
            var videoUrl = await handleUploadFileToServer(videoFile);
          }
        }
        if(myStreamData.schedule_post){
          data = {
            addPost:streamName,
            user_id: user.aud,
            board_post: myStreamData.board_id ? true : false,
            action: "offline",
            description: myStreamData.description,
            commenting: myStreamData.commenting,
            board_id: myStreamData.board_id !== 'public' ? myStreamData.board_id : undefined,
            thumbnail: myStreamData.thumbnail,
            name: myStreamData.name , 
            media: videoUrl ? videoUrl : '' ,
            schedule_post :  false 
          }  
        }else{
          data = {
            addPost:streamName,
            user_id: user.aud,
            board_post: myStreamData.board_id ? true : false,
            action: "offline",
            description: myStreamData.description,
            commenting: myStreamData.commenting,
            board_id: myStreamData.board_id !== 'public' ? myStreamData.board_id : undefined,
            thumbnail: myStreamData.thumbnail,
            name: myStreamData.name , 
            media: videoUrl ? videoUrl : '' ,
          }  
        }
        stopLiveVideo(data)
      }

    }
  /******************************************************************************************************************************************/

    /****************************   function which are used for form handling  ****************************************/
      const handleOnChange = (e) => {
        const { name, value, checked } = e.target;
        if(name === 'commenting'){
          setMyStreamData({ ...myStreamData, [name]: checked });
        }else{
          setMyStreamData({ ...myStreamData, [name]:value });
          setErrors({ ...errors, [name]: '' })
        }
      }

      const handleThumbnail = async (e) => {
        let thumbnail= '' ;
        if (validationImage(e.target.files[0])) {
          thumbnail = e.target.files[0];
          setThumnailError(false)
    
        } else {
          setThumnailError('Please use only jpg,png,jpeg or gif file')
        }
        if (!thumbnailError) {
          setThumbLoader(true);
          const url = await handleUploadFileToServer(thumbnail) ; 
          if(url){
            let copyMyStream = {...myStreamData}
            copyMyStream.thumbnail = url
            setMyStreamData(copyMyStream)
            setThumbLoader(false);
          }
        }
      }

      const selectBoard = () => {
        if(boardList && boardList.length > 0){
            return (
                <Form.Group className='form-group col-sm-12'>
                    <Form.Label>Realm</Form.Label>
                    <Form.Control value={myStreamData.board_id || '' } onChange= {handleBoard} as="select">
                        <option selected>Select</option>
                        {boardList.map((item , idx)=>{
                            return (
                                <option key={idx} value={item.id}>{item.name}</option>
                            )
                        })}
                    </Form.Control>
                </Form.Group>
            )
        }
      }
    
      const handleBoard = (e)=>{
        let copyMyStreamData = {...myStreamData};
        copyMyStreamData.board_id = e.target.value
        setMyStreamData(copyMyStreamData)
      }
    /******************************************************************************************************************/

      /***********************   form Validation function  ****************************/
        const isValid = () => {
          let isValid = true;
          let errorMessages = {};
          const check = ['', null, undefined, '0', 0, 'null', 'undefined'];
          if (check.includes(myStreamData.description)) {
            errorMessages['description'] = 'Description is required'
            isValid = false;
          }
          if (check.includes(myStreamData.name)) {
            errorMessages['name'] = 'Title is required'
            isValid = false;
          }
          if (!isValid) {
            setErrors({ ...errors, ...errorMessages });
          }
          return isValid;
        }

        const validationImage = (image) => {
          const extentions = ['image/jpg', 'image/jpeg', 'image/png', 'image/gif']
          if (extentions.includes(image.type)) {
            return true;
          }
          else {
            return false;
          }
        }
      /***********************************************************************************/
    /*************************  Helper functions  *******************************************/
        /***********  used for genrate a random stream Name  *************************/
          const random = () => {
            var randomString = "livestream";
            randomString = "livestream";
            var charSet = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
            for (var i = 0; i < 10; i++) {
              var randomPoz = Math.floor(Math.random() * charSet.length);
              randomString += charSet.substring(randomPoz, randomPoz + 1);
            }
            return randomString;
          };
        /****************************************************************************** */
        /***********  used for show the time in stop wotch  format *****************************/
          const formatTime = ({ timer }) => {
            const hours = Math.floor(timer / 3600);
            const minutes = Math.floor((timer % 3600) / 60);
            const seconds = timer % 60;
          
            const formattedHours = `0${hours}`.slice(-2);
            const formattedMinutes = `0${minutes}`.slice(-2);
            const formattedSeconds = `0${seconds}`.slice(-2);
          
            return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
          };
        /******************************************************************************************/
        /*******************   used for show a loader timer  *************************/
          const renderTime = ({ remainingTime }) => {
            return (
              <div className="timer">
                <div className="text">Live In</div>
                <div className="value">{remainingTime}</div>
                <div className="text">seconds</div>
              </div>
            );
          };
        /*******************************************************************************/
        /***********************  used for switch the camera /Rear/front  **************/
          const toggleCameraFacingMode = () => {
            setFacingMode((prevFacingMode) =>
              prevFacingMode === 'user' ? 'environment' : 'user'
            );
            if(streamName){
              webRTCAdaptor.current.switchVideoCameraFacingMode(streamName, facingMode)
            }
          };
        /********************************************************************************/
        /***********   function used for store and remove the thumbnail or video into google cloud and get the cloud url **/
          const handleUploadFileToServer = async (file) => {
            try {
                const formData = new FormData();
                formData.append('thumbnail', file);
                const response = await API.apiUploadFileURL('post', `/upload-live-video`, formData);
                if(response.data && response.data.success){
                  return response.data.fileUrl
                }
            } catch (error) {
              toast.error('Oops, unable to upload file');
            }
          }

          const uploadThumbnailFileToServer = async (file) => {
            try {
                const formData = new FormData();
                formData.append('thumbnail', file);
                const response = await API.apiUploadFileURL('messenger', `/get-file-url`, formData);
                if(response.data && response.data.success){
                  return response.data.fileUrl
                }
            } catch (error) {
              toast.error('Oops, unable to upload file');
            }
          }

          const handleRemoveThubnail = async() =>{
            await API.apiPostUrl('messenger', '/remove-file', { fileUrl:myStreamData.thumbnail});  // using for remove file from google cloud also
            let copyMedia = {...myStreamData} ;
            copyMedia.thumbnail = '' 
            setMyStreamData(copyMedia);
          }
        /*****************************************************************************************************************/
        /************************  used for open the golive later model ********************/
          const handleScheduleModel = () =>{
            setScheduleModel(false)
          }
        /***********************************************************************************/

        const buttonsHandling = () =>{
          if(!isEdit){
            return (
              <>
                {!isStart 
                  ? 
                  <>
                      <Button variant="secondary" className='me-2' onClick= {connectWebrtc}>
                          Go Live Now
                      </Button>
                      {!myStreamData.schedule_post &&
                        <Button variant="primary" onClick = {()=>setScheduleModel(true)}>
                          Go Live Later
                        </Button>
                      
                      }
                  </>  
                  :
                    <Button variant="secondary stopBtn" className='me-2' onClick={handleStopLiveVideo}>
                        Stop
                    </Button>
                }  
              </>
      
            )
          }else{
            if(myStreamData.schedule_post){
              return (
                <>
                   {!isStart 
                    ? <>
                        <Button variant="secondary" className='me-2' onClick= {connectWebrtc}>
                            Go Live Now
                        </Button>
                      </>  
                    :
                      <Button variant="secondary stopBtn" className='me-2' onClick={handleStopLiveVideo}>
                          Stop
                      </Button>
                  }  
                </>
              )
            }else{
              return (
                <>
                  <Button variant="secondary" className='me-2' onClick= {!isStart ? updateLiveVideo : handleStopLiveVideo}>
                    Update
                  </Button>
                </>  
              )  
            }
          }
        }

          const updateLiveVideo = () =>{
            setloading(true)
            dispatch(editLiveVideoPost(myStreamData))
          }

          const renderVideoLink = () =>{
            if(myStreamData.slug){
              const link = `${window.location.origin}/Live-Video/${myStreamData.slug}`;
              return(
                <div>
                  <p>{link}</p>  
                  <CopyToClipboard text={handleLink(link)} onCopy={()=>{handleCopy()}}>
                      <Button>Copy Link</Button>
                  </CopyToClipboard>   
                </div>
              )
            }
          }
          const handleLink = (link) =>{
            return link
          }

          const handleCopy = () => {
            toast.success('Link copy to clipboard', {position: 'top-center'});
          };


          const detectBrowser = () =>{
            if (navigator.userAgent.indexOf("Firefox") > -1) {
              setMimeType('video/webm')
            }
            if (navigator.userAgent.indexOf("Safari") > -1) {
              setMimeType('video/mp4')
            }
            if(navigator.userAgent.indexOf("Chrome") > -1){
              setMimeType('video/webm;codecs=vp8')
            }
          }

      
  /*******************   ---------------------------------------------------------------------------------------------------------------------------------------------- */

  return (
    <>
      <PageHeader />
      <div className='page--content'>
        <SidebarMenu />
        <div className='common--wrapper create--form'>
            <LoadingOverlay
              active={loading}
              spinner
              text =' Please Wait '
              // text={
              //   <CountdownCircleTimer
              //     isPlaying
              //     duration={12}
              //     colors={[['#000000']]}
              //   >
              //     {renderTime}
              //   </CountdownCircleTimer>
              // }
            >
              <Form>
              <Row className='live--frame--row'>
                      <Form.Group className='form-group col-sm-12 col-lg-6 live--frame--wrapper'>
                          <div className='live--frame' >
                              {(myStreamData.media && myStreamData.media !== '') ?
                                <>
                                  <ReactPlayer  url= {myStreamData.media} width={'100%'} height={'100%'} controls/>
                                  {/* <span className="post--media--close" onClick= {handleRemove}><IoClose/></span> */}
                                </>
                                :
                                  <video
                                    ref={videoRef} 
                                    id="localVideo"
                                    autoPlay
                                    muted
                                    width={'100%'} 
                                    height={'100%'} 
                                    style={{"transform": "scaleX(-1)"}}
                                  />
                              }
                              <span className='online--dot'><span className={ myStreamData.action === 'online' ?'dot--circle circle--green' : 'dot--circle circle--red'}></span>{myStreamData.action === 'online' ? 'Online' : 'Offline'}</span>
                          </div>
                          <div className='user--frame'>
                              <Link to='javascript:;'>
                                  <img src={(user && user.image) ? user.image : '/images/default-avtaar.png'} alt='...' />
                                  <span>{(user && user.firstName) ? user.firstName : 'N/A'}</span>
                              </Link>
                          </div>
                          {/* <div className='live--comments'>
                              <Form.Control as='textarea' rows={1} placeholder='Your Comments...'/>
                              <Button type='submit' variant='primary'><FaPaperPlane/></Button>
                          </div> */}
                          {(intialPost && myStreamData.commenting )&&
                            <>
                              <LiveComments  postId ={intialPost}/>
                            </>
                        }
                          <div>
                            {isStart &&
                              <p><strong>{formatTime({ timer })}</strong></p>
                            }  
                        </div>
                      </Form.Group>
                      <Form.Group className='form-group col-sm-12 col-lg-6'>
                        <Form.Label>Video Title</Form.Label>
                        <Form.Control type="text" className='mb-2'  placeholder='Enter video title' name="name" value = {myStreamData.name}onChange={(e) => handleOnChange(e)}/>
                        {errors.name && <small className="error error-massege text-danger">{errors.name}</small>}
                        {selectBoard()}
                        {myStreamData.thumbnail 
                        ?
                        
                          <Form.Group className='form-group col-sm-12 col-lg-12'>
                            <div className="post--thumb--upload">
                              <img src={typeof myStreamData.thumbnail === 'object' ? URL.createObjectURL(myStreamData.thumbnail) : myStreamData.thumbnail}/>
                              <span className="post--media--close" onClick= {handleRemoveThubnail} ><IoClose/></span>
                            </div>
                          </Form.Group>   
                        : thumbLoader ?
                            <Spinner animation="border"/>
                          :
                          <Form.Group className='form-group col-sm-12 thumbnail-wrap'>
                            <Form.Label>Thumbnail</Form.Label>
                            <input type='file' hidden id="upload--art" onChange={(e) => handleThumbnail(e)}/>
                            <label className='label--upload text-center' htmlFor="upload--art">Choose File ( Preferred Size 1920 X 1080 )</label>
                            {thumbnailError && <small className="error error-massege text-danger">{thumbnailError}</small>}
                          </Form.Group>
                        }  
                        <Form.Group className='form-group col-sm-12 Desc-wrap'>
                        <Form.Label>Description</Form.Label>
                        <Form.Control as='textarea' rows={5}  className='mb-2' name="description" value={myStreamData['description']} onChange={(e) => handleOnChange(e)} />
                        {errors.description && <small className="error error-massege text-danger">{errors.description}</small>}
                        </Form.Group>
                        <Form.Check type="checkbox" label="Allow Comments" checked={myStreamData.commenting} name="commenting" onChange={(e) => handleOnChange(e)}/>
                        {buttonsHandling()}
                      </Form.Group>
                      <Col sm={12} className='text-end'>
                        {renderVideoLink()}
                      </Col>
                         
                  </Row>
              </Form>
            </LoadingOverlay>  
            <Modal centered className="modal__form" show={scheduleModel} onHide={handleScheduleModel}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <div className="login--Wrapper">
                        <ScheduleLiveVideo  streamData= {myStreamData} CloseModel={()=>handleScheduleModel()}/>
                    </div>
                </Modal.Body>
            </Modal>
          
        </div>
        <Toaster/>
      </div>
    </>
  );
      
}

export default EditLiveVideo;