import React from 'react';
import { Container, Row, Col, Table, Button, Badge, ButtonGroup } from 'react-bootstrap';
import PageHeader from '../../Header/HeaderTop';
import SidebarMenu from '../../Sidebar/SidebarNav';
import 'bootstrap/dist/css/bootstrap.min.css';
import './profileShopStyle.css';
import '../../../App.css';

function ShopRefund() {

    return (
        <>
            <PageHeader />
            <div className='page--content'>
                <SidebarMenu />
                <div className='discover--module common--wrapper order--table'>
                    <Container>
                        <Row className='justify-content-center'>
                            <Col sm={12}>
                                <Table striped responsive variant="dark">
                                    <thead>
                                        <tr>
                                            <th className='text-start'>Order ID</th>
                                            <th className='text-start'>Product</th>
                                            <th>Order Date</th>
                                            <th>Order Status</th>
                                            <th>Order Total</th>
                                            <th>Refund Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className='text-start'>3297-131211</td>
                                            <td className='text-start'>
                                                <span className='pro--image'>
                                                    <img src='/images/1602696800022-IMG_2767.jpeg' alt='..' />
                                                    <strong>Healing Herbs <small>Seller: Danny Fitzpatrick</small></strong>
                                                </span>
                                            </td>
                                            <td>26 May 2023</td>
                                            <td><span className='badge bg-danger'>Declined</span></td>
                                            <td>$ 200.5</td>
                                            <td><span className='badge bg-info'>Pending</span></td>
                                         </tr>
                                         <tr>
                                            <td className='text-start'>3297-131211</td>
                                            <td className='text-start'>
                                                <span className='pro--image'>
                                                    <img src='/images/1602696800022-IMG_2767.jpeg' alt='..' />
                                                    <strong>Healing Herbs <small>Seller: Danny Fitzpatrick</small></strong>
                                                </span>
                                            </td>
                                            <td>26 May 2023</td>
                                            <td><span className='badge bg-danger'>Cancelled</span></td>
                                            <td>$ 200.5</td>
                                            <td><span className='badge bg-success'>Completed</span></td>
                                         </tr>
                                         <tr>
                                            <td className='text-start'>3297-131211</td>
                                            <td className='text-start'>
                                                <span className='pro--image'>
                                                    <img src='/images/1602696800022-IMG_2767.jpeg' alt='..' />
                                                    <strong>Healing Herbs <small>Seller: Danny Fitzpatrick</small></strong>
                                                </span>
                                            </td>
                                            <td>26 May 2023</td>
                                            <td><span className='badge bg-danger'>Declined</span></td>
                                            <td>$ 200.5</td>
                                            <td><span className='badge bg-info'>Pending</span></td>
                                         </tr>
                                         <tr>
                                            <td className='text-start'>3297-131211</td>
                                            <td className='text-start'>
                                                <span className='pro--image'>
                                                    <img src='/images/1602696800022-IMG_2767.jpeg' alt='..' />
                                                    <strong>Healing Herbs <small>Seller: Danny Fitzpatrick</small></strong>
                                                </span>
                                            </td>
                                            <td>26 May 2023</td>
                                            <td><span className='badge bg-danger'>Cancelled</span></td>
                                            <td>$ 200.5</td>
                                            <td><span className='badge bg-success'>Completed</span></td>
                                         </tr>
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    );
}

export default ShopRefund;