import React, { Component} from 'react'
import { connect } from 'react-redux';
import { Button, Container, Row, Col, Form , Spinner } from 'react-bootstrap';
import { toast, Toaster } from "react-hot-toast";
import { BsArrowLeft} from 'react-icons/bs';
import { FaExpandAlt } from 'react-icons/fa';
import PageHeader from '../Header/HeaderTop';
import SidebarMenu from '../Sidebar/SidebarNav';
import 'bootstrap/dist/css/bootstrap.min.css';
import { getUserProfile , updateUserProfile } from '../../redux/actions/user.action';
import { notValid, emailValidation } from "../../utils/validations";
import './editProfileStyle.css';
import '../../App.css';
import * as Auth from '../../helpers/auth';
import { Link} from 'react-router-dom';
import { convertToPlainText } from '../../utils/validations';
import LightboxComponent from '../LightBoxComponent';
import { VscCalendar } from "react-icons/vsc";
import { withRouter } from '../../wrapper/withRouter';


const check = ['undefined' , undefined , null , 'null' , '']


class EditProfilePage  extends Component{

    constructor(props){
        super(props)
        this.state = {
            show: false,
            profile: '',
            loader: true,
            loggedUserId: null,
            error:{},
            submitLoader:{},
            loading: false ,
            postInterest : {
                audio: false,
                link: false,
                visual: false,
                text: false,
                film: false,
            },
            interestList : [] ,
            isLightBoxOpen: false ,
            postMedia: '' ,
            hideSideBar : false , 
            
        }
    }

    componentDidMount = async () => {
        await this.getLoggedUser();
        await this.getUserProfileData();
       
    }


    getLoggedUser = async() =>{
        const userData = Auth.decodedLoggedUser();
        this.setState({
            loggedUserId: userData.aud 
        }) 
    }


    getUserProfileData = async () =>{
        try{
            if(this.state.loggedUserId !== null){
                await this.props.getUserProfile(this.state.loggedUserId);
                if(this.props.userDetails && this.props.userDetails.success){
                    this.setState({profile : this.props.userDetails.profile})
                    this.getInterests(this.props.userDetails.profile)
                    const userPostInterest  = this.props.userDetails.profile.intrests
                    this.setState({ loader: false })
                }else{
                    toast.error(this.props.error.message , {
                        position: 'top-center', 
                    });
                    this.setState({ loader: false })
                }
            }else{
                this.props.history.push('/login')
            }
        }catch(err){
            toast.error(err , {
                position: 'top-center', 
            });
            this.setState({ loader: false })
        }    
    }


    getInterests = (profile) =>{
        let copyPostInterest = this.state.postInterest
        if(profile.intrests !==null){
            var intrests = profile.intrests.split(',') ;
            this.setState({interestList:intrests})
            if(intrests && intrests.length > 0){
                intrests.forEach(item => {
                    if (item === 'audio'){
                        copyPostInterest['audio'] = true 
                    }
                    if (item === 'link'){
                        copyPostInterest['link'] = true 
                    }
                    if (item === 'visual'){
                        copyPostInterest['visual'] = true 
                    }
                    if (item === 'text'){
                        copyPostInterest['text'] = true 
                    }
                    if (item === 'film'){
                        copyPostInterest['film'] = true 
                    }
                    this.setState({postInterest:copyPostInterest})
                });
            }
        }
    }
/////////////////upload file on server ///////////////////////////////////////////
    handleUploadFile = async(file, section) =>{
        const submitLoader = {} ; 
        try{
            submitLoader[section] = true ; 
            this.setState({ submitLoader });
            const formData =  new FormData();
            formData.append(`${file.name}`, file);
            if(section === 'image'){
                formData.append(`image`, true);
            }
            if(section === 'cover'){
                formData.append(`cover`, true);
            }
            await this.props.updateUserProfile( formData );
            submitLoader[section] = false
            this.setState({ submitLoader });
            if(this.props.userUpdated && this.props.userUpdated.success){
                toast.success(this.props.userUpdated.message , {
                    position: 'top-center', 
                });
                await this.getUserProfileData()
            }else{
                toast.error(this.props.error.message , {
                    position: 'top-center', 
                });
            }
        }catch(err){
            toast.error(err , {
                position: 'top-center', 
            });
        }
    }
//////////// using for handle the image and cover photo /////////////////////////////
    handleFileChange = (e , section ) =>{
        const allImages = ['png', 'jpg', 'jpeg', 'JPG', 'JPEG', 'PNG'];
        const { files } = e.target ; 
        const userProfile = { ...this.state.profile }
        if(files && files.length < 2 ){
            const file = files[0];
            const fileName = file.name.toLowerCase();
            const fileExtension = fileName.split('.').pop()
            if (section === 'image' && allImages.includes(fileExtension)) {
                userProfile.image = URL.createObjectURL(file);
                this.setState({ profile: userProfile });
                this.handleUploadFile(file, section)         //////////  using for upload image on server //////////////
            }
            if(section === 'cover' && allImages.includes(fileExtension)){
                userProfile.cover = URL.createObjectURL(file);
                this.setState({ profile: userProfile })
                this.handleUploadFile(file, section)
            }
              
        }

    }

    handleInterest = (e) =>{
        const userProfile = {...this.state.profile}
        const {interestList}= this.state ;
        const copyPostInterest = {...this.state.postInterest}
        const {checked , name} = e.target ; 
        if(checked){
            interestList.push(name)
            copyPostInterest[name] = true
            userProfile.intrests= interestList.join(',');

        }else{
            const unCheckInterest = interestList.filter(item => item !== name);
            userProfile.intrests= unCheckInterest.join(',');
            copyPostInterest[name] = false
           this.setState({interestList:unCheckInterest})
        }
        this.setState({profile:userProfile})
        this.setState({postInterest : copyPostInterest})
    }

    handleChange = (e) =>{
        const {name , value } = e.target ;
        let userDetail = { ...this.state.profile }
        let copyError = { ...this.state.error }
        userDetail = { ...userDetail, [name]: value }
        copyError = { ...copyError , [name]: '' }
        this.setState({ profile:userDetail, error: copyError })
    }
    
     validate = () =>{
        let isValid = true
        let userNameErrorMessage = "Please provide Username";
        let email = "Please enter your email";
        let validEmail = "Please enter a valid email address";
        let errorMessages = {};
        const user = this.state.profile
        if (notValid(user.first)) {
            errorMessages['first'] = 'Please provide first Name ';
            isValid = false;
        }
        if (notValid(user.last)) {
            errorMessages['last'] = 'Please provide last Name ';
            isValid = false;
        }
        if (notValid(user.username)) {
            errorMessages['username'] = userNameErrorMessage;
            isValid = false;
        }
        if (notValid(user.email)) {
            errorMessages['email'] = email;
            isValid = false;
        }else if (!emailValidation(user.email)) {
            errorMessages['email'] = validEmail;
            isValid = false;
        }
        if (!isValid) {
            this.setState({ error: { ...this.state.error, ...errorMessages } });
        }
        return isValid;

    }

    handleUpdateUserDetail = async(e) =>{
        e.preventDefault();
        if(this.validate()){
            this.setState({loading : true})
            await this.props.updateUserProfile(this.state.profile)
            if(this.props.userUpdated && this.props.userUpdated.success){
                this.setState({loading:false})
                toast.success(this.props.userUpdated.message , {
                    position: 'top-center', 
                });
                await this.getUserProfileData()
            }else{
                toast.error(this.props.error.message , {
                    position: 'top-center', 
                });
            }
        }
    }

/////////  using for remove file ////////////////////////////////////
    handleRemoveFile = async() =>{
        const user = this.state.profile ;
        const check = ['null' , undefined , 'undefined' , null , ''] ;
        if(!check.includes(user.image)){
            user.image = ''
            this.setState({profile:user}) 
            await this.removeProfilePhoto()
        }
    }

    removeProfilePhoto = async() =>{
        const submitLoader = {} ; 
        submitLoader['removeImage'] = true ; 
        this.setState({ submitLoader });
        await this.props.updateUserProfile(this.state.profile)
        submitLoader['removeImage'] = false ; 
        this.setState({ submitLoader });
        if(this.props.userUpdated && this.props.userUpdated.success){
            this.setState({loading:false})
            toast.success(this.props.userUpdated.message , {
                position: 'top-center', 
            });
            await this.getUserProfileData()
        }else{
            toast.error(this.props.error.message , {
                position: 'top-center', 
            });
        }
    }


    handleLightBox = (media) =>{
        const data = [{src:media, alt: 'profile'}];
        this.setState({postMedia:data})
        this.setState({isLightBoxOpen:true , hideSideBar: true})
    }

    handleCloseLightBox = ()=>{
        this.setState({isLightBoxOpen:false , hideSideBar:false})
    }


    handleSchudle = () =>{
        if(!this.props.userDetails.profile.paypal_email){
            return(
                toast.error('Please update your paypal email' , {
                    position: 'top-center', 
                })
            )
        }else{
            this.props.navigate('/schedule')
        }

    }

    handleCancel = async() =>{
        await this.getUserProfileData();
    }

    render(){

        const {loader, profile , postInterest , loading , submitLoader , isLightBoxOpen , postMedia , hideSideBar , error}= this.state 
        return (
            <>

                {isLightBoxOpen && 
                    <LightboxComponent
                        isOpen={isLightBoxOpen}
                        images={postMedia}
                        onClose={() => this.handleCloseLightBox()}
                    />
                }
                <PageHeader />
                <div className='page--content'>
                    {!hideSideBar && 
                        <SidebarMenu />
                    }
                    <div className='discover--module common--wrapper Edit--Profile--form'>
                        <Container>
                            <Row className='justify-content-center pt-3'>
                                {loader 
                                    ?   <Col sm={12}>
                                            <div className='text-center spinner--loader'></div>
                                        </Col>
                                    :    
                                    <Col sm={12} lg={10}>
                                        <Link className='back--btn mb-3' to='/stream'><BsArrowLeft/> Back</Link>
                                        <h2>Profile Settings</h2>
                                        <Form onSubmit={this.handleUpdateUserDetail}>
                                            <Row>
                                                <Form.Group className='form-group col-sm-12 col-lg-12'>
                                                    <h4>Profile Picture</h4>
                                                    <div className='image--change'>
                                                    <div className='image--change--wrap'><img src={(profile && profile.image) ?  profile.image : '/images/user.png'} alt='Profile' />
                                                        {!check.includes(profile.image) &&
                                                            <span className='fullscreen--icon' onClick={()=> this.handleLightBox(profile.image)}><FaExpandAlt/></span>
                                                        } 
                                                        </div>   
                                                        <div className='button--change'>
                                                            <label htmlFor="label--image" className='btn btn-primary mb-3 mb-md-0 me-md-3'>
                                                                <span>Change Image</span>
                                                                <input type='file' hidden id='label--image' onChange={(e) => this.handleFileChange(e, 'image')}  multiple={false} accept="image/png, image/jpg, image/jpeg"/>
                                                                {submitLoader['image'] && <span><Spinner/></span>}
                                                                {submitLoader['removeImage'] && <span><Spinner/></span>}
                                                            </label>
                                                            <Button variant='secondary' onClick = {this.handleRemoveFile}>Remove Image</Button>
                                                            <p><small className='text-light'>Must be JPG, PNG, or GIF and can not exceed 10MB</small></p>
                                                        </div>
                                                    </div>
                                                </Form.Group>
                                                <Form.Group className='form-group col-sm-12 col-lg-12'>
                                                    <h4>Cover Photo</h4>
                                                    <div className='image--cover'>
                                                        <img src={(profile && profile.cover) ? profile.cover : '/images/shop-banner.png'} alt='Cover' />
                                                        <div className='button--cover'>
                                                            <label htmlFor="label--cover">
                                                                <span>Choose File (Preferred Size 1100 X 200)</span>
                                                                <input type='file' hidden id='label--cover'  onChange={(e) => this.handleFileChange(e, 'cover')}   multiple={false} accept="image/png, image/jpg, image/jpeg"/>
                                                                {submitLoader['cover'] && <span><Spinner/></span>}
                                                            </label>
                                                        </div>
                                                    </div>
                                                </Form.Group>
                                                <Col sm={12} className='text-end'>
                                                    <Button className='btn btn-primary mb-3' onClick={this.handleSchudle}>
                                                        <VscCalendar />
                                                        <span className="schudler-cal ms-2">Set Your Availability</span>
                                                    </Button>
                                                </Col>
                                                <Col sm={12}>
                                                    <h4>Personal Information</h4>
                                                </Col>
                                                <Form.Group className='form-group col-sm-12 col-lg-6'>
                                                    <Form.Label>First Name</Form.Label>
                                                    <Form.Control type="text" name = "first"  value={profile.first || '' } onChange= {this.handleChange}/>
                                                    {error.first && <small className="error error-massege text-danger">{error.first}</small>}
                                                </Form.Group>
                                                <Form.Group className='form-group col-sm-12 col-lg-6'>
                                                    <Form.Label>Last Name</Form.Label>
                                                    <Form.Control type="text" name = "last" value={profile.last || '' } onChange= {this.handleChange}/>
                                                    {error.last && <small className="error error-massege text-danger">{error.last}</small>}
                                                </Form.Group>
                                                <Form.Group className='form-group col-sm-12 col-lg-6'>
                                                    <Form.Label>Username</Form.Label>
                                                    <Form.Control type="text" name = "username" value={profile.username || '' } onChange= {this.handleChange}/>
                                                    {error.username && <small className="error error-massege text-danger">{error.username}</small>}
                                                </Form.Group>
                                                <Form.Group className='form-group col-sm-12 col-lg-6'>
                                                    <Form.Label>Email</Form.Label>
                                                    <Form.Control type="text" name = "email" value={profile.email || '' } onChange= {this.handleChange}/>
                                                    {error.email && <small className="error error-massege text-danger">{error.email}</small>}
                                                </Form.Group>
                                                <Form.Group className='form-group col-sm-12 col-lg-6'>
                                                    <Form.Label>PayPal Email</Form.Label>
                                                    <Form.Control type="text" name = "paypal_email" value={profile.paypal_email || '' } onChange= {this.handleChange}  />
                                                </Form.Group>
                                                <Form.Group className='form-group col-sm-12'>
                                                    <Form.Label>Bio</Form.Label>
                                                    <Form.Control as="textarea" rows={5} name = "bio" value = {convertToPlainText(profile.bio) || ''} onChange = {this.handleChange} />
                                                </Form.Group>
                                                <Col sm={12} className='text-end'>
                                                    <Button variant="secondary" className='me-2' type="button" onClick={this.handleCancel}>Cancel</Button>
                                                    <Button variant="primary" type="submit">{ loading ? 'Please Wait' : 'Save Changes'}</Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Col>
                                }    
                            </Row>
                            <Toaster/>
                        </Container>
                    </div>
                </div>
            </>
        );
    }    
}


const maopStateToProps = (state) => {
    return{
        userDetails : state.user.getUserDetails, 
        userUpdated : state.user. userUpdatedSuccess,
        error: state.user.userError
    }

}
const dispatchActions = {getUserProfile , updateUserProfile } ; 
export default connect(maopStateToProps, dispatchActions)(withRouter(EditProfilePage));