import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Button, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './LiveCommentsAnimation.css';
import { BsFillReplyAllFill, BsTrash } from 'react-icons/bs';
import { IoMdHeart } from "react-icons/io";
import { FaPaperPlane, FaRegHeart } from 'react-icons/fa';
import moment from 'moment';
import { getSinglePost } from '../../redux/actions/post.action';
import { getUserProfile } from '../../redux/actions/user.action';
import { decodedLoggedUser, SendComment, likeComments, deleteComment } from '../../helpers/auth';

function LiveCommentsAnimation(prop) {
    const messageRef = useRef(null);
    const [commentList, setCommentsList] = useState([]);
    const [activeReplyStates, setActiveReplyStates] = useState(null);
    const [commentError, setCommentError] = useState(false);
    const [activeId, setActiveId] = useState(null);
    const [commentText, setCommentText] = useState('');
    const [replyCommentText, setReplyCommentText] = useState('');
    const [user, setUser] = useState('');
    const [userProfile, setUserProfile] = useState('');
    const [commentLoading, setCommentLoading] = useState(false);
    const [loadComment, setLoadComment] = useState([]);
    const [replyLoading, setReplyLoading] = useState(false);
    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const userResult = useSelector(state => state.user);
    const commentResult = useSelector(state => state?.post?.singlePostSuccess);


    useEffect(() => {
        if (auth.loggedIn) {
            let token = decodedLoggedUser()
            setUser(token)
            dispatch(getUserProfile(token.aud))
        }
    }, [])


    useEffect(() => {
        if (commentResult && commentResult.success) {
            setCommentLoading(false)
            setReplyLoading(false)
            if (loadComment.length === 0) {
                setLoadComment(commentResult.commentList)
            }
            setCommentsList(commentResult.commentList)
            setActiveReplyStates(null)
        } else {
            setCommentLoading(false)
            setReplyLoading(false)
            setActiveReplyStates(null)

        }
    }, [commentResult])

    useEffect(() => {
        if (userResult.getUserDetails && userResult.getUserDetails.success) {
            setUserProfile(userResult.getUserDetails.profile)
        }
    }, [userResult])

    const handleComment = (e) => {
        const { value } = e.target;
        if (value) {
            setCommentError(false)
        }
        setCommentText(value)
    }

    const handleReplyComment = (e) => {
        const { value } = e.target;
        if (value) {
            setCommentError(false)
        }
        setReplyCommentText(value)
    }

    const handleSummitComment = (parent, comment) => {
        const check = ['undefined', undefined, '', null, 'null',]
        if (!check.includes(commentText) || !check.includes(replyCommentText)) {
            if (check.includes(parent)) {
                const data = {
                    user_id: user.aud,
                    post_id: prop.postId.id,
                    sequence_no: (commentList && commentList.length > 0) ? commentList.length : 0 + 1,
                    receiver_id: prop.postId.user_id,
                    comment_id: null,
                    comment_type: "main",
                    message: commentText || replyCommentText,
                    reply: ''
                }
                SendComment(data);
                // setActiveReplyStates(null);
                setActiveId(null)
                setCommentText('')
                setReplyCommentText('')
                setCommentLoading(true)
                // dispatch(getSinglePost(prop.postId.slug))
                if (prop.updateComment) {
                    prop.updateComment();
                }
            } else {
                const data = {
                    user_id: user.aud,
                    post_id: prop.postId.id,
                    sequence_id: (commentList && commentList.length > 0) ? commentList.length : 0 + 1,
                    receiver_id: parseInt(comment.user_id),
                    message: commentText || replyCommentText,
                    comment_id: comment.id,
                    comment_type: "reply",
                    parent_id: comment.id,
                    reply: '',
                }
                SendComment(data);
                setReplyCommentText('')
                // setActiveReplyStates(null);
                setCommentText('')
                setReplyLoading(true)
                // dispatch(getSinglePost(prop.postId.slug));
                if (prop.updateComment) {
                    prop.updateComment();
                }
                if (prop.commentBox) {
                    prop.commentBox(true);
                }
            }
        } else {
            setCommentError(true)
        }

    }

    const handleLikeComments = (comment) => {
        const data = {
            post_id: comment.post_id,
            user_id: user.aud,
            comment_id: comment.id,
            notifyUser_id: comment.user_id
        };
        if (comment.user_id !== user.aud) {
            likeComments(data)
        }
    }

    const handleDeleteComment = (comment) => {
        const data = {
            post_id: prop.postId.id,
            commentId: comment.id
        }
        deleteComment(data)
        if (prop.updateComment) {
            prop.updateComment();
        }
    }

    const handleKeyPress = (e, parent, comment) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            // If Enter is pressed without Shift key, submit the comment
            e.preventDefault(); // Prevents adding a new line in the textarea
            handleSummitComment(parent, comment);
        }
    };


    const handleShowToggle = (idx) => {
        setActiveReplyStates(activeReplyStates === idx ? null : idx);
    };


    const renderComments = () => {
        if (commentList && commentList.length > 0) {
            const reversedCommentList = commentList.slice().reverse();
            return (
                <div id='commentRepyWrapper'>
                    {reversedCommentList.map((item, idx) => {
                        if (Number(item.status) === Number(1)) {
                            return (
                                <>
                                    <div>
                                        <div className="halfDiv">
                                            <div className="userInfo">
                                                <div className="commentsTwo">
                                                    <div className='comment--img'>
                                                        <Link className='userLink' to={`/${item.user.username}`}>
                                                            <img
                                                                src={(item.user && item.user.image) ? item.user.image : '/images/default-avtaar.png'}
                                                                alt={item.user.first}
                                                                style={{ position: 'relative', top: '7px' }}
                                                                className="imgdefault"
                                                            />
                                                        </Link>
                                                        <div className="fullName">
                                                            <div className="optionIcon"><span>{moment(item.createdAt).format('LL')}</span></div>
                                                            <Link className='userLink' to={`/${item.user.username}`}>
                                                                {item.user.first}
                                                            </Link>
                                                            <p className='m-0'>{item.message}</p>
                                                        </div>
                                                    </div>
                                                    <div className="infoStyle">
                                                        <div className='comments--icon'>
                                                            <button className="delBtn" onClick={() => handleLikeComments(item)}>
                                                                <span className='me-2'>
                                                                    {(item?.Comment_Likes?.length && item?.Comment_Likes?.length > 0) ? item?.Comment_Likes?.length : null}
                                                                </span>
                                                                {item.Comment_Likes.some(like => like?.user_id === user.aud) ? (
                                                                    <IoMdHeart />) : (<FaRegHeart />
                                                                )}
                                                            </button>

                                                            {/* <button className="replyBtn" onClick={() => handleShowToggle(item.id)}>
                                                                    <BsFillReplyAllFill />
                                                                </button> */}
                                                            {Number(user.aud) == Number(item.user.id) &&
                                                                <button className="delBtn" onClick={() => handleDeleteComment(item)}><BsTrash /></button>

                                                            }
                                                            <button className="replyBtn" onClick={() => handleShowToggle(item.id)}>
                                                                <BsFillReplyAllFill />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {(activeReplyStates === item.id) && replyBox(item.id, item)}
                                    {/* {renderNestedComments(item.id , item.nested_comments)} */}
                                    {renderNestedComments(item.id, item.children , 1)}
                                </>
                            )
                            // }
                        }
                    })}
                </div>


            )
        }
    }


    const renderNestedComments = (parent, nestComm, level) => {
        if (nestComm && nestComm.length > 0) {
            const reversedCommentList = nestComm.slice().reverse();
            return (
                <>
                    {reversedCommentList.map((item, idx) => {
                        if (Number(item.status) === Number(1)) {
                            if (Number(parent) === Number(item.comment_id)) {
                                const marginLeft = level * 60;
                                return (
                                    <>
                                        <div className="replySection" style={{ marginLeft }} key={idx}>
                                            <div key={idx} className="replySectionBox">
                                                <div className="halfDiv">
                                                    <div className="userInfo">
                                                        <div className="commentsTwo">
                                                            <div className='comment--img'>
                                                                <Link className="userLink" to={`/${item.user.username}`}>
                                                                    <img
                                                                        src={(item.user && item.user.image) ? item.user.image : '/images/default-avtaar.png'}
                                                                        alt={item.user.first}
                                                                        className="imgdefault"
                                                                        style={{
                                                                            position: 'relative',
                                                                            top: '7px'
                                                                        }}
                                                                    />
                                                                </Link>
                                                                <div className="fullName">
                                                                    <div className="optionIcon"><span>{moment(item.createdAt).format('LL')}</span></div>
                                                                    <Link className="userLink" to={`/${item.user.username}`}>{item.user.first}</Link>
                                                                    <p className="m-0">{item.message}</p>
                                                                </div>
                                                            </div>
                                                            <div className="userActions">
                                                                <div className='comments--icon'>
                                                                    <button className="delBtn" onClick={() => handleLikeComments(item)}>
                                                                        <span className='me-2'>{(item?.Comment_Likes?.length && item?.Comment_Likes?.length > 0) ? item?.Comment_Likes?.length : null}</span>
                                                                        {item.Comment_Likes.some(like => like?.user_id === user.aud) ? (
                                                                            <IoMdHeart />) : (<FaRegHeart />
                                                                        )}
                                                                    </button>
                                                                    {/* <button className="replyBtn" onClick={() => handleShowToggle(item.id)}>
                                                                    <BsFillReplyAllFill/>
                                                                </button> */}
                                                                    {Number(user.aud) == Number(item.user.id) &&
                                                                        <button className="delBtn" onClick={() => handleDeleteComment(item)}>
                                                                            <BsTrash />
                                                                        </button>
                                                                    }
                                                                    {/* <button className="delBtn" onClick={()=>handleLikeComments(item)}>
                                                                    <span className='me-2'>{(item?.Comment_Likes?.length && item?.Comment_Likes?.length> 0)? item?.Comment_Likes?.length : null}</span>
                                                                    {item.Comment_Likes.some(like => like?.user_id === user.aud) ? (
                                                                        <IoMdHeart />) : (<FaRegHeart/> 
                                                                    )}
                                                                </button> */}
                                                                    <button className="replyBtn" onClick={() => handleShowToggle(item.id)}>
                                                                        <BsFillReplyAllFill />
                                                                    </button>
                                                                </div>
                                                                <div className="szh-menu-container"></div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            {(activeReplyStates === item.id) && replyBox(parent, item)}
                                        </div>
                                        
                                        {(item.children && item.children.length > 0) && renderNestedComments(item.id, item.children, level + 1)}
                                        
                                    </>
                                )
                            }
                        }
                    })}
                </>
            )
        }


    }



    const commentBox = () => {
        if (userProfile) {
            if (!commentLoading) {
                return (
                    <div className='commentInputWrapper'>
                        <form className="form">
                            <div className="userImg" style={{ margin: '0px' }}>
                                <Link to={`/${userProfile.username}`}>
                                    <img src={userProfile.image || '/images/default-avtaar.png'} alt={userProfile.first} className="imgdefault" />
                                </Link>
                            </div>
                            <div className="emoji-input">
                                <input className="postComment effect-3" placeholder="Write a comment" type="text" value={commentText} onChange={handleComment} />
                                <span class="focus-border"> &nbsp;</span>
                            </div>
                            <Button variant="primary" className="postBtn" type="button" onClick={(() => handleSummitComment())}>Post</Button>
                        </form>
                    </div>
                )
            } else {
                return (
                    <Spinner animation="border" size="sm" />
                )
            }
        }
    }


    const replyBox = (parent, comment) => {
        if (userProfile) {
            if (!replyLoading) {
                return (
                    <>
                        <div className='commentInputWrapper '>
                            <form className="form nestedform">
                                <div className='wrapMsgEmoji'>
                                    <div className="userImg" style={{ margin: '0px' }}>
                                        <Link>
                                            <img src={userProfile.image || '/images/default-avtaar.png'} alt={userProfile.first} className="imgdefault" />
                                        </Link>
                                    </div>
                                    <div className="emoji-input">
                                        <textarea rows='2' className="postComment effect-3" placeholder="Type your reply here" onChange={handleReplyComment}>{replyCommentText}</textarea>
                                        {/* <input className="postComment effect-3" placeholder="Type your reply here" type="text" value={replyCommentText} onKeyPress={(e) => handleKeyPress(e, parent, comment)} onChange={handleReplyComment} /> */}
                                        <span class="focus-border"> &nbsp;</span>
                                    </div>
                                </div>
                                <div className='btnGroup'>
                                    <Button className="cancelBtn" type="button" onClick={() => setActiveReplyStates(null)}>Cancel</Button>
                                    <Button variant="primary" className="postBtn" onClick={(() => handleSummitComment(parent, comment))}><FaPaperPlane /></Button>
                                </div>
                            </form>
                        </div>
                    </>
                )
            } else {
                return (
                    <Spinner animation="border" size="sm" />
                )
            }
        }
    }


    const handleCommentsLength = () => {
        if (commentList && commentList.length > 0) {
            const validComments = commentList.filter((item, index) => {
                if (item.status === '1') {
                    if (item.comment_id === null) {
                        return item
                    } else {
                        const reply = commentList.find((c) => Number(c.id) === Number(item.comment_id));
                        if (reply && reply.status === '1') {
                            return item
                        }
                    }
                }
            });
            if (validComments && validComments.length > 0) {
                return (
                    <>
                        <span className="comment-title">Comments</span>
                        <hr className="hr-style" />
                    </>

                )
            }
        }
    }

    return (
        <>
           
            <div id="commentsWrapper">
                {handleCommentsLength()}
                {commentBox()}
                {renderComments()}
            </div>
            
        </>
    )
}

export default LiveCommentsAnimation
