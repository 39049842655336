import { get } from 'lodash';
import API from '../../helpers/api';
import * as auth from '../../helpers/auth';
import {
    GET_SINGLE_POST_SUCCESS,
    GET_SINGLE_POST_FAILED,
    COMMON_SINGLE_POST_ERROR,
    GET_BOARD_POST_SUCCESS,
    GET_BOARD_POST_FAILED,
    GET_SINGLE_BOARD_SUCCESS,
    GET_DRAFT_POST_SUCCESS,
    GET_DRAFT_POST_FAILED,
    GET_SINGLE_BOARD_FAILED,
    CHANGE_POST_STATUS_SUCCESS,
    CHANGE_POST_STATUS_FAILED, 
    DISCOVER_POST,
    DISCOVER_POST_ERROR,
    CLEAR_POST_DATA,
    STREAM_POST_SUCCESS,
    STREAM_POST_ERROR,
    GET_NOTIFICATION,
    GET_NOTIFICATION_LIST,
    GET_NOTIFICATION_ERROR
} from "./types";


function errorRequest(err, dispatch, type) {
  let data = get(err, 'response.data', null);
  data = data || get(err, 'response');
  data = data || err;
  if (data.error) {
    dispatch({
      type: type ? type: COMMON_SINGLE_POST_ERROR,
      payload: data.error.message,
    });
  } else if (data.status === 401) {   
    auth.logout()
  } else {
    dispatch({
      type: type ? type : COMMON_SINGLE_POST_ERROR,
      payload: data.message,
    });
  }
}

const config = {
  headers: {
    "Content-Type": "multipart/form-data; charset=utf-8;"
  }
}

/**  using for get the single post (any) by slug  */

export const getSinglePost = (slug) =>{
  return async (dispatch) => {
    try{
      const response = await API.apiGetUrl('post', '/get-single-post' , `/${slug}`) ;
      if(response.data && response.data.success){
        await dispatch({ type: GET_SINGLE_POST_SUCCESS, payload: response.data});
      }else{
        await dispatch({ type: GET_SINGLE_POST_FAILED, payload: response.data});
      }
    }catch(error){
      errorRequest(error, dispatch,GET_SINGLE_POST_FAILED);
    }
  }
}
/**********     get the board posts ***********************************/

export const getBoardPostList = () =>{
  return async (dispatch) => {
    try{
      const response = await API.apiGetUrl('post', '/board-list') ;
      if(response.data && response.data.success){
        await dispatch({ type: GET_BOARD_POST_SUCCESS, payload: response.data});
      }else{
        await dispatch({ type: GET_BOARD_POST_FAILED, payload: response.data});
      }
    }catch(error){
      errorRequest(error, dispatch , GET_BOARD_POST_FAILED);
    }
  }
}


/******  using for get the realm cat post like( music , art , disclousr) *************************/

export const getSingleBoardPostList = (slug) =>{
  return async (dispatch) => {
    try{
      const response = await API.apiGetUrl('post', '/single-board-list' ,`/${slug}`) ;
      if(response.data && response.data.success){
        await dispatch({ type: GET_SINGLE_BOARD_SUCCESS, payload: response.data});
      }else{
        await dispatch({ type: GET_SINGLE_BOARD_FAILED, payload: response.data});
      }
    }catch(error){
      errorRequest(error, dispatch);
    }
  }

}


/************  using for get the draft post list  *************************************/

export const getDraftPost = (payload)=>{
  return async (dispatch) => {
    try{
      const response = await API.apiGetUrl('post', '/draft-list' , payload) ;
      if(response.data && response.data.success){
        await dispatch({ type: GET_DRAFT_POST_SUCCESS, payload: response.data});
      }else{
        await dispatch({ type: GET_DRAFT_POST_FAILED, payload: response.data});
      }
    }catch(error){
      errorRequest(error, dispatch);
    }
  } 
}

/*************   this function is using for move post to draft /  from to draft /  or detelte( archeive the post status('0')) */

export const changePostStatus = (payload)=>{
  return async (dispatch) => {
    try{
      const response = await API.apiPutUrl('post', '/change-status' , payload) ;
      if(response.data && response.data.success){
        await dispatch({ type: CHANGE_POST_STATUS_SUCCESS, payload: response.data});
      }else{
        await dispatch({ type:  CHANGE_POST_STATUS_FAILED, payload: response.data});
      }
    }catch(error){
      errorRequest(error, dispatch);
    }
  } 
}

export const getDiscoverPagePosts = (payload) =>{
  return async (dispatch) => {
    try{
      const response = await API.apiGetUrl('post', '/get-discover-posts' , payload) ;
      if(response.data && response.data.success){
        await dispatch({ type: DISCOVER_POST, payload: response.data});
      }else{
        await dispatch({ type: DISCOVER_POST_ERROR, payload: response.data});
      }
    }catch(error){
      errorRequest(error, dispatch);
    }
  }  

}

export const clearPostData = () =>{
  return async (dispatch) => {
    await dispatch({type:CLEAR_POST_DATA});
  }
}

export const getStreamPosts = (payload) =>{
  return async(dispatch)=>{
    try{
      const response = await API.apiGetUrl('post', '/get-stream-post' , payload) ;
      if(response.data && response.data.success){
        await dispatch({ type: STREAM_POST_SUCCESS, payload: response.data});
      }else{
        await dispatch({ type: STREAM_POST_ERROR, payload: response.data});
      }
    }catch(error){
      errorRequest(error, dispatch , STREAM_POST_ERROR);
    }

  }
}

export const getNotification = (payload)=>{
  return async (dispatch)=>{
    try{
      const response = await API.apiGetUrl('post','/get_notify' ,payload) ; 
      if(response.data && response.data.success){
        await dispatch({type: GET_NOTIFICATION_LIST , payload:response.data})
      }else{
        await dispatch({ type: GET_NOTIFICATION_ERROR, payload: response.data});
      }
    }catch(error){
      errorRequest(error, dispatch);
    }
  }
}



export const addVideos = async( data )=> {
  try {
    const result = await API.apiPostUrl('post' , '/upload_video', data );
    if(result && result.data.success){
      return result.data.url
    }
  }catch (error) {
    console.log("error", error )
  }
}




/************************************************************************************************************************** */

