import React, {useState , useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux";
import ReactPlayer from 'react-player';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { clearPlayer } from '../../redux/actions/audioPost.action';
import {useNavigate ,useLocation} from "react-router-dom";
import './audioplayer.css';
import {IoClose} from 'react-icons/io5';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';


function AudioMediaPlayer() {

    const [tracks , setTracks] = useState([]);
    const [post , setPost] = useState('');
    const [isPlaying, setIsPlaying] = useState(true); // Track playing state
    const [trackUrl , setTrackUrl] = useState(0);
    const [trackIndex , setTrackIndex ]= useState(0);
    const [hideClose , setHideClose] = useState(false);
    const [showList , setShowList] = useState(false)
    const audioPost= useSelector(state => state.audioPlayer.audioList);
    const dispatch = useDispatch();
    const location = useLocation();


  
    useEffect(()=>{
        if(audioPost && audioPost !== null){
            setTrackslist(audioPost)
            setTrackIndex(0)
        }
    },[audioPost])

    const showCloseButton = () =>{
        const { pathname } = location;
        const check = ['/audio/single' , '/audio/album']
        if (check.some(path => pathname.includes(path))) {
            return null
        }else{
            return(
                <Button className='closeBtn' onClick={closePlayer}><IoClose/></Button>
            )
        }
    }


    useEffect(() => {
        if (tracks.length > 0) {
            setTrackUrl(tracks[trackIndex].url);
            setIsPlaying(true)
        }
    }, [trackIndex, tracks]);

    const setTrackslist = (data) =>{
        if(data && data.media){
            var trackUrls = data.media.split(',') ;
            var trackNames = data.name.split(',') ; 
            const combinedArray = trackUrls.map((url, index) => ({
                url,
                name: trackNames[index]
            }));

            setTracks(combinedArray)
            setPost(data)
        }
    }


    const showtrackList = () =>{
        setShowList(!showList)
    }


    const shuffleTracks = () => {
        const shuffledTracks = [...tracks]; // Copy tracks array
        shuffledTracks.sort(() => Math.random() - 0.5); // Shuffle the array
        setTracks(shuffledTracks);
    };

    const nextTrack = () => {
        // if (shuffleMode) {
        //     const newIndex = Math.floor(Math.random() * tracks.length);
        //     setTrackIndex(newIndex);
        // } else {
            setTrackIndex(prevIndex => (prevIndex + 1) % tracks.length);
        // }
    };

    const closePlayer = () =>{
       dispatch(clearPlayer())
    }

    const handleTrackIndex = (ind) =>{
        setTrackIndex(ind)
        setIsPlaying(true)
    }

    const renderTrackList = () =>{
        if(tracks && tracks.length > 0){
            return (
                <div className="release__list" data-scrollbar="true" tabindex="-1" style={{overflow: 'hidden', outline: 'none'}}>
                        <div className="scroll-content">
                            <ul className="main__list main__list--playlist main__list--dashbox">
                                {tracks.map((item , ind)=>{
                                    return (
                                        <li className={trackIndex === ind ? "single-item active" : 'single-item'} onClick={()=>setTrackIndex(ind)}>
                                            <div className="btnWrapper">
                                                {trackIndex === ind 
                                                    ?
                                                        <img src="/images/pause.svg" width={18} alt="pause" />
                                                    :
                                                        <img src="/images/play.svg" width={18} alt="play" />
                                                }
                                            </div>
                                            <div className="single-item__title">
                                                <h4>{ind+1.} {item.name}</h4>
                                                {(post && post.Postmeta .length > 0)  
                                                    ? 
                                                        <span>{post.Postmeta[0].meta_value}</span> 
                                                    : null
                                                }
                                            </div>
                                        </li>

                                    )
                                })}
                            </ul>
                            
                        </div>
                        <Button className='close-list' onClick={()=>setShowList(false)}><IoClose/></Button>
                    </div> 
            )
        }

    }


    const renderPlayer = () =>{
        if(tracks && tracks.length > 0){
            return (
                <Container className="player mt-5">
                    <Row>
                        <Col className='imgwrap'>
                            <img src={post.thumbnail ? post.thumbnail: '/images/thumb-400kb.jpg'} alt='audio' className={isPlaying ? "imgaudio rotate" : 'imgaudio' }/>
                            <h4 className='nameWrap'>{tracks[trackIndex].name} </h4>
                        </Col>
                        <Col xs={5} sm={6} md={6} lg={7} className='playerwrappermain'> 
                            <div className='player-wrapper'>
                                <AudioPlayer
                                    className='react-player'
                                    src={tracks[trackIndex].url}
                                    showJumpControls={false}
                                    autoPlay={isPlaying}
                                    onEnded={nextTrack}
                                    onPlay={() => setIsPlaying(true)}
                                    onPause={() => setIsPlaying(false)}
                                    
                                />
                            </div>
                        </Col>
                        <Col className='alignRight'> 
                            <Button className='shuffle' onClick = {shuffleTracks}><img src={'/images/shuffleIcon.svg'} alt="" className="imgshuffle"/></Button>
                            <Button className='listPlayer' onClick= {showtrackList}>  <img src={'/images/playlist.svg'} alt="" className="imglistPlayer"/> </Button> 
                            {/* {hideClose && <Button className='closeBtn' onClick={closePlayer}><IoClose/></Button>} */}
                            {showCloseButton()}
                        </Col>
                    </Row>
                    {(showList)&& renderTrackList()}
                </Container>
            )
        }
    }






  return (
    <>
       {renderPlayer()}
    </>
  )
}

export default AudioMediaPlayer
