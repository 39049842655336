import React, { useEffect, useState } from "react";
import { Button, Row, Col, Form , Spinner} from 'react-bootstrap';
import { FaCogs} from 'react-icons/fa';
import {useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { EditorState, convertToRaw, getDefaultKeyBinding , ContentState} from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {IoClose} from 'react-icons/io5';
import { toast} from "react-hot-toast";
import { notValid} from "../../../utils/validations";
import ImageCropper from "./imageCropper";
import { createTextPost } from "../../../redux/actions/textPost.action";
import { validURL } from "../../../helpers/auth";



function CreateText({savedData, saveData}) {

    const intialValue = {name:'' ,   description: '', thumbnail: '',  isStream: true,  commenting: true , board_name: '',board_id: '', media:''};
    const [textFields , setTextFields] = useState(intialValue) ;
    const [boardList , setBoardList] = useState('')
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
    const [errors , setErrors] = useState('');
    const [loading , setLoading] = useState(false);
    const [draftLoading , setDraftLoading] = useState(false)
    const [ isCropperOpen, setCropperOpen ] = useState(false);
    const [ imageForCrop, setImageForCrop ] = useState(null);
    const [showEditor , setShowEditor] = useState(true);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const apiResult = useSelector(state => state.textPost);
    const getPostResult = useSelector(state => state.post);

    useEffect(() => {
        if (savedData) {
            if(savedData.description){
                const blocksFromHtml = htmlToDraft(savedData.description);
                const { contentBlocks, entityMap } = blocksFromHtml;
                const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
                const editorState = EditorState.createWithContent(contentState);
                setEditorState(editorState)
            }
            setTextFields(savedData);
        }
    }, [savedData]);


    useEffect(()=>{
        if(getPostResult && getPostResult.boardPostList){
            setBoardList(getPostResult.boardPostList.boardPosts)
        }
    },[getPostResult])


    const updateParentState = () => {
        saveData(textFields);
    };


    const addHttp = (url) => {
        if (!/^https?:\/\//i.test(url)) {
          url = 'http://' + url;
        }
        return url;
    }

    const handleChange = (e) =>{
        const { value, name } = e.target;
        let updatedValue = value;
        // If changing the 'media' field, ensure the URL starts with http/https
        if (name === 'media') {
            updatedValue = addHttp(value);
            
        }
        setTextFields({ ...textFields, [name]: updatedValue });
        setErrors({ ...errors, [name]: '' });
    }

    const handleCommenting = (e) =>{ 
        const copyTextFields = {...textFields} ;
        copyTextFields.commenting = e.target.checked
        setTextFields(copyTextFields)
    }

/*************************************   crop Image Function  ****************************************************************************/

    const handleCloseCropper = () => {
        setImageForCrop(null)
        setCropperOpen(false)
    }

    const handeSaveCroppedImage = (dataURI) => {
        const imageFile = dataURItoBlob(dataURI);
        let copyTextFields = { ...textFields }
        copyTextFields['thumbnail'] = imageFile
        setTextFields(copyTextFields)
    }

    const dataURItoBlob = (dataURI) => {
        // convert base64/URLEncoded data component to raw binary data held in a string
        var byteString;
        if (dataURI.split(',')[0].indexOf('base64') >= 0)
            byteString = atob(dataURI.split(',')[1]);
        else
            byteString = unescape(dataURI.split(',')[1]);
    
        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    
        // write the bytes of the string to a typed array
        var ia = new Uint8Array(byteString.length);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ia], {type:mimeString});
    }


    const handleOpenCropper = () => {
        setCropperOpen(true)
        let data = {
            src: URL.createObjectURL(textFields.thumbnail)
        }
        setImageForCrop(data)
    }

/*********************************************************************************************************************************** */ 


/*******************************************     upload file / remove file handle function  ***********************************************/

    const handleThumnail = async(e)=>{
        const allImages = ['png', 'jpg', 'jpeg', 'JPG', 'JPEG', 'PNG'];
        let errorMessages = {...errors};
        const { files } = e.target ; 
        const copyTextFields = {...textFields}
        if(files && files.length < 2 ){
            const file = files[0];
            const fileName = file.name.toLowerCase();
            const fileExtension = fileName.split('.').pop();
            if(allImages.includes(fileExtension)){
                copyTextFields.thumbnail = file
                setTextFields(copyTextFields)
                errorMessages['thumbnail'] = ''
                setErrors(errorMessages)
            }else{
                errorMessages['thumbnail'] = ' Please use only  png, jpg, jpeg, JPG, JPEG, PNG'
                setErrors(errorMessages)
            }    
        }

    }

   
      


    const handleRemoveFile = () =>{
        const copyTextFields = {...textFields}
        copyTextFields.thumbnail = '' ;
       setTextFields(copyTextFields)
    }

/************************************************************************************************************************************** */

/***************    using for form fields  validation  *************************************/
    const validate = () =>{
        let isValid = true ;
        let errorMessages = {};
        let desc = checkHTML(textFields.description) ? convertToPlainText(textFields.description) : textFields.description
        if (notValid(desc)) {
            errorMessages['description'] = 'Description is required'
            isValid = false;
        }
        if (notValid(textFields.name)) {
            errorMessages['name'] = 'Title is required'
            isValid = false;
        }

        if (!isValid) {
            setErrors({ ...errors, ...errorMessages });
        }
        if(textFields.media !== ''){
            if(!validURL(textFields.media)){
                errorMessages['media'] = 'Invalid Link '
                isValid = false;
            }
        }
        if (!isValid) {
            setErrors({ ...errors, ...errorMessages });
        }
        return isValid;

    }

    const checkHTML = param => {
        return new RegExp(/(\<\w*)((\s\/\>)|(.*\<\/\w*\>))/gm).test(param)
    }


    const convertToPlainText = (html) => {
        const parser = new DOMParser();
        const parsedDocument = parser.parseFromString(html, 'text/html');
        return parsedDocument.body.textContent || '';
    };
/************************************************************************************************ */

/**********************    handle description  /////   text editor  function using draft.js for text editing        ******/

    const onEditorStateChange = (editorState) => {
        const copyTextFields = {...textFields} ;
        let errorMessages = {...errors};
        const rawContentState = convertToRaw(editorState.getCurrentContent());
        const description = draftToHtml(rawContentState);
        copyTextFields.description = description
        errorMessages.description = '' ;
        setTextFields(copyTextFields)
        setErrors(errorMessages)
        setEditorState(editorState);
    };


    const myKeyBindingFn = event => {
        return getDefaultKeyBinding(event);
    }
/**************************************************************************************************************************** */


/************************  form submit function  //  publish or save post in draft depends status  *******************/
    const handleCreateTextPost = (draft) =>{
        const copyTextFields = {...textFields}
        if(validate()){
            if(draft){
                copyTextFields.status = '2'
                setLoading(false)
                setDraftLoading(true)
            }else{
                setLoading(true)
                setDraftLoading(false)
            }
            const formData = new FormData();
            for (const [key, value] of Object.entries(copyTextFields)) {
                formData.append(key, value)
            }  
            dispatch(createTextPost(formData)) ;
        }
    }
/**************************************************************************************************** */

    useEffect(()=>{
        if(apiResult && apiResult.createTextSuccess){
            setLoading(false)
            setTextFields(intialValue)
            navigate('/')
        }
        if(apiResult && apiResult.textPostError){
            setLoading(false)
            toast.error(apiResult.textPostError, {
                position: 'top-center', 
            });
            setTextFields(intialValue)
        }

    },[apiResult])


    const selectBoard = () => {
        if(boardList && boardList.length > 0){
            return (
                <Form.Group className='form-group col-sm-12'>
                    <Form.Label>Realm</Form.Label>
                    <Form.Control value={textFields.board_id || '' } onChange= {handleBoard} as="select">
                        <option selected>Select</option>
                        {boardList.map((item , idx)=>{
                            return (
                                <option key={idx} value={item.id}>{item.name}</option>
                            )
                        })}
                    </Form.Control>
                </Form.Group>

            )
        }
    }


    const handleBoard = (e)=>{
        let copyTextFields = {...textFields};
        copyTextFields.board_id = e.target.value
        setTextFields(copyTextFields)

    }


    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
    });

    
    return (
        <div className='create--form'>
            <Form  onBlur={updateParentState}>
                <Row>
                    <Form.Group className='form-group col-sm-12'>
                        <Form.Label>Title</Form.Label>
                        <Form.Control type="text" name= 'name'  value= {textFields.name} onChange={handleChange} required />
                        {errors.name && <small className="error error-massege text-danger">{errors.name}</small>}
                    </Form.Group>
                    <Form.Group className='form-group col-sm-12'>
                        <span className='editor--tool' onClick = {()=>setShowEditor(!showEditor)}><FaCogs /></span>
                    </Form.Group>
                    <Form.Group className='form-group col-sm-12 text-title-post'>
                    <Form.Label>Post</Form.Label>
                        <Editor
                            editorState={editorState}
                            wrapperClassName="demo-wrapper editor-w"
                            editorClassName="demo-editor input resizable-editor"
                            onEditorStateChange={onEditorStateChange}
                            keyBindingFn={myKeyBindingFn}
                            hashtag={{
                                separator: ' ',
                                trigger: '#',
                            }}
                            toolbarHidden={showEditor}  
                            config={{ link: { trailingWhitespace: true } }}
                            // className="resizable-editor"
                        />
                        {errors.description && <small className="error error-massege text-danger">{errors.description}</small>}
                        
                    </Form.Group>
                    
                    {textFields.thumbnail ?
                        <>
                            <Form.Group className='form-group col-sm-12'>
                                <div className="post--thumb--upload">
                                    <img src={typeof textFields.thumbnail === 'object' ? URL.createObjectURL(textFields.thumbnail) : textFields.thumbnail}/>
                                    <span className="post--media--edit" onClick={() => handleOpenCropper()}>Edit</span>
                                    <span className="post--media--close" onClick={handleRemoveFile}><IoClose/></span>
                                </div>
                            </Form.Group>
                        </>
                        :
                        <Form.Group className='form-group col-sm-12'>
                            <Form.Label>Add Thumbnail</Form.Label>
                            <input type='file' hidden id="upload--header" onChange ={handleThumnail} />
                            <label className='label--upload' htmlFor="upload--header">Choose File</label>
                            {errors.thumbnail && <small className="error error-massege text-danger">{errors.thumbnail}</small>}
                        </Form.Group>
                    }
                    <Form.Group className='form-group col-sm-12'>
                        <Form.Label>Link</Form.Label>
                        <Form.Control type="text" name='media' value={textFields.media} onChange= {handleChange}/>
                        {errors.media && <small className="error error-massege text-danger">{errors.media}</small>}
                    </Form.Group>
                    {selectBoard()}
                    <Form.Group className='form-group col-sm-12'>
                        <Form.Check type="checkbox" label="Allow Comments"  id='commentCheckbox' checked={textFields.commenting} onChange= {handleCommenting}/>
                        <Form.Label htmlFor="commentCheckbox" onClick={handleCommenting}/>
                    </Form.Group>
                    <Col sm={12} className='text-end'>
                        <Button variant="secondary"  className='me-2' disabled = {loading || draftLoading} onClick={()=>handleCreateTextPost(true)}>
                            {draftLoading ? <> <Spinner animation="border" size="sm"/> Please wait </> : 'Save to Draft'}
                        </Button>
                        <Button variant="primary"  disabled = {loading || draftLoading} onClick={()=>handleCreateTextPost(false)}>
                            {loading ? <> <Spinner animation="border" size="sm"/> Please wait </> : 'Publish Now'}
                        </Button>
                    </Col>
                </Row>
            </Form>
            {isCropperOpen && 
                <ImageCropper
                    isOpen={isCropperOpen}
                    src={imageForCrop.src}
                    onClose={() => handleCloseCropper()}
                    result={(val) => handeSaveCroppedImage(val)}
                />
            }
           

        </div>
    );
}

export default CreateText;