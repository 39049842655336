import React, { useState , useEffect} from 'react';
import { useSelector, useDispatch} from "react-redux";
import { Link , useNavigate} from 'react-router-dom';
import { Row, Col, Button, Container } from 'react-bootstrap';
import { BsArrowLeft } from 'react-icons/bs';
import { TiTimes } from 'react-icons/ti';
import { decodedLoggedUser , logout , getUserNotifications , removeAllNotifications , removeNotification} from '../../helpers/auth';
import { getUserProfile } from '../../redux/actions/user.action';
import { getCartItems } from '../../redux/actions/shoppingCart.action';
import { getNotification } from '../../redux/actions/post.action';
import PageHeader from '../Header/HeaderTop';
import SidebarMenu from '../Sidebar/SidebarNav';
import 'bootstrap/dist/css/bootstrap.min.css';
import './notificationStyle.css';
import moment from 'moment';
 
function NotificationMain() {

    const [isActive, setIsActive] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userId , setUserId] = useState('') ;
    const [notification , setNotification] = useState('')
    const [userProfile , setUserProfile] = useState('') ; 
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const userResult = useSelector(state => state.user);
    const cartResult = useSelector(state =>state.cart);
    const postResult = useSelector(state=>state.post);
   

    useEffect(() => {
        if (auth.loggedIn) {
            setIsLoggedIn(true)
            let token = decodedLoggedUser()
            setUserId(token.aud)
            const data ={userId:token.aud}
            // getUserNotifications(data)
            dispatch(getNotification(data))
        }
    }, [])


    useEffect(()=>{
        if(postResult && postResult.notificationList){
            if(postResult.notificationList && postResult.notificationList.notificationList){
                setNotification(postResult.notificationList.notificationList)
            }
        }
    },[postResult])

    const handleClick = event => {
        setIsActive(current => !current);
    };


   

    // const renderNewNotify = () =>{
    //     if(notification && notification.length > 0){
    //             const groupedNotifications = {};
    //             let day = ''
    //             notification.forEach((item , idx) => {
    //                 const { Post } = item;
    //                 const {comment} = item ;
    //                 const{Like} = item ; 
    //                 let url = '#';
    //                 let message = '';
    //                 let messagePost ='';
    //                 let userDetail ='';
    //                 var startOfToday = moment().startOf('day');
    //                 var startOfDate = moment(item.createdAt).startOf('day');
    //                 var daysDiff = startOfDate.diff(startOfToday, 'days');
    //                 if(daysDiff === 0){
    //                     day = 'Taday'
    //                 }else{
    //                     if(daysDiff === -1 ){
    //                         day = 'Yesterday'
    //                     }else{
    //                         day = 'Previous'
    //                     }
    //                 }
    //                 if (!groupedNotifications[day]) {
    //                     groupedNotifications[day] = [];
    //                 }
                       
    //                 if(Post){
    //                     if (Post.PostCategory.slug === 'audio') {
    //                         if (Post.Postmeta.length) {
    //                             url = `/audio/album/${Post.slug}`;
    //                         }
    //                         else {
    //                             url = `/audio/single/${Post.slug}`;
    //                         }
    //                     }
    //                     else if (Post.PostCategory.slug === 'video' || Post.PostCategory.slug === 'film') {
    //                         url = `/video/${Post.slug}`;
    //                     }
    //                     else {
    //                         url = `/${Post.PostCategory.slug}/${Post.slug}`
    //                     }
    //                     if (item.type === 'LIKE' && Like !==null) {
    //                         message = "your post"
    //                         messagePost ="liked"
    //                         userDetail = Like.user
    //                     }
    //                     else if (item.type === "COMMENTLIKE") {
    //                         message = ""
    //                         messagePost ="liked Your Comment "
    //                         userDetail = comment.Comment_Likes[idx].user
    //                     } else {
    //                         if(comment !==null){
    //                             message = "your post"
    //                             messagePost ="commented on "
    //                             userDetail = comment.user
    //                         }

    //                     }
    //                     groupedNotifications[day].push({
    //                         message,
    //                         messagePost,
    //                         user: userDetail,
    //                         createdAt: item.createdAt,
    //                         url,
    //                         id:item.id
    //                     });
    //                 }
    //             })
    //             const cols = Object.keys(groupedNotifications).map((day) => {
    //                 const notifications = groupedNotifications[day];
    //                 return (
    //                     <React.Fragment key={day}>
    //                     <h4>{(notifications && notifications.length > 0) ? day : ''}</h4>
    //                     {notifications.map((item, idx) => {
    //                         if(Number(item.user.id) !== Number(userId)){
    //                             return (
    //                                 <div className='single--notif' key ={idx}>
    //                                     <span className='noti--image'>
    //                                         <Link to={`/profile/${item.user.username}`} state={{user:item.user.id}}>
    //                                             <img src={item.user.image || '/images/user.png'} alt='Profile image' />
    //                                         </Link>
    //                                     </span>
    //                                     <p>
    //                                         <Link to={`/profile/${item.user.username}`} state={{user:item.user.id}}>{item.user.first} {item.user.last}  {item.messagePost}  </Link> <Link to={item.url}>{item.message}</Link>
    //                                         <span>{moment(item.createdAt).fromNow()}</span>
    //                                     </p>
    //                                     <Link to={item.url}>
    //                                         <Button variant='primary'>View Post</Button>
    //                                     </Link>
    //                                     <TiTimes onClick = {()=>handleRemoveNotication(item)}/>
    //                                 </div>
    //                             )
    //                         }        
    //                     })}
    //                     </React.Fragment>
    //                 );
    //             });

    //             return cols ;
    //     }


    // }

    const consolidateCommentLikes = (notifications) => {
        if(notifications && notifications.length > 0){
            const consolidated = [];
            notifications.forEach((item,idx) => {
                if (item.type === "COMMENTLIKE" && item.comment && item.comment.Comment_Likes && item.Post) {
                    item.comment.Comment_Likes.forEach((like) => {
                        consolidated.push({ likes:like, Post: item.Post, id: item.id, like_id: like.id });
                    });
                }
            });
        
            if(consolidated && consolidated.length > 0){
                const uniqueConsolidated = consolidated.filter((v, i, a) => a.findIndex(t => t.like_id === v.like_id) === i);
                return uniqueConsolidated;
            }
        }
    };


    const renderNonCommentLikesNotifications = (notifications) => {
        if(notifications && notifications.length > 0){
            return notifications.map((item, idx) => {
                const { Post } = item;
                const{Like} = item ; 
                const {comment} = item ;
                let url = '#';
                let message = '';
                let messagePost ='';
                let userDetail ='';
        
                if (Post) {
                    if (Post.PostCategory.slug === 'audio') {
                        if (Post.Postmeta.length) {
                            url = `/audio/album/${Post.slug}`;
                        }
                        else {
                            url = `/audio/single/${Post.slug}`;
                        }
                    }
                    else if (Post.PostCategory.slug === 'video' || Post.PostCategory.slug === 'film') {
                        url = `/video/${Post.slug}`;
                    }
                    else {
                        url = `/${Post.PostCategory.slug}/${Post.slug}`
                    }
                    if (item.type === 'LIKE' && Like !== null) {
                        message = "your post"
                        messagePost ="liked"
                        userDetail = Like.user || ''
                    }
                    if(comment !== null){
                        message = "your post"
                        messagePost ="commented on "
                        userDetail = comment.user || ''
                    }
                } 
                return (
                    <div className='single--notif' key ={idx}>
                        <span className='noti--image'>
                            <Link to={`/${userDetail.username}`}>
                                <img src={userDetail.image || '/images/user.png'} alt='Profile image' />
                            </Link>
                        </span>
                        <p>
                            <Link to={`/${userDetail.username}`}>{userDetail.first} {userDetail.last}  {messagePost}  </Link> <Link to={url}>{message}</Link>
                            <span>{moment(item.createdAt).fromNow()}</span>
                        </p>
                        <Link to={url}>
                            <Button variant='primary'>View Post</Button>
                        </Link>
                        <TiTimes onClick = {()=>handleRemoveNotication(item)}/>
                    </div>
                )
            });
        }else{
            return []
        }
    };

    const renderCommentLikesNotifications = (notifications) => {
        if(notifications && notifications.length > 0){
            return notifications.map((item, idx) => {

                const { Post } = item;
                const{likes} = item ; 
                let url = '#';
                let message = '';
                let userDetail =likes.user;
                let messagePost = "liked your comment";
            
                if (Post) {
                    if (Post.PostCategory.slug === 'audio') {
                        if (Post.Postmeta.length) {
                            url = `/audio/album/${Post.slug}`;
                        } else {
                            url = `/audio/single/${Post.slug}`;
                        }
                    } else if (Post.PostCategory.slug === 'video' || Post.PostCategory.slug === 'film') {
                        url = `/video/${Post.slug}`;
                    } else {
                        url = `/${Post.PostCategory.slug}/${Post.slug}`;
                    }
                }
        
                return (
                    <div className='single--notif' key ={idx}>
                        <span className='noti--image'>
                            <Link to={`/${userDetail.username}`} state={{user:userDetail.id}}>
                                <img src={userDetail.image || '/images/user.png'} alt='Profile image' />
                            </Link>
                        </span>
                        <p>
                            <Link to={`/${userDetail.username}`} state={{user:userDetail.id}}>{userDetail.first} {userDetail.last}  {messagePost}  </Link> <Link to={url}>{message}</Link>
                            <span>{moment(item.createdAt).fromNow()}</span>
                        </p>
                        <Link to={url}>
                            <Button variant='primary'>View Post</Button>
                        </Link>
                        <TiTimes onClick = {()=>handleRemoveNotication(item)}/>
                    </div>
                );
            });
        }else{
            return []
        }
    };
    
    

    const renderNewNotify = () =>{
        if (!notification || notification.length === 0) {
            return null;
        }
        const nonCommentLikes = notification.filter((item) => item.type !== "COMMENTLIKE");
        const commentLikes = consolidateCommentLikes(notification.filter((item) => item.type === "COMMENTLIKE"));
        const renderedNonCommentLikes = renderNonCommentLikesNotifications(nonCommentLikes);
        const renderedCommentLikes = renderCommentLikesNotifications(commentLikes);
        const combinedNotifications = [...renderedNonCommentLikes , ...renderedCommentLikes];

        return (
            <>
                {combinedNotifications}
            </>
        );

    }


    const handleRemoveAllNotification = async() => {
        if(notification && notification.length > 0){
            let ids = [];
            notification.forEach((date) => {
                // date.notifications.forEach((notify) => {
                    ids.push(date.id)
                // })
            })
            const data = {id:ids}
            removeAllNotifications(data)
            const dataTwo ={userId:userId}
            // getUserNotifications(data)
            dispatch(getNotification(dataTwo))
        }
    }

    const handleRemoveNotication = (item) =>{
        const data = {id:item.id}
        removeNotification(data)
        const dataTwo ={userId:userId}
        dispatch(getNotification(dataTwo))
    }

    return (
        <>
            <PageHeader />
            <div className='page--content'>
                <SidebarMenu />
                <div className='discover--module common--wrapper notify--main'>
                    <Container>
                        <Row className='align-items-center'>
                            <Col sm={12} lg={6}>
                                <Link className='back--btn' to='/discover'><BsArrowLeft /> Back</Link>
                            </Col>
                            <Col sm={12} lg={6} className='text-end'>
                                <Button variant='secondary' onClick = {handleRemoveAllNotification}>Clear All</Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} className='mt-4'>
                                <div className='noti--cell'>
                                    {renderNewNotify()}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    );
}

export default NotificationMain;