
import React, { useState, useEffect } from "react";
import { Col, Container, Row, Form, ListGroup, Button , Modal} from "react-bootstrap";
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from "react-redux";
import Calendar from 'react-calendar';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-calendar/dist/Calendar.css';
import { getSchedules,setSchedules , bookSchedule} from "../../redux/actions/schedule.action";
import * as Auth from '../../helpers/auth';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import timezone from '../../utils/timezone';
import { IoMdTime } from "react-icons/io";
import moment, { duration } from 'moment-timezone';
import BookCheckout from "./BookCheckout";


function BookingCalendar(props) {
    const { id } = useParams();
    const dispatch = useDispatch();
    const [events, setEvents] = useState([]);
    const [timeSlots, setTimeSlots] = useState([]);
    const [loader, setLoader] = useState(false);
    const [ showError , setShowError] = useState(false);
    const [selectedDate, setSelectedDate] = useState('');
    const [isActive , setIsActive] = useState(false);
    const [selectedSlot, setSelectedSlot] = useState(null);
    const [isDisable , setIsDisable] = useState(false)
    const [userId , setUserId]= useState('');
    const [payloadCheckout , setPayloadCheckout] = useState({})
    const [showMeetingCheckout , setShowMeetingCheckout] = useState(false);
    const [selectedTimeZone, setSelectedTimeZone] = useState(moment.tz.guess()); // Default to user's local time zone
    const [showModal, setShowModal] = useState(false);
    const [bookingDetails, setBookingDetails] = useState(null);
    const [ bookingLoader, setBookingLoader] = useState(false);
    const timeZones = moment.tz.names();
    const minimumDate = new Date();
    const navigate = useNavigate();
    


    const scheduleState = useSelector(state => state.schedule);



    const getLoggedUser = async () => {
        const userData = Auth.decodedLoggedUser();
        setUserId(Number(userData.aud))
        // setUserName(userData.firstName)
    }

    const resetStates = () => {
        setSelectedDate('');
        setSelectedSlot(null);
        setTimeSlots([]);
        setIsActive(false);
        setIsDisable(false);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    // Fetch schedules and update state
    useEffect(() => {
        handleGetSchedules();
        getLoggedUser();
    }, []);


    useEffect(() => {
        if (scheduleState.error) {
            toast.error(scheduleState.error.message);
            setLoader(false);
            setIsDisable(false)
            setBookingLoader(false)
        }
        if (!scheduleState.error) {
            if (scheduleState.schedules && scheduleState.schedules.success) {
                if (scheduleState.schedules.schedules && scheduleState.schedules.schedules.length > 0) {
                    const filteredSchedules = scheduleState.schedules.schedules.filter(schedule => schedule.booked_scheduler === null);
                    const convertedSchedules = filteredSchedules.map(schedule => ({
                        ...schedule,
                        start_time: new Date(schedule.event_start_time),
                        end_time: new Date(schedule.event_end_time),
                        timezone:schedule.timezone ,
                        meetingDuration: schedule.meeting_duration,
                        schedule_id:schedule.schedule_id,
                    }));
                    setEvents(convertedSchedules);
                }else{
                    setShowError(true)
                }
            }
        }
     
        if(scheduleState.scheduleBooked && scheduleState.scheduleBooked.success){
            toast.success('Event Booked');
            setIsDisable(false)
            setBookingLoader(false);
            navigate('/meetings')
        }
        
    },[scheduleState]);

    useEffect(() => {
        // if (selectedDate) {
            handleAvailableTimeSlots(selectedDate);
        // }
    }, [selectedDate, selectedTimeZone]);  // Recompute when selected date or time zone changes

    const handleGetSchedules = () => {
        const data = { userId: Number(id) , eventId: props.event.id};
        dispatch(getSchedules(data))
       
    };

    const convertToTimeZone = (time, userTimeZone, eventTimeZone) => {
        // Safely extract the time zone region from the input strings
        const userTimeZoneRegion = userTimeZone.includes(') ') ? userTimeZone.split(') ')[1] : userTimeZone;
        const eventTimeZoneRegion = eventTimeZone.includes(') ') ? eventTimeZone.split(') ')[1] : eventTimeZone;
        // Convert the time from the event's time zone to the user's time zone
        const convertedTime = moment.tz(time, eventTimeZoneRegion).tz(userTimeZoneRegion).format('YYYY-MM-DD HH:mm');
        return convertedTime;
    };
    

    // Handle when the user clicks on a date in the calendar
    const handleDayClick = (date) => {
        setSelectedDate(date);
        handleAvailableTimeSlots(date);
        if(date){
            setIsActive(true);
        }
    };

    // Handle available time slots based on selected date and time zone
    const handleAvailableTimeSlots = (date) => {
        const formattedDate = moment(date).format('YYYY-MM-DD');
        const filteredEvents = events.filter(event => moment(event.event_start_time).isSame(formattedDate, 'day'));
        const availableSlots = filteredEvents.map(event => {
            return {
                start: convertToTimeZone(event.event_start_time, selectedTimeZone , event.timezone),
                end: convertToTimeZone(event.event_end_time, selectedTimeZone , event.timezone),
                timezone:event.timezone ,
                meetingDuration: event.meetingDuration ,
                schedule_id:event.schedule_id
            };
        });
        setTimeSlots(availableSlots);
    };

    const showTimeList = () => {
        return (
          <div className={isActive ? 'book--time--list show--book--time' : 'book--time--list'}>
            <h5>{moment(selectedDate).format('ll')}</h5>
            <ListGroup>
                {timeSlots.map((slot, index) => (
                    <ListGroup.Item key={index}>
                    <input
                        type="radio"
                        id={`slot${index}`}
                        name="radio-group"
                        value={index}
                        checked={selectedSlot === index}
                        onChange={() => handleSlotSelect(index)}
                    />
                    <label htmlFor={`slot${index}`}>
                        {/* {`${slot.start} to ${slot.end}`} */}
                        {`${moment(slot.start).format('h:mm A')}`}
                    </label>
                    <Button variant="primary"  disabled={isDisable} onClick={() => handleBookSlot()}> {bookingLoader ? 'Please Wait' : 'Book'}</Button>
                    </ListGroup.Item>
                ))}
            </ListGroup>
          </div>
        );
    };

    // Handle time zone change
    const handleTimeZoneChange = (e) => {
        setSelectedTimeZone(e.target.value);
    };


    const getTimeZoneOffset = (zone) => {
        const currentTime = moment();
        const offset = currentTime.tz(zone).format('Z'); // Get UTC offset for the timezone
        return `(UTC${offset}) ${zone}`;
    };


    const isAvailable = ({ date }) => {
        if (!events || events.length === 0) {
            return true;
        }
        if (events && events.length > 0) {
            const enabledDates = events.map(entry => moment(entry.start_time).startOf('day').format('YYYY-MM-DD'));
            const formattedDate = moment(date).startOf('day').format('YYYY-MM-DD');
            return !enabledDates.includes(formattedDate);
        }
    }

    const handleBookSlot = () =>{
        const bookerTimeZoneData = timeSlots[selectedSlot] ;
        const payload = {slotDetail:bookerTimeZoneData , bookerTimeZone:selectedTimeZone}
        const data = {
            user_id: userId,
            payload,
            schedule_id:bookerTimeZoneData.schedule_id,
            timezone:selectedTimeZone,
            duration:props.event.duration,
            amount: props.event.amount
        }
        if(props.event.amount === '0'){
            dispatch(bookSchedule(data));
            setBookingLoader(true)
            setIsDisable(true)
        }else{
            setPayloadCheckout(data)
            setShowMeetingCheckout(true)
        }
    }
    

    const handleSlotSelect = (index) => {
        setSelectedSlot(index);
    };


   

    const handleBackButton = () =>{
        dispatch(setSchedules());
        props.hideAvailability();
    }

    return (
        <div className="main-wraper-service-book pt-5">
            <Container>
                {!showMeetingCheckout &&
                    <Row className="justify-content-center">
                        <Col lg={12}>
                            <Button variant="primary" onClick={handleBackButton}>Back</Button>
                            <div className="booking--date">
                                <div className="meeting--time">
                                <h3>{props.event.name}</h3>
                                    <h3> <IoMdTime /> {props.event.duration} Minute Meeting</h3>
                                    <p> Meeting Charges : {props.event.amount === '0' ? 'Free' : `${props.event.amount} USD`} </p>
                                    {props.creater && props.creater.first && <p> Creator : {`${props.creater.first} ${props.creater.last}`}</p>}
                                </div>
                                <div className="book--calender">
                                    <p className="mb-1">Select Date To Book Appointment</p>
                                    <Calendar 
                                        onChange={handleDayClick} 
                                        minDate={minimumDate} 
                                        tileDisabled={isAvailable}
                                        next2Label={null}  // Hides the forward year button
                                        prev2Label={null}  // Hides the backward year button
                                    />
                                    <Form.Group className="mb-0 mt-3 form-group">
                                        <label>Time Zone</label>
                                        <Form.Select onChange={handleTimeZoneChange} value={selectedTimeZone}>
                                            <option value={moment.tz.guess()}>{moment.tz.guess()}</option>
                                            {
                                                timeZones.map((tz, index) => (
                                                <option key={index} value={tz.Description}>
                                                    {getTimeZoneOffset(tz)}
                                                </option>
                                                ))
                                            }
                                        </Form.Select>
                                    </Form.Group>
                                    
                                </div>
                                <div className="time-slot-list">
                                    {showTimeList()}
                                </div>
                            </div>
                        </Col>
                    </Row>
                }
                {showMeetingCheckout && <BookCheckout  meetingData= {payloadCheckout} eventDetail={props.event} back={()=>setShowMeetingCheckout(false)}/>}
            </Container>
            
           
        </div>
    );
}

export default BookingCalendar;