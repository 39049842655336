import { 
    CREATE_VISUAL_SUCCESS,
    CREATE_VISUAL_FAILED,
    GET_VISUAL_SUCCESS,
    GET_VISUAL_FAILED,
    VISUAL_COMMON_ERROR,
    EDIT_VISUAL_POST_SUCCESS,
    EDIT_VISUAL_POST_FAILED,
    LIKE_POST,
    CLEAR_VISUAL_CREATE,
    CLEAR_POSTS
} from "../actions/types";

const initialState = {
    createVisualSuccess: null,
    visualPostError: null,
    visualPostList: null
    
};

export default (state = initialState, action) => {
    switch (action.type) {
        case CREATE_VISUAL_SUCCESS:
            return{
                createVisualSuccess:action.payload,
            }
        case CREATE_VISUAL_FAILED:
            return{
                visualPostError:action.payload.message,
            }    
        case  GET_VISUAL_SUCCESS:
            if(state.visualPostList !==null){
                if(action.payload.pageNumber !== '1'){
                    const copyPost = state.visualPostList
                    let  post = state.visualPostList.visualPostList.visualPost
                    let newPost = action.payload.visualPostList.visualPost
                    var mergePost = [...post , ...newPost]
                    mergePost = mergePost.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i)
                    copyPost.visualPostList.visualPost = mergePost
                    return{
                        ...state,
                        visualPostList: copyPost
                    }    
                }else{
                    return{
                        visualPostList: action.payload
                    }   
                }    
            }else{
                return{
                    ...state,
                    visualPostList: action.payload
                }    
            }    
        case GET_VISUAL_FAILED:
            return{
                visualPostError:action.payload,
            }    
        case EDIT_VISUAL_POST_SUCCESS:
            return{
                editVisualPost:action.payload,
            }        
        case EDIT_VISUAL_POST_FAILED:
            return{
                visualPostError:action.payload,
            }            
        case VISUAL_COMMON_ERROR:
            return{
                visualPostError:action.payload,
            }      
        case LIKE_POST:
            if(state.visualPostList){
                const postLikes = state.visualPostList?.visualPostList
                const likesIndex = postLikes.visualPost.findIndex(post => post.id === action.payload.post.postId)
                if(likesIndex !== -1){
                    var copyVisualPost = state.visualPostList
                    copyVisualPost.visualPostList.visualPost[likesIndex].Likes = action.payload.post.likes
                    const updateLikes = {...copyVisualPost}
                    return{
                        // ...state,
                        visualPostList: updateLikes,
                    }       
                }    
            }
        case CLEAR_VISUAL_CREATE:{
            return {
                createVisualSuccess:null
            }
        }
        case CLEAR_POSTS: {
            return{
                createVisualSuccess: null,
            }
        }
        default: return state;               
    }
}


