import React, { Component } from 'react';
import { Row, Col, Dropdown, ListGroup, Button, Form } from 'react-bootstrap';
import { BiTrash, BiPlus } from 'react-icons/bi';
import { BsHeart, BsReply, BsTrash, BsCardList, BsGridFill } from 'react-icons/bs';
import { FaEllipsisV, FaPencilAlt, FaEye, FaPaperPlane, FaRegComment } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getMyShops, deleteShop } from '../../../redux/actions/shop.action';
import { getUserProductsList , ChangeProductStatus} from '../../../redux/actions/product.action';
import { toast } from "react-hot-toast";
import { decodedLoggedUser } from '../../../helpers/auth';
import Swal from 'sweetalert2';

class ProfileShopTab extends Component {
    constructor(props){
        super(props);
        this.state = {
            isActive: localStorage.getItem('shopGrid') || false,
            myShops:[],
            userId : this.props.user.id ,
            logggedUser : false , 
            products : [],
            loading : false
        }
    }

    /** ---- Mounting ----*/
    async componentDidMount(){
        if (this.props.auth.loggedIn) {
            let token = decodedLoggedUser()
            this.setState({logggedUser:token.aud})
        }
        this.setState({loading:true})
        await this.getLoggedUserShopList();
        await this.getProductsList();
    }

    handleToggle = () => {
        localStorage.setItem('shopGrid', !this.state.isActive);
        this.setState({isActive: !this.state.isActive});
    };

    /** ---- Fetch Logged In Users Shop List ---- */
    getLoggedUserShopList = async () => {
        const data = {userId:this.state.userId}
        await this.props.getMyShops(data);
        if(this.props.shop && this.props.shop.success){
            this.setState({
                myShops: this.props.shop.myShopsList
            })
        }
    }


    getProductsList = async() =>{
        await this.props.getUserProductsList(this.state.userId)
        if(this.props.product_List && this.props.product_List.success){
            if(this.props.product_List.products){
                this.setState({products:this.props.product_List.products , loading:false})
            }
        }else{
            this.setState({loading:false})
        }
    }

    handleShopDelete = async (shopId) => {
       await this.props.deleteShop({ shopId });
       if(this.props.shopDeleteError){
           toast.error(this.props.shopDeleteError , {
               position: 'top-center', 
            });
        }
        await this.getLoggedUserShopList();
    }


    handleStatusChange = ( id , action) =>{
        if(action === 'delete'){
            const data = {productId:id , status:true , hidden:false , show:false}
            Swal.fire({
                title: 'Delete a product',
                icon: 'warning',
                text: 'By doing this, the product will be removed from the orders or user shopping cart of this product.',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
            }).then((result) => {
                    if (result.isConfirmed) {
                        this.handleYesClick(data)
                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                    this.handleNoClick();
                    }
              });
           
        }
        if(action === 'hide'){
            const data = {productId:id , status:false , hidden:true , show:false}
            Swal.fire({
                title: 'Hide a product',
                icon: 'warning',
                text:'By doing this, the product will be hide from the orders or user shopping cart of this product.',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
            }).then((result) => {
                    if (result.isConfirmed) {
                        this.handleYesClick(data)
                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                        this.handleNoClick();
                    }
              });
        }
    }

    handleNoClick = () => {
        Swal.close();
    };
    
    handleYesClick = async (data) => {
        await this.props.ChangeProductStatus(data)
        if(this.props.productStatus && this.props.productStatus.success){
            toast.success(this.props.productStatus.message , {
                position: 'top-center', 
            });
            this.setState({loading:true})
            this.getProductsList();
        }
        Swal.close();
    };

    renderProducts = () =>{
        const { isActive, logggedUser , products} = this.state
        if(products && products.length > 0 ){
            return (
                <>
                    {   
                        products.map((item , itemIndex)=>{
                            return (

                                <Col key={itemIndex} sm={isActive ? '6' : '12'} lg={isActive ? '4' : '10'} className='mb-4'>
                                    <div className="Gr--singleStream">
                                        <div className="Gr--streamImage">
                                            {(logggedUser && Number(logggedUser) === Number(item.user.id))&&
                                                <Dropdown className='edit--post--dropdown'>
                                                    <Dropdown.Toggle id="dropdown-basic">
                                                        <FaEllipsisV />
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                            <Link className='dropdown-item' to={`/edit-product/${item.slug}`}><FaPencilAlt/> Edit</Link>
                                                            <Dropdown.Item className='dropdown-item' onClick={()=> this.handleStatusChange(item.id ,'delete')}><BiTrash/> Delete</Dropdown.Item>
                                                            <Dropdown.Item className='dropdown-item' onClick={()=>this.handleStatusChange(item.id ,'hide')}><FaEye/> hide</Dropdown.Item> 
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            }
                                            <Link to={`/marketplace/single-product/${item.slug}`}>
                                                <img src={item.marketplaceProductMeta ? item.marketplaceProductMeta[0].meta_value : ''} alt="..." />
                                            </Link>
                                        </div>
                                        <div className="Gr--streamUser">
                                            <h4><Link to={`/marketplace/single-product/${item.slug}`}>{item.title}</Link></h4>
                                            <p className='product--price'>{Number (item.price) === 0 ? 'Free' : item.price }</p>
                                        </div>
                                    </div>
                                </Col>

                            )
                        })
                    }
                </>
            )
        }else{
            return(
                // <Col sm={isActive ? '6' : '12'} lg={isActive ? '4' : '10'} className='mb-4'>
                    <h2 className="mt-5 text-center">Products Not Found</h2>  
                // </Col>
            )
        }
    }

    render(){
        const { isActive, myShops , logggedUser , products , loading } = this.state
        return (
            <>
                <Row className='mb-3 align-items-center'>
                    {(logggedUser && Number(logggedUser) === Number(this.state.userId))&&
                        <Col className='pe-0'>
                            <Button variant='secondary'><Link to='/create-shop'><BiPlus /> Create Shop</Link></Button>
                        </Col>
                    }
                    <Col>
                        <ListGroup as="ul" horizontal>
                            <ListGroup.Item as="li" className={isActive ? 'active' : 'inactive'} action href="javascript:;" onClick={() => this.handleToggle()}><BsGridFill /></ListGroup.Item>
                            <ListGroup.Item as="li" className={isActive ? 'inactive' : 'active'} action href="javascript:;" onClick={() => this.handleToggle()}><BsCardList /></ListGroup.Item>
                        </ListGroup>
                    </Col>
                </Row>
                
                <Row className={isActive ? 'justify-content-start grid--view' : 'justify-content-center list--view'}>
                {!loading  ?
                    // (products.length > 0 )
                        // ?
                            this.renderProducts()
                        // :   null
                    :    <Col>
                            <div className='text-center spinner--loader'></div>
                        </Col>   
                }
                </Row>
            </>
        );
    }
}
const maopStateToProps = (state) => {
    const { shop, auth , product} = state
    return{
        shop: shop.myShopList,
        shopDeletedSuccess: shop.shopDeleted,
        shopDeleteError: shop.shopDeleteError,
        auth : auth,
        product_List : product.user_products,
        product_error : product.user_products_error,
        productStatus : product.statusChanged
    }    

}

const dispatchActions = { getMyShops, deleteShop , getUserProductsList , ChangeProductStatus}; 

export default connect(maopStateToProps, dispatchActions)(ProfileShopTab);
