import React, { Component} from 'react'
import { connect } from 'react-redux';
import { Container, Row, Col, Dropdown, ListGroup, Carousel, OverlayTrigger, Tooltip, Button} from 'react-bootstrap';
import { IoMdHeart } from "react-icons/io";
import { BsArrowLeft} from 'react-icons/bs';
import { TbCalendarTime } from "react-icons/tb";
import { FaExpand, FaEllipsisV, FaRegHeart} from 'react-icons/fa';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link} from "react-router-dom";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import moment from 'moment';
import { toast, Toaster } from "react-hot-toast";
import PageHeader from '../Header/HeaderTop';
import SidebarMenu from '../Sidebar/SidebarNav';
import 'bootstrap/dist/css/bootstrap.min.css';
import './visualStyle.css';
import '../../App.css';
import { changePostStatus , clearPostData , getSinglePost} from '../../redux/actions/post.action';
import {decodedLoggedUser , likePosts , SendComment} from '../../helpers/auth';
import { withRouter } from '../../wrapper/withRouter';
import { checkHTML , convertToPlainText , valid} from '../../utils/validations';
import LightboxComponent from '../LightBoxComponent';
import LiveCommentsAnimation from '../Comments/LiveCommentsAnimation';

const check = ['undefined' , undefined , null , 'null' , '']




class VisualPostSingle  extends Component {

    constructor(props){
        super(props)
        this.state =  {
            slug : this.props.params.slug ,
            loading : false ,  
            singlePost: '' ,
            isActive : true ,
            userId : '' ,
            isBActive : false,
            commentList : '' , 
            isLightBoxOpen: false ,
            postMedia:'' , 
            hideSideBar : false , 
        }
    }
    
    componentDidMount = async () => {
        if (this.props.auth.loggedIn) {
            let token = decodedLoggedUser()
            this.setState({userId:token.aud})
        }
        await this.getVisualPost();
    }

    componentWillUnmount = async ()=>{
        await this.props.clearPostData()
    }


    getVisualPost = async() =>{
        this.setState({loading : true}) ;
        await this.props.getSinglePost(this.state.slug)
        if(this.props.singleVisualPost && this.props.singleVisualPost.success){
            this.setState({singlePost : this.props.singleVisualPost.Post})
            if(this.props.singleVisualPost.commentList){
                this.setState({commentList : this.props.singleVisualPost.commentList})
            }
            this.setState({loading : false})
        }
        if(this.props.singlePostError && this.props.singlePostError.message){
            // toast.error(this.props.singlePostError.message, {
            //     position: 'top-center', 
            // });
            this.setState({loading:false})
        }
        this.setState({loading:false})
    }


    handleShowToggle = () => {
        this.setState({isBActive: !this.state.isBActive})
    };

    renderSinglePost = () =>{
        const {singlePost} = this.state;
        if (singlePost){
            if(singlePost.Postmeta.length > 1){
                return(
                    <Carousel cols={2} rows={1} gap={10}>
                        {singlePost.Postmeta.map((item , ind) => (
                            <Carousel.Item key={ind}>
                                <img
                                    onClick={this.handleLightBox}
                                    src={item.meta_value} 
                                    alt={singlePost.name}  
                                    style={{
                                        width: '100%',
                                        height: '500px',
                                        objectFit: 'contain',
                                        borderRadius: '8px',
                                    }}
                                />
                            </Carousel.Item>
                        ))}
                    </Carousel>
                )    
                
            }else{
                return (
                    // <img src={(singlePost.Postmeta && singlePost.Postmeta.length > 0) ? singlePost.Postmeta[0].meta_value : singlePost.thumbnail} />
                    <LazyLoadImage
                        width="100%"
                        placeholderSrc="/images/placeholder.gif"
                        effect="blur"
                        src={(singlePost.Postmeta && singlePost.Postmeta.length > 0) ? singlePost.Postmeta[0].meta_value : singlePost.thumbnail}
                        alt={singlePost.name || ''}
                        onClick={this.handleLightBox}
                    /> 
                    
                )
            }
        }
        
    }

    renderUserAction = ()=>{
        const {singlePost , userId } = this.state
        if (singlePost.user && singlePost.user.id == userId){
            return (
                <Dropdown className='edit--post--dropdown'>
                    <Dropdown.Toggle id="dropdown-basic">
                        <FaEllipsisV />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Link className='dropdown-item' to={`/edit-visual/${singlePost.slug}`}>Edit</Link>
                        {/* <Link className='dropdown-item' onClick={this.handleCopy}>Share Link</Link> */}
                        <Dropdown.Item>
                            <CopyToClipboard text={window.location.href} onCopy={()=>{this.handleCopy()}}>
                                <p>Share Link</p>
                            </CopyToClipboard>   
                        </Dropdown.Item>
                        <Dropdown.Item onClick={()=>this.handleChangePostStatus(singlePost.id,'2')}>Move to Drafts</Dropdown.Item>
                        <Dropdown.Item onClick={()=>this.handleChangePostStatus(singlePost.id,'0')}>Delete</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            )
        }
    }


    handleChangePostStatus = async(postId , status) =>{
        const {  navigate } = this.props;
        this.setState({status : status})
        const data = {postId:postId , status:status}
        await this.props.changePostStatus(data)
        if(this.props.postStatus && this.props.postStatus.success){
            if(status === '0'){
                navigate('/')
            }else{
                navigate('/drafts')
            }
        }
        if(this.props.postStatusError && this.props.postStatusError.message){
            toast.error( this.props.postStatusError.message, {
                position: 'top-center', 
            });
        }
    }

    
    handleCopy = () => {
        
        toast.success('Link copy to clipboard', {position: 'top-center'});
        
    };

    handleLike = async (data) => {
        // if(Number(this.state.singlePost.user.id) !== Number(this.state.userId)){
            likePosts(this.state.userId, data.id)
        // }
    }



    handleCloseLightBox = ()=>{
        this.setState({isLightBoxOpen:false , hideSideBar : false})
    }
    

    handleLightBox = () =>{
        const {singlePost} = this.state
        if (singlePost){
            if(singlePost.Postmeta && singlePost.Postmeta.length > 0){
                let images = singlePost.Postmeta.map(item => { return { src: item.meta_value , alt:singlePost.name}});
                this.setState({postMedia:images})
                this.setState({isLightBoxOpen:true , hideSideBar:true})
            }
        }
    
    }

    commentList = async() =>{
        await this.props.getSinglePost(this.state.slug);
        if(this.props.singleVisualPost && this.props.singleVisualPost.success){
            this.setState({singlePost : this.props.singleVisualPost.Post})
            if(this.props.singleVisualPost.commentList){
                this.setState({commentList : this.props.singleVisualPost.commentList})
            }
            this.setState({loading : false})
        }
    }




    handleCommentsLength = (comments) =>{

        if(comments && comments.length > 0){

            const activeComments = comments.filter((item , index)=>{
                if(item.status === '1'){
                    return item
                }
            })
            if(activeComments && activeComments.length >0){
                return (
                    <small className='ms-2'>{activeComments.length}</small>
                )
            }
        }
    }

    updateCommentList = async() =>{
        await this.props.getSinglePost(this.state.slug);
    }



    handleLikeLength = (post) =>{
        if(post){
            const postUserId = post.user_id
            const userFound = post.Likes.some(like => Number(like.user_id) === Number(postUserId))
            if(userFound){
                if(post.Likes && post.Likes.length > 0){
                    const length =  post.Likes.length-1 ;
                    if(length === 0){
                        return null
                    }else{
                        return length
                    }
                }
            }else{
                return post.Likes.length ;
            }
        }
    }


   

    render (){
        const {loading , singlePost , userId , commentList , isLightBoxOpen , postMedia , hideSideBar} = this.state

        return (
            <>
                {isLightBoxOpen && 
                    <LightboxComponent
                        isOpen={isLightBoxOpen}
                        images={postMedia}
                        onClose={() => this.handleCloseLightBox()}
                    />
                }
                <PageHeader />
                <div className='page--content'>
                    {!hideSideBar &&
                        <SidebarMenu />
                    }
                    <div className='discover--module common--wrapper visual--single--page'>
                        <Container>
                            <Row>
                                <Col sm={12}>
                                    <Link className='back--btn' onClick={()=>this.props.navigate(-1)}><BsArrowLeft /> Back</Link>
                                </Col>
                            {!loading  ? 
                                !check.includes(singlePost) ?
                                    <Col sm={12} lg={8} className=' mx-auto'>
                                        {(Number(userId) !== Number(singlePost.user.id) && singlePost.user.booked_schedulers && singlePost.user.booked_schedulers.length > 0)&&
                                            <div className='calendar--icon'>
                                                <OverlayTrigger
                                                    key='top'
                                                    placement='top'
                                                    overlay={<Tooltip id={`tooltip-top`}>Schedule a Call</Tooltip>}
                                                    >
                                                    <Button variant='primary'><TbCalendarTime onClick={()=>this.props.navigate(`/booking/${singlePost.user.id}`)}/></Button>
                                                </OverlayTrigger>
                                            </div>
                                        }
                                        <div className='single--bg--col'>
                                            <div className='visual--user Gr--streamDesc'>
                                                <Link to={`/${singlePost.user.username}`}>
                                                    <img src={singlePost.user.image || '/images/user.png'} alt={singlePost.user.name} />
                                                </Link>
                                                <div class="Gr--streamUser">
                                                    <Link to={`/${singlePost.user.username}`}> <h5>{(singlePost.user && valid(singlePost.user.first))? singlePost.user.first : ''}</h5></Link>
                                                    <p>{moment(singlePost.createdAt).format('LL')}</p> 
                                                </div>
                                                {this.renderUserAction()}
                                            </div>
                                            <div className='visual--desc'>
                                                <h2 className='mt-3 mb-2'>{checkHTML(singlePost.name) ? convertToPlainText(singlePost.name) : singlePost.name}</h2>
                                                {checkHTML(singlePost.description) ? 
                                                    <div  className='mb-0' dangerouslySetInnerHTML={{ __html: singlePost.description }} />
                                                    :
                                                    <p className='mb-0'>{singlePost.description}</p>
                                                }
                                            </div>
                                           
                                            <div className='visual--block'>
                                                {this.renderSinglePost()}
                                            </div>
                                            {/* <div>
                                                
                                            </div> */}
                                            <div className='visual--likeBtn'>
                                                {(this.props.auth.loggedIn)&&
                                                    <ListGroup>
                                                        <ListGroup.Item onClick = {()=>this.handleLike(singlePost)}>
                                                            {(singlePost.Likes && singlePost.Likes.length > 0) && <span className='me-2'>{this.handleLikeLength(singlePost)}</span>}
                                                            {singlePost?.Likes?.some(like => like?.user_id === userId) ? (
                                                                <IoMdHeart />) : (<FaRegHeart /> )}
                                                        </ListGroup.Item>
                                                        <ListGroup.Item onClick={this.handleLightBox}>
                                                            <FaExpand/>
                                                        </ListGroup.Item>

                                                    </ListGroup>
                                                }
                                            </div>
                                            
                                        </div>
                                    </Col>
                                :   <h2 className="mt-5 text-center">Posts Not Found</h2>    
                            :   <Col sm={12}>
                                    <div className='text-center spinner--loader'></div>
                                </Col>    
                            }        
                                <Col sm={12} lg={8} className='mt-4 mx-auto'>
                                    {singlePost?.commenting &&
                                        <LiveCommentsAnimation   updateComment={()=>this.updateCommentList()} postId= {singlePost}/>
                                    }
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
                <Toaster/>
            </>
        );
    }
}


const maopStateToProps = (state) => {
    return{
        singleVisualPost :  state.post.singlePostSuccess, 
        singlePostError : state.post.singlePostError,
        auth : state.auth ,
        postStatus : state.post.changeStatusSuccess,
        postStatusError: state.post.changeStatusError
    }    

}

const dispatchActions = {getSinglePost , changePostStatus , clearPostData} ; 

export default connect(maopStateToProps, dispatchActions)(withRouter(VisualPostSingle));

