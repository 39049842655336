import {useState , useEffect} from 'react';
import { useNavigate , Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col, Table, Button, Badge } from 'react-bootstrap';
import PageHeader from '../../Header/HeaderTop';
import SidebarMenu from '../../Sidebar/SidebarNav';
import 'bootstrap/dist/css/bootstrap.min.css';
import './profileShopStyle.css';
import '../../../App.css';
import { getBuyOrderList , ChangeOrderStatus} from '../../../redux/actions/orders.action';
import { toast, Toaster } from "react-hot-toast";
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fileDownload from 'js-file-download';
import axios from 'axios';
import { downloadFile } from '../../../redux/actions/orders.action';


function ShopPurchases() {
    const [from , setFrom] = useState(new Date(new Date().setDate(new Date().getDate() - 60)));
    const [to , setTo] = useState(new Date());
    const [loading , setLoading] = useState(true);
    const [orderList , setOrderList] = useState('');
    const dispatch = useDispatch();
    const orderResult = useSelector(state =>state.order);


    useEffect(()=>{ 
        getOrders();
    },[])


    const getOrders = () =>{
        const data = {from:from.toISOString() , to:to.toISOString()}
        dispatch(getBuyOrderList(data))
    }

/**********************    handle component Mounting  **********************************/

    useEffect(()=>{
        if(orderResult && orderResult.buyOrderList){
            setOrderList(orderResult.buyOrderList.orders)
            setLoading(false)
        }
        if(orderResult && orderResult.orderError){
            setLoading(false)
        }
        if(orderResult && orderResult.buyOrderError){
            setLoading(false)
        }
        if(orderResult && orderResult.orderStatus){
            toast.success(orderResult.orderStatus.message, {
                position: 'top-center', 
            });
            getOrders();
        }
        if(orderResult && orderResult.orderStatusError){
            toast.error(orderResult.orderStatusError.message, {
                position: 'top-center', 
            });
        }
    },[orderResult])

/************************************************************************************************** */    


    const handleTotalAmount = (quantity , amount) =>{
        const total = Number(quantity)*Number(amount).toFixed();
        return total

    }

/*****************  ////  using for filtering the order by dates //////// **************************/

    const onDateChange = (date, name) =>{
        if(name === 'startDate'){
            setFrom(date)
            const data = {from:date.toISOString() , to:to.toISOString()}
            dispatch(getBuyOrderList(data))
            setLoading(true)
        }
        if(name === 'endDate'){
            setTo(date);
            const data = {from:from.toISOString() , to:date.toISOString()}
            dispatch(getBuyOrderList(data))
            setLoading(true)
        }
    }

/*****************  ////  ------------------------------------------ //////// **************************/    

//////////    **********    using for cancel the order *********  /////////////////

    const handleOrderStatus  = (orderId)=>{
        const data = {orderId:orderId , status:'Cancel'}
        dispatch(ChangeOrderStatus(data))
    }

//////////    **********    ------------------------------ *********  /////////////////    


    const handleDownload = async(e , url) => {
        // const  fileUrl = Array.isArray(url) ? url[0].meta_value : url; // Replace with your file's public URL
        const fileUrl = 'https://storage.googleapis.com/video.thegalaxy.me/8c15d76e-54b4-43a2-8904-f5ec138c5615.wav'
        const fileExtension = fileUrl.split('.').pop();
        const mimeTypeMap = {
            jpeg: 'image/jpeg',
            jpg: 'image/jpeg',
            png: 'image/png',
            pdf: 'application/pdf',
            mp4: 'video/mp4',
            txt: 'text/plain',
            mp3: 'audio/mpeg'
        };
        const contentType = mimeTypeMap[fileExtension.toLowerCase()] || 'application/octet-stream';
        fetch('https://cors-anywhere.herokuapp.com/' + fileUrl, {
            method: 'GET',
            headers: {
            'Content-Type': contentType,
            },
        })
        .then((response) => console.log("response", response))
        .then((blob) => {
            // Create a new Blob object using the response data
            // const url = window.URL.createObjectURL(blob);
            // const link = document.createElement('a');
            // link.href = url;
            // const filename = fileUrl.split('/').pop() || 'download';
            // link.setAttribute('download', filename); // Replace with your desired file name and extension
            // document.body.appendChild(link);
            // link.click();
            // document.body.removeChild(link);
            // window.URL.revokeObjectURL(url);
        })
        .catch((error) => console.error('Download error:', error));
    };


    const showDownloadButton = (item) =>{
        if(item.marketplaceProduct && item.marketplaceProduct.marketplaceProductMeta.length > 0){

            const mediaUrl = item.marketplaceProduct.marketplaceProductMeta.filter((item ,ind)=>{
                if(item.meta_key === 'media'){
                    return item
                }
            })
            if(mediaUrl && mediaUrl.length > 0){
                return (
                    <Button onClick={(e)=>handleDownload(e , mediaUrl)}> Download</Button>

                )
            }else{
                return (
                    <Button onClick={(e)=>handleDownload(e , item?.marketplaceProduct?.marketplaceProductMeta[0]?.meta_value)}> Download</Button>
                )
            }

        }

    }


    return (
        <>
            <PageHeader />
            <div className='page--content'>
                <SidebarMenu />
                <div className='discover--module common--wrapper order--table'>
                    <Container>
                        <Row className='justify-content-center'>
                        {!loading ?
                            <>
                                <div className='col-sm-12 mb-3'>
                                    <div className='d-flex align-items-center justify-content-end'>
                                        <DatePicker
                                            selected={from}
                                            dateFormat="dd/MM/yyyy"
                                            onChange={(date) => onDateChange(date, "startDate")}
                                            selectsStart
                                            className="form-control"
                                            startDate={from}
                                            endDate={to}
                                        />
                                        <DatePicker
                                            selected={to}
                                            dateFormat="dd/MM/yyyy"
                                            onChange={(date) => onDateChange(date, "endDate")}
                                            selectsEnd
                                            className="form-control"
                                            startDate={from}
                                            endDate={to}
                                            minDate={from}
                                        />
                                    </div>
                                </div>
                                
                                {(orderList && orderList.length > 0)
                                    ?
                                        <Col sm={12}>
                                            <Table striped responsive variant="dark">
                                                <thead>
                                                    <tr>
                                                        <th className='text-start'>Order</th>
                                                        <th className='text-start'>Product</th>
                                                        <th>Total Items</th>
                                                        {/* <th>Amount</th> */}
                                                        <th>Payment Type</th>
                                                        <th>Order Date</th>
                                                        <th>Order Status</th>
                                                        <th>Payment Status</th>
                                                        <th>Order Total</th>
                                                        <th>Action</th>
                                                        <th> download File</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {orderList.map((item , ind)=>{
                                                        return (
                                                            <tr key={ind}>
                                                                <td className='text-start'>{item.order_id}</td>
                                                                <td className='text-start'>
                                                                    <span className='pro--image'>
                                                                        
                                                                        <img src={item.marketplaceProduct.marketplaceProductMeta[0].meta_value} alt='..'/>
                                                                        <strong>{item.marketplaceProduct.title}<small>Seller: {item.seller.first}</small></strong>    
                                                                    </span>
                                                                </td>
                                                                <td>{item.quantity}</td>
                                                                <td>{item.payment_method}</td>
                                                                <td>{moment(item.createdAt).format('LL')}</td>
                                                                <td><Badge variant="danger" className='bg-danger'>{item.order_status}</Badge></td>
                                                                <td>{item.payment_status}</td>
                                                                <td>${handleTotalAmount(item.quantity , item.marketplaceProduct.price)}</td>
                                                                <td>
                                                                    {item.order_status !=='Cancel' ? 
                                                                        <Button variant='primary' size='sm' onClick = {()=>handleOrderStatus(item.order_id)}>Cancel Order </Button>
                                                                    : null
                                                                    }
                                                                </td>
                                                                <td>{showDownloadButton(item)}</td>
                                                            </tr>
                                                        )
                                                    })}
                                                
                                                </tbody>
                                            </Table>
                                        </Col>
                                    :
                                        <Col sm={12}>
                                            <h3>Order Not Found</h3>
                                        </Col>
                                } 
                            </>      

                        :    
                            <Col sm={12}>
                                <div className='text-center spinner--loader'></div>
                            </Col>
                        }    
                        </Row>
                    </Container>
                </div>
                <Toaster/>
            </div>
        </>
    );
}

export default ShopPurchases;