import { get } from 'lodash';
import API from '../../helpers/api';
import * as auth from '../../helpers/auth';
import {
    CREATE_LINK_SUCCESS,
    CREATE_LINK_FAILED,
    GET_LINK_SUCCESS,
    GET_LINK_FAILED,
    LINK_COMMON_ERROR,
    EDIT_LINK_SUCCESS,
    EDIT_LINK_FAILED,
    CLEAR_LINK_CREATE,
    CLEAR_LIVE_CREATE,
    CLEAR_LIVE_POST,
    CLEAR_POSTS
  } from "./types";

const config = {
    headers: {
        "Content-Type": "multipart/form-data; charset=utf-8;"
        
    }
}


function errorRequest(err, dispatch) {
    let data = get(err, 'response.data', null);
    data = data || get(err, 'response');
    data = data || err;
    if (data.error) {
      dispatch({
        type: LINK_COMMON_ERROR,
        payload: data.error.message,
      });
    } else if (data.status === 401) {   
      auth.logout()
    } else {
      dispatch({
        type: LINK_COMMON_ERROR,
        payload: data.message,
      });
    }
}


export const clearLinkCreate = () =>{
  return async (dispatch) => {
    dispatch({type:CLEAR_LINK_CREATE});
  }
}



/** using for create the link post  */

export const createLinkPost = (payload) =>{
    return async (dispatch) => {
        try{
            const response = await API.apiPostUrl('post', '/create-link' , payload ) ;
            if(response.data && response.data.success){
                await dispatch({ type: CREATE_LINK_SUCCESS, payload: response.data});
            }else{
                await dispatch({ type: CREATE_LINK_FAILED, payload: response.data});
            }
        }catch(error){
            errorRequest(error, dispatch);
        }
    }
}


/** using for get the link post list by  ///   perPage /  */

export const getLinkPostList = (payload) =>{
    return async (dispatch) => {
        try{
            const response = await API.apiGetUrl('post', '/link-post' , payload) ;
            if(response.data && response.data.success){
              await dispatch({ type: GET_LINK_SUCCESS, payload: response.data});
            }else{
              await dispatch({ type: GET_LINK_FAILED, payload: response.data});
            }
        }catch(error){
            errorRequest(error, dispatch);
        }
    }
}

export const editLinkPost = (payload)=>{
  return async (dispatch) => {
    try{
        const response = await API.apiPutUrl('post', '/edit-link-post' , payload) ;
        if(response.data && response.data.success){
          await dispatch({ type: EDIT_LINK_SUCCESS, payload: response.data});
        }else{
          await dispatch({ type: EDIT_LINK_FAILED, payload: response.data});
        }
    }catch(error){
        errorRequest(error, dispatch);
    }
  }
}

export const clearLiveData = () =>{
  return async (dispatch) => {
    dispatch({type:CLEAR_LIVE_POST});
  }
}


export const clearPostData = () =>{
  return async (dispatch) => {
    dispatch({type:CLEAR_POSTS});
  }

}

