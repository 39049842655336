import { get } from 'lodash';
import API from '../../helpers/api';
import * as auth from '../../helpers/auth';
import { 
    SEARCH_SUCCESS,
    SEARCH_FAILED,
    SEARCH_COMMON_ERROR
} from "./types";

function errorRequest(err, dispatch) {
    let data = get(err, 'response.data', null);
    data = data || get(err, 'response');
    data = data || err;
    if (data.error) {
      dispatch({
        type: SEARCH_COMMON_ERROR,
        payload: data.error.message,
      });
    } else if (data.status === 401) {   
      auth.logout()
    } else {
      dispatch({
        type: SEARCH_COMMON_ERROR,
        payload: data.message,
      });
    }
}


export const getSearchData = (payload) =>{
    return async (dispatch) => {
        try{
            const response = await API.apiGetUrl('search', '/search-any' , payload) ;
            if(response.data && response.data.success){
              await dispatch({ type: SEARCH_SUCCESS, payload: response.data});
            }else{
              await dispatch({ type: SEARCH_FAILED, payload: response.data});
            }
        }catch(error){
            errorRequest(error, dispatch);
        }
    }
}