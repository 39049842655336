import React, { Component} from 'react'
import { connect } from 'react-redux';
import { Container, Row, Col, Dropdown } from 'react-bootstrap';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link, useNavigate, useParams } from "react-router-dom";
import { BiPencil, BiTrash } from 'react-icons/bi';
import { BsCardList, BsGridFill , BsPlay} from 'react-icons/bs';
import { FaEllipsisV, FaRegPaperPlane } from 'react-icons/fa';
import PageHeader from '../../Header/HeaderTop';
import SidebarMenu from '../../Sidebar/SidebarNav';
import moment from 'moment';
import { toast, Toaster } from "react-hot-toast";
import InfiniteScroll from 'react-infinite-scroller';
import 'bootstrap/dist/css/bootstrap.min.css';
import './profileShopStyle.css';
import '../../../App.css';
import { getDraftPost } from '../../../redux/actions/post.action';
import { checkHTML , convertToPlainText , valid } from '../../../utils/validations';
import LinkRender from '../../Link/LinkRender';
import { decodedLoggedUser } from '../../../helpers/auth';
import { changePostStatus } from '../../../redux/actions/post.action';
import { FaRegUser, FaRegCalendarAlt } from 'react-icons/fa';
import { withRouter } from '../../../wrapper/withRouter';




class ShopDrafts extends Component {
    constructor(props){
        super(props)
        this.state =  {
            draftPostList : [],
            draftPostMeta : '',
            loading : false , 
            page : 1 ,
            perPage : 10 ,
            loadingState : false ,
            isActive : true,
            userId : '' ,
            status: '2'
        }
    }    

    componentDidMount = async () => {
        if (this.props.auth.loggedIn) {
            let token = decodedLoggedUser()
            this.setState({userId:token.aud})
        }
        await this.getdraftPostList();  
    }


    getdraftPostList = async() =>{
        const {page , perPage , draftPostList} = this.state ; 
        const data = {perPage:perPage , page:page};
        if (this.state.page == 1) {
            this.setState({ loading: true })
        }
        await this.props.getDraftPost(data);
        if(this.props.draftList  && this.props.draftList.success){
            var posts = draftPostList ;
            Array.prototype.push.apply(posts, this.props.draftList.draftPostList.draftPost);
            posts = posts.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i)
            this.setState({
                draftPostList : posts ,
                loading: false,
                loadingState: false,
                draftPostMeta: this.props.draftList.draftPostList.draftPostMeta ,
            })
            let totalPages = this.props.draftList.draftPostList.draftPostMeta
            if(posts.length !== totalPages){
                this.setState({page: page+1})
            }else{
                this.setState({ loadingState: true })
            }
        }
        if(this.props.draftPostError && this.props.draftPostError.message){
            toast.error( this.props.draftPostError.message , {
                position: 'top-center', 
            });
            this.setState({ loading: false })
        }
        this.setState({ loading: false })
    }

    loadMoreItems() {
        if (this.state.loadingState) {
            return;
        } else {
            this.getdraftPostList();
        }
    }


    renderDraftList = () =>{
        const {draftPostList} = this.state ; 
        if(draftPostList && draftPostList.length > 0 ){
            return (
                <>
                    {
                        draftPostList.map(( item , idx)=>{
                                             
                            if(item.PostCategory.slug === 'audio'){
                                return this.renderAudio(item)
                            }
                            if(item.PostCategory.slug === 'text'){
                                return this.renderText(item)
                            }
                            if(item.PostCategory.slug === 'film'){
                                return this.renderVideo(item)
                            }
                            if(item.PostCategory.slug === 'visual'){
                                return this.renderVisual(item)
                            }
                            if(item.PostCategory.slug === 'Live-Video'){
                                return this.renderLiveVideo(item)
                            }
                            // if(item.PostCategory.slug === 'link'){
                            //     return this.renderLink(item)
                            // }
                        })
                        
                    }    
                </>
            )

        }
    }
    renderAudio = (audio) =>{
        if(valid(audio)){
            let url = ''
            if (audio.Postmeta.length) {
                url = `/audio/album/${audio.slug}`;
            }
            else {
                url = `/audio/single/${audio.slug}`;
            }
            return (
                // <Col sm={6} lg={3} className='mb-3'>
                //     <div className="Gr--singleStream">
                //         <Dropdown className='edit--post--dropdown'>
                //             <Dropdown.Toggle id="dropdown-basic">
                //                 <FaEllipsisV />
                //             </Dropdown.Toggle>
                //             <Dropdown.Menu>
                //                 <Link className='dropdown-item' to={`/edit${url}`}>Edit</Link>
                //                 <Link className='dropdown-item' onClick={()=>this.handleChangePostStatus(audio.id , '1')}>publish</Link>
                //                 <Link className='dropdown-item' onClick={()=>this.handleChangePostStatus(audio.id , '0')}>Delete</Link>
                //             </Dropdown.Menu>
                //         </Dropdown>
                //          <div className="Gr--streamImage">
                //                 <LazyLoadImage
                //                     width="100%"
                //                     placeholderSrc="/images/placeholder.gif"
                //                     effect="blur"
                //                     src={valid(audio.thumbnail) ? audio.thumbnail: '/images/thumb-400kb.jpg'}
                //                     alt={audio.name}
                //                 />
                //          </div>
                //          <div className="Gr--streamDesc">
                //             <p>{(audio.user && valid(audio.user.first))? audio.user.first : ''}</p>
                //          </div>
                //          <div className="Gr--streamUser">
                //             <h4>{checkHTML(audio.name) ? convertToPlainText(audio.name) : audio.name}</h4>
                //             <p>{moment(audio.updatedAt).format('LL')}</p>
                //          </div>  
                //     </div>     
 
                // </Col>
                <Col  sm={'6'} xl={'4'} className='mb-4'>
                    <div>
                        <div className="Gr--singleStream">
                        <Dropdown className='edit--post--dropdown'>
                           <Dropdown.Toggle id="dropdown-basic">
                                <FaEllipsisV />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {/* <Link className='dropdown-item' to={`/edit${url}`}>Edit</Link> */}
                                <Link className='dropdown-item' onClick={()=>this.handleChangePostStatus(audio.id , '1')}>publish</Link>
                               <Link className='dropdown-item' onClick={()=>this.handleChangePostStatus(audio.id , '0')}>Delete</Link>
                            </Dropdown.Menu>
                        </Dropdown>
                            <div className="Gr--streamImage">
                                <LazyLoadImage
                                    width="100%"
                                    placeholderSrc="/images/placeholder.gif"
                                    effect="blur"
                                    src={valid(audio.thumbnail) ? audio.thumbnail: '/images/thumb-400kb.jpg'}
                                    alt={audio.name}
                                /> 
                            </div>  
                            <div className="Gr--streamDesc">
                                <Link to={`/${audio.user.username}`}>
                                    <img src={(audio.user && valid(audio.user.image)) ? audio.user.image : "/images/user.png"} alt='...' />
                                </Link>
                                <div className="Gr--streamUser">
                                    <h4>{checkHTML(audio.name) ? convertToPlainText(audio.name) : audio.name}</h4>
                                    <Link to={`/${audio.user.username}`}>
                                        <p><FaRegUser/> {(audio.user && valid(audio.user.first))? audio.user.first : ''}</p>
                                    </Link>
                                    <p><FaRegCalendarAlt /> {moment(audio.createdAt).format('LL')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            )
        }
 
    }
    renderText = (post) =>{
        if(valid(post)){
            return(
                // <Col sm={6} lg={3} className='mb-3'>
                //     <div className="Gr--singleStream">
                //         <Dropdown className='edit--post--dropdown'>
                //             <Dropdown.Toggle id="dropdown-basic">
                //                 <FaEllipsisV />
                //             </Dropdown.Toggle>
                //             <Dropdown.Menu>
                //                 <Link className='dropdown-item' to={`/edit-text/${text.slug}`}>Edit</Link>
                //                 <Link className='dropdown-item' onClick={()=>this.handleChangePostStatus(text.id , '1')}>publish</Link>
                //                 <Link className='dropdown-item' onClick={()=>this.handleChangePostStatus(text.id , '0')}>Delete</Link>
                //             </Dropdown.Menu>
                //         </Dropdown>
                //         <div className="Gr--streamImage">
                //             {valid(text.thumbnail) ?
                //                 <LazyLoadImage
                //                     width="100%"
                //                     placeholderSrc="/images/placeholder.gif"
                //                     effect="blur"
                //                     src={text.thumbnail}
                //                     alt={text.name}
                //                 />
                //             :
                //                 <pre>{checkHTML(text.description) ? convertToPlainText(text.description) : text.description}</pre>    
                //             }
                //         </div>
                //         <div className="Gr--streamDesc">
                //             <p>{text.user ? text.user.first: ''}</p>
                //         </div>
                //         <div className="Gr--streamUser">
                //             <h4>{checkHTML(text.name) ? convertToPlainText(text.name) : text.name}</h4>
                //             <p>{moment(text.updatedAt).format('LL')}</p>
                //         </div>
                //     </div>    

                // </Col>
                <Col  sm={'6'} xl={'4'} className='mb-4'>
                    <div className="Gr--singleStream">
                    <Dropdown className='edit--post--dropdown'>
                        <Dropdown.Toggle id="dropdown-basic">
                            <FaEllipsisV />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Link className='dropdown-item' onClick={()=>this.handleChangePostStatus(post.id , '1')}>publish</Link>
                            <Link className='dropdown-item' onClick={()=>this.handleChangePostStatus(post.id , '0')}>Delete</Link>
                        </Dropdown.Menu>
                    </Dropdown>
                        {valid(post.thumbnail) ?
                            <div className="Gr--streamImage">
                                <LazyLoadImage
                                    width="100%"
                                    placeholderSrc="/images/placeholder.gif"
                                    effect="blur"
                                    src={post.thumbnail} 
                                    alt={post.name}
                                /> 
                            </div>
                            :
                            <div className="Gr--streamImage">
                                <Link to={`/text/${post.slug}`}>
                                    <pre>{checkHTML(post.description) ? convertToPlainText(post.description) : post.description}</pre>
                                </Link>
                            </div>
                        }
                        <div className="Gr--streamDesc">
                            <Link to={`/${post.user.username}`}>
                                <img src={(post.user && valid(post.user.image)) ? post.user.image : "/images/user.png"} alt='...' />
                            </Link>
                            <div className="Gr--streamUser">
                                <h4>{checkHTML(post.name) ? convertToPlainText(post.name) : post.name}</h4>
                                <Link to={`/${post.user.username}`}>
                                    <p><FaRegUser/> {(post.user && valid(post.user.first))? post.user.first : ''}</p>
                                </Link>
                                <p><FaRegCalendarAlt /> {moment(post.createdAt).format('LL')}</p>
                            </div>
                        </div>
                    </div>
                </Col>
            )
        }
    }


    renderVideo = (video) =>{
        if(valid(video)){
            const {userId} = this.state
            return (
                // <Col sm={6} lg={3} className='mb-3'>
                //     <div className="Gr--singleStream">
                //         {userId == video.user.id &&
                //              <Dropdown className='edit--post--dropdown'>
                //                  <Dropdown.Toggle id="dropdown-basic">
                //                      <FaEllipsisV />
                //                  </Dropdown.Toggle>
                //                  <Dropdown.Menu>
                //                     <Link className='dropdown-item' to={`/edit-video/${video.slug}`}>Edit</Link>
                //                     <Dropdown.Item className='dropdown-item' onClick={()=>this.handleChangePostStatus(video.id , '1')}>publish</Dropdown.Item>
                //                     <Dropdown.Item className='dropdown-item' onClick={()=>this.handleChangePostStatus(video.id , '0')}>Delete</Dropdown.Item>
                //                  </Dropdown.Menu>
                //              </Dropdown>
                //         }
                //         <div className="Gr--streamImage">
                //             <span className='play--icon'><BsPlay/></span>
                //             <LazyLoadImage
                //                 width="100%"
                //                 placeholderSrc="/images/placeholder.gif"
                //                 effect="blur"
                //                 src={valid(video.thumbnail) ? video.thumbnail : '/images/thumbnail.jpg'}
                //                 alt={video.name}
                //             />
                //         </div>
                //         <div className="Gr--streamDesc">    
                //             <p>{video.user ? video.user.first: ''}</p>
                //         </div>
                //         <div className="Gr--streamUser">
                //             <h4>{checkHTML(video.name) ? convertToPlainText(video.name) : video.name}</h4>
                //             <p>{moment(video.updatedAt).format('LL')}</p>
                //         </div>
                //     </div>
 
                // </Col>    
                <Col  sm={'6'} xl={'4'} className='mb-4'>
                    <div>
                        <div className="Gr--singleStream">
                        <Dropdown className='edit--post--dropdown'>
                            <Dropdown.Toggle id="dropdown-basic">
                                <FaEllipsisV />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item className='dropdown-item' onClick={()=>this.handleChangePostStatus(video.id , '1')}>publish</Dropdown.Item>
                                <Dropdown.Item className='dropdown-item' onClick={()=>this.handleChangePostStatus(video.id , '0')}>Delete</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                            <div className="Gr--streamImage">
                                <LazyLoadImage
                                    width="100%"
                                    placeholderSrc="/images/placeholder.gif"
                                    effect="blur"
                                    src={valid(video.thumbnail) ? video.thumbnail : '/images/thumbnail.jpg'} 
                                    alt={checkHTML(video.name) ? convertToPlainText(video.name) : video.name}
                                /> 
                            </div>
                            <div className="Gr--streamDesc">
                                <Link to={`/${video.user.username}`}>
                                    <img src={(video.user && valid(video.user.image)) ? video.user.image : "/images/user.png"} alt='...' />
                                </Link>
                                <div className="Gr--streamUser">
                                    <h4>{checkHTML(video.name) ? convertToPlainText(video.name) : video.name}</h4>
                                    <Link to={`/${video.user.username}`}>
                                        <p><FaRegUser/> {(video.user && valid(video.user.first))? video.user.first : ''}</p>
                                    </Link>
                                    <p><FaRegCalendarAlt /> {moment(video.createdAt).format('LL')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            )
         
        }
 
    }

    renderLiveVideo = (video) =>{
        if(valid(video)){
            const {userId} = this.state
             return (
                // <Col sm={6} lg={3} className='mb-3'>
                //     <div className="Gr--singleStream">
                //         {userId == video.user.id &&
                //              <Dropdown className='edit--post--dropdown'>
                //                  <Dropdown.Toggle id="dropdown-basic">
                //                      <FaEllipsisV />
                //                  </Dropdown.Toggle>
                //                  <Dropdown.Menu>
                //                     <Link className='dropdown-item' to={`/edit-video/${video.slug}`}>Edit</Link>
                //                     <Dropdown.Item className='dropdown-item' onClick={()=>this.handleChangePostStatus(video.id , '1')}>publish</Dropdown.Item>
                //                     <Dropdown.Item className='dropdown-item' onClick={()=>this.handleChangePostStatus(video.id , '0')}>Delete</Dropdown.Item>
                //                  </Dropdown.Menu>
                //              </Dropdown>
                //         }
                //         <div className="Gr--streamImage">
                //             <span className='play--icon'><BsPlay/></span>
                //             <LazyLoadImage
                //                 width="100%"
                //                 placeholderSrc="/images/placeholder.gif"
                //                 effect="blur"
                //                 src={valid(video.thumbnail) ? video.thumbnail : '/images/thumbnail.jpg'}
                //                 alt={video.name}
                //             />
                //         </div>
                //         <div className="Gr--streamDesc">    
                //             <p>{video.user ? video.user.first: ''}</p>
                //         </div>
                //         <div className="Gr--streamUser">
                //             <h4>{checkHTML(video.name) ? convertToPlainText(video.name) : video.name}</h4>
                //             <p>{moment(video.updatedAt).format('LL')}</p>
                //         </div>
                //     </div>
 
                // </Col>    
                <Col  sm={'6'} xl={'4'} className='mb-4'>
                    <div>
                        <div className="Gr--singleStream">
                        <Dropdown className='edit--post--dropdown'>
                            <Dropdown.Toggle id="dropdown-basic">
                                <FaEllipsisV />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item className='dropdown-item' onClick={()=>this.handleChangePostStatus(video.id , '1')}>publish</Dropdown.Item>
                                <Dropdown.Item className='dropdown-item' onClick={()=>this.handleChangePostStatus(video.id , '0')}>Delete</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                            <div className="Gr--streamImage">
                                <LazyLoadImage
                                    width="100%"
                                    placeholderSrc="/images/placeholder.gif"
                                    effect="blur"
                                    src={valid(video.thumbnail) ? video.thumbnail : '/images/thumbnail.jpg'} 
                                    alt={checkHTML(video.name) ? convertToPlainText(video.name) : video.name}
                                /> 
                            </div>
                            <div className="Gr--streamDesc">
                                <Link to={`/${video.user.username}`} state={{user:video.user.id}}>
                                    <img src={(video.user && valid(video.user.image)) ? video.user.image : "/images/user.png"} alt='...' />
                                </Link>
                                <div className="Gr--streamUser">
                                    <h4>{checkHTML(video.name) ? convertToPlainText(video.name) : video.name}</h4>
                                    <Link to={`/${video.user.username}`} state={{user:video.user.id}}>
                                        <p><FaRegUser/> {(video.user && valid(video.user.first))? video.user.first : ''}</p>
                                    </Link>
                                    <p><FaRegCalendarAlt /> {moment(video.createdAt).format('LL')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
             )
        }

    }


    renderVisual = (visual) =>{
        const {userId} = this.state
        if(valid(visual)){
            return  (
                <Col  sm={'6'} xl={'4'} className='mb-4'>
                    <div>
                        <div className="Gr--singleStream">
                        <Dropdown className='edit--post--dropdown'>
                            <Dropdown.Toggle id="dropdown-basic">
                            <FaEllipsisV />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item className='dropdown-item' onClick={()=>this.handleChangePostStatus(visual.id , '1')}>publish</Dropdown.Item>
                                <Dropdown.Item className='dropdown-item' onClick={()=>this.handleChangePostStatus(visual.id , '0')}>Delete</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                            <div className="Gr--streamImage">
                                <LazyLoadImage
                                    width="100%"
                                    placeholderSrc="/images/placeholder.gif"
                                    effect="blur"
                                    src={visual.thumbnail || visual.Postmeta[0].meta_value }
                                    alt={visual.name}
                                /> 
                            </div>
                            <div className="Gr--streamDesc">
                                <Link to={`/${visual.user.username}`}>
                                    <img src={(visual.user && valid(visual.user.image)) ? visual.user.image : "/images/user.png"} alt='...' />
                                </Link>
                                <div className="Gr--streamUser">
                                    <h4>{checkHTML(visual.name) ? convertToPlainText(visual.name) : visual.name}</h4>
                                    <Link to={`/${visual.user.username}`} >
                                        <p><FaRegUser/> {(visual.user && valid(visual.user.first))? visual.user.first : ''}</p>
                                    </Link>
                                    <p><FaRegCalendarAlt /> {moment(visual.createdAt).format('LL')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                // <Col sm={6} lg={3} className='mb-3'>
                //     <div className="Gr--singleStream">
                //         {userId == visual.user.id &&
                //             <Dropdown className='edit--post--dropdown'>
                //                 <Dropdown.Toggle id="dropdown-basic">
                //                     <FaEllipsisV />
                //                 </Dropdown.Toggle>
                //                 <Dropdown.Menu>
                //                     <Link className='dropdown-item' to={`/edit-visual/${visual.slug}`}>Edit</Link>
                //                     <Dropdown.Item className='dropdown-item' onClick={()=>this.handleChangePostStatus(visual.id , '1')}>publish</Dropdown.Item>
                //                     <Dropdown.Item className='dropdown-item' onClick={()=>this.handleChangePostStatus(visual.id , '0')}>Delete</Dropdown.Item>
                //                 </Dropdown.Menu>
                //             </Dropdown>
                //         }
                //         <div className="Gr--streamImage">
                //                 <LazyLoadImage
                //                     width="100%"
                //                     placeholderSrc="/images/placeholder.gif"
                //                     effect="blur"
                //                     src={valid(visual.thumbnail)?visual.thumbnail :visual.Postmeta[0].meta_value}
                //                     alt={visual.name}
                //                 />
                //         </div>
                //         <div className="Gr--streamDesc">
                //             <p>{visual.user ? visual.user.first: ''}</p>
                //         </div>
                //         <div className="Gr--streamUser">
                //             <h4>{checkHTML(visual.name) ? convertToPlainText(visual.name) : visual.name}</h4>
                //             <p>{moment(visual.updatedAt).format('LL')}</p>
                //         </div>
                //     </div>    
                // </Col>   
            )
        }
    }


    // renderLink = (link) =>{
    //     const {userId} = this.state
    //     if(valid(Link)){
    //         return(
    //             <Col sm={6} lg={3} className='mb-3'>
    //                 <div className="Gr--singleStream">
    //                     {userId == link.user.id &&
    //                         <Dropdown className='edit--post--dropdown'>
    //                             <Dropdown.Toggle id="dropdown-basic">
    //                                 <FaEllipsisV />
    //                             </Dropdown.Toggle>
    //                             <Dropdown.Menu>
    //                                 <Link className='dropdown-item' to={`/edit-link/${link.slug}`}>Edit</Link>
    //                                 <Dropdown.Item className='dropdown-item' onClick={()=>this.handleChangePostStatus(link.id , '1')}>publish</Dropdown.Item>
    //                                 <Dropdown.Item className='dropdown-item' onClick={()=>this.handleChangePostStatus(link.id , '0')}>Delete</Dropdown.Item>
    //                             </Dropdown.Menu>
    //                         </Dropdown>
    //                     }
    //                     <LinkRender post= {link}/>
    //                 </div>

    //             </Col>
    //         )

    //     }

    // }

    handleChangePostStatus = async(postId , status) =>{
       
        const data = {postId:postId , status:status}
        await this.props.changePostStatus(data)
        if(this.props.postStatus && this.props.postStatus.success){
            toast.success(this.props.postStatus.message, {
                position: 'top-center', 
            });
            this.setState({status : status})
            this.props.navigate('/')
        }
        if(this.props.postStatusError && this.props.postStatusError.message){
            toast.error( this.props.postStatusError.message, {
                position: 'top-center', 
            });
        }
    }

    componentDidUpdate(prevProps, prevState){
        if(prevState.status !== this.state.status){
            this.setState({
                draftPostList : [],
                draftPostMeta : '',
                loading : false , 
                page : 1 ,
                perPage : 10 ,
                loadingState : false ,
                status: '2'
            },()=>this.getdraftPostList())
        }
    }
 



    render(){
        const {loading , draftPostList , loadingState , page } = this.state ;

        return (
            <>
                <PageHeader />
                <div className='page--content'>
                    <SidebarMenu />
                    <div className='discover--module common--wrapper draft--page'>
                        <Container>
                            <Row id="scrollableDiv" ref={(ref) => this.scrollParentRef = ref} style={{ height: "100%" }}>
                                {!loading  ?
                                    
                                    (draftPostList && draftPostList.length > 0)
                                    ?

                                        <InfiniteScroll
                                            pageStart={0}
                                            loadMore={() => this.loadMoreItems()}
                                            hasMore={!loadingState}
                                            loader={
                                                <Col sm={12}>
                                                    <div className='text-center spinner--loader'></div>
                                                </Col>
                                            }
                                            scrollableTarget="scrollableDiv"
                                            getScrollParent={() => this.scrollParentRef}
                                            window={false}
                                        >
                                            <Col sm={12}>
                                                <Row >
                                                    {this.renderDraftList()}
                                                </Row>    
                                            </Col>

                                        </InfiniteScroll>
                                    :  
                                       
                                        <h1>Posts Not Found </h1>
                                       

                                :   <Col sm={12}>
                                        <div className='text-center spinner--loader'></div>
                                    </Col>  
                                }          

                                
                            </Row>
                        </Container>
                    </div>
                </div>
            </>
        );
    }    
}



const maopStateToProps = (state) => {
    return{
        draftList : state.post.draftList,
        draftPostError : state.post.draftPostError,
        auth : state.auth,
        postStatus : state.post.changeStatusSuccess,
        postStatusError: state.post.changeStatusError
    }    

}

const dispatchActions = {getDraftPost , changePostStatus} ; 

export default connect(maopStateToProps, dispatchActions)(withRouter(ShopDrafts));

