import React, { useEffect, useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import LiveSingleAudio from './LiveAudioTab';
import LiveSingleVideo from './LiveVideoTab';

function CreateLive({savedData , saveDataToForm}) {
    const [ tab, setTab ] = useState('livevideo');
    useEffect(() => {
    }, []);

    const handleSelect = (e) => {
        setTab(e)
    }
    return (
        <div className='sub--Tabs'>
            <Tabs 
                mountOnEnter={true} 
                unmountOnExit={true} 
                activeKey={tab} 
                id="create--sub--tabs"
                onSelect={(e) => handleSelect(e)}
            >
                <Tab eventKey="livevideo" title='Live Video'>
                    <LiveSingleVideo savedData={savedData} saveDataToForm={(tab , data)=>saveDataToForm(tab, data)}/>
                </Tab>
                <Tab eventKey="liveaudio" title='Live Audio'>
                    <LiveSingleAudio savedData={savedData} saveDataToForm={(tab , data)=>saveDataToForm(tab, data)}/>
                </Tab>
            </Tabs>
        </div>
    );
}

export default CreateLive;