import {useState , useEffect} from 'react';
import { Container, Row, Col, Button , Dropdown} from 'react-bootstrap';
import { useSelector, useDispatch } from "react-redux";
import { useNavigate , useParams , Link} from 'react-router-dom';
import { toast, Toaster } from "react-hot-toast";
import { BsArrowLeft } from 'react-icons/bs';
import { FaEllipsisV, FaEye, FaPencilAlt } from 'react-icons/fa';
import { BiTrash, BiPlus } from 'react-icons/bi';
import { FaExpandAlt } from 'react-icons/fa';
import PageHeader from '../../Header/HeaderTop';
import 'bootstrap/dist/css/bootstrap.min.css';
import './profileShopStyle.css';
import '../../../App.css';
import { getMyShops, deleteShop } from '../../../redux/actions/shop.action';
import {decodedLoggedUser , logout} from '../../../helpers/auth';
import { getUserProfile} from '../../../redux/actions/user.action';
import SidebarMenu from '../../Sidebar/SidebarNav';


function MyShop() {
    const [shops , setShops] = useState('');
    const [loading , setLoading] = useState(true);
    const [userProfile , setUserProfile] = useState('') ; 
    const dispatch= useDispatch();
    const shopResult = useSelector(state => state.shop);
    const auth = useSelector(state => state.auth);
    const userResult = useSelector(state => state.user);


    useEffect(()=>{
        if (auth.loggedIn) {
            let token = decodedLoggedUser()
            dispatch(getUserProfile(token.aud))
            const data = {userId:token.aud}
            dispatch(getMyShops(data))
        }
    },[])

    useEffect(()=>{

        if(shopResult){

            if(shopResult.myShopList){
                setShops( shopResult.myShopList.myShopsList)
            }
            if(shopResult.shopProductsError){
                toast.error(shopResult.shopProductsError , {
                    position: 'top-center', 
                });
            }

            if(shopResult.shopDeleted && shopResult.shopDeleted.success){
                toast.success(shopResult.shopDeleted.message, {
                    position: 'top-center', 
                });
                dispatch(getMyShops())
            }
    
            if(shopResult.shopDeleteError){
                toast.error(shopResult.shopDeleteError, {
                    position: 'top-center', 
                });
            }
        }

      
        if(userResult.getUserDetails && userResult.getUserDetails.success){
            setUserProfile(userResult.getUserDetails.profile)
        }
        setLoading(false)

    },[shopResult , userResult])

    const renderUserProfile = () =>{
        if(userProfile){
            return(
                <Col sm={12}>
                    <div className='profile--banner'>
                        <span className='profile--thumb'>
                            <img src={userProfile.image || '/images/user.png' } alt='Profile image' />
                            <FaExpandAlt/>
                        </span>
                        <h4>{userProfile.name}</h4>
                    </div>
                </Col>

            )
        }
    }

    const handleDeleteShop = (shopId) => {
        dispatch(deleteShop({shopId}));
    }

    const renderShop = () =>{
        if(shops && shops.length > 0){
            return (
                shops.map((item , idx)=>{
                    return (
                        <Col sm={4} lg={3} className='mb-4'>
                            <div className="Gr--singleStream">
                                <div className="Gr--streamImage">
                                    <Dropdown className='edit--post--dropdown'>
                                        <Dropdown.Toggle id="dropdown-basic">
                                            <FaEllipsisV />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Link className='dropdown-item' to={`/edit-shop/${item.slug}`}><FaPencilAlt/> Edit</Link>
                                            <Link className='dropdown-item' onClick={() => handleDeleteShop(item.id)}><BiTrash/> Delete</Link>
                                            <Link className='dropdown-item' to="/marketplace"><FaEye/> Show</Link>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    {/* <Button className='zoom--image'><FaExpandAlt /></Button> */}
                                    <Link to={`/marketplace/product/${item.slug}`}>
                                        <img src={item.header || '' } alt="..." />
                                    </Link>
                                </div>
                                <div className="Gr--streamUser">
                                    <h4><Link to={`/marketplace/product/${item.slug}`}>{item.title}</Link></h4>
                                    {/* <p className='product--price'>{item.updatedAt}</p> */}
                                </div>
                            </div>
                        </Col>
                    )
                })
            )
        }else{
            return (
                <h5> No Shops Found </h5>
            )
        }
    }


    return (
        <>
            <PageHeader />
            <div className='page--content'>
                <SidebarMenu />
                <div className='discover--module common--wrapper myShop--page'>
                    <Container>
                        <>
                            <Row>
                                {!loading
                                    ?
                                    <>
                                        
                                        {renderUserProfile()}
                                        {renderShop()}
                                    </>        
                                        
                                    :
                                        <Col sm={12}>
                                            <div className='text-center spinner--loader'></div>
                                        </Col>    
                                    
                                }     
                            </Row>
                        </> 
                    </Container>
                </div>
                <Toaster/>
            </div>
        </>
    );
}

export default MyShop;