import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {useNavigate } from "react-router-dom";
import { toast} from "react-hot-toast";   
import { EditorState, convertToRaw, getDefaultKeyBinding, ContentState} from 'draft-js';
import { FaCogs} from 'react-icons/fa';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Button, Row, Col, Form , Spinner} from 'react-bootstrap';
import {IoClose} from 'react-icons/io5';
import { BsFileEarmarkMusic, BsPlusLg } from 'react-icons/bs';
import { notValid,checkHTML , convertToPlainText } from "../../../utils/validations";
import ImageCropper from "./imageCropper";
import { createAudioPost } from "../../../redux/actions/audioPost.action";
import { clearAudioCreate } from "../../../redux/actions/audioPost.action";

function CreateSingleAudio({savedData , saveDataToForm}) {
    const intialValue = {name:'' , description: '',isStream: true,  commenting: true , board_name: '', board_id: '', images:'' , thumbnail:''};
    const [audioFields , setAudioFields] = useState(intialValue);
    const [loading , setLoading] = useState(false) ; 
    const [boardList , setBoardList] = useState('');
    const [draftLoading , setDraftLoading] = useState(false);
    const [draft , setDraft] = useState(false);
    const [audio , setAudio] = useState(false);
    const [ isCropperOpen, setCropperOpen ] = useState(false);
    const [ imageForCrop, setImageForCrop ] = useState(null);
    const [showEditor , setShowEditor] = useState(true);
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
    const [errors , setErrors] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const apiResult = useSelector(state => state.audioPost); 
    const getPostResult = useSelector(state => state.post);


    useEffect(()=>{
        dispatch(clearAudioCreate())
    },[])

    useEffect(() => {
        if (savedData.single) {
            setAudioFields(savedData.single);
            if(savedData.single.description){
                const blocksFromHtml = htmlToDraft(savedData.single.description);
                const { contentBlocks, entityMap } = blocksFromHtml;
                const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
                const editorState = EditorState.createWithContent(contentState);
                setEditorState(editorState)
            }
        }
    },[savedData]);


    useEffect(()=>{
        if(getPostResult && getPostResult.boardPostList){
            setBoardList(getPostResult.boardPostList.boardPosts)
        }
    },[getPostResult])



    useEffect(()=>{
        if(getPostResult && getPostResult.boardPostList){
            setBoardList(getPostResult.boardPostList.boardPosts)
        }
    },[getPostResult])

    const handleInput = (e) =>{
        const {value , name } = e.target ; 
        setAudioFields({...audioFields, [name]: value })
        setErrors({ ...errors, [name]: '' })
    }

    const handleCommenting = (e) =>{
        const copyAudioFileds = {...audioFields} ;
        copyAudioFileds.commenting = e.target.checked
        setAudioFields(copyAudioFileds)
    }


    /********************************************* using for crop image  *********************************************/

    const handleCloseCropper = () => {
        setImageForCrop(null)
        setCropperOpen(false)
    }

    const handeSaveCroppedImage = (dataURI) => {
        const imageFile = dataURItoBlob(dataURI);
        const copyAudioFields = {...audioFields}
        copyAudioFields['thumbnail'] = imageFile
        setAudioFields(copyAudioFields)
    }

    const dataURItoBlob = (dataURI) => {
        // convert base64/URLEncoded data component to raw binary data held in a string
        var byteString;
        if (dataURI.split(',')[0].indexOf('base64') >= 0)
            byteString = atob(dataURI.split(',')[1]);
        else
            byteString = unescape(dataURI.split(',')[1]);

        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        // write the bytes of the string to a typed array
        var ia = new Uint8Array(byteString.length);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        
        return new Blob([ia], {type:mimeString});
    }


    const handleOpenCropper = () => {
        setCropperOpen(true)
        let data = {
            src: URL.createObjectURL(audioFields.thumbnail)
        }
        setImageForCrop(data)
    }
/************************************************************************************************************** */


/**---------------------  form validation  -----------------------*/
    const validate = () =>{
        let isValid = true ;
        let errorMessages = {};
        let desc = checkHTML(audioFields.description) ? convertToPlainText(audioFields.description) : audioFields.description
        if (notValid(desc)) {
            errorMessages['description'] = 'Description is required'
            isValid = false;
        }
        if (notValid(audioFields.name)) {
            errorMessages['name'] = 'Title is required'
            isValid = false;
        }

        if(audioFields.images === ''){
            errorMessages['images'] = 'Please upload audio '
            isValid = false;
        }

        if (!isValid) {
            setErrors({ ...errors, ...errorMessages });
        }
        return isValid;
    }

/** ------------------------------   file handling  -----------------------------------------------*/
    const handleAudioFile = (e) =>{
        const copyAudioFile = {...audioFields};
        let errorMessages = {...errors};
        const file = e.target.files[0];
        if (file && file.type.includes('audio/')) {
            copyAudioFile.images = file ; 
            errorMessages.images = ''
            setErrors(errorMessages)
            setAudioFields(copyAudioFile)
        }else{
            errorMessages.images = 'Please select a valid audio file (e.g., MP3, WAV).';
            setErrors(errorMessages)

        }
    }
 

    const handleThumnail = (e)=>{
        const allImages = ['png', 'jpg', 'jpeg', 'JPG', 'JPEG', 'PNG'];
        let errorMessages = {...errors};
        const { files } = e.target ; 
        const copyAudioFile = {...audioFields};
        if(files && files.length < 2 ){
            const file = files[0];
            const fileName = file.name.toLowerCase();
            const fileExtension = fileName.split('.').pop();
            if(allImages.includes(fileExtension)){
                copyAudioFile.thumbnail = file
                setAudioFields(copyAudioFile)
                errorMessages['thumbnail'] = ''
                setErrors(errorMessages)
            }else{
                errorMessages['thumbnail'] = ' Please use only  png, jpg, jpeg, JPG, JPEG, PNG'
                setErrors(errorMessages)
            }    
        }

    }

    const handleRemove = () =>{
        const copyAudioFields = {...audioFields};
        copyAudioFields.images = '' ;
        setAudioFields(copyAudioFields)
    }

    const handleRemoveThubnail = () =>{
        const copyAudioFields = {...audioFields};
        copyAudioFields.thumbnail = '' ;
        setAudioFields(copyAudioFields)
    }
/**** ---------------------------------------------------------------------------------------------------------***/


/**********************    handle description  /////   text editor  function using draft.js for text editing        ******/

    const onEditorStateChange = (editorState) => {
        const copyAudioFields = {...audioFields} ;
        let errorMessages = {...errors};
        const rawContentState = convertToRaw(editorState.getCurrentContent());
        const description = draftToHtml(rawContentState);
        copyAudioFields.description = description
        errorMessages.description = '' ;
        setAudioFields(copyAudioFields)
        setErrors(errorMessages)
        setEditorState(editorState);
    };

    const myKeyBindingFn = event => {
        return getDefaultKeyBinding(event);
    }
/********************************************************************************************************************* */



    const handleCreateAudioPost = (draft) =>{
        const copyAudioFields = {...audioFields}
        if(validate()){
            if(draft){
                copyAudioFields.status = '2'
                setLoading(false)
                setDraftLoading(true)
                setDraft(true)
                setAudio(false)
            }else{
                setLoading(true)
                setDraftLoading(false)
                setAudio(true)
            }
            const formData = new FormData();
            for (const [key, value] of Object.entries(copyAudioFields)) {
                formData.append(key, value)
            }  
            dispatch(createAudioPost(formData)) ;
        }
    }    

    useEffect(()=>{
        if(apiResult && apiResult.createSingleAudio){
            setLoading(false)
            setDraftLoading(false)
            // toast.success(apiResult.createSingleAudio.message, {
            //     position: 'top-center', 
            // });
            if(audio){
                navigate('/stream')
            }
            if(draft){
                navigate('/drafts')
            }
        }
        if(apiResult && apiResult.audioPostError){
            setLoading(false)
            setDraftLoading(false)
            toast.success(apiResult.audioPostError.message, {
                position: 'top-center', 
            });
        }
        setLoading(false)
        setDraftLoading(false)

    },[apiResult])

    const selectBoard = () => {
        if(boardList && boardList.length > 0){
            return (
                <Form.Group className='form-group col-sm-12'>
                    <Form.Label>Realm</Form.Label>
                    <Form.Control value={audioFields.board_id || '' } onChange= {handleBoard} as="select">
                        <option selected>Select</option>
                        {boardList.map((item , idx)=>{
                            return (
                                <option key={idx} value={item.id}>{item.name}</option>
                            )
                        })}
                    </Form.Control>
                </Form.Group>
            )
        }
    }

    const handleBoard = (e)=>{
        let copyAudioFields = {...audioFields};
        copyAudioFields.board_id = e.target.value
        setAudioFields(copyAudioFields)
    } 

    
    const updateParentState = () => {
        saveDataToForm( 'single' , audioFields);
    };

    



    return (
        <div className='create--form'>
            <Form onBlur={updateParentState}>
                <Row>
                    <Form.Group className='form-group col-sm-12'>
                        <Form.Label>Track Title</Form.Label>
                        <Form.Control type="text" name = 'name' value ={audioFields.name} onChange={handleInput} required />
                        {errors.name && <span className="error error-massege text-danger">{errors.name}</span>}
                    </Form.Group>

                    {audioFields.thumbnail 
                        ?
                            <Form.Group className='form-group col-sm-12 col-lg-6'>
                                <div className="post--thumb--upload">
                                    <img src={typeof audioFields.thumbnail === 'object' ? URL.createObjectURL(audioFields.thumbnail) : audioFields.thumbnail}/>
                                    <span className="post--media--edit" onClick={() => handleOpenCropper()}>Edit</span>
                                    <span className="post--media--close" onClick= {handleRemoveThubnail} ><IoClose/></span>
                                </div>
                            </Form.Group>   
                        :
                            <Form.Group className='form-group col-sm-12 col-lg-6'>
                                <input type='file' hidden id="upload--art" onChange={handleThumnail} />
                                <label className='label--upload text-center' htmlFor="upload--art"><BsPlusLg/>Add Cover Art</label>
                                {errors.thumbnail && <small className="error error-massege text-danger">{errors.thumbnail}</small>}
                            </Form.Group>
                    }
                    {audioFields.images 
                    ?
                        <Form.Group className='form-group col-sm-12 col-lg-6'>
                            <div className="post--thumb--upload">
                                <audio controls>
                                    <source src={typeof audioFields.images === 'object' ? URL.createObjectURL(audioFields.images) : audioFields.images} type={audioFields.images.type} />
                                </audio>
                                <span className="post--media--close" onClick = {handleRemove}><IoClose/></span>
                            </div>
                        </Form.Group>

                    :
                        <Form.Group className='form-group col-sm-12 col-lg-6'>
                            <input type='file' hidden id="upload--audio"  accept="audio/*" onChange = {handleAudioFile}/>
                            <label className='label--upload text-center' htmlFor="upload--audio"><BsFileEarmarkMusic/>Upload Audio</label>
                            {errors.images && <small className="error error-massege text-danger">{errors.images}</small>}
                        </Form.Group>
                    }
                    <Form.Group className='form-group col-sm-12'>
                        <span className='editor--tool' onClick = {()=>setShowEditor(!showEditor)}><FaCogs /></span>
                    </Form.Group>
                    <Form.Group className='form-group col-sm-12 text-title-post'>
                        <Form.Label>Description</Form.Label>
                        <Editor
                            editorState={editorState}
                            wrapperClassName="demo-wrapper editor-w"
                            editorClassName="demo-editor input"
                            onEditorStateChange={onEditorStateChange}
                            keyBindingFn={myKeyBindingFn}
                            hashtag={{
                                separator: ' ',
                                trigger: '#',
                            }}
                            toolbarHidden={showEditor}
                            config={{ link: { trailingWhitespace: true } }}
                        />
                        {errors.description && <small className="error error-massege text-danger">{errors.description}</small>}
                    </Form.Group>
                    {selectBoard()}
                    <Form.Group className='form-group col-sm-12'>
                        <Form.Check type="checkbox" label="Allow Comments"  id='commentCheckbox' checked={audioFields.commenting} onChange= {handleCommenting}/>
                        <Form.Label htmlFor="commentCheckbox" onClick={handleCommenting}/>
                    </Form.Group>
                    <Col sm={12} className='text-end'>
                        <Button variant="secondary"  className='me-2' disabled = {loading || draftLoading} onClick={()=>handleCreateAudioPost(true)}>
                            { draftLoading ? <> <Spinner animation="border" size="sm"/> Please wait </> : 'Save to Draft'}
                        </Button>
                        <Button variant="primary" disabled = {loading || draftLoading} onClick={()=>handleCreateAudioPost(false)}>
                            {loading ?  <> <Spinner animation="border" size="sm"/> Please wait </> : 'Publish Now'}
                        </Button>
                    </Col>
                </Row>
            </Form>
            {isCropperOpen && 
                <ImageCropper
                    isOpen={isCropperOpen}
                    src={imageForCrop.src}
                    onClose={() => handleCloseCropper()}
                    result={(val) => handeSaveCroppedImage(val)}
                />
            }
        </div>
    );
}

export default CreateSingleAudio;