import moment from 'moment-timezone'

const Email = new RegExp(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i); 
const Dateformat= new RegExp(/^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/i);
const Alphanumeric = new RegExp(/^[A-Za-z\d\s]+$/);
const Aphabeticals = new RegExp(/^[a-zA-Z ]*$/);
const Numeric = new RegExp(/^[0-9]+$/);
const Amount = new RegExp(/^\$?[\d,]+(\.\d*)?$/);

export const removeExtraSpaces = (string) => {
    return string.replace(/\s{2,}/g, ' ').trim()
}

export const notValid = string => {
    return [null, undefined, 'null', 'undefined', ''].includes(removeExtraSpaces(string))
}

export const valid = string => {
    const check = [null, undefined, 'null', 'undefined', ''] ; 
    if(!check.includes(string)){
        return true
    }else{
        return false
    }
    
}



export const emailValidation = email => Email.test(email);

export const capitalizeFirstLetterEachWord = (str) => {
    if(!str) return '';
    str = str.split(" ");
    for (var i = 0, x = str.length; i < x; i++) {
        str[i] = str[i][0].toUpperCase() + str[i].substr(1);
    }
    return str.join(" ");
}

export const dateFormatValidation = date => Dateformat.exec(date);
export const alphanumeicValidation = text => Alphanumeric.test(text);
export const aphabeticalsValidation = text => Aphabeticals.test(text);
export const numericValidation = number => Numeric.test(number);
export const amountValidation = amount => Amount.test(amount);
export const removeCommaInDigit = (value) => {
    if(!value) return ;
    return parseFloat(value.split(',').join(''))
}

export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const extractDate = (date) => {
    if(!date instanceof Date) return date;
    return date.split('T')[0];
}

export const formatPrice = (value) => {
    if(!value) return value;
    if(value <= 1000) return value;
    var nStr = value + '';
    nStr = nStr.replace(/\,/g, "");
    let x = nStr.split('.');
    let x1 = x[0];
    let x2 = x.length > 1 ? '.' + x[1] : '';
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    return x1 + x2;
}

export const formatPhoneNumber = (value) => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, '');
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return`(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3,6)}-${phoneNumber.slice(6, 10)}`;
}

export function kFormatter(num) {
    if(!num) return num;
    return Math.abs(num) > 999 ? Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) + 'k' : Math.sign(num)*Math.abs(num)
}


export const formatOTP = (value) => {
    if (!value) return value;
    const otp = value.replace(/[^\d]/g, '');
    const otpLength = otp.length;
    if (otpLength <= 6) return otp;

    return null;
}



export function checkHTML (param){
    return new RegExp(/(\<\w*)((\s\/\>)|(.*\<\/\w*\>))/gm).test(param)
}


export function convertToPlainText(html){
    const parser = new DOMParser();
    const parsedDocument = parser.parseFromString(html, 'text/html');
    return parsedDocument.body.textContent || '';
};


export function filterPostCat(likes , singlePost){
    const postLikes = likes.filter((like , ind)=>{
        if(like.postId === singlePost.id){
            return like
        }
    })
}


export const isValueNotNull = (value) => {
    const check = ["", null, "null", undefined, "undefined"];
    return !check.includes(value)
}


export const genrateOrderNumber = () =>{
    const timestamp = new Date().getTime();
    const randomNumber = Math.floor(Math.random() * 10000);
    const orderID = `${timestamp}${randomNumber}`;
    return orderID
}


function convertToOffset(inputString, isTime = false, targetOffset) {
    // Parse the input string as UTC datetime if not already in UTC
    const utcMoment = moment.utc(inputString);
    // Apply offset only if converting time (not date)
    const offsetMoment = utcMoment.utcOffset(targetOffset)

    // return isTime ? offsetMoment.format('HH:mm') : offsetMoment.format("LL");
    return isTime ? offsetMoment : offsetMoment
}


function convertDateTimeToOffset(dateString, startTimeString, endTimeString, targetOffset) {
    if(!targetOffset){
        targetOffset = -300
    }
    const date = dateString ? convertToOffset(dateString, false, targetOffset) : '' ;
    const startTime = startTimeString ? convertToOffset(startTimeString, true, targetOffset) : '';
    const endTime = endTimeString ? convertToOffset(endTimeString, true, targetOffset) : '';

    
    return { date, startTime, endTime };
}


export const generateTimeOptions = (selectedDate, startTime) => {
    const timeOptions = [];
    const currentTime = new Date(); // Get the current time
    const areSameDate = moment(selectedDate).isSame(moment(currentTime), 'day');

    for (let hour = 0; hour < 24; hour++) {
        for (let minute = 0; minute < 60; minute += 15) {
            const formattedHour = hour.toString().padStart(2, '0');
            const formattedMinute = minute.toString().padStart(2, '0');
            const time = `${formattedHour}:${formattedMinute}`;

            // Check if the startTime is provided and the current time is smaller or equal to it
            if (startTime && `${formattedHour}:${formattedMinute}` <= startTime) {
                timeOptions.push({ time, disabled: true });
            } else {
                // Check if the option time is in the past relative to the current time
                if (areSameDate) {
                    const optionTime = new Date(); // Create a new Date object for the current option time
                    optionTime.setHours(hour, minute - 15); // Set the hours and minutes for the option time

                    if (optionTime < currentTime) {
                        // If the option time is in the past or equal to the current time, add the 'disabled' attribute to the option
                        timeOptions.push({ time, disabled: true });
                        continue; // Skip to the next iteration
                    }
                }

                // If the option time is in the future or startTime is not provided, add the option without the 'disabled' attribute
                timeOptions.push({ time });
            }
        }
    }
    return timeOptions;
};


export function isSameTime(date1, date2) {
    const d1 = new Date(date1);
    const d2 = new Date(date2);
  
    return (
      d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate() &&
      d1.getHours() === d2.getHours() &&
      d1.getMinutes() === d2.getMinutes()
    );
}


export const momentFormat = {
    timeZone:(dateTime) => {
        const tz = moment().format('z')
        return tz
    },
    timeInHHmm: (dateTime) => {
        return moment(dateTime).format('HH:mm')
    },
    dateTimeFormat: (dateTime) => {
        return moment(dateTime).format('lll')
    },
    timeFormat: (time) => {
        return moment(time).format('LT')
    },
    dateFormat: (date) => {
        return moment(date).format('YYYY-MM-DD');
    },
    fullDate: (date) => {
        return moment(date).format('LL');
    },
    formatReactCalendarDate: (date) => {
        date = new Date(date)
        let year = date.getYear();
        let month = date.getMonth();
        let dt = date.getDate();
        return new Date(year, month, dt);
    },
    formatReactCalendarTime: (date) => {
        date = new Date(date)
        let hr = date.getHours();
        let min = date.getMinutes();

        hr = hr > 9 ? hr : `0${hr}`;
        min = min > 9 ? min : `0${min}`;

        return `${hr}:${min}`
    },
    correctDate: (date) => {
        date.setDate(date.getDate() - 1);
        return date;
    },
    combineDateTime: (date, time) => {
        date = typeof date === 'string' ? new Date(date) : date
        if (time) {
            let _time = time.split(':');
            date.setHours(_time[0]);
            date.setMinutes(_time[1]);
        }
        return date;
    },
    formatWeekDate: (date) => {
        // return moment(date).format('dddd, DD MMMM, YY');
        return moment(date).format('dddd, MM/DD/YY');
    },

    increaseDateByOne: (date) => {
        date.setDate(date.getDate() + 1);
        return date;
    },
    convertTimeSlots: (data) => {
        let times = []
        data.forEach(d => {
            let start = moment(d.start).format('HH:mm')
            let end = moment(d.end).format('HH:mm')

            times.push({ start, end })
        })

        return times;
    },

}

export const validateNumber = (value) => {
    if (!value) return value;
    const number = value.replace(/[^\d]/g, '');
    return number;
}


// const generateTimeSlots = () => {
//     const timeSlots = [];
//     let hour = 9; // Start time in hours
//     let minute = 0; // Start time in minutes
  
//     // Loop to generate time slots with half-hour interval
//     while (!(hour === 17 && minute === 0)) { // Assuming working hours end at 6:00 PM
//       const startTime = `${hour}:${minute === 0 ? '00' : '30'}`;
//       const nextHour = minute === 30 ? hour + 1 : hour;
//       const nextMinute = minute === 30 ? 0 : 30;
//       const endTime = `${nextHour}:${nextMinute === 0 ? '00' : '30'}`;
  
//       timeSlots.push(`${startTime} to ${endTime}`);
  
//       // Increment time by half-hour
//       hour = nextHour;
//       minute = nextMinute;
//     }
  
//     return timeSlots;
// };
const generateTimeSlots = () => {
    const timeSlots = [];
    let hour = 0; // Start time at 12:00 AM
    let minute = 0; // Start time in minutes

    // Loop to generate time slots with half-hour interval
    while (!(hour === 23 && minute === 30)) { // Stop when it reaches 11:30 PM
        // Convert 24-hour time to 12-hour format with AM/PM
        const startHour = hour === 0 ? 12 : hour > 12 ? hour - 12 : hour;
        const startPeriod = hour < 12 ? 'AM' : 'PM';
        const startTime = `${startHour}:${minute === 0 ? '00' : '30'} ${startPeriod}`;

        const nextHour = minute === 30 ? hour + 1 : hour;
        const nextMinute = minute === 30 ? 0 : 30;
        const endHour = nextHour === 0 ? 12 : nextHour > 12 ? nextHour - 12 : nextHour;
        const endPeriod = nextHour < 12 ? 'AM' : 'PM';
        const endTime = `${endHour}:${nextMinute === 0 ? '00' : '30'} ${endPeriod}`;

        timeSlots.push(`${startTime} to ${endTime}`);

        // Increment time by half-hour
        hour = nextHour;
        minute = nextMinute;
    }

    return timeSlots;
};


function parseStartTimeSlot(timeSlotString , startDate) {
    if (!(startDate instanceof Date)) {
        startDate = new Date(startDate);
    }
    const [startTimeString, endTimeString] = timeSlotString.split(" to ");
    const [time, period] = startTimeString.trim().split(' ');
    const [startHourString, startMinuteString] = startTimeString.split(":");
    let startHour = parseInt(startHourString, 10);
    const startMinute = parseInt(startMinuteString, 10);
    // Convert 12-hour format to 24-hour format
    if (period === 'PM' && startHour !== 12) {
        startHour += 12;
    } else if (period === 'AM' && startHour === 12) {
        startHour = 0; // Midnight case
    }
    const start = new Date(
        startDate.getFullYear(),
        startDate.getMonth(),
        startDate.getDate(),
        startHour,
        startMinute
    );

    return start 
}

function parseEndTimeSlot(timeSlotString , endDate) {

    if (!(endDate instanceof Date)) {
        endDate = new Date(endDate);
    }
    const [startTimeString, endTimeString] = timeSlotString.split(" to ");
    const [time, period] = endTimeString.trim().split(' ');
    const [endHourString, endMinuteString] = endTimeString.split(":");
    let endHour = parseInt(endHourString, 10);
    const endMinute = parseInt(endMinuteString, 10);  
    
    if (period === 'PM' && endHour !== 12) {
        endHour += 12;
    } else if (period === 'AM' && endHour === 12) {
        endHour = 0; // Midnight case
    }

    const end = new Date(
        endDate.getFullYear(),
        endDate.getMonth(),
        endDate.getDate(),
        endHour,
        endMinute
    );
  
    return end
}

export {
    convertDateTimeToOffset ,
    parseStartTimeSlot ,
    parseEndTimeSlot , 
    generateTimeSlots 
}