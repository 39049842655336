const apiKeys = {
    auth:'/auth',
    user:'/user',
    post: '/post',
    marketplace: '/marketPlace',
    search:'/search',
    messenger:'/messenger' ,
    schedule: '/schedule'
};
  
export default apiKeys;