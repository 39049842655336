import React, { useEffect, useState } from "react";
import {useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toast} from 'react-hot-toast';
import { Col, Button, Form , Alert} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { notValid, emailValidation, formatOTP } from "../../utils/validations";
import { requestOtp,resetPassword, verifyOtp} from "../../redux/actions/auth.action";
import '../../App.css';
import './authStyle.css';

function ResetPassword ({hideResetModel}){
    const alertValue = { message: '', show: false, variant: ''};
    const[alert , setAlert] = useState({...alertValue})
    const [user , setUser] = useState({email:'' , password: '' }) ; 
    const [loading , setLoading] = useState(false);
    const [errors , setErrors] = useState('');
    const [ show, setShow ] = useState({ emailInput: true, otpInput:false, passwordInput: false });
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const apiResult = useSelector(state => state.auth);


    const handleOnChange = (e) =>{
        const {name , value } = e.target ;
        if(name === 'otp'){
            const otp = formatOTP(value);
            if(otp || otp === ''){
                setUser({ ...user, [name]:otp});
            }
        }else{
            setUser({ ...user, [name]: value })
        }

        setErrors({ ...errors, [name]: '' })
        setAlert(alertValue);
    }

    /**   using for email validation  */

    const validateEmail = () =>{
        let isValid = true
        let validEmail = "Please enter a valid email address";
        let email = "Please enter your email";
        let errorMessages = {};
        if (notValid(user.email)) {
            errorMessages['email'] = email;
            isValid = false;
        }else if (!emailValidation(user.email)) {
            errorMessages['email'] = validEmail;
            isValid = false;
        }
        if (!isValid) {
            setErrors({ ...errors, ...errorMessages });
        }
        return isValid;
    }

    /**   using for password validation  */
    const validatePassword = () =>{
        let isValid = true ; 
        let passwordErrorMessage = "Please provide password";
        let errorMessages = {};
        if (notValid(user.password)) {
            errorMessages['password'] = passwordErrorMessage;
            isValid = false;
        }else if (user.password.length < 6 ){
            errorMessages['password'] = ' Password must be 6 characters long. Please enter a password with 6 characters. ';
            isValid = false;
        }
        if (!isValid) {
            setErrors({ ...errors, ...errorMessages });
        }
        return isValid;
    }

    /**  ----- Requested For OTP ------  */
    const handleRequestOtp = (e) =>{
        e.preventDefault();
        setAlert({ ...alertValue });
        if (validateEmail()){
            setLoading(true);
            // dispatch(requestResetPassword(user))
            dispatch(requestOtp({ email: user.email }));
        }
    }

    /** ---- Submit For OTP ---- */
    const handleSubmitCode = (e) => {
        e.preventDefault();
        setAlert({ ...alertValue });
        if(user.otp){
            setLoading(true);
            dispatch(verifyOtp({ email: user.email, otp: user.otp }));
        }
    }


    /** ---- Submit For Password ---- */
    const handleSubmitPassword = (e) =>{
        e.preventDefault();
        setAlert({ ...alertValue });
        if(validatePassword()){
            setLoading(true);
            dispatch(resetPassword(user))
        }
    }

    useEffect(() => {
        if(apiResult){
            if(apiResult.requestOtp && apiResult.requestOtp.success){
                const _alert = alert;
                _alert['show'] = true
                _alert['message'] = apiResult.requestOtp.message || apiResult.requestOtp
                _alert['variant'] = "success"
                setAlert(_alert);
                setLoading(false);
                setShow({ 
                    emailInput: false,
                    otpInput: true,
                    passwordInput: false,
                });
            }

            if(apiResult.verifyOtp && apiResult.verifyOtp.success){
                setLoading(false);
                setShow({ 
                    emailInput: false,
                    otpInput: false,
                    passwordInput: true,
                });
            }

            if(apiResult.resetPasswordSuccess){
                toast.success(apiResult.resetPasswordSuccess.message , {
                    position: 'top-center', 
                });
                setLoading(false)
                setShow({ 
                    emailInput: true,
                    otpInput: false,
                    passwordInput: false,
                });
                // window.location.replace('/login')
                hideResetModel(false)
            }

            if( apiResult.error ){
                const _alert = alert;
                _alert['show'] = true
                _alert['message'] = apiResult.error
                _alert['variant'] = "danger"

                setAlert(_alert);
                setLoading(false)
            }

            if(apiResult && apiResult.otpError){
                const _alert = alert;
                _alert['show'] = true
                _alert['message'] = apiResult.otpError.message || apiResult.otpError
                _alert['variant'] = "danger"
                setAlert(_alert);
                setLoading(false)
            }

            if (apiResult && apiResult.otpVerifyError){
                const _alert = alert;
                _alert['show'] = true
                _alert['message'] = apiResult.otpVerifyError.message ||  apiResult.otpVerifyError
                _alert['variant'] = "danger"
                setAlert(_alert);
                setLoading(false)
            }

        }
    }, [apiResult])

    const showAlertMessage = () => {
        return (
            <Alert variant={alert.variant}> {alert['message']} </Alert>
        )
    }

   /** using for send the requset for reset the password  */
    const emailInput = ()=>{
        return(
            <Form onSubmit = {handleRequestOtp}>
                <h4> Send Requset To Reset Password </h4>
                {alert['show'] && showAlertMessage()}
                <Form.Group className='form-group'>
                    <Form.Control type="email" placeholder="Email" name = 'email' value = {user.email} onChange = {handleOnChange} />
                    {errors.email && <small className="error error-massege text-danger">{errors.email}</small>}
                </Form.Group>
                <Form.Group className='form-group'>
                    <Button variant="primary" type="submit">{loading ? 'Please Wait... ' : ' Submit '}</Button>    
                </Form.Group>
            </Form>    
        )
    }

    const otpInput = () => {
        return (
            <Form onSubmit={handleSubmitCode}>
                <h4> Enter Code to Verify Email </h4>
                {alert['show'] && showAlertMessage()}
                <Form.Group className='form-group'>
                    <Form.Control type="password" placeholder="Password" name='otp' value={user.otp} onChange={handleOnChange} />
                    {errors.otp && <small className="error error-massege text-danger">{errors.otp}</small>}
                </Form.Group>
                <Form.Group className='form-group'>
                    <Button variant="primary" type="submit">{loading ? 'Please Wait... ' : ' Submit '}</Button>    
                </Form.Group>
            </Form>
        )
    }

    /** using for  set the new password  /////  update Password  */
    
    const passwordInput = () =>{
        return(
            <Form onSubmit={handleSubmitPassword}>
                <h4> Enter New Password </h4>
                {alert['show'] && showAlertMessage()}
                <Form.Group className='form-group'>
                    <Form.Control type="password" placeholder="Password" name='password' value={user.password} onChange={handleOnChange} />
                    {errors.password && <small className="error error-massege text-danger">{errors.password}</small>}
                </Form.Group>
                <Form.Group className='form-group'>
                    <Button variant="primary" type="submit">{loading ? 'Please Wait...' : ' Submit '}</Button>    
                </Form.Group>
            </Form>    
        )
    }

    return(
        <Col sm={12} className='text-start'>
            { show.emailInput &&  emailInput() }
            { show.otpInput && otpInput() }
            { show.passwordInput && passwordInput() }
        </Col>          
    )
}

export default ResetPassword ;