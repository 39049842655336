import React, { useEffect , useState } from 'react';
import { useNavigate , Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Container, Row, Col, Form , Spinner} from 'react-bootstrap';
import { toast, Toaster } from "react-hot-toast";  
import {IoClose} from 'react-icons/io5';
import { BsArrowLeft } from 'react-icons/bs';
import PageHeader from '../Header/HeaderTop';
import SidebarMenu from '../Sidebar/SidebarNav';
import { getShopCatgory , createShop} from '../../redux/actions/shop.action';
import 'bootstrap/dist/css/bootstrap.min.css';
import './createStyle.css';
import '../../App.css';


function CreateShopForm() {
    const intialValue = {title: '',category: '',video: '', thumbnail: '', description: ''}
    const [shopFields , setShopFileds] = useState(intialValue);
    const [categories ,  setCategories] = useState(false);
    const [loading , setLoading] = useState(false) ;
    const [errors , setErrors] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const apiResult = useSelector(state => state.shop)

    useEffect(()=>{
        dispatch(getShopCatgory())
    },[])

    useEffect(()=>{
        if(apiResult && apiResult.shopCatList){
            setCategories(apiResult.shopCatList.shopCatList)
        }
        if(apiResult && apiResult.shopCreated){
            toast.success(apiResult.shopCreated.message, {
                position: 'top-center', 
            });
            navigate('/my-shop')
        }

        if(apiResult && apiResult.shopCreateError){
            toast.success(apiResult.shopCreateError, {
                position: 'top-center', 
            });
        }
        setLoading(false)
    },[apiResult])

    const handleInput = (e) =>{
        const {value , name } = e.target ; 
        setShopFileds({...shopFields, [name]: value })
        setErrors({ ...errors, [name]: '' })
    }

    const handleImages = (e ,section)=>{
        const allImages = ['png', 'jpg', 'jpeg', 'JPG', 'JPEG', 'PNG', 'gif'];
        let errorMessages = {...errors};
        const { files } = e.target ; 
        const copyShopsFileds = {...shopFields}
        if(files && files.length < 2 ){
            const file = files[0];
            const fileName = file.name.toLowerCase();
            const fileExtension = fileName.split('.').pop();
            if(allImages.includes(fileExtension)){
                if(section === 'header'){
                    copyShopsFileds.video = file
                }else{
                    copyShopsFileds.thumbnail = file
                }    
                setShopFileds(copyShopsFileds)
            }else{
                if(section === 'header'){

                    errorMessages['header'] = ' Please use only  png, jpg, jpeg, JPG, JPEG, PNG'
                }else{
                    errorMessages['logo'] = ' Please use only  png, jpg, jpeg, JPG, JPEG, PNG'
                }
                setErrors(errorMessages)
            }    
        }
    }


    const selecteCategory = () => {
        if(categories && categories.length > 0){
            return (
                <Form.Group className='form-group col-sm-12 col-lg-6'>
                    <Form.Label>Category</Form.Label>
                    <Form.Label>(optional) </Form.Label>
                    <Form.Control value={shopFields.category || '' } onChange= {handleCategory} as="select">
                        <option selected>Select</option>
                        {categories.map((category, index) => (
                            <optgroup label={`${category.name} (${category.marketplaceSubCategories.length})`}>
                                {category.marketplaceSubCategories.map((subcategory, subIndex) => (
                                    <option key={subIndex} value={subcategory.id}>
                                    {subcategory.name}
                                    </option>
                                ))}
                            </optgroup>
                            
                        ))}
                    </Form.Control>
                </Form.Group>

            )
        }
    }

    const handleCategory = (e)=>{
        let copyShopsFileds = {...shopFields};
        copyShopsFileds.category = e.target.value
        setShopFileds(copyShopsFileds)
    }

    const handleRemoveFile = (section) =>{
        let copyShopsFileds = {...shopFields};
        if(section === 'header'){
            copyShopsFileds.video = ''
        }else{
            copyShopsFileds.thumbnail = ''
        }
        setShopFileds(copyShopsFileds)
    }


    const isValid = () =>{
        let isValid = true ;
        let errorMessages = {};
        const check = ['null' , null , undefined , 'undefined' , '' , []]
        if (check.includes(shopFields.description)) {
            errorMessages['description'] = 'Description is required'
            isValid = false;
        }
        if (check.includes(shopFields.title)) {
            errorMessages['title'] = 'Title is required'
            isValid = false;
        }

        if(check.includes(shopFields.video)){
            errorMessages['header'] = 'Please upload header '
            isValid = false;
        }
        if(check.includes(shopFields.thumbnail)){
            errorMessages['logo'] = 'Please upload logo '
            isValid = false;
        }
        if (!isValid) {
            setErrors({ ...errors, ...errorMessages });
        }
        return isValid;
    }


    const handleCreateShop = (e) =>{
        e.preventDefault();
        if(isValid()){
            setLoading(true)
            const formData = new FormData();
            for (const [key, value] of Object.entries(shopFields)) {
                formData.append(key, value)
            }  
            dispatch(createShop(formData)) ;
        }
    }


    return (
        <>
            <PageHeader />
            <div className='page--content'>
                <SidebarMenu />
                <div className='discover--module common--wrapper create--shop--form'>
                    <Container>
                        <Row className='justify-content-center pt-3'>
                            <Col sm={12} lg={10}>
                                <Link className='back--btn' to='/marketplace'><BsArrowLeft/> Back</Link>
                                <Form onSubmit ={handleCreateShop}>
                                    <Row>
                                        <Form.Group className='form-group col-sm-12 col-lg-6'>
                                            <Form.Label>Shop Title</Form.Label>
                                            <Form.Control type="text" name='title' value = {shopFields.title} onChange={handleInput} required />
                                            {errors.name && <small className="error error-massege text-danger">{errors.name}</small>}
                                        </Form.Group>
                                        {selecteCategory()}
                                        {
                                            shopFields.video 
                                            ?    <Form.Group className='form-group col-sm-12 col-lg-6'>
                                                    <div className="post--thumb--upload">
                                                        <img src={typeof shopFields.video === 'object' ? URL.createObjectURL(shopFields.video) : shopFields.video}/>
                                                        <span className="post--media--close" onClick = {()=>handleRemoveFile('header')}><IoClose/></span>
                                                    </div>
                                                </Form.Group>

                                            :   
                                                <Form.Group className='form-group col-sm-12 col-lg-6'>
                                                    <Form.Label>Shop Header</Form.Label>
                                                    <input type='file' hidden id="upload--header"  onChange ={(e)=>handleImages(e , 'header')}/>
                                                    <label className='label--upload' htmlFor="upload--header">Choose File ( Preferred Size 1100 X 200 )</label>
                                                    {errors.header  && <small className="error error-massege text-danger">{errors.header}</small>}
                                                </Form.Group>
                                        }
                                        {
                                            shopFields.thumbnail
                                            ?    <Form.Group className='form-group col-sm-12 col-lg-6'>
                                                        <div className="post--thumb--upload">
                                                            <img src={typeof shopFields.thumbnail === 'object' ? URL.createObjectURL(shopFields.thumbnail) : shopFields.thumbnail}/>
                                                            <span className="post--media--close" onClick = {()=>handleRemoveFile('logo')}><IoClose/></span>
                                                        </div>
                                                </Form.Group>
                                            :
                                                <Form.Group className='form-group col-sm-12 col-lg-6'>
                                                    <Form.Label>Shop Logo</Form.Label>
                                                    <input type='file' hidden id="upload--logo"  onChange ={(e)=>handleImages(e , 'logo')}/>
                                                    <label className='label--upload' htmlFor="upload--logo">Choose File ( Preferred Size 300 X 165 )</label>
                                                    {errors.logo && <small className="error error-massege text-danger">{errors.logo}</small>}
                                                </Form.Group>
                                        }
                                        <Form.Group className='form-group col-sm-12'>
                                            <Form.Label>Shop Description</Form.Label>
                                            <Form.Control as="textarea" name='description' value = {shopFields.description} onChange={handleInput} required  rows={3} />
                                        </Form.Group>
                                        <Col sm={12} className='text-end'>
                                            <Button disabled={loading} variant="primary" type="submit">
                                                {loading ?  <> <Spinner animation="border" size="sm"/> Please wait </> : 'Create Shop'}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                    </Container>
                    <Toaster/>
                </div>
            </div>
        </>
    );
}

export default CreateShopForm;

