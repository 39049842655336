import React, { Component} from 'react'
import { connect } from 'react-redux';
import Slider from "react-slick";
import { Container, Row, Col, Button } from 'react-bootstrap';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';
import { BsPlayFill } from 'react-icons/bs';
import { FaRegUser, FaRegCalendarAlt } from 'react-icons/fa';
import { getBoardPostList , getDiscoverPagePosts} from '../../redux/actions/post.action';
import PageHeader from '../Header/HeaderTop';
import SidebarMenu from '../Sidebar/SidebarNav';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import './discoverStyle.css';
import '../../App.css';
import sliderSetting from '../../utils/sliderSetting';
import moment from 'moment';
import { checkHTML , convertToPlainText , valid} from '../../utils/validations';



class DiscoverMain  extends Component {
    constructor(props){
        super(props)
        this.state =  {
            postList : {},
            loading : false, 
            page : 1,
            perPage : 10,
            loadingState : false,
            isActive : true
        }
    }    

    componentDidMount = async() => {
        await this.getPostsList();
    }

    wordsLimit = (text) =>{
        let content = convertToPlainText(text)
        const words = content.split(' ');
        if (words.length <= 20) {
          return content
        } else {
          const truncatedWords = words.slice(0, 50);
          content = truncatedWords.join(' ') + '...'
          return content
        }

    }
    
    getPostsList = async() =>{
        const{postList} = this.state;
        const data = {status:'1' , perPage:10, page:1};
        this.setState({loading:true})
        await this.props.getDiscoverPagePosts()
        await this.props.getBoardPostList();
        if(this.props?.discoverPostList  && this.props?.discoverPostList?.success){
            if(this?.props?.discoverPostList?.discoverPosts?.textPost){
                postList.textPosts = this.props?.discoverPostList?.discoverPosts.textPost
            }
            if(this?.props?.discoverPostList?.discoverPosts?.visualPost){
                postList.visualPost = this.props.discoverPostList?.discoverPosts?.visualPost
            }
            if(this?.props?.discoverPostList?.discoverPosts?.videoPost || this.props?.discoverPostList?.discoverPosts?.liveVideoPost){
                postList.videoPost = [
                    ...(this.props.discoverPostList.discoverPosts.videoPost || []),
                    ...(this.props.discoverPostList.discoverPosts.liveVideoPost || [])
                ];
            }
            if(this?.props?.discoverPostList?.discoverPosts?.liveVideoPost){
                postList.liveVideoPost = this.props?.discoverPostList?.discoverPosts?.liveVideoPost
            }
            if(this?.props?.discoverPostList?.discoverPosts?.audioPost){
                postList.audioPost = this.props?.discoverPostList?.discoverPosts?.audioPost
            }
            this.setState({loading:false , postList})
        }
        if(this.props.boardPost && this.props?.boardPost?.success){
            postList.boardPost = this.props?.boardPost?.boardPosts
        }
       
    }

    textPostSlider = () =>{
        const {postList} = this.state ; 
        const check= [null , undefined , 'undefined' , '' , [] , 'null']
            if(postList.textPosts && postList.textPosts.length > 0){
                return (
                    <Slider className='grid--Slider' {...sliderSetting.settingsAudio}>
                        {
                            postList.textPosts.map((item , idx)=>{
                                if(item.user && item.user.status ==='1'){
                                    return (
                                        <div key={idx}>
                                            <div className="Gr--singleStream">
                                                <Link to={`/text/${item.slug}`}>
                                                    {valid(item.thumbnail) ?
                                                        <div className="Gr--streamImage">
                                                            <LazyLoadImage
                                                                width="100%"
                                                                placeholderSrc="/images/placeholder.gif"
                                                                effect="blur"
                                                                src={item.thumbnail} 
                                                                alt={item.name}
                                                            /> 
                                                        </div>
                                                        :
                                                        <div className="Gr--streamImage">
                                                            <Link to={`/text/${item.slug}`}>
                                                                {/* <pre>{checkHTML(item.description) ? convertToPlainText(item.description) : item.description}</pre> */}
                                                                <div className='pre--para' dangerouslySetInnerHTML={{ __html: item.description}} ></div>
                                                            </Link>
                                                        </div>
                                                    }
                                                    <div className="Gr--streamDesc">
                                                        <Link to={`/${item.user.username}`}>
                                                            <img src={(item.user && valid(item.user.image)) ? item.user.image : "/images/user.png"} alt='...' />
                                                        </Link>
                                                        <div className="Gr--streamUser">
                                                            <h4><Link to={`/text/${item.slug}`}>{checkHTML(item.name) ? convertToPlainText(item.name) : item.name}</Link></h4>
                                                            <Link to={`/${item.user.username}`}>
                                                                <p><FaRegUser/> {(item.user && valid(item.user.first))? item.user.first : ''}</p>
                                                            </Link>
                                                            <p><FaRegCalendarAlt /> {moment(item.createdAt).format('LL')}</p>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                            
                                        </div>
                                    )  
                                }      
                            })
                        }
                    </Slider>    
                )
            }        
    }

    visualPostSlider = () =>{
        const {postList} = this.state ; 
        if(postList.visualPost && postList.visualPost.length > 0){
            return(
                <Slider className='grid--Slider' {...sliderSetting.settingsAudio}>
                    {postList.visualPost.map((item , idx)=>{
                        return (
                            <div>
                                <div className="Gr--singleStream">
                                    <div className="Gr--streamImage">
                                        <Link to={`/visual/${item.slug}`}>
                                            <LazyLoadImage
                                                width="100%"
                                                placeholderSrc="/images/placeholder.gif"
                                                effect="blur"
                                                src={item.thumbnail || item.Postmeta[0].meta_value }
                                                alt={item.name}
                                            /> 
                                        </Link>
                                    </div>
                                    <div className="Gr--streamDesc">
                                        <Link to={`/${item.user.username}`}>
                                            <img src={(item.user && valid(item.user.image)) ? item.user.image : "/images/user.png"} alt='...' />
                                        </Link>
                                        <div className="Gr--streamUser">
                                            <h4><Link to={`/visual/${item.slug}`}>{checkHTML(item.name) ? convertToPlainText(item.name) : item.name}</Link></h4>
                                            <Link to={`/${item.user.username}`}>
                                                <p><FaRegUser/> {(item.user && valid(item.user.first))? item.user.first : ''}</p>
                                            </Link>
                                            <p><FaRegCalendarAlt /> {moment(item.createdAt).format('LL')}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </Slider>
            )
        }
    }

    videoPostSlider = () =>{
        const {postList} = this.state ; 
        if(postList.videoPost && postList.videoPost.length > 0){
            return (
                <Slider className='grid--Slider' {...sliderSetting.settingsAudio}>
                    {
                        postList.videoPost.map((item , idx)=>{
                            let url = '';
                            if (item.PostCategory.slug === 'Live-Video') {
                                url = `/live-video/${item.slug}`;
                            }
                            else {
                                url = `/video/${item.slug}`;
                            }
                            return (
                                <div>
                                    <div className="Gr--singleStream">
                                        <div className="Gr--streamImage">
                                            <Link to={url}>
                                                <LazyLoadImage
                                                    width="100%"
                                                    placeholderSrc="/images/placeholder.gif"
                                                    effect="blur"
                                                    src={valid(item.thumbnail) ? item.thumbnail : '/images/thumbnail.jpg'} 
                                                    alt={checkHTML(item.name) ? convertToPlainText(item.name) : item.name}
                                                /> 
                                            </Link>
                                        </div>
                                        <div className="Gr--streamDesc">
                                            <Link to={`/${item.user.username}`}>
                                                <img src={(item.user && valid(item.user.image)) ? item.user.image : "/images/user.png"} alt='...' />
                                            </Link>
                                            <div className="Gr--streamUser">
                                                <h4><Link to={url}>{checkHTML(item.name) ? convertToPlainText(item.name) : item.name}</Link></h4>
                                                <Link to={`/${item.user.username}`}>
                                                    <p><FaRegUser/> {(item.user && valid(item.user.first))? item.user.first : ''}</p>
                                                </Link>
                                                <p><FaRegCalendarAlt /> {moment(item.createdAt).format('LL')}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </Slider>
            )
        }
    }
    

    audioPostSlider = () =>{
        const {postList} = this.state ; 
        if(postList.audioPost && postList.audioPost.length > 0){
            return(
                <Slider className='grid--Slider' {...sliderSetting.settingsAudio}>
                    {
                        postList.audioPost.map((item , idx)=>{
                            let url = '';
                            if (item.Postmeta.length) {
                                url = `/audio/album/${item.slug}`;
                            }
                            else {
                                url = `/audio/single/${item.slug}`;
                            }
                            return(
                                <div>
                                    <div className="Gr--singleStream">
                                        <div className="Gr--streamImage">
                                            <Link to={url}>
                                                <LazyLoadImage
                                                    width="100%"
                                                    placeholderSrc="/images/placeholder.gif"
                                                    effect="blur"
                                                    src={valid(item.thumbnail) ? item.thumbnail: '/images/thumb-400kb.jpg'}
                                                    alt={item.name}
                                                /> 
                                            </Link>
                                        </div>  
                                        <div className="Gr--streamDesc">
                                            <Link to={`/${item.user.username}`}>
                                                <img src={(item.user && valid(item.user.image)) ? item.user.image : "/images/user.png"} alt='...' />
                                            </Link>
                                            <div className="Gr--streamUser">
                                                <h4><Link to={url}>{checkHTML(item.name) ? convertToPlainText(item.name) : item.name}</Link></h4>
                                                <Link to={`/${item.user.username}`}>
                                                    <p><FaRegUser/> {(item.user && valid(item.user.first))? item.user.first : ''}</p>
                                                </Link>
                                                <p><FaRegCalendarAlt /> {moment(item.createdAt).format('LL')}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </Slider>
            )
        }
    }
    renderLivePost = () =>{
        const {postList} = this.state ; 
        return (
            <>
                <h1>Discover</h1>
                <div id="liveVideo_grid" className='Live--video--grid'>
                    { (postList.liveVideoPost  && postList.liveVideoPost.length > 0 ) 
                        ?   <div className='bigVideo__wrap div1'>
                                <div class="Gr--slider--Desc">
                                    <Link className="Gr--post--image" to={`/live-video/${postList.liveVideoPost[0].slug}`}>
                                        <span className='video--play--icon'><BsPlayFill /></span>
                                        <LazyLoadImage
                                            width="100%" height="100%"
                                            placeholderSrc="/images/placeholder.gif"
                                            effect="blur"
                                            src={valid(postList.liveVideoPost[0].thumbnail) ? postList.liveVideoPost[0].thumbnail : '/images/thumbnail.jpg'}
                                            alt={checkHTML(postList.liveVideoPost[0].name) ? convertToPlainText(postList.liveVideoPost[0].name) : postList.liveVideoPost[0].name}
                                        /> 
                                    </Link>
                                    <div class="Gr--userRComments">
                                        <Link to={`/${postList.liveVideoPost[0].user.username}`}>
                                            <img src={(postList.liveVideoPost[0].user && valid(postList.liveVideoPost[0].user.image)) ? postList.liveVideoPost[0].user.image : "/images/user.png"} alt='...' />
                                        </Link>
                                        <div class="Gr--userData">
                                            <h4><Link to={`/live-video/${postList.liveVideoPost[0].slug}`}>{postList.liveVideoPost[0].name || ''}</Link></h4>
                                            <Link to={`/${postList.liveVideoPost[0].user.username}`}>
                                                <p><span><FaRegUser/> {postList.liveVideoPost[0].user.first || ''}</span><span><FaRegCalendarAlt />{moment(postList.liveVideoPost[0].createdAt).format('LL')}</span></p>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        :   (postList.videoPost && postList.videoPost.length > 0) 
                                ?   <div className='bigVideo__wrap div1'>
                                        <div class="Gr--slider--Desc">
                                            <Link className="Gr--post--image" to={`/video/${postList.videoPost[0].slug}`}>
                                                <span className='video--play--icon'><BsPlayFill /></span>
                                                <LazyLoadImage
                                                    width="100%"
                                                    placeholderSrc="/images/placeholder.gif"
                                                    effect="blur"
                                                    src={valid(postList.videoPost[0].thumbnail) ? postList.videoPost[0].thumbnail : '/images/thumbnail.jpg'}
                                                    alt={checkHTML(postList.videoPost[0].name) ? convertToPlainText(postList.videoPost[0].name) : postList.videoPost[0].name}
                                                /> 
                                            </Link>
                                            <div class="Gr--userRComments">
                                                <Link to={`/${postList.videoPost[0].user.username}`}>
                                                    <img src={(postList.videoPost[0].user && valid(postList.videoPost[0].user.image)) ? postList.videoPost[0].user.image : '/images/user.png'} alt='...' />
                                                </Link>
                                                <div class="Gr--userData">
                                                    <h4><Link to={`/video/${postList.videoPost[0].slug}`}>{postList.videoPost[0].name || ''}</Link></h4>
                                                    <Link to={`/${postList.videoPost[0].user.username}`}>
                                                        <p><span><FaRegUser/> {postList.videoPost[0].user.first || ''}</span><span><FaRegCalendarAlt />{moment(postList.videoPost[0].createdAt).format('LL')}</span></p>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                :   null

                    }
                    { (postList.videoPost && postList.videoPost.length > 1)&& 
                    <>
                            <div className='bigVideo__wrap div2'>
                                <div class={(postList.videoPost.length === 2 )? "Gr--slider--Desc h-100" : "Gr--slider--Desc"}>
                                    <Link class="Gr--post--image" to={`/video/${postList.videoPost[1].slug}`}>
                                        <span className='video--play--icon'><BsPlayFill /></span>
                                        <LazyLoadImage
                                            width="100%"
                                            placeholderSrc="/images/placeholder.gif"
                                            effect="blur"
                                            src={valid(postList.videoPost[1].thumbnail) ? postList.videoPost[1].thumbnail : '/images/thumbnail.jpg'}
                                            alt={checkHTML(postList.videoPost[1].name) ? convertToPlainText(postList.videoPost[1].name) : postList.videoPost[1].name}
                                        /> 
                                    </Link>
                                    <div class="Gr--userRComments">
                                        <Link to={`/${postList.videoPost[1].user.username}`}>
                                            <img src={(postList.videoPost[1].user && valid(postList.videoPost[1].user.image)) ? postList.videoPost[1].user.image : "/images/user.png"} alt='...' />
                                        </Link>
                                        <div class="Gr--userData">
                                            <h4><Link to={`/video/${postList.videoPost[1].slug}`}>{postList.videoPost[1].name || ''}</Link></h4>
                                            <Link to={`/${postList.videoPost[1].user.username}`} >
                                                <p><span><FaRegUser/> {postList.videoPost[1].user.first || ''}</span><span><FaRegCalendarAlt /> {moment(postList.videoPost[1].createdAt).format('LL')}</span></p>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {postList.visualPost && postList.visualPost.length > 0 && 
                                <>
                                    <div className='bigVideo__wrap div3' >
                                        <div class="Gr--slider--Desc">
                                            <Link class="Gr--post--image" to={`/visual/${postList.visualPost[0].slug}`}>
                                                <LazyLoadImage
                                                    width="100%"
                                                    placeholderSrc="/images/placeholder.gif"
                                                    effect="blur"
                                                    src={postList.visualPost.thumbnail || postList.visualPost[0].Postmeta[0].meta_value }
                                                    alt={checkHTML(postList.visualPost[0].name) ? convertToPlainText(postList.visualPost[0].name) : postList.visualPost[0].name}
                                                /> 
                                            </Link>
                                            <div class="Gr--userRComments">
                                                <Link to={`/${postList.visualPost[0].user.username}`}>
                                                    <img src={(postList.visualPost[0].user && valid(postList.visualPost[0].user.image)) ? postList.visualPost[0].user.image : '/images/user.png'} alt='' />
                                                </Link>
                                                <div class="Gr--userData">
                                                    <h4><Link to={`/visual/${postList.visualPost[0].slug}`}>{postList.visualPost[0].name || ''}</Link></h4>
                                                    <Link to={`/${postList.visualPost[0].user.username}`}>
                                                        <p><span><FaRegUser/> {postList.visualPost[0].user.first || ''}</span><span><FaRegCalendarAlt /> {moment(postList.visualPost[0].createdAt).format('LL')}</span></p>
                                                    </Link>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {postList.audioPost && postList.audioPost.length > 0 
                                    
                                        ? 
                                            <div  className='bigVideo__wrap div4'>
                                                <div class="Gr--slider--Desc">
                                                    <Link class="Gr--post--image" to={`/audio/single/${postList.audioPost[0].slug}`}>
                                                        <span className='video--play--icon'><BsPlayFill /></span>
                                                        <LazyLoadImage
                                                            width="100%"
                                                            placeholderSrc="/images/placeholder.gif"
                                                            effect="blur"
                                                            src={valid(postList.audioPost[0].thumbnail) ? postList.audioPost[0].thumbnail : '/images/thumbnail.jpg'}
                                                            alt={checkHTML(postList.audioPost[0].name) ? convertToPlainText(postList.audioPost[0].name) : postList.audioPost[0].name}
                                                        /> 
                                                    </Link>
                                                    <div class="Gr--userRComments">
                                                        <Link to={`/${postList.audioPost[0].user.username}`}>
                                                            <img src={(postList.audioPost[0].user && valid(postList.audioPost[0].user.image)) ? postList.audioPost[0].user.image : "/images/user.png"} alt='...' />
                                                        </Link>
                                                        <div class="Gr--userData">
                                                            <h4><Link to={`/audio/single/${postList.audioPost[0].slug}`}>{postList.audioPost[0].name || ''}</Link></h4>
                                                            <Link to={`/${postList.audioPost[0].user.username}`}>
                                                                <p><span><FaRegUser/> {postList.audioPost[0].user.first || ''}</span><span><FaRegCalendarAlt /> {moment(postList.audioPost[0].createdAt).format('LL')}</span></p>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        :   (postList.textPosts && postList.textPosts.length > 0 ) 
                                            ?
                                                <div  className='bigVideo__wrap div4'>
                                                    <div class="Gr--slider--Desc">
                                                        <Link class="Gr--post--image" to={`/text/${postList.textPosts[0].slug}`}>
                                                            <LazyLoadImage
                                                                width="100%"
                                                                placeholderSrc="/images/placeholder.gif"
                                                                effect="blur"
                                                                src={valid(postList.textPosts[0].thumbnail) ? postList.textPosts[0].thumbnail : '/images/textBackground.png'}
                                                                alt={checkHTML(postList.textPosts[0].name) ? convertToPlainText(postList.textPosts[0].name) : postList.textPosts[0].name}
                                                            /> 
                                                        </Link>
                                                        <div class="Gr--userRComments">
                                                            <Link to={`/${postList.textPosts[0].user.username}`}>
                                                                <img src={(postList.textPosts[0].user && valid(postList.textPosts[0].user.image)) ? postList.textPosts[0].user.image : "/images/user.png"} alt='...' />
                                                            </Link>
                                                            <div class="Gr--userData">
                                                                <h4><Link to={`/text/${postList.textPosts[0].slug}`}>{postList.textPosts[0].name || ''}</Link></h4>
                                                                <Link to={`/${postList.textPosts[0].user.username}`}>
                                                                    <p><span><FaRegUser/> {postList.textPosts[0].user.first || ''}</span><span><FaRegCalendarAlt /> {moment(postList.textPosts[0].createdAt).format('LL')}</span></p>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            : null
                                        } 
                                </> 
                            }
                        </>
                    }
                </div>
            </>    
        )

    }

    boardPostList = ()=>{
        const {postList} = this.state;
        if(postList.boardPost && postList.boardPost.length > 0){
            return (
                <Row>
                    {
                        postList.boardPost.slice(0, 5).map((item,idx)=>{
                            return(
                                <Col sm={6} lg={3}>
                                    <div className="Gr--realmMPanel">
                                        <Link to={`/realm/${item.name}`} state={{slug:item.slug}}>
                                            <img src={item.thumbnail} alt="..." />
                                            <h4>{item.name}</h4>
                                        </Link>
                                    </div>
                                </Col>
                            )
                        })
                    }     
                </Row>
            )
        }    
    }
    
    render(){
        const{postList , loading} = this.state ;
        return (
            <>
                <PageHeader />
                <div className='page--content'>
                    <SidebarMenu />
                    <div className='discover--module common--wrapper discover--main--page'>
                        <Container>
                            {!loading ?
                                <>
                                    {this.renderLivePost()}
                                    {(postList?.videoPost && postList?.videoPost?.length > 0)&&
                                        <>
                                            <h4 className='mt-4 category--title'>Video <Link className='btn btn-primary' to='/video'>View More</Link></h4>
                                            {this.videoPostSlider()}
                                        </>
                                    }    
                                    {(postList?.textPosts && postList?.textPosts?.length > 0)&&
                                        <>
                                            <h4 className='mt-4 category--title'>Text <Link className='btn btn-primary' to='/text'>View More</Link></h4>
                                            {this.textPostSlider()}
                                        </>
                                    }
                                    {(postList?.audioPost && postList?.audioPost?.length > 0)&&
                                        <>
                                            <h4 className='mt-4 category--title'>Audio <Link className='btn btn-primary' to='/audio'>View More</Link></h4>
                                            { this.audioPostSlider()}
                                        </>
                                    }
                                    {(postList.visualPost && postList?.visualPost?.length > 0)&&
                                        <>
                                            <h4 className='mt-4 category--title'>Photo <Link className='btn btn-primary' to='/visual'>View More</Link></h4>
                                            {this.visualPostSlider()}
                                        </>
                                    }
                                    {(postList?.boardPost && postList?.boardPost.length > 0)&&
                                        <>
                                            <h4 className='mt-4 category--title'>Popular Realms</h4>
                                            {this.boardPostList()}
                                        </>
                                    }
                                </>
                            :   <Col sm={12}>
                                    <div className='text-center spinner--loader'></div>
                                </Col>
                            }    
                        </Container>
                    </div>
                </div>
            </>
        );
    }    
}


const mapStateToProps = (state) => {
    return{
        boardPost: state.post.boardPostList,
        discoverPostList: state.discoverList?.discoverPostsList
    }    

}

const dispatchActions = { getBoardPostList , getDiscoverPagePosts   } ; 

export default connect(mapStateToProps, dispatchActions)(DiscoverMain);


