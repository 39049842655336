import React, {Component} from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Cropper } from 'react-cropper';
import "cropperjs/dist/cropper.css";

class imageCropper extends Component {
    constructor(props) {
        super(props);
        this.cropperRef = React.createRef();
    }
    saveCrop = () => {
        if (typeof this.cropperRef.current.cropper !== "undefined") {
            this.props.result(this.cropperRef.current.cropper.getCroppedCanvas().toDataURL())
            this.props.onClose();
        }
    };

    render(){

    
        return (
            <Modal
                className="cropper--modal"
                show={this.props.isOpen}
                onHide={() => this.props.onClose()}
                backdrop="static"
                centered
            >
            <Modal.Header closeButton>
                <Modal.Title>
                    Crop & Save
                </Modal.Title>
            </Modal.Header>
            <Cropper
                src={this.props.src}
                style={{ height: 400, width: "100%" }}
                zoomTo={0.5}
                viewMode={1}
                initialAspectRatio={1}
                minCropBoxHeight={10}
                minCropBoxWidth={10}
                background={false}
                responsive={true}
                guides={true}
                autoCropArea={1}
                checkOrientation={false} 
                ref={this.cropperRef}
                {...this.props}
            />
            <Modal.Footer>
                {/* <Button className='secondary'>Close</Button> */}
                <Button variant='primary' onClick={() => this.saveCrop()}>Crop</Button>
            </Modal.Footer>
        </Modal>
        )
    }    
}

export default imageCropper