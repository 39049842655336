import { get } from 'lodash';
import API from '../../helpers/api';
import * as auth from '../../helpers/auth';

import {
  CREATE_SHOP_SUCCESS,
  CREATE_SHOP_FAILED,
  GET_SHOP_SUCCESS,
  GET_SHOP_FAILED,
  SHOP_CAT_SUCCESS,
  SHOP_CAT_FAILED,
  GET_MY_SHOP_SUCCESS,
  GET_MY_SHOP_FAILED,
  GET_SINGLE_SHOP_FAILED,
  GET_SINGLE_SHOP_SUCCESS,
  CHANGE_SHOP_STATUS_SUCCESS,
  CHANGE_SHOP_STATUS_FAILED,
  SHOP_COMMON_ERROR,
  EDIT_SHOP_SUCCESS,
  EDIT_SHOP_FAILED,
  DELETE_SHOP_FAILED,
  DELETE_SHOP_SUCCESS,
} from "./types";


const config = {
    headers: {
        "Content-Type": "multipart/form-data; charset=utf-8;"
    }
}

function errorRequest(err, dispatch) {
    let data = get(err, 'response.data', null);
    data = data || get(err, 'response');
    data = data || err;
    if (data.error) {
      dispatch({
        type: SHOP_COMMON_ERROR,
        payload: data.error.message,
      });
    } else if (data.status === 401) {   
      auth.logout()
    } else {
      dispatch({
        type: SHOP_COMMON_ERROR,
        payload: data.message,
      });
    }
}


/**---------------------------   using for create Shop  ********************************************************/
export const createShop = (payload) =>{
    return async (dispatch) => {
        try{
            const response = await API.apiUploadFileURL('marketplace', '/create-shop' , payload , config) ;
            if(response.data && response.data.success){
                await dispatch({ type: CREATE_SHOP_SUCCESS, payload: response.data});
            }else{
                await dispatch({ type: CREATE_SHOP_FAILED, payload: response.data});
            }
        }catch(error){
            errorRequest(error, dispatch);
        }
    }
}

/*****************   using for get the shops catgory-subcategory list  *******************************/

export const getShopCatgory = () =>{
    return async (dispatch) => {
      try{
        const response = await API.apiGetUrl('marketplace', '/shop-catgory') ;
        if(response.data && response.data.success){
            await dispatch({ type: SHOP_CAT_SUCCESS, payload: response.data});
        }else{
            await dispatch({ type: SHOP_CAT_FAILED, payload: response.data});
        }
      }catch(error){
          errorRequest(error, dispatch);
      }
    }
}

/***********************    using for get the all shop list  *************************************************/
export const getShopList = (payload) =>{
  return async (dispatch) => {
      try{
        const response = await API.apiGetUrl('marketplace', '/shops-list') ;
        if(response.data && response.data.success){
          await dispatch({ type: GET_SHOP_SUCCESS, payload: response.data});
        }else{
          await dispatch({ type: GET_SHOP_FAILED, payload: response.data});
        }
      }catch(error){
        errorRequest(error, dispatch);
      }
  }
}

/*********************************  using for  get user shops list ( my-shop profile page ) ***************************************/

export const getMyShops = (payload) =>{
  return async (dispatch) => {
      try{
        const response = await API.apiGetUrl('marketplace', '/my-shops' , payload) ;
        if(response.data && response.data.success){
          await dispatch({ type: GET_MY_SHOP_SUCCESS, payload: response.data});
        }else{
          await dispatch({ type: GET_MY_SHOP_FAILED, payload: response.data});
        }
      }catch(error){
        errorRequest(error, dispatch);
      }
  }
}

/************************************  using for   get single shop using slug  ***************************************/

export const getSingleShop = (slug) =>{
  return async (dispatch) => {
      try{
        const response = await API.apiGetUrl('marketplace', '/single-shop' , `/${slug}`) ;
        if(response.data && response.data.success){
          await dispatch({ type: GET_SINGLE_SHOP_SUCCESS, payload: response.data});
        }else{
          await dispatch({ type: GET_SINGLE_SHOP_FAILED, payload: response.data});
        }
      }catch(error){
        errorRequest(error, dispatch);
      }
  }
}

/****************************************     using for hide or delete the shop  ************************************/

export const changeShopStatus = (payload)=>{
  return async (dispatch) => {
    try{
      const response = await API.apiPutUrl('marketplace', '/change-status' , payload) ;
      if(response.data && response.data.success){
        await dispatch({ type: CHANGE_SHOP_STATUS_SUCCESS, payload: response.data});
      }else{
        await dispatch({ type: CHANGE_SHOP_STATUS_FAILED, payload: response.data});
      }
    }catch(error){
      errorRequest(error, dispatch);
    }
  } 
}

export const editShop = (payload)=>{
  return async (dispatch) => {
    try{
        const response = await API.apiUpdateUploadFile('marketplace', '/edit-shop' , payload , config) ;
        if(response.data && response.data.success){
          await dispatch({ type: EDIT_SHOP_SUCCESS, payload: response.data});
        }else{
          await dispatch({ type: EDIT_SHOP_FAILED, payload: response.data});
        }
    }catch(error){
        errorRequest(error, dispatch);
    }
  }
}

export const deleteShop = (payload) => {
  return async (dispatch) => {
    try {
      const response = await API.apiPutUrl('marketplace', '/delete-shop', payload);
      if(response.data && response.data.success){
        await dispatch({ type: DELETE_SHOP_SUCCESS, payload: response.data });
      } else {
        await dispatch({ type: DELETE_SHOP_FAILED, payload: response.data });
      }
    } catch (error) {
       errorRequest(error, dispatch, DELETE_SHOP_FAILED);
    }
  }
}



