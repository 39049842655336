import { 
  GET_AUDIO_POST_SUCCESS,
  GET_AUDIO_POST_FAILED,
  CREATE_AUDIO_SINGLE_SUCCESS,
  CREATE_AUDIO_SINGLE_FAILED,
  COMMOM_AUDIO_ERROR,
  EDIT_AUDIO_SUCCESS,
  EDIT_AUDIO_FAILED,
  LIKE_POST,
  CLEAR_AUDIO_CREATE ,
  CLEAR_POSTS , 
  LIVE_AUDIO_STARTED
    
} from "../actions/types";
import * as auth from '../../helpers/auth';

  
const initialState = {
  getAudioPostSuccess: null,
  audioPostError: null,
  getAudioPostList:null
};

export default (state = initialState, action) => {
  switch (action.type) {
      case GET_AUDIO_POST_SUCCESS:
          if(state.getAudioPostList){
            if(action.payload.pageNumber !== '1'){
              const copyPost = state.getAudioPostList
              let  post = state.getAudioPostList.audioPostList.audioPost
              let newPost = action.payload.audioPostList.audioPost
              var mergePost = [...post , ...newPost]
              mergePost = mergePost.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i)
              copyPost.audioPostList.audioPost = mergePost
              return{
                  ...state ,
                  getAudioPostList: copyPost
              }   
            }else{
              return{
                getAudioPostList: action.payload
              }   
            }   
          }else{
              return{
                ...state ,
                  getAudioPostList: action.payload
              }    
        }    
      case GET_AUDIO_POST_FAILED:
          return{
            audioPostError: action.payload,
          }     
      case CREATE_AUDIO_SINGLE_SUCCESS:
        return{
          createSingleAudio: action.payload,
        }            
      case CREATE_AUDIO_SINGLE_FAILED:
        return{
          audioPostError: action.payload,
        }              
      case EDIT_AUDIO_SUCCESS:
        return{
          editAudio: action.payload,
        }            
      case EDIT_AUDIO_FAILED:
          return{
            editAudio: action.payload,
          }              
      case COMMOM_AUDIO_ERROR:
        return{
          audioPostError: action.payload,
        }              
      case LIKE_POST:
        if(state.getAudioPostList){
            const postLikes = state.getAudioPostList?.audioPostList
            const likesIndex = postLikes.audioPost.findIndex(post => post.id === action.payload.post.postId)
            if(likesIndex !== -1){
              var copyAudioPost = state.getAudioPostList
              copyAudioPost.audioPostList.audioPost[likesIndex].Likes = action.payload.post.likes
              const updateLikes = {...copyAudioPost}
              return{
                // ...state,
                getAudioPostList: updateLikes,
              }       
            }  
        }  
      case CLEAR_AUDIO_CREATE:
        return{
          createSingleAudio:null
        }
      case CLEAR_POSTS: {
        return{
          createSingleAudio: null,
        }
      }
      case LIVE_AUDIO_STARTED:{
        return{
          liveAudioStarted: action.payload
        }
      }
      default: return state;               
  }
}
