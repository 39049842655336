import React, {useState , useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Navbar, Nav , OverlayTrigger , Tooltip } from 'react-bootstrap';
import { RiCompassDiscoverLine, RiStoreLine } from 'react-icons/ri';
import { MdLiveTv } from 'react-icons/md';
import { LuWaves} from "react-icons/lu";
import { BsGlobeAmericas } from 'react-icons/bs';
import 'bootstrap/dist/css/bootstrap.min.css';
import './SidebarStyle.css';
import '../../App.css';
import { Link } from 'react-router-dom';
import { getBoardPostList } from '../../redux/actions/post.action';
import { valid } from '../../utils/validations';
import { decodedLoggedUser , logout , getUserNotifications , removeNotification} from '../../helpers/auth';
    
function SidebarMenu() {

    const [isActive, setIsActive] = useState(false);
    const [realmList , setRealmList] = useState('');
    const [hoveredItem, setHoveredItem] = useState('');
    const apiResult = useSelector(state =>  state.post.boardPostList);
    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch() ; 

    useEffect(()=>{
        dispatch(getBoardPostList())
    },[])

    useEffect(()=>{
        if(apiResult && apiResult.success){
            setRealmList(apiResult.boardPosts)
        }
    },[apiResult]);


    const handleClick = event => {
        setIsActive(current => !current);
    };

    const handleMouseEnter = (item) => {
        setHoveredItem(item);
      };
    
    const handleMouseLeave = () => {
    setHoveredItem(null);
    };

    const renderTooltip = (props , item) => {
        return (
            <Tooltip id="button-tooltip" {...props}>
                {item.name || ''}
            </Tooltip>
        )
    }


    const renderRealm = () =>{
        if(realmList && realmList.length > 0){
            return (
                <ul class="Gr--List--realm">
                    {
                        realmList.map((item , ind)=>{
                            return (
                                <OverlayTrigger
                                    placement="right"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={(props)=>renderTooltip(props , item)}
                                   
                                >
                                    <li key = {ind}>
                                        <Link to={`/realm/${item.name.toLowerCase()}`} state={{slug:item.slug}}>
                                            <img src={valid(item.thumbnail)? item.thumbnail: '/images/background.png'} alt="..." />
                                            {hoveredItem === item && <p>{item.name}</p>}
                                        </Link>
                                    </li>
                                </OverlayTrigger>
                            )
                        })
                    }
                </ul>   
            )
        }
    }

    return (
        <>
            <button className={isActive ? 'button--Toggle' : ' btn--Click'} onClick={handleClick}><span>&lsaquo;</span></button> 
            <Navbar className={isActive ? 'collapse--Nav' : ' collapse--Nav02'}>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav>
                        { (auth && auth.loggedIn)&&
                            <Link to= '/stream' className={window.location.pathname === '/stream' ? 'active nav-link' : 'nav-link'}>
                                <LuWaves/><span>Stream</span>
                            </Link>
                        }
                        <Link to= '/discover' className={window.location.pathname === '/discover' ? 'active nav-link' : 'nav-link'}>
                            <RiCompassDiscoverLine/><span>Discover</span>
                        </Link>
                        <Link to= '/marketplace' className={window.location.pathname === '/marketplace' ? 'active nav-link' : 'nav-link'}>
                            <RiStoreLine/> <span>Marketplace</span>
                        </Link>    
                    </Nav>
                    {renderRealm()}
                </Navbar.Collapse>
            </Navbar>
        </>
    );
}

export default SidebarMenu;