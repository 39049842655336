import {
    GET_PRODUCTS_LIST_SUCCESS,
    GET_PRODUCTS_LIST_FAILED,
    GET_SHOP_PRODUCTS_SUCCESS,
    GET_SHOP_PRODUCTS_FAILED,
    GET_SINGLE_PRODUCTS_SUCCESS,
    CREATE_PRODUCT_SUCCESS,
    CREATE_PRODUCT_FAILED,
    EDIT_PRODUCT_SUCCESS,
    EDIT_PRODUCT_FAILED,
    EDIT_PRODUCT_IMAGES_SUCCESS,
    EDIT_PRODUCT_IMAGES_FAILED,
    GET_SINGLE_PRODUCTS_FAILED,
    CHANGE_PRODUCT_STATUS_SUCCESS,
    CHANGE_PRODUCT_STATUS_FAILED,
    USER_PRODUCTS_LIST,
    USER_PRODUCTS_LIST_ERROR,
    
    PRODUCTS_COMMON_ERROR
} from "../actions/types";


const initialState = {
    productList: null,
    productError: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_PRODUCTS_LIST_SUCCESS:
            return{
               productList:action.payload,
            }
        case  GET_PRODUCTS_LIST_FAILED:
            return{
                productListError:action.payload.message,
            }
        case GET_SHOP_PRODUCTS_SUCCESS:
            return{
                shopProductList:action.payload,
            }
        case  GET_SHOP_PRODUCTS_FAILED:
            return{
                shopProductsError:action.payload.message,
            }
        case  GET_SINGLE_PRODUCTS_SUCCESS:
            return{
                singleProduct:action.payload,
            }
        case  GET_SINGLE_PRODUCTS_FAILED:
            return{
                singleProductError:action.payload.message,
            }
        case PRODUCTS_COMMON_ERROR:
            return{
                productError:action.payload,
            }    
        case CREATE_PRODUCT_SUCCESS:
            return {
                productCreated:action.payload,
            }    
        case CREATE_PRODUCT_FAILED:
            return{
                productCreatedError:action.payload.message,
            }    
        case EDIT_PRODUCT_SUCCESS:
            return{
                productUpdated:action.payload,
            }        
        case EDIT_PRODUCT_FAILED:
            return{
                productUpdatedError:action.payload.message,
            }           
        case EDIT_PRODUCT_IMAGES_SUCCESS:
            return{
                productImagesUpdated:action.payload,
            }         
        case EDIT_PRODUCT_IMAGES_FAILED:
            return{
                productImagesError:action.payload.message,
            }             
        case CHANGE_PRODUCT_STATUS_SUCCESS:
            return{
                statusChanged:action.payload,
            }    
        case CHANGE_PRODUCT_STATUS_FAILED:
            return{
                statusError:action.payload.message,
            }        
        case USER_PRODUCTS_LIST : 
            return {
                user_products: action.payload
            }
        case USER_PRODUCTS_LIST : 
            return {
                user_products_error: action.payload
            }
        default: return state;    
    }
}    