import { get } from 'lodash';
import API from '../../helpers/api';
import * as auth from '../../helpers/auth';
import {
    GET_AUDIO_POST_SUCCESS,
    GET_AUDIO_POST_FAILED,
    CREATE_AUDIO_SINGLE_SUCCESS,
    CREATE_AUDIO_SINGLE_FAILED,
    COMMOM_AUDIO_ERROR,
    EDIT_AUDIO_SUCCESS,
    EDIT_AUDIO_FAILED,
    CLEAR_AUDIO_CREATE,
    AUDIO_LIST ,
    CLEAR_AUDIO_LIST,
  } from "./types";

const config = {
    headers: {
        "Content-Type": "multipart/form-data; charset=utf-8;"
        
    }
}



function errorRequest(err, dispatch) {
    let data = get(err, 'response.data', null);
    data = data || get(err, 'response');
    data = data || err;
    if (data.error) {
      dispatch({
        type: COMMOM_AUDIO_ERROR,
        payload: data.error.message,
      });
    } else if (data.status === 401) {   
      auth.logout()
    } else {
      dispatch({
        type: COMMOM_AUDIO_ERROR,
        payload: data.message,
      });
    }
}


export const clearAudioCreate = () =>{
  return async (dispatch) => {
    dispatch({type:CLEAR_AUDIO_CREATE});
  }
}

export const getAudioPostList = (payload) =>{
  return async (dispatch) => {
    try{
        const response = await API.apiGetUrl('post', '/audio-post' , payload) ;
        if(response.data && response.data.success){
          await dispatch({ type: GET_AUDIO_POST_SUCCESS, payload: response.data});
        }else{
          await dispatch({ type: GET_AUDIO_POST_FAILED, payload: response.data});
        }
    }catch(error){
        errorRequest(error, dispatch);
    }
  }
}

export const createAudioPost = (payload) =>{
  return async (dispatch) => {
    try{
        const response = await API.apiUploadFileURL('post', '/create-audio' , payload , config) ;
        if(response.data && response.data.success){
            await dispatch({ type: CREATE_AUDIO_SINGLE_SUCCESS, payload: response.data});
        }else{
            await dispatch({ type: CREATE_AUDIO_SINGLE_FAILED, payload: response.data});
        }
    }catch(error){
      errorRequest(error, dispatch);
    }
  }
}



export const editAudioPost = (payload)=>{
  return async (dispatch) => {
    try{
        const response = await API.apiUpdateUploadFile('post', '/edit-audio' , payload , config) ;
        if(response.data && response.data.success){
          await dispatch({ type: EDIT_AUDIO_SUCCESS, payload: response.data});
        }else{
          await dispatch({ type: EDIT_AUDIO_FAILED, payload: response.data});
        }
    }catch(error){
        errorRequest(error, dispatch);
    }
  }
}


export const clearPlayer= () =>{
  return async (dispatch) => {
    await dispatch({ type: CLEAR_AUDIO_LIST});
  }
}

export const AddAudio= (data) =>{
  return async (dispatch) => {
    await dispatch({ type: AUDIO_LIST , payload: data});
  }
}
  
  