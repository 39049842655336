import React, {useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {generateVideoThumbnails} from "@rajesh896/video-thumbnails-generator";
import { toast, Toaster } from "react-hot-toast";   
import { EditorState, convertToRaw, getDefaultKeyBinding , ContentState} from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { notValid,checkHTML , convertToPlainText } from "../../../utils/validations";
import ImageCropper from "./imageCropper";
import { Button, Row, Col, Form , Spinner} from 'react-bootstrap';
import { FaCogs} from 'react-icons/fa';
import {IoClose} from 'react-icons/io5';
import { createVideoPost , uploadVideo, clearVideoCreate} from "../../../redux/actions/videoPost.action";
import API from "../../../helpers/api";

function CreateVideo({savedData, saveData}) {
    const maxSize = 500 * 1024 * 1024 ;
    const intialValue = {media:'' , name:'' , video : '' ,thumbnail:'' ,  description: '',isStream: true,  commenting: true , board_name: '', board_id: '',};
    const [videoFields , setVideoFields] = useState(intialValue);
    const [loading , setLoading] = useState(false) ; 
    const [videoUploading , setVideoUploading] = useState(false);
    const [boardList , setBoardList] = useState('');
    const [draftLoading , setDraftLoading] = useState(false);
    const [ isCropperOpen, setCropperOpen ] = useState(false);
    const [ imageForCrop, setImageForCrop ] = useState(null);  
    const [draft , setDraft] =  useState(false); 
    const [showEditor , setShowEditor] = useState(true);
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
    const [errors , setErrors] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const apiResult = useSelector(state => state.videoPost);
    const getPostResult = useSelector(state => state.post);


    useEffect(()=>{
        dispatch(clearVideoCreate());
    },[])


    useEffect(() => {
        if (savedData) {
            setVideoFields(savedData);
            if(savedData.description){
                const blocksFromHtml = htmlToDraft(savedData.description);
                const { contentBlocks, entityMap } = blocksFromHtml;
                const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
                const editorState = EditorState.createWithContent(contentState);
                setEditorState(editorState)
            }
        }
    },[savedData]);

    const updateParentState = () => {
        saveData(videoFields);
    };


    useEffect(()=>{
        if(getPostResult && getPostResult.boardPostList){
            setBoardList(getPostResult.boardPostList.boardPosts)
        }
    },[getPostResult])

    const handleInput = (e) =>{
        const {value , name } = e.target ; 
        setVideoFields({...videoFields, [name]: value })
        setErrors({ ...errors, [name]: '' })
    }

    const handleCommenting = (e) =>{
        const copyVideoFields = {...videoFields} ;
        copyVideoFields.commenting = e.target.checked
        setVideoFields(copyVideoFields)
    }
/**********************    handle description  /////   text editor  function using draft.js for text editing        ******/

    const onEditorStateChange = (editorState) => {
        const copyVideoFields = {...videoFields} ;
        let errorMessages = {...errors};
        const rawContentState = convertToRaw(editorState.getCurrentContent());
        const description = draftToHtml(rawContentState);
        copyVideoFields.description = description
        errorMessages.description = '' ;
        setVideoFields(copyVideoFields)
        setErrors(errorMessages)
        setEditorState(editorState);
    };

    const myKeyBindingFn = event => {
        return getDefaultKeyBinding(event);
    }
/********************************************************************************************************************* */
/*****************   form validation  ***************************************/
    const validate = () =>{
        let isValid = true ;
        let errorMessages = {};
        let desc = checkHTML(videoFields.description) ? convertToPlainText(videoFields.description) : videoFields.description
        if (notValid(desc)) {
            errorMessages['description'] = 'Description is required'
            isValid = false;
        }
        if (notValid(videoFields.name)) {
            errorMessages['name'] = 'Title is required'
            isValid = false;
        }

        if(videoFields.media === ''){
            errorMessages['media'] = 'Please upload video '
            isValid = false;
        }
        if(videoFields.thumbnail === ''){
            errorMessages['thumbnail'] = 'Please upload thumbnail '
            isValid = false;
        }
        if (!isValid) {
            setErrors({ ...errors, ...errorMessages });
        }
        return isValid;
    }

/******************************************************************************************************************** */
/********************************************* using for crop image  *********************************************/

const handleCloseCropper = () => {
    setImageForCrop(null)
    setCropperOpen(false)
}

const handeSaveCroppedImage = (dataURI) => {
    const imageFile = dataURItoBlob(dataURI);
    const copyVideoFields = {...videoFields}
    copyVideoFields['thumbnail'] = imageFile
    setVideoFields(copyVideoFields)
}

const dataURItoBlob = (dataURI) => {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(dataURI.split(',')[1]);
    else
        byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    
    return new Blob([ia], {type:mimeString});
}


const handleOpenCropper = () => {
    setCropperOpen(true)
    let data = {
        src: URL.createObjectURL(videoFields.thumbnail)
    }
    setImageForCrop(data)
}

/************************************************************************************************************** */

/**-------------  video or thumbnail handling  -------------------------- */

    const handleVideoChange = async(event) => {
        const copyVideoFields = {...videoFields} ;
        let errorMessages = {...errors};
        const file = event.target.files[0];
        if (file && file.type.includes('video/')) {
            if(file.size < maxSize){
                await getVideoUrl(file) ;
                errorMessages.video = ''
                setErrors(errorMessages)    
                const thumbnail = await generateThumbnail(file);
                if(thumbnail){
                    copyVideoFields.thumbnail = thumbnail
                    setVideoFields(copyVideoFields)
                }
            }else{
                errorMessages.video = 'Video size exceeds the maximum limit of 500 MB';
                setErrors(errorMessages)
            }
        }else{
            errorMessages.video = 'Please select a valid video file (e.g., MP4, MOV).';
            setErrors(errorMessages)
        
        }
    };


    const uploadThumbnailToServer = async (file) => {
        try {
            const formData = new FormData();
            formData.append('thumbnail', file);
            const response = await API.apiUploadFileURL('messenger', `/get-file-url`, formData);
            if(response.data && response.data.success){
                return response.data.fileUrl
            }
        } catch (error) {
            toast.error('Oops, unable to upload file');
        }
    }


    const getVideoUrl = async(file) =>{
        setVideoUploading(true)
        const formData =  new FormData();
        formData.append('video' ,file)
        dispatch(uploadVideo(formData))
    }


    const handleThumnail = (e)=>{
        const allImages = ['png', 'jpg', 'jpeg', 'JPG', 'JPEG', 'PNG'];
        let errorMessages = {...errors};
        const { files } = e.target ; 
        const copyVideoFields = {...videoFields}
        if(files && files.length < 2 ){
            const file = files[0];
            const fileName = file.name.toLowerCase();
            const fileExtension = fileName.split('.').pop();
            if(allImages.includes(fileExtension)){
                copyVideoFields.thumbnail = file
                setVideoFields(copyVideoFields)
                errorMessages['thumbnail'] = ''
                setErrors(errorMessages)
            }else{
                errorMessages['thumbnail'] = ' Please use only  png, jpg, jpeg, JPG, JPEG, PNG'
                setErrors(errorMessages)
            }    
        }

    }

    


    const generateThumbnail = async(file ) => {
        if (file) {
            setLoading(true)
            var thumbnail = await generateVideoThumbnails(file , 1)
            const thumbnailImage =  dataURItoBlob(thumbnail[0])
            setLoading(false)
            return thumbnailImage
        }
        
    };

    const handleRemove = () =>{
        let copyVideoFields = {...videoFields};
        copyVideoFields.media = '';
        setVideoFields(copyVideoFields)
    }

    const handleRemoveThumbnail = () =>{
        let copyVideoFields = {...videoFields};
        copyVideoFields.thumbnail = '';
        setVideoFields(copyVideoFields)
    }


    const handleCreateVideoPost = (draft)=>{
        const copyVideoFields = {...videoFields}
        if(validate()){
            if(draft){
                copyVideoFields.status = '2'
                setLoading(false)
                setDraftLoading(true)
                setDraft(true);
            }else{
                setLoading(true)
                setDraftLoading(false)
            }
            const formData = new FormData();
            for (const [key, value] of Object.entries(copyVideoFields)) {
                formData.append(key, value)
            }  
            dispatch(createVideoPost(formData)) ;
        }

    }

    useEffect(()=>{
        if(apiResult && apiResult.createVideoSuccess){
            setLoading(false)
            setDraftLoading(false)
            if(draft){
                navigate('/drafts')
            }else{
                navigate('/')
            }
        }    
        if(apiResult && apiResult.videoPostError){
            setLoading(false)
            setDraftLoading(false)
            toast.error(apiResult.videoPostError.message, {
                position: 'top-center', 
            });
        }
        if(apiResult && apiResult.videoUrl){
            let copyVideoFields = {...videoFields}
            copyVideoFields.media = apiResult.videoUrl ; 
            setVideoFields(copyVideoFields);
            setVideoUploading(false)
        }
        setLoading(false)
        setDraftLoading(false)
    },[apiResult])


    const selectBoard = () => {
        if(boardList && boardList.length > 0){
            return (
                <Form.Group className='form-group col-sm-12'>
                    <Form.Label>Realm</Form.Label>
                    <Form.Control value={videoFields.board_id || '' } onChange= {handleBoard} as="select">
                        <option selected>Select</option>
                        {boardList.map((item , idx)=>{
                            return (
                                <option key={idx} value={item.id}>{item.name}</option>
                            )
                        })}
                    </Form.Control>
                </Form.Group>

            )
        }
    }

    const handleBoard = (e)=>{
        let copyVideoFields = {...videoFields};
        copyVideoFields.board_id = e.target.value
        setVideoFields(copyVideoFields)
    }
    return (
        <div className='create--form'>
            
            <Form onBlur={updateParentState}>
                <Row>
                    <Form.Group className='form-group col-sm-12'>
                        <Form.Label>Title</Form.Label>
                        <Form.Control type="text" name = 'name' value = {videoFields.name} onChange={handleInput} required />
                        {errors.name && <span className="error error-massege text-danger">{errors.name}</span>}
                    </Form.Group>
                    {videoFields.media 
                        ?
                            <Form.Group className='form-group col-sm-12 col-lg-6'>
                                <div className="post--thumb--upload">
                                    <video preload="auto" src={typeof videoFields.media === 'object' ? URL.createObjectURL(videoFields.media) : videoFields.media} controls/>
                                    <span className="post--media--close" onClick = {handleRemove}><IoClose/></span>
                                </div>
                            </Form.Group>

                        :    videoUploading ? 
                                (
                                    <Form.Group className='form-group col-sm-12 col-lg-6'>
                                        <div className="post--thumb--upload">
                                            <Spinner animation="border"/>
                                        </div>
                                    </Form.Group>
                                
                                )
                            :
                                <Form.Group className='form-group col-sm-12 col-lg-6'>
                                    <Form.Label>Add Video</Form.Label>
                                    <input type='file' hidden id="upload--header-video"  accept="video/*"  onChange={handleVideoChange}/>
                                    <label className='label--upload' htmlFor="upload--header-video">Choose File</label>
                                    {errors.media ?
                                        <span className="error error-massege text-danger">{errors.media}</span>
                                        :  errors.video
                                        ? 
                                            <span className="error error-massege text-danger">{errors.video}</span>
                                        : ''
                                    }
                                </Form.Group>
                    }
                   
                    {videoFields.thumbnail 

                    ?
                        <Form.Group className='form-group col-sm-12 col-lg-6'>
                            <div className="post--thumb--upload">
                                <img src={typeof videoFields.thumbnail === 'object' ? URL.createObjectURL(videoFields.thumbnail) : videoFields.thumbnail}/>
                                <span className="post--media--close" onClick = {handleRemoveThumbnail}><IoClose/></span>
                            </div>
                        </Form.Group>
                    :
                        <Form.Group className='form-group col-sm-12 col-lg-6'>
                            <Form.Label>Add Thumbnail</Form.Label>
                            <input type='file' hidden id="upload--header-thumnail" onChange ={handleThumnail} />
                            <label className='label--upload' htmlFor="upload--header-thumnail">Choose File</label>
                            {errors.thumbnail && <span className="error error-massege text-danger">{errors.thumbnail}</span>}
                        </Form.Group>
                    }    
                   
                    <Form.Group className='form-group col-sm-12'>
                        <span className='editor--tool' onClick = {()=>setShowEditor(!showEditor)}><FaCogs /></span>
                    </Form.Group>
                    <Form.Group className='form-group col-sm-12 text-title-post'>
                        <Form.Label>Description</Form.Label>
                        <Editor
                            editorState={editorState}
                            wrapperClassName="demo-wrapper editor-w"
                            editorClassName="demo-editor input"
                            onEditorStateChange={onEditorStateChange}
                            keyBindingFn={myKeyBindingFn}
                            hashtag={{
                                separator: ' ',
                                trigger: '#',
                            }}
                            toolbarHidden={showEditor}
                            config={{ link: { trailingWhitespace: true } }}
                        />
                        {errors.description && <span className="error error-massege text-danger">{errors.description}</span>}
                    </Form.Group>
                    {selectBoard()}
                    <Form.Group className='form-group col-sm-12'>
                        <Form.Check type="checkbox" label="Allow Comments" id = "commentCheckVideo" checked={videoFields.commenting} onChange={handleCommenting}/>
                        <Form.Label htmlFor="commentCheckVideo" onClick={handleCommenting}/>
                    </Form.Group>
                    <Col sm={12} className='text-end'>
                        <Button variant="secondary"  className='me-2' disabled = {loading || draftLoading} onClick={()=>handleCreateVideoPost(true)}>
                            { draftLoading ? <> <Spinner animation="border" size="sm"/> Please wait </> : 'Save to Draft'}
                        </Button>
                        <Button variant="primary" disabled = {loading || draftLoading} onClick={()=>handleCreateVideoPost(false)}>
                            {loading ?  <> <Spinner animation="border" size="sm"/> Please wait </> : 'Publish Now'}
                        </Button>
                    </Col>
                </Row>
            </Form>
            {isCropperOpen && 
                <ImageCropper
                    isOpen={isCropperOpen}
                    src={imageForCrop.src}
                    onClose={() => handleCloseCropper()}
                    result={(val) => handeSaveCroppedImage(val)}
                />
            }
            <Toaster/>
        </div>
    );
}

export default CreateVideo;