

import { useState , useEffect , useRef} from "react";
import { Lightbox } from "yet-another-react-lightbox";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";


export default function LightboxComponent(props) {
  const [index, setIndex] = useState(0);
  const [images , setImages] = useState(props.images)
  const currentImage = images[index];
  const nextIndex = (index + 1) % images.length;
  const nextImage = images[nextIndex] || currentImage;
  const prevIndex = (index + images.length - 1) % images.length;
  const prevImage = images[prevIndex] || currentImage;
  const zoomRef = useRef(null);
  const lightboxRef = useRef(null);

  const handleClose = () => setIndex(-1);
  const handleMovePrev = () => setIndex(prevIndex);
  const handleMoveNext = () => setIndex(nextIndex);


  useEffect(() => {
      if(props.images && props.images.length > 0){
          setImages(props.images)
      }
  }, [props.images])


  useEffect(() => {
    function handleClickOutside(event) {
      const clickedElement = event.target;
      if(clickedElement.tagName === 'DIV'){
        props.onClose()
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [lightboxRef]);


  const ShowImages = () =>{
    if(images.length > 1 ){
      return (
        <div ref={lightboxRef}>
          <Lightbox
            ref={lightboxRef}
            open={props.isOpen}
            close={() => props.onClose()}
            slides={props.images}
            Zoom={true}
          />
        </div>
      )

    }else{  
      return (
        <div ref={lightboxRef}>
          <Lightbox
            open={props.isOpen}
            close={() => props.onClose()}
            slides={props.images}
            Zoom={true}
            render={{
              buttonPrev: images.length > 1 ? handleMovePrev : () => null,
              buttonNext: images.length > 1 ? handleMoveNext : () => null,
            }}
          />
        </div>

      )
    }
  }

  return (
    <div>
      {ShowImages()}

    </div>
   
  
  );
}
