import {useState , useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux";
// import Slider from "react-slick";
import Carousel from 'react-grid-carousel'
// import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import draftToHtml from 'draftjs-to-html';
import DOMPurify from 'dompurify'
import { useNavigate , useParams , Link} from 'react-router-dom';
import { FaExpand, FaEllipsisV  , FaRegHeart} from 'react-icons/fa';
import { toast, Toaster } from "react-hot-toast";
import { Button, Container, Row, Col, Form , ListGroup} from 'react-bootstrap';
import PageHeader from '../Header/HeaderTop';
import SidebarMenu from '../Sidebar/SidebarNav';
import { BsArrowLeft } from 'react-icons/bs';
import 'bootstrap/dist/css/bootstrap.min.css';
import { getSingleProduct } from '../../redux/actions/product.action';
import { addToCart , getCartItems} from '../../redux/actions/shoppingCart.action';
import { decodedLoggedUser } from '../../helpers/auth';
// import sliderSetting from '../../utils/sliderSetting';
import { checkHTML , convertToPlainText } from '../../utils/validations';
import './productStyle.css';
import '../../App.css';
import { parseInt } from 'lodash';
import ReactImageMagnify from 'react-image-magnify';
import LightboxComponent from '../LightBoxComponent';
// import { initial } from 'lodash';


const check = ['undefined' , null , 'null' , '' , undefined]

// const rimProps = {
//     enlargedImagePortalId: 'portal',
//     // enlargedImageContainerDimensions: {
//     //     width: '200%',
//     //     height: '100%'
//     // }
// }



function SingleProduct() {
    const initialValue = {product_id: '' , quantity: 1 };
    const{slug} = useParams();
    const [loading , setLoading] = useState(true)
    const [cartLoading , setCartLoading] =  useState(false);
    const [product , setProduct] = useState('');
    const [addProduct , setAddProduct]= useState(initialValue);
    const [ loggedUserId, setLoggedUserId ] = useState(false);
    const [ isLightBoxOpen , setIsLightBoxOpen] = useState(false);
    const [postMedia , setPostMedia] =  useState('');
    const [hideSideBar , setHideSideBar] = useState(false) ;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const apiResult = useSelector(state => state.product);
    const cartResult = useSelector(state => state.cart);
    const auth = useSelector(state => state.auth);

    useEffect(()=>{
        if (auth.loggedIn) {
            let token = decodedLoggedUser();
            setLoggedUserId(token.aud)
        }
        dispatch(getSingleProduct(slug));
    },[])

    useEffect(()=>{
        if(apiResult && apiResult.singleProduct){
            setProduct( apiResult.singleProduct.product)
            setLoading(false)
        }
        if(apiResult && apiResult.singleProductError){
            toast.error(apiResult.singleProductError , {
                position: 'top-center', 
            });
            setLoading(false)
        }
    },[apiResult])

    useEffect(()=>{
        if(cartResult && cartResult.addedToCart){
            toast.success(cartResult.addedToCart.message , {
                position: 'top-center', 
            });
            dispatch(getCartItems())
            navigate('/cart')
        }
        if(cartResult && cartResult.addedToCartError){
            toast.error(cartResult.addedToCartError , {
                position: 'top-center', 
            });

        }

        if(cartResult && cartResult.cartError){
            toast.error(cartResult.cartError , {
                position: 'top-center', 
            });

        }
        setCartLoading(false)
    },[cartResult])

    const sanitizedData = (data) => ({
        __html: DOMPurify.sanitize(data)
    })

    const renderProduct = () =>{
       
        if(!check.includes(product)){
            if(product.marketplaceProductMeta &&  product.marketplaceProductMeta.length > 0 ){
                return (
                    <>
                        <Col sm={12} lg={6}>
                            <div className='product--images'>
                                <Carousel cols={1} rows={1} gap={10}>
                                    {product.marketplaceProductMeta.map((item , idx)=>{
                                        return (
                                            <Carousel.Item key={idx}>
                                                <img
                                                    src={item.meta_value} 
                                                    alt={product.title}  
                                                    style={{
                                                        width: '100%',
                                                        height: '500px',
                                                        objectFit: 'contain',
                                                        borderRadius: '8px',
                                                    }}
                                                />
                                            </Carousel.Item>
                                        )
                                    })}
                                </Carousel> 
                                <span className='expand--Lightbox' onClick={handleLightBox}>
                                    <FaExpand/>
                                </span> 
                            </div>
                        </Col>
                    </>
                )
            }    
        }
    }

    const handleQuantity = (e) =>{
        let copyAddProduct = {...addProduct} ;
        copyAddProduct.quantity = e.target.value ; 
        setAddProduct(copyAddProduct)
    }


    const handleAddToCart = (e)=>{
        e.preventDefault();
        setCartLoading(true)
        let copyAddProduct = {...addProduct};
        copyAddProduct.product_id = product.id
        dispatch(addToCart(copyAddProduct));
    }

    const handleCloseLightBox = ()=>{
        setIsLightBoxOpen(false)
        setHideSideBar(false)
    }


    const handleLightBox = () =>{
        if (product){
            if(product.marketplaceProductMeta && product.marketplaceProductMeta.length > 0){
                let images = product.marketplaceProductMeta.map(item => { return { src: item.meta_value , alt:product.title}});
                setPostMedia(images)
                setIsLightBoxOpen(true)
                setHideSideBar(true)
            }
        }
    
    }
    return (
        <>
            {isLightBoxOpen && 
                <LightboxComponent
                    isOpen={isLightBoxOpen}
                    images={postMedia}
                    onClose={() => handleCloseLightBox()}
                />
            }
            <PageHeader />
            <div className='page--content'>
                {!hideSideBar &&
                    <SidebarMenu />
                }
                <div className='discover--module common--wrapper product--single--page'>
                    <Container fluid>
                        <Row>
                            <Col sm={12}>
                                <Link className='back--btn' to='/marketplace'><BsArrowLeft /> Back</Link>
                            </Col>
                            {!loading ?
                                !check.includes(product) ?
                                    <>
                                        {renderProduct()}
                                        <Col sm={12} lg={6}>
                                            <div className='product--title'>
                                                <h1>{product.title}</h1>
                                                {Number(product.price) === 0 ? <h3> Free </h3> :  <h3>Price: ${product.price }</h3> }
                                                <p>{product.short_description ? product.short_description : ''}</p>
                                                <>
                                                    {product.stock !== '0' ?
                                                        <Form>
                                                            <Form.Group className='form-group'>
                                                                <Form.Label>Quantity</Form.Label>
                                                                <Form.Control 
                                                                    value={addProduct.quantity || '' } 
                                                                    onChange= {( !loggedUserId || parseInt(loggedUserId) === parseInt(product.user_id)) ? null : handleQuantity} 
                                                                    as="select"
                                                                    disabled={ (!loggedUserId || parseInt(loggedUserId) === parseInt(product.user_id))}
                                                                >
                                                                    {[...Array(Number(product.stock))].map((x, i) => {
                                                                        return <option key={i} value={i + 1} >{i + 1}</option>
                                                                    })}
                                                                </Form.Control>
                                                                <Button 
                                                                    disabled={(!loggedUserId || parseInt(loggedUserId) === parseInt(product.user_id))} 
                                                                    variant='secondary' 
                                                                    type='submit'
                                                                    onClick={(e) => ( !loggedUserId || parseInt(loggedUserId) === parseInt(product.user_id)) ? null : handleAddToCart(e)}
                                                                >
                                                                    { cartLoading ? 'Please Wait... ' : 'Add to Cart'}
                                                                </Button>
                                                               {parseInt(loggedUserId) === parseInt(product.user_id) && <p className='error mt-2'>You Can't Purchase your own product</p>}
                                                            </Form.Group>
                                                        </Form>
                                                    :
                                                        <span className="text-danger">Out Of Stock</span>
                                                    }          
                                                </>
                                            </div>
                                        </Col>
                                        <Col sm={12} className='mt-4 product--description'>
                                            <h3>Description</h3>
                                                <div className='dataproduct'>
                                                    {product.short_description}
                                                </div>
                                                <div className='mt-2' dangerouslySetInnerHTML={{ __html: product.description }}></div>
                                        </Col>
                                    </>
                                :  null
                            :
                                <Col sm={12}>
                                    <div className='text-center spinner--loader'></div>
                                </Col>    
                            }   
                        </Row>         
                    </Container>
                    <Toaster/>
                </div>
            </div>
        </>
    );
}

export default SingleProduct;