import { get } from 'lodash';
import API from '../../helpers/api';
import * as auth from '../../helpers/auth';
import {
    GET_PRODUCTS_LIST_SUCCESS,
    GET_PRODUCTS_LIST_FAILED,
    GET_SHOP_PRODUCTS_SUCCESS,
    GET_SHOP_PRODUCTS_FAILED,
    GET_SINGLE_PRODUCTS_SUCCESS,
    GET_SINGLE_PRODUCTS_FAILED,
    CREATE_PRODUCT_SUCCESS,
    CREATE_PRODUCT_FAILED,
    EDIT_PRODUCT_SUCCESS,
    EDIT_PRODUCT_FAILED,
    EDIT_PRODUCT_IMAGES_SUCCESS,
    EDIT_PRODUCT_IMAGES_FAILED,
    PRODUCTS_COMMON_ERROR,
    CHANGE_PRODUCT_STATUS_SUCCESS,
    CHANGE_PRODUCT_STATUS_FAILED,
    USER_PRODUCTS_LIST,
    USER_PRODUCTS_LIST_ERROR,
} from "./types";

const config = {
    headers: {
        "Content-Type": "multipart/form-data; charset=utf-8;"
    }
}

function errorRequest(err, dispatch) {
    let data = get(err, 'response.data', null);
    data = data || get(err, 'response');
    data = data || err;
    if (data.error) {
      dispatch({
        type: PRODUCTS_COMMON_ERROR,
        payload: data.error.message,
      });
    } else if (data.status === 401) {   
      auth.logout()
    } else {
      dispatch({
        type: PRODUCTS_COMMON_ERROR,
        payload: data.message,
      });
    }
}


export const getProductsList = () =>{
    return async (dispatch) => {
        try{
            const response = await API.apiGetUrl('marketplace', '/all-products-list') ;
            if(response.data && response.data.success){
                await dispatch({ type: GET_PRODUCTS_LIST_SUCCESS, payload: response.data});
            }else{
                await dispatch({ type: GET_PRODUCTS_LIST_FAILED, payload: response.data});
            }
        }catch(error){
            errorRequest(error, dispatch);
        }
    }
}


/*****************  using for get all the products of a shop  ***************************************/
export const getSingleShopProducts = (slug) =>{
    return async (dispatch) => {
        try{
            const response = await API.apiGetUrl('marketplace', '/shop-products' , `/${slug}`) ;
            if(response.data && response.data.success){
                await dispatch({ type: GET_SHOP_PRODUCTS_SUCCESS, payload: response.data});
            }else{
                await dispatch({ type: GET_SHOP_PRODUCTS_FAILED, payload: response.data});
            }
        }catch(error){
            errorRequest(error, dispatch);
        }
    }
}


/*********************   using for get only one product  ****************************************/
export const getSingleProduct = (slug) =>{
    return async (dispatch) => {
        try{
            const response = await API.apiGetUrl('marketplace', '/get-single-product' , `/${slug}`) ;
            if(response.data && response.data.success){
                await dispatch({ type: GET_SINGLE_PRODUCTS_SUCCESS, payload: response.data});
            }else{
                await dispatch({ type: GET_SINGLE_PRODUCTS_FAILED, payload: response.data});
            }
        }catch(error){
            errorRequest(error, dispatch);
        }
    }
}

/*******************************************************************************************************/

export const createProduct = (payload) =>{
    return async (dispatch) => {
        try{
            const response = await API.apiUploadFileURL('marketplace', '/create-product' , payload , config) ;
            if(response.data && response.data.success){
                await dispatch({ type: CREATE_PRODUCT_SUCCESS, payload: response.data});
            }else{
                await dispatch({ type: CREATE_PRODUCT_FAILED, payload: response.data});
            }
        }catch(error){
          errorRequest(error, dispatch);
        }
    }
}


export const editProduct = (payload)=>{
    return async (dispatch) => {
        try{
            const response = await API.apiPutUrl('marketplace', '/edit-product' , payload) ;
            if(response.data && response.data.success){
                await dispatch({ type: EDIT_PRODUCT_SUCCESS, payload: response.data});
            }else{
                await dispatch({ type: EDIT_PRODUCT_FAILED, payload: response.data});
            }
        }catch(error){
        errorRequest(error, dispatch);
        }
    } 
}

export const editProductImages = (payload)=>{
    return async (dispatch) => {
        try{
            const response = await API.apiUpdateUploadFile('marketplace', '/edit-product-images' , payload , config) ;
            if(response.data && response.data.success){
              await dispatch({ type: EDIT_PRODUCT_IMAGES_SUCCESS, payload: response.data});
            }else{
              await dispatch({ type: EDIT_PRODUCT_IMAGES_FAILED, payload: response.data});
            }
        }catch(error){
            errorRequest(error, dispatch);
        }
    }
}


export const ChangeProductStatus = (payload) =>{
    return async (dispatch) => {
        try{
            const response = await API.apiPutUrl('marketplace', '/change-product-status' , payload) ;
            if(response.data && response.data.success){
                await dispatch({ type: CHANGE_PRODUCT_STATUS_SUCCESS, payload: response.data});
            }else{
                await dispatch({ type: CHANGE_PRODUCT_STATUS_FAILED, payload: response.data});
            }
        }catch(error){
        errorRequest(error, dispatch);
        }
    } 
}

export const getUserProductsList = (userId) =>{
    return async (dispatch) => {
        try{
            const response = await API.apiGetUrl('marketplace', '/all-user-products' , `/${userId}`) ;
            if(response.data && response.data.success){
                await dispatch({ type: USER_PRODUCTS_LIST, payload: response.data});
            }else{
                await dispatch({ type: USER_PRODUCTS_LIST_ERROR, payload: response.data});
            }
        }catch(error){
            errorRequest(error, dispatch);
        }
    }

}
 
